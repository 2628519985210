import { fetchBaseQuery } from '@rtk-incubator/rtk-query'
/* -------------------------------------------------------------------------- */

const BASE_URL = process.env.REACT_APP_BACKEND

export function getBaseQuery(path: string) {
  return fetchBaseQuery({
    baseUrl: `${BASE_URL}/v1/admin/${path}`,
    credentials: 'include',
  })
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Assessment as AssessmentIcon } from '@material-ui/icons'
import * as PropTypes from 'prop-types'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from 'components/Sales/SalesPerYear/SalesPerYearColumns'
import options from 'components/Sales/SalesPerYear/tableOptions'
import { balanceItemsArr } from 'util/custom'
/* -------------------------------------------------------------------------- */

const SalesPerYearTableCard = ({ title, data }) => {
  const { isGettingSales } = useSelector((state) => state.sales)
  const {
    t,
    i18n: { language: localeCode },
  } = useTranslation()

  return (
    <BasicCardLoadable title={title} isLoading={isGettingSales} action={<AssessmentIcon />}>
      <MuiDataTableThemed data={balanceItemsArr(t)} columns={columns({ data, t, localeCode })} options={options()} />
    </BasicCardLoadable>
  )
}

SalesPerYearTableCard.propTypes = {
  data: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
}

export default SalesPerYearTableCard

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { get } from 'lodash'
import { NoteAdd as NoteAddIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import LangAdornmentTextField from 'fragments/Forms/Formik/LangAdornmentTextField'
import { localeCodes } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const OfferAddInfoCard = (props) => {
  const offer = useSelector((state) => state.offer)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { isCreate, isEdit } = props

  const isGettingOfferByUuid = offer.isGettingOfferByUuid
  const localizedInformation = offer.offer?.localizedInformation ?? null

  const infoInput = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <LangAdornmentTextField name='localizedInformation.de' variant='standard' language={localeCodes?.de} />
      </Grid>
      <Grid item xs={12}>
        <LangAdornmentTextField name='localizedInformation.en' variant='standard' language={localeCodes?.en} />
      </Grid>
    </Grid>
  )

  const rows = [
    {
      label: t('OfferAddInfoCard.description'),
      value: isCreate || isEdit ? infoInput() : get(localizedInformation, language, null),
    },
  ]

  return (
    <BasicCardLoadable title={t('OfferAddInfoCard.title')} action={<NoteAddIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}
/* -------------------------------------------------------------------------- */
OfferAddInfoCard.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

OfferAddInfoCard.defaultProps = {
  isCreate: false,
  isEdit: false,
}

export default OfferAddInfoCard

import { get } from 'lodash'

import { GET_OFFERS, RESET_OFFERS, RESET_OFFERS_FILTERS } from 'redux/Types'
import { unpackOffers } from 'api/offerMgmt/offerMgmtUnpackers'
import { BODY_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  offers: [],
  isGettingOffers: false,
  isReGettingOffers: false,
  errGettingOffers: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  lastSuccessDate: null,
  pagination: {
    totalCount: 0,
    page: 0,
    rows: 10,
  },
  filters: {
    offerType: null,
    isVisible: null,
    carParkUuids: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_OFFERS.REQUEST: {
      const { page, rows, isReload, offerType, isVisible, carParkUuids } = action
      return {
        ...state,
        isGettingOffers: !isReload,
        isReGettingOffers: !!isReload,
        errGettingOffers: INIT_STATE.errGettingOffers,
        pagination: {
          ...state.pagination,
          page: Number.isInteger(page) ? page : state.pagination.page,
          rows: Number.isInteger(rows) ? rows : state.pagination.rows,
        },
        filters: {
          offerType: offerType || state.filters.offerType,
          isVisible: isVisible ?? state.filters.isVisible,
          carParkUuids: carParkUuids || state.filters.carParkUuids,
        },
      }
    }
    case GET_OFFERS.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR: OFFERS, TOTAL_ELEMENTS, PAGE_NUMBER, PAGE_SIZE } = BODY_PAGINATION
      const offers = data[OFFERS]
      return {
        ...state,
        offers: unpackOffers(offers),
        isGettingOffers: false,
        isReGettingOffers: false,
        lastSuccessDate: new Date(),
        pagination: {
          totalCount: get(data, TOTAL_ELEMENTS, state.pagination.totalCount),
          page: get(data, PAGE_NUMBER, state.pagination.page),
          rows: get(data, PAGE_SIZE, state.pagination.rows),
        },
      }
    }
    case GET_OFFERS.ERROR: {
      const { error } = action
      return {
        ...state,
        offers: INIT_STATE.offers,
        isGettingOffers: false,
        isReGettingOffers: false,
        errGettingOffers: error,
        pagination: INIT_STATE.pagination,
      }
    }
    case RESET_OFFERS_FILTERS: {
      return {
        ...state,
        filters: INIT_STATE.filters,
      }
    }
    case RESET_OFFERS: {
      return {
        ...state,
        offers: INIT_STATE.offers,
      }
    }
    default:
      return { ...state }
  }
}

const chartColors = {
  default: '#657786',
  axesColor: '#657786',
  legendFontColor: '#AAAEB3',
  chartGridColor: '#EAEAEA',
  shadowColor: 'rgba(0,0,0,0.6)',
  dataStackOneSetOne: '#26a1f1',
  dataStackOneSetTwo: '#e6007e',
  dataStackTwoSetOne: '#A5A7B2',
  dataStackTwoSetTwo: '#e6007e',
  white: '#FFFFFF',
  pink: '#e6007e',
  greyLighten: '#A5A7B2',
  primary: '#26a1f1',
}

/* ----- BAR CHART----------------------------------------------------------- */

export const barChartOptions = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: chartColors.white,
        },
        ticks: {
          fontColor: chartColors.axesColor,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        gridLines: {
          color: chartColors.white,
        },
        ticks: {
          fontColor: chartColors.axesColor,
          beginAtZero: true,
        },
      },
    ],
  },
  maintainAspectRatio: true,
}

export const getDataWithConfig = (stackNumber, setNumber, label, data) => {
  let color = chartColors.default
  if (stackNumber === 1) {
    if (setNumber === 1) {
      color = chartColors.dataStackOneSetOne
    } else if (setNumber === 2) {
      color = chartColors.dataStackOneSetTwo
    }
  } else if (stackNumber === 2) {
    if (setNumber === 1) {
      color = color = chartColors.dataStackTwoSetOne
    } else if (setNumber === 2) {
      color = color = chartColors.dataStackTwoSetTwo
    }
  }
  return {
    label: label,
    backgroundColor: color,
    borderColor: color,
    borderWidth: 1,
    hoverBackgroundColor: color,
    hoverBorderColor: color,
    data,
    stack: stackNumber,
  }
}

/* ----- LINE CHART --------------------------------------------------------- */

export const lineChartOptions = {
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: chartColors.white,
        },
        ticks: {
          fontColor: chartColors.axesColor,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: chartColors.white,
        },
        ticks: {
          fontColor: chartColors.axesColor,
          beginAtZero: true,
          max: 100,
        },
      },
    ],
  },
  maintainAspectRatio: true,
}

export const getLineChartDataWithConfig = (data) => {
  return {
    fill: false,
    lineTension: 0.1,
    borderColor: chartColors.primary, // line color
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: chartColors.primary,
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: chartColors.pink,
    pointHoverBorderColor: chartColors.greyLighten,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data,
  }
}

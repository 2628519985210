import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from 'components/AppUser/BarrierConnections/BarrierConnectionsTableColumns'
import { muiDataTableOptions as options } from 'fragments/tables/TableConfig'
/* -------------------------------------------------------------------------- */

const BarrierConnectionsTable = () => {
  const {
    isGettingAppUser,
    appUser: { barrierConnections },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()

  return (
    <BasicCardLoadable isLoading={isGettingAppUser}>
      <MuiDataTableThemed data={barrierConnections || []} columns={columns(t)} options={options()} />
    </BasicCardLoadable>
  )
}

export default BarrierConnectionsTable

/**
 * Helpers for unit conversions
 * @namespace unit
 */

/**
 * Convert cents to euro and format output
 * @memberof unit
 * @function
 * @param   {number} cents - cents to be converted to euros
 * @param {string} localeCode - language code, e.g. 'en'
 * @return  {number} - formatted amount in euro
 */
export const centsToEuroLocalized = (cents, localeCode) => {
  const euros = Number.isInteger(cents) ? (cents / 100).toFixed(1) : 0
  return new Intl.NumberFormat(localeCode, { style: 'currency', currency: 'EUR' }).format(euros)
}

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const options = ({ appUsers, history }) => ({
  onRowClick: (_rowData, { dataIndex }) => {
    const appUserUuid = appUsers?.[dataIndex]?.uuid ?? null
    history.push({
      pathname: APP.APP_USER,
      search: getQueryString({ [QUERY_PARAMS.UUID]: appUserUuid }),
    })
  },
})

export default (props) => ({
  ...muiDataTableOptions(),
  ...options(props),
})

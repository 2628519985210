import axiosInstance from 'api/axiosConfig'
import { PATH_SEGMENTS_STATISTICS, PARAMS_STATISTICS } from 'api/statisticsMgmt/statisticsMgmtModels'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

/**
 * Api functions for statistics
 * @namespace statisticsMgmtApi
 */

/**
 * Get general statistics
 * @function
 * @memberof statisticsMgmtApi
 * @returns {Object}
 */
export const getGeneralStatisticsReq = (args) => {
  const qs = getQueryString(args)
  const url = `${PATH_SEGMENTS_STATISTICS.GENERAL_STATISTICS}${qs}`
  return axiosInstance.get(url).then((res) => {
    const { data: barrier } = res
    return barrier
  })
}

/**
 * Get statistics
 * @function
 * @memberof statisticsMgmtApi
 * @returns {Object[]}
 */
export const getStatisticsReq = ({ carParkUuids, date, interval, operatorUuid, page, rows }) => {
  const { CAR_PARK_UUIDS, DATE, INTERVAL, OPERATOR_UUID, PAGE_NUMBER, PAGE_SIZE } = PARAMS_STATISTICS
  const qs = getQueryString({
    [CAR_PARK_UUIDS]: carParkUuids,
    [DATE]: date,
    [INTERVAL]: interval,
    [OPERATOR_UUID]: operatorUuid,
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
  })
  const url = `${PATH_SEGMENTS_STATISTICS.STATISTICS}${qs}`
  return axiosInstance.get(url).then((res) => {
    const { data: statistics } = res
    return statistics
  })
}

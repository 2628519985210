import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import OfferCreateForm from 'components/Offer/Create/OfferCreateForm'
/* -------------------------------------------------------------------------- */

const OfferCreateContainer = () => {
  const { t } = useTranslation()
  const errCreatingOffer = useSelector((state) => state.offer?.errCreatingOffer)

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('OfferCreateContainer.title')}
      pageTitle={t('OfferCreateContainer.title')}
    >
      <ErrorAlertContainer errors={[errCreatingOffer]} />
      <OfferCreateForm />
    </BasicPageContainerLoadable>
  )
}

/* -------------------------------------------------------------------------- */

export default OfferCreateContainer

import { ADMIN_API_BASE_PATH, PARAMS_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_STATISTICS = {
  STATISTICS: `${ADMIN_API_BASE_PATH}/statistics`,
  GENERAL_STATISTICS: `${ADMIN_API_BASE_PATH}/general-statistics`,
}

// ----- STATISTICS / OCCUPANCY -----

export const PARAMS_STATISTICS = {
  CAR_PARK_UUIDS: 'carParkUuid',
  DATE: 'date',
  INTERVAL: 'interval',
  OPERATOR_UUID: 'operatorUuid',
  ...PARAMS_PAGINATION,
}

export const BODY_STATISTICS = {
  CAR_PARK_NAME: 'carParkName',
  PARKING_LOTS: 'carParkParkingLots',
  CAR_PARK_UUID: 'carParkUuid',
  STATISTICS_VALUES_OBJ: 'statisticsValues',
  AVERAGE_PROCESS_TIME: 'averageProcessTimeInMs',
  AVERAGE_USER_INSIDE: 'averageUsersInside',
  ENTRIES_APP: 'entriesApp',
  ENTRIES_TOTAL: 'entriesTotal',
  EXITS_APP: 'exitsApp',
  EXITS_TOTAL: 'exitsTotal',
  INDEX: 'index',
}

export const STATISTICS_INTERVAL_VALUES = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

// ----- GENERAL STATISTICS -----

export const BODY_GENERAL_STATISTICS = {
  APP_USERS_COUNT_OBJ: 'appUserRegistrations',
  CAR_PARKS_COUNT_OBJ: 'carParks',
  TICKETS_COUNT_OBJ: 'tickets',
  CURRENT_DAY: 'currentDay',
  CURRENT_MONTH: 'currentMonth',
  CURRENT_YEAR: 'currentYear',
  TOTAL: 'total',
}

import { call, put, takeLatest, all, fork } from 'redux-saga/effects'

import { GET_OPERATOR_BY_UUID, CREATE_OPERATOR, EDIT_OPERATOR } from 'redux/Types'
import {
  getOperatorByUuidSuccessAct,
  getOperatorByUuidErrorAct,
  catchSagaErrorsAct,
  createOperatorSuccessAct,
  createOperatorErrorAct,
  editOperatorSuccessAct,
  editOperatorErrorAct,
} from 'redux/Actions'
import { getOperatorByUuidReq, createOperatorReq, editOperatorReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

// ----- LOAD -----

function* workerGetOperatorByUuid({ uuid }) {
  try {
    const response = yield call(getOperatorByUuidReq, uuid)
    yield put(getOperatorByUuidSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getOperatorByUuidErrorAct))
  }
}

function* watcherGetOperatorByUuid() {
  yield takeLatest(GET_OPERATOR_BY_UUID.REQUEST, workerGetOperatorByUuid)
}

// ----- CREATE -----

function* workerCreateOperator(action) {
  const { operatorCreatable } = action
  try {
    const response = yield call(createOperatorReq, operatorCreatable)
    yield put(createOperatorSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, createOperatorErrorAct))
  }
}

function* watcherCreateOperator() {
  yield takeLatest(CREATE_OPERATOR.REQUEST, workerCreateOperator)
}

// ----- EDIT -----

function* workerEditOperator(action) {
  try {
    const { operatorUpdatable, uuid } = action
    const response = yield call(editOperatorReq, uuid, operatorUpdatable)
    yield put(editOperatorSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editOperatorErrorAct))
  }
}

function* watcherEditOperator() {
  yield takeLatest(EDIT_OPERATOR.REQUEST, workerEditOperator)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetOperatorByUuid), fork(watcherCreateOperator), fork(watcherEditOperator)])
}

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'

import PageGrid from 'fragments/Grid/PageGrid'
import OperatorGrid from 'components/Operator/OperatorGrid'
import { createOperatorAct, resetCreateOperatorAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getRequiredInfo } from 'util/core'
import { validationSchema, initialValues } from 'components/Operator/Create/operatorCreate'
import { SaveButton, CancelButton } from 'fragments/Buttons'
/* -------------------------------------------------------------------------- */

const OperatorCreateForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    operator: { uuid },
    createOperatorSuccess,
  } = useSelector((state) => state.operator)

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  useEffect(() => {
    if (createOperatorSuccess) {
      history.push({
        pathname: APP.OPERATOR,
        search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
      })
    }
    return () => {
      if (createOperatorSuccess) {
        dispatch(resetCreateOperatorAct())
      }
    }
  }, [createOperatorSuccess, dispatch, history, uuid])

  const handleSubmit = (values) => dispatch(createOperatorAct(values))

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={validationSchema(t)}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const { isValidating, isSubmitting } = formikProps
        return (
          <Form onKeyDown={onKeyDown}>
            <PageGrid justify='space-between'>
              <OperatorGrid isCreate />
              <Grid container justify='space-between' item>
                <Grid item>{getRequiredInfo(t)}</Grid>
                <Grid item xs>
                  <Grid container spacing={2} justify='flex-end'>
                    <Grid item>
                      <CancelButton component={Link} to={APP.OPERATORS} />
                    </Grid>
                    <Grid item>
                      <SaveButton disabled={isValidating || isSubmitting} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageGrid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OperatorCreateForm

import qs from 'query-string'
import { get } from 'lodash'
/* -------------------------------------------------------------------------- */

export const getParsedQueryParam = (search, key, options = { parseNumbers: false }) => {
  const queryObj = qs.parse(search, { parseBooleans: true, parseNumbers: options?.parseNumbers })
  return get(queryObj, key, null)
}

export const getQueryString = (params) => {
  const queryString = qs.stringify(params, { skipNull: true })
  return queryString ? `?${queryString}` : ''
}

import { History } from 'history'
import { TFunction } from 'i18next'

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
import { Ticket } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type Options = {
  tickets: Ticket[]
  onChangePage?: (n: number) => void
  onChangeRowsPerPage?: (n: number) => void
  history: History
  page?: number
  rows?: number
  t: TFunction
  totalCount?: number
  search?: string
  onSearchChange?: (s: string) => void
}

const options = ({
  history,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rows,
  t,
  tickets,
  totalCount,
  onSearchChange,
  search,
}: Options) => ({
  count: totalCount,
  onCellClick: (_: unknown, cellMeta: { colIndex: number; dataIndex: number }) => {
    const { colIndex, dataIndex } = cellMeta

    if (colIndex !== 1) {
      const publicId = tickets?.[dataIndex]?.publicId

      if (publicId) {
        history.push({
          pathname: APP.TICKET,
          search: getQueryString({
            [QUERY_PARAMS.PUBLIC_ID]: publicId,
          }),
        })
      }
    }
  },
  onChangePage,
  onChangeRowsPerPage,
  onSearchChange,
  page,
  rowsPerPage: rows,
  search: true,
  searchText: search,
  searchPlaceholder: t('ticketsTableOptions.searchPlaceholder'),
  serverSide: true,
})

export default (args: Options) => ({
  ...muiDataTableOptions(),
  ...options(args),
})

import { GET_BARRIERS, RESET_BARRIERS_FILTERS, RESET_BARRIERS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getBarriersAct = (params) => ({
  type: GET_BARRIERS.REQUEST,
  isReload: params?.isReload,
  page: params?.page,
  rows: params?.rows,
  operatingState: params?.operatingState,
  onlineState: params?.onlineState,
})

export const getBarriersSuccessAct = (data) => ({
  type: GET_BARRIERS.SUCCESS,
  data,
})

export const getBarriersErrorAct = (error) => ({
  type: GET_BARRIERS.ERROR,
  error,
})

export const resetBarriersFiltersAct = () => ({
  type: RESET_BARRIERS_FILTERS,
})

export const resetBarriersAct = () => ({
  type: RESET_BARRIERS,
})

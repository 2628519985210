import { muiDataTableOptions } from 'fragments/tables/TableConfig'
/* -------------------------------------------------------------------------- */

const options = {
  pagination: false,
}

export default () => ({
  ...muiDataTableOptions(true),
  ...options,
})

import { GET_OCCUPANCY, RESET_OCCUPANCY_FILTERS } from 'redux/Types'
import { BODY_PAGINATION } from 'api/backendConstants'
import { unpackStatistics } from 'api/statisticsMgmt/statisticsMgmtUnpackers'
import { STATS_VIEW_VALUES } from 'util/custom'
import { getTimeIntervalValue } from 'util/core'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  errGettingOccupancy: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  filters: {
    date: new Date(),
    interval: getTimeIntervalValue('WEEK'),
    view: STATS_VIEW_VALUES.OPENINGS,
  },
  isGettingOccupancy: false,
  occupancy: [],
  pagination: {
    page: 0,
    rows: 10,
    totalCount: 0,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action

  switch (type) {
    case GET_OCCUPANCY.REQUEST: {
      const { date, interval, view, page, rows } = action
      if (view) {
        return {
          ...state,
          filters: {
            ...state.filters,
            view: view ?? state.filters.view,
          },
        }
      }
      return {
        ...state,
        isGettingOccupancy: true,
        errGettingOccupancy: INIT_STATE.errGettingOccupancy,
        pagination: {
          ...state.pagination,
          page: page ?? state.pagination.page,
          rows: rows ?? state.pagination.rows,
        },
        filters: {
          ...state.filters,
          date: date ?? state.filters.date,
          interval: interval ?? state.filters.interval,
        },
      }
    }
    case GET_OCCUPANCY.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR, TOTAL_ELEMENTS, PAGE_NUMBER, PAGE_SIZE } = BODY_PAGINATION
      return {
        ...state,
        isGettingOccupancy: false,
        occupancy: unpackStatistics(data[CONTENT_ARR]),
        pagination: {
          totalCount: data?.[TOTAL_ELEMENTS] ?? state.pagination.totalCount,
          page: data?.[PAGE_NUMBER] ?? state.pagination.page,
          rows: (data?.[PAGE_SIZE] ?? state.pagination.rows) || 10,
        },
      }
    }
    case GET_OCCUPANCY.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingOccupancy: false,
        errGettingOccupancy: error,
      }
    }
    case RESET_OCCUPANCY_FILTERS: {
      return {
        ...state,
        filters: INIT_STATE.filters,
      }
    }
    default:
      return { ...state }
  }
}

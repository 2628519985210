import React from 'react'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

import SelectTextField from 'fragments/Forms/SelectTextField'

/* -------------------------------------------------------------------------- */

const OfferVisibilitySelect = (props) => {
  const { onChange, selected } = props

  const { t } = useTranslation()

  const options = [
    { label: t('OfferVisibilitySelect.visible'), value: true },
    { label: t('OfferVisibilitySelect.invisible'), value: false },
  ]

  return (
    <SelectTextField
      label={t('OfferVisibilitySelect.label')}
      variant='outlined'
      selected={selected}
      options={options}
      onChange={onChange}
    />
  )
}

/* -------------------------------------------------------------------------- */
OfferVisibilitySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
}

OfferVisibilitySelect.defaultProps = {
  selected: null,
}

export default OfferVisibilitySelect

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { Info as InfoIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import OfferTypeChip from 'fragments/IconChips/OfferTypeChip'
import { getOfferName, getOfferTypeSelects } from 'util/custom'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import LangAdornmentTextField from 'fragments/Forms/Formik/LangAdornmentTextField'
import CarParksSelect from 'fragments/Forms/Formik/CarParksSelect'
import { localeCodes } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const OfferInfoCard = (props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const {
    isGettingOfferByUuid,
    offer: { carParkName, localizedGeneratedNames, localizedNames, type },
  } = useSelector((state) => state.offer)
  const { isCreate, isEdit } = props

  const carParksSelect = () => <CarParksSelect name='carParkUuid' variant='standard' />

  const typeSelect = () => <SelectTextField name='type' variant='standard' options={getOfferTypeSelects(t)} />

  const nameInputs = () => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <LangAdornmentTextField name='localizedNames.de' variant='standard' language={localeCodes?.de} />
      </Grid>
      <Grid item xs={12}>
        <LangAdornmentTextField name='localizedNames.en' variant='standard' language={localeCodes?.en} />
      </Grid>
    </Grid>
  )

  const rows = [
    {
      label: t('OfferInfoCard.carPark') + (isCreate ? ' *' : ''),
      value: isCreate ? carParksSelect() : carParkName,
    },
    {
      label: t('OfferInfoCard.type') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? typeSelect() : <OfferTypeChip type={type} />,
    },
    {
      label: t('OfferInfoCard.name'),
      value:
        isCreate || isEdit
          ? nameInputs()
          : getOfferName({
              localizedNames,
              localizedGeneratedNames,
              language,
            }),
    },
  ]

  return (
    <BasicCardLoadable title={t('OfferInfoCard.title')} action={<InfoIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

/* -------------------------------------------------------------------------- */

OfferInfoCard.propTypes = {
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

OfferInfoCard.defaultProps = {
  isCreate: false,
  isEdit: false,
}

export default OfferInfoCard

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

import SelectTextField from 'fragments/Forms/SelectTextField'
import { getTimeIntervalSelects } from 'util/core'
/* -------------------------------------------------------------------------- */

const TimeIntervalSelect = (props) => {
  const { onChange, selected } = props

  const { t } = useTranslation()

  return (
    <SelectTextField
      label={t('TimeIntervalSelect.label')}
      variant='outlined'
      selected={selected}
      options={getTimeIntervalSelects(t)}
      onChange={onChange}
    />
  )
}

/* -------------------------------------------------------------------------- */
TimeIntervalSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
}

TimeIntervalSelect.defaultProps = {
  selected: null,
}

export default TimeIntervalSelect

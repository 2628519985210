import { GET_CAR_PARKS } from 'redux/Types'
import { BODY_PAGINATION } from 'api/backendConstants'
import { unpackCarParks } from 'api/carParkMgmt/carParkMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  carParks: [],
  errGettingCarParks: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  isGettingCarParks: false,
  lastSuccessDate: new Date(),
  pagination: {
    page: 0,
    rows: 10,
    totalCount: 0,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_CAR_PARKS.REQUEST: {
      const { page, rows } = action
      return {
        ...state,
        isGettingCarParks: true,
        errGettingCarParks: INIT_STATE.errGettingCarParks,
        pagination: {
          ...state.pagination,
          page: page ?? state.pagination.page,
          rows: rows ?? state.pagination.rows,
        },
      }
    }
    case GET_CAR_PARKS.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR: CAR_PARKS, PAGE_NUMBER, PAGE_SIZE, TOTAL_ELEMENTS } = BODY_PAGINATION
      const carParks = data[CAR_PARKS]
      return {
        ...state,
        isGettingCarParks: false,
        carParks: unpackCarParks(carParks),
        lastSuccessDate: new Date(),
        pagination: {
          totalCount: data?.[TOTAL_ELEMENTS] ?? state.pagination.totalCount,
          page: data?.[PAGE_NUMBER] ?? state.pagination.page,
          rows: (data?.[PAGE_SIZE] ?? state.pagination.rows) || 10,
        },
      }
    }
    case GET_CAR_PARKS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingCarParks: false,
        errGettingCarParks: error,
        pagination: INIT_STATE.pagination,
      }
    }
    default:
      return { ...state }
  }
}

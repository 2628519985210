import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@material-ui/core'
import { Language as LanguageIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import LanguageSelect from 'fragments/Forms/Custom/LanguageSelect'
import { changeMyProfileAct } from 'redux/Actions'
import { isPending } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const LanguageCard = () => {
  const statusChangingProfile = useSelector((state) => state.auth.statusChangingProfile)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <BasicCardLoadable
      title={t('LanguageCard.title')}
      action={<LanguageIcon />}
      isLoading={isPending(statusChangingProfile)}
    >
      <FormControl fullWidth>
        <LanguageSelect onChange={(v) => dispatch(changeMyProfileAct({ languageCode: v }))} />
      </FormControl>
    </BasicCardLoadable>
  )
}

export default LanguageCard

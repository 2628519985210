import get from 'lodash/get'
/* -------------------------------------------------------------------------- */

/**
 * Check if form has changed values
 * @function
 * @memberof form
 * @param  {Object} form   Object containing all fields of a form with the shape:
 * {
 *    key: {
 *      value: ...,
 *      error: ...,
 *    }, ...
 * }
 * @return {Boolean}  Returns true if any obj representating a form field has
 * a value that is not null or undefined
 */
export const hasChangedValues = (form) =>
  Object.values(form)
    .map((obj) => !!get(obj, 'value'))
    .includes(true)

/**
 * Check if form has errors
 * @function
 * @memberof form
 * @param  {Object} form   Object containing all fields of a form with the shape:
 * {
 *    key: {
 *      value: ...,
 *      error: ...,
 *    }, ...
 * }
 * @return {Boolean}  Returns true if any obj representating a form field has
 * an error that is not null or undefined
 */
export const hasErrors = (form) =>
  Object.values(form)
    .map((obj) => !!get(obj, 'error'))
    .includes(true)

export const getRequiredInfo = (t) => t('form.required')

import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Payment as PaymentIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const OperatorStripeCard = (props) => {
  const {
    operator: { stripeAccountId },
    isGettingOfferByUuid,
  } = useSelector((state) => state.operator)
  const { t } = useTranslation()
  const { isCreate, isEdit } = props

  const rows = [
    {
      label: t('OperatorStripeCard.stripeConnectedAccount') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? <TextField name='stripeAccountId' /> : stripeAccountId,
    },
  ]

  return (
    <BasicCardLoadable title={t('OperatorStripeCard.title')} action={<PaymentIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

OperatorStripeCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

OperatorStripeCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default OperatorStripeCard

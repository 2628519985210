export { validationSchema } from 'components/Operator/validationSchema'

export const initialValues = (operator) => ({
  email: operator.email ?? '',
  name: operator.name ?? '',
  street: operator.street ?? '',
  houseNumber: operator.houseNumber ?? '',
  postalCode: operator.postalCode ?? '',
  city: operator.city ?? '',
  country: operator.country ?? '',
  phoneNumber: operator.phoneNumber ?? '',
  stripeAccountId: operator.stripeAccountId ?? '',
})

import yup from 'util/yup'
/* -------------------------------------------------------------------------- */

export const validationSchema = (t) =>
  yup.object().shape({
    email: yup.string().trim().required(),
    name: yup.string().trim().required(),
    street: yup.string().trim().required(),
    houseNumber: yup.string().trim().required(),
    postalCode: yup.string().trim().required(),
    city: yup.string().trim().required(),
    phoneNumber: yup.string().trim(),
    stripeAccountId: yup
      .string()
      .matches(/^acct_.+/, t('Operator.validationSchema.mustStartWith', { start: 'acct_' }))
      .trim()
      .required(),
  })

import React from 'react'
import { TFunction } from 'i18next'

import TicketIdCell from 'components/Tickets/TicketIdCell'
import MoneyAmount from 'fragments/Misc/MoneyAmount'
import TicketUsabilityChip from 'components/Tickets/TicketUsabilityChip'
import TicketUsageChip from 'components/Tickets/TicketUsageChip'
import { getOfferTypeAsString } from 'util/custom'
import { Ticket, TicketUsability, TicketUsage } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

function getColumns(data: Ticket[], language: 'en' | 'de', t: TFunction) {
  return [
    {
      name: 'carParkName',
      label: t('TicketsTableColumns.carPark'),
    },
    {
      name: 'publicId',
      label: t('TicketsTableColumns.ticketId'),
      options: {
        customBodyRender: (publicId: string) => <TicketIdCell value={publicId} />,
      },
    },
    {
      name: 'publicId',
      label: t('TicketsTableColumns.ticketName'),
      options: {
        customBodyRender: (publicId: string) => {
          const ticket = data.find((e) => e.publicId === publicId)
          return (
            ticket?.offerVersion?.localizedName?.[language] || ticket?.offerVersion?.localizedGeneratedName?.[language]
          )
        },
      },
    },
    {
      name: 'publicId',
      label: t('TicketsTableColumns.ticketType'),
      options: {
        customBodyRender: (publicId: string) => {
          const ticket = data.find((e) => e.publicId === publicId)
          return getOfferTypeAsString(t, ticket?.offerVersion?.type)
        },
      },
    },
    {
      name: 'totalPriceInCent',
      label: t('TicketsTableColumns.totalPriceInCent'),
      options: {
        customBodyRender: (v: number) => <MoneyAmount valueInCent={v} />,
      },
    },
    {
      name: 'usage',
      label: t('TicketsTableColumns.status'),
      options: {
        customBodyRender: (v: TicketUsage) => <TicketUsageChip value={v} />,
      },
    },
    {
      name: 'usability',
      label: t('TicketsTableColumns.usability'),
      options: {
        customBodyRender: (v: TicketUsability) => <TicketUsabilityChip value={v} />,
      },
    },
  ]
}

export default getColumns

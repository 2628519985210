import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, makeStyles, IconButton, Collapse, Grid, Tooltip, Divider } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons'

import OperatorSelector from 'layouts/AppLayout/Topbar/OperatorSelector'
import CarParksSelector from 'layouts/AppLayout/Topbar/CarParksSelector'
import FilterIconWithBadges from 'layouts/AppLayout/Topbar/FilterIconWithBadges'
import { getMinifiedCarParksAct, getOperatorsAct } from 'redux/Actions'
import { isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  menuText: {
    fontSize: theme.typography.pxToRem(18),
  },
  paper: {
    minWidth: 200,
    borderRadius: 15,
  },
}))

const GlobalFilter = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const { role } = useSelector((state) => state.auth.user)

  useEffect(() => {
    dispatch(
      getMinifiedCarParksAct({
        page: 0,
        rows: 1000,
      }),
    )
  }, [dispatch, selectedOperator])

  useEffect(() => {
    dispatch(getOperatorsAct())
  }, [dispatch])

  // MENU
  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const open = Boolean(anchorElMenu)
  const handleOpenMenu = (event) => setAnchorElMenu(event.currentTarget)
  const handleCloseMenu = () => setAnchorElMenu(null)

  // OPERATOR SELECT
  const [operatorSelectorOpen, setOperatorSelectorOpen] = useState(false)
  const handleToggleOperatorSelector = () => setOperatorSelectorOpen((operatorSelectorOpen) => !operatorSelectorOpen)

  // CAR PARK SELECT
  const [carParksSelectorOpen, setCarParksSelectorOpen] = useState(false)
  const handleToggleCarParksSelector = () => setCarParksSelectorOpen((carParksSelectorOpen) => !carParksSelectorOpen)

  return (
    <>
      <Tooltip title={t('GlobalFilter.globalFilters')} placement='bottom'>
        <IconButton
          aria-label={t('GlobalFilter.globalFilters')}
          aria-controls='global-filter-menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenMenu}
          color='inherit'
        >
          <FilterIconWithBadges />
        </IconButton>
      </Tooltip>
      <Menu
        id='global-filter-menu-appbar'
        open={open}
        onClose={handleCloseMenu}
        classes={{ paper: classes.paper }}
        anchorEl={anchorElMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isTriveParkAdmin(role) && (
          <MenuItem onClick={handleToggleOperatorSelector} className={classes.menuItem}>
            <Grid container justify='space-between' alignItems='center'>
              <span className={classes.menuText}>{t('GlobalFilter.operators')}</span>
              {operatorSelectorOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Grid>
          </MenuItem>
        )}
        {isTriveParkAdmin(role) && (
          <Collapse in={operatorSelectorOpen} timeout='auto' unmountOnExit>
            <OperatorSelector />
          </Collapse>
        )}
        {isTriveParkAdmin(role) && <Divider />}
        <MenuItem onClick={handleToggleCarParksSelector} className={classes.menuItem}>
          <Grid container justify='space-between' alignItems='center'>
            <span className={classes.menuText}>{t('GlobalFilter.carParks')}</span>
            {carParksSelectorOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </MenuItem>
        <Collapse in={carParksSelectorOpen} timeout='auto' unmountOnExit>
          <CarParksSelector />
        </Collapse>
      </Menu>
    </>
  )
}

export default GlobalFilter

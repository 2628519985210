import { getTimeWithBiggestPossibleUnit, getDateByMinFromMidnight } from 'util/core'
/* -------------------------------------------------------------------------- */

export { validationSchema } from 'components/Offer/validationSchema'

export const initialValues = (offer) => {
  const { value: usageDuration, unit: usageUnit } = getTimeWithBiggestPossibleUnit(offer?.durationInMin ?? 0)
  const { value: validityDuration, unit: validityUnit } = getTimeWithBiggestPossibleUnit(offer?.validityInMin ?? 0)

  const accessEarliestInMinFromMidnight = getDateByMinFromMidnight(offer?.accessEarliestInMinFromMidnight ?? null)
  const accessLatestInMinFromMidnight = getDateByMinFromMidnight(offer?.accessLatestInMinFromMidnight ?? null)

  return {
    localizedInformation: {
      de: offer?.localizedInformation?.de ?? '',
      en: offer?.localizedInformation?.en ?? '',
    },
    localizedNames: {
      de: offer?.localizedNames?.de ?? '',
      en: offer?.localizedNames?.en ?? '',
    },
    isVisible: offer?.isVisible ?? true,
    priceInEuro: (offer?.priceInCent ?? 0) / 100,
    validityDuration,
    validityUnit,
    usageDuration,
    usageUnit,
    contingent: offer?.contingent ?? '',
    availableFrom: offer?.availableFrom ?? null,
    availableUntil: offer?.availableUntil ?? null,
    accessEarliestInMinFromMidnight,
    accessLatestInMinFromMidnight,
    type: offer?.type ?? '',
  }
}

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { getOperatorsAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const options = ({ page, rows, operators, dispatch, history }) => ({
  onChangePage: (page) => dispatch(getOperatorsAct({ page })),
  onChangeRowsPerPage: (rows) => dispatch(getOperatorsAct({ rows })),
  onRowClick: (_, { dataIndex }) => {
    const uuid = operators?.[dataIndex]?.uuid
    if (uuid) {
      history.push({
        pathname: APP.OPERATOR,
        search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
      })
    }
  },
  page: page,
  rowsPerPage: rows,
})

export default (args) => ({
  ...muiDataTableOptions(),
  ...options(args),
})

import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { AppBar, Grid, Toolbar, IconButton, Tooltip, makeStyles, Paper } from '@material-ui/core'
import { FirstPage as FirstPageIcon, LastPage as LastPageIcon, Menu as MenuIcon } from '@material-ui/icons'

import UserMenu from 'layouts/AppLayout/Topbar/UserMenu'
import Search from 'layouts/AppLayout/Topbar/Search'
import GlobalFilter from 'layouts/AppLayout/Topbar/GlobalFilter'
import { useDesktop } from 'hooks'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  topbar: {
    background: '#fff',
    color: '#7b7b7b',
    boxShadow: 'none',
  },
  iconButton: {
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  icon: {
    color: '#575756',
  },
}))

const Topbar = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isDesktop = useDesktop()
  const { toggleSidebar, isCollapsed } = props

  return (
    <AppBar position='absolute' className={classes.topbar}>
      <Paper square>
        <Toolbar component={Grid} container>
          <Grid item xs={8} container alignItems='center'>
            <Tooltip title={isCollapsed ? t('Topbar.show') : t('Topbar.hide')} placement='bottom'>
              <IconButton
                edge='start'
                onClick={toggleSidebar}
                color='inherit'
                aria-label='menu'
                className={classes.iconButton}
              >
                {!isDesktop ? (
                  <MenuIcon className={classes.icon} />
                ) : isCollapsed ? (
                  <LastPageIcon className={classes.icon} />
                ) : (
                  <FirstPageIcon className={classes.icon} />
                )}
              </IconButton>
            </Tooltip>
            <Grid item xs={9}>
              <Search />
            </Grid>
          </Grid>
          <Grid item xs={4} container justify='flex-end' alignItems='center'>
            <GlobalFilter />
            <UserMenu />
          </Grid>
        </Toolbar>
      </Paper>
    </AppBar>
  )
}

Topbar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
}

export default Topbar

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Person as PersonIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import AccountStatusChip from 'fragments/IconChips/AccountStatusChip'
import { getRoleString, getCompanyName } from 'util/custom'
/* -------------------------------------------------------------------------- */

const ProfileInfoCard = () => {
  const { t } = useTranslation()
  const { email, role, operatorUuid, isActivated, isDeleted, deletedAt } = useSelector((state) => state.auth.user)
  const { operators } = useSelector((state) => state.operators)

  const rows = [
    {
      label: t('ProfileInfoCard.company'),
      value: getCompanyName(t, operatorUuid, operators, role),
    },
    {
      label: t('ProfileInfoCard.email'),
      value: email,
    },
    {
      label: t('ProfileInfoCard.role'),
      value: getRoleString(t, role),
    },
    {
      label: t('ProfileInfoCard.accountStatus'),
      value: <AccountStatusChip isActivated={isActivated} isDeleted={isDeleted} deletedAt={deletedAt} />,
    },
  ]

  return (
    <BasicCardLoadable title={t('ProfileInfoCard.title')} action={<PersonIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default ProfileInfoCard

import {
  CREATE_OFFER,
  EDIT_OFFER_BY_UUID,
  GET_OFFER_BY_UUID,
  RESET_EDIT_OFFER,
  RESET_OFFER,
  RESET_CREATE_OFFER,
} from 'redux/Types'
/* -------------------------------------------------------------------------- */

// ----- LOAD -----

export const getOfferByUuidAct = (params) => ({
  type: GET_OFFER_BY_UUID.REQUEST,
  uuid: params?.uuid,
  versionUuid: params?.versionUuid,
  isReload: params?.isReload,
})

export const getOfferByUuidSuccessAct = (offer, versionUuid) => ({
  type: GET_OFFER_BY_UUID.SUCCESS,
  offer,
  versionUuid,
})

export const getOfferByUuidErrorAct = (error) => ({
  type: GET_OFFER_BY_UUID.ERROR,
  error,
})

export const resetOfferAct = () => ({
  type: RESET_OFFER,
})

// ----- EDIT -----

export const editOfferByUuidAct = (offerUpdatable, uuid) => ({
  type: EDIT_OFFER_BY_UUID.REQUEST,
  offerUpdatable,
  uuid,
})

export const editOfferByUuidSuccessAct = (offer) => ({
  type: EDIT_OFFER_BY_UUID.SUCCESS,
  offer,
})

export const editOfferByUuidErrorAct = (error) => ({
  type: EDIT_OFFER_BY_UUID.ERROR,
  error,
})

export const resetEditOfferByUuidAct = () => ({
  type: RESET_EDIT_OFFER,
})

// ----- CREATE -----

export const createOfferAct = (offerCreatable) => ({
  type: CREATE_OFFER.REQUEST,
  offerCreatable,
})

export const createOfferSuccessAct = (offer) => ({
  type: CREATE_OFFER.SUCCESS,
  offer,
})

export const createOfferErrorAct = (error) => ({
  type: CREATE_OFFER.ERROR,
  error,
})

export const resetCreateOfferAct = () => ({
  type: RESET_CREATE_OFFER,
})

import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  logInUserSuccessAct,
  logInUserErrorAct,
  logOutUserSuccessAct,
  logOutUserErrorAct,
  getCurrentUserSuccessAct,
  getCurrentUserErrorAct,
  setSelectedOperatorAct,
  changeMyProfileSuccessAct,
  changeMyProfileErrorAct,
  catchSagaErrorsAct,
} from 'redux/Actions'
import { LOGIN_USER, LOGOUT_USER, GET_CURRENT_USER, CHANGE_MY_PROFILE } from 'redux/Types'
import { BODY_AUTH } from 'api/adminAuth/adminAuthModels'
import { unpackError } from 'util/core'
import { loginReq, logoutReq, getCurrentUserReq, changeMyProfileReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

/* ----- LOGIN ----- */

function* workerLogInUserWithEmailPassword({ email, password }) {
  try {
    const user = yield call(loginReq, email, password)
    yield put(setSelectedOperatorAct(user[BODY_AUTH.OPERATOR_UUID]))
    yield put(logInUserSuccessAct(user))
  } catch (error) {
    const uError = unpackError(error)
    yield put(logInUserErrorAct(uError))
  }
}

export function* watcherLogInUser() {
  yield takeLatest(LOGIN_USER.REQUEST, workerLogInUserWithEmailPassword)
}

/* ----- LOGOUT ----- */

function* workerLogOutUser() {
  try {
    yield call(logoutReq)
    yield put(logOutUserSuccessAct())
  } catch (error) {
    yield put(catchSagaErrorsAct(error, logOutUserErrorAct))
  }
}

export function* watcherLogOutUser() {
  yield takeLatest(LOGOUT_USER.REQUEST, workerLogOutUser)
}

/* ----- GET_CURRENT_USER ----- */

function* workerGetCurrentUser() {
  try {
    const user = yield call(getCurrentUserReq)
    yield put(setSelectedOperatorAct(user[BODY_AUTH.OPERATOR_UUID]))
    yield put(getCurrentUserSuccessAct(user))
  } catch (error) {
    yield put(getCurrentUserErrorAct(error))
  }
}

export function* watcherGetCurrentUser() {
  yield takeLatest(GET_CURRENT_USER.REQUEST, workerGetCurrentUser)
}

/* ----- CHANGE MY PROFILE ----- */

function* workerChangeMyProfile({ languageCode }) {
  try {
    const user = yield call(changeMyProfileReq, { languageCode })
    yield put(changeMyProfileSuccessAct(user))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, changeMyProfileErrorAct))
  }
}

export function* watcherChangeMyProfile() {
  yield takeLatest(CHANGE_MY_PROFILE.REQUEST, workerChangeMyProfile)
}

/* ----- ROOT SAGA ----- */

export default function* rootSaga() {
  yield all([
    fork(watcherLogInUser),
    fork(watcherLogOutUser),
    fork(watcherGetCurrentUser),
    fork(watcherChangeMyProfile),
  ])
}

import { differenceInMinutes } from 'date-fns'
import { map } from 'lodash'

import { BARRIER_STATE_VALUES } from 'api/carParkMgmt/carParkMgmtModels'
import { getRoundedTimeAmountString, errorStringIsRequired, getKeyByValue } from 'util/core'
/* -------------------------------------------------------------------------- */

/**
 * Helper methods for barrier
 * @namespace barrier
 */

export const sortBarriers = (barriers) => {
  return [].concat(barriers).sort((a, b) => {
    const {
      beaconMinor: beaconMinorA,
      carParkName: nameA,
      isOnline: statusA,
      lastBeaconTimestamp: lastBeaconTimestampA,
      lastUpdateCheckAt: lastUpdateCheckAtA,
    } = a
    const {
      beaconMinor: beaconMinorB,
      carParkName: nameB,
      isOnline: statusB,
      lastBeaconTimestamp: lastBeaconTimestampB,
      lastUpdateCheckAt: lastUpdateCheckAtB,
    } = b
    if (statusA < statusB) return -1
    if (statusA > statusB) return 1
    if (new Date(lastUpdateCheckAtA) < new Date(lastUpdateCheckAtB)) return 1
    if (new Date(lastUpdateCheckAtA) > new Date(lastUpdateCheckAtB)) return -1
    if (new Date(lastBeaconTimestampA) < new Date(lastBeaconTimestampB)) return -1
    if (new Date(lastBeaconTimestampA) > new Date(lastBeaconTimestampB)) return 1
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    if (beaconMinorA < beaconMinorB) return -1
    if (beaconMinorA > beaconMinorB) return 1
    return 0
  })
}

// ----- TYPE -----

const BARRIER_TYPES = {
  entrance: 'entrance',
  exit: 'exit',
}

export const getBarrierTypeSelects = (t) =>
  map(BARRIER_TYPES, (v, k) => ({
    value: v,
    // i18next-extract-mark-context-next-line ["entrance","exit"]
    label: t('barrier.openings', { context: k }),
  }))

export const getBarrierTypeKey = (isEntrance) => (isEntrance ? 'entrance' : 'exit')

export const getBarrierTypeAsBool = (key) => {
  if (key === 'entrance') return true
  else if (key === 'exit') return false
  else return null
}

export const getBarrierTypeAsString = (t, isEntrance) => {
  if (isEntrance === true) return t('barrier.entrance')
  else if (isEntrance === false) return t('barrier.exit')
  else return null
}

export const getBarrierTypeOptions = (t) => [
  {
    label: getBarrierTypeAsString(t, true),
    value: true,
  },
  {
    label: getBarrierTypeAsString(t, false),
    value: false,
  },
]

// ----- OPERATING STATES -----

export const getBarrierOperatingStates = (t) =>
  map(BARRIER_STATE_VALUES, (v, _k) => ({
    value: v,
    label: getBarrierOperatingStateAsString(t, v),
  }))

export const getBarrierOperatingStateAsString = (t, state) =>
  // i18next-extract-mark-context-next-line ["", "notInstalled", "operating", "underMaintenance"]
  t('barrier.operatingState', { context: getKeyByValue(BARRIER_STATE_VALUES, state) })

// ----- ONLINE STATES -----

export const BARRIER_ONLINE_STATES = {
  online: 'online',
  offline: 'offline',
}

/**
 * Get boolean representing online state
 * @function
 * @memberof barrier
 * @param {string} onlineState  one of BARRIER_ONLINE_STATES
 * @returns {boolean} whether the barrier is online or offline
 */
export const isOnlineBarrierState = (onlineState) => onlineState === BARRIER_ONLINE_STATES.online

// ----- BEACON  -----

export const BEACON_STATES = {
  SENDING: 'sending',
  NOT_SENDING: 'notSending',
}

export const getBeaconMinorString = (t, v) => v || t('barrier.beaconMinor.unknown')

// ----- BARRIER / BEACON -----

export const getDeviceOfflineTimeAsString = (t, lastSeenAt) =>
  getRoundedTimeAmountString(t, differenceInMinutes(new Date(), new Date(lastSeenAt)))

// ----- BARRIER UPDATE ERRORS -----

export const getUrlFormValueError = (t, value) => errorStringIsRequired({ t, value })

export const getBeaconMinorError = (beaconMinor, type, t) => {
  if (beaconMinor < 10) {
    return t('barrier.errors.beaconNrTooSmall')
  } else if (beaconMinor > 99) {
    return t('barrier.errors.beaconNrTooBig')
  } else if (type === 'exit' && parseInt(beaconMinor) % 2 === 0) {
    return t('barrier.errors.beaconNrOdd')
  } else if (type === 'entrance' && parseInt(beaconMinor) % 2 === 1) {
    return t('barrier.errors.beaconNrEven')
  } else {
    return null
  }
}

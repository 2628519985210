import { ADMIN_API_BASE_PATH } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_ADMINS = {
  ADMINS: `${ADMIN_API_BASE_PATH}/admins`,
  ME: `${ADMIN_API_BASE_PATH}/me`,
  ACTIVATION: `${ADMIN_API_BASE_PATH}/me/activation`,
}

export const PARAMS_ADMINS = {
  OPERATOR_UUID: 'operatorUuid',
}

export const REQ_ADMINS = {
  NEW_PASSWORD: 'newPassword',
  TOKEN: 'token',
}

export const RES_ADMINS = {
  CAR_PARK_UUIDS: 'carParkUuids',
  DELETED_AT: 'deletedAt',
  EMAIL: 'email',
  IS_ACTIVATED: 'isActivated',
  IS_DELETED: 'isDeleted',
  IS_EMAIL_VERIFIED: 'isEmailVerified',
  LANGUAGE: 'language',
  OPERATOR_UUID: 'operatorUuid',
  ROLE: 'role',
  UUID: 'uuid',
}

import { GET_BARRIER_BY_ID, RESET_BARRIER, EDIT_BARRIER, RESET_EDIT_BARRIER } from 'redux/Types'
import { unpackBarrier } from 'api/carParkMgmt/carParkMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  barrier: unpackBarrier(),
  isGettingBarrierByUuid: false,
  isReGettingBarrierByUuid: false,
  lastSuccessDate: null,
  errGettingBarrierByUuid: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  isEditingBarrier: false,
  editBarrierSuccess: false,
  errUpdatingBarrier: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_BARRIER_BY_ID.REQUEST: {
      const { isReload } = action
      return {
        ...state,
        isGettingBarrierByUuid: !isReload,
        isReGettingBarrierByUuid: !!isReload,
        errGettingBarrierByUuid: INIT_STATE.errGettingBarrierByUuid,
      }
    }
    case GET_BARRIER_BY_ID.SUCCESS: {
      const { barrier } = action
      return {
        ...state,
        isGettingBarrierByUuid: false,
        isReGettingBarrierByUuid: false,
        barrier: unpackBarrier(barrier),
        lastSuccessDate: new Date(),
      }
    }
    case GET_BARRIER_BY_ID.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingBarrierByUuid: false,
        isReGettingBarrierByUuid: false,
        errGettingBarrierByUuid: error,
      }
    }
    case EDIT_BARRIER.REQUEST: {
      return {
        ...state,
        isEditingBarrier: true,
        editBarrierSuccess: false,
        errUpdatingBarrier: INIT_STATE.errUpdatingBarrier,
      }
    }
    case EDIT_BARRIER.SUCCESS: {
      const { barrier } = action
      return {
        ...state,
        isEditingBarrier: false,
        editBarrierSuccess: true,
        barrier: unpackBarrier(barrier),
      }
    }
    case EDIT_BARRIER.ERROR: {
      const { error } = action
      return {
        ...state,
        isEditingBarrier: false,
        editBarrierSuccess: false,
        errUpdatingBarrier: error,
      }
    }
    case RESET_EDIT_BARRIER: {
      return {
        ...state,
        editBarrierSuccess: false,
      }
    }
    case RESET_BARRIER: {
      return {
        ...state,
        barrier: INIT_STATE.barrier,
      }
    }
    default:
      return { ...state }
  }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import AdminCreateForm from 'components/Admin/Create/AdminCreateForm'
/* -------------------------------------------------------------------------- */

const AdminCreateContainer = () => {
  const { t } = useTranslation()
  const errCreatingAdmin = useSelector((state) => state.admin.errCreatingAdmin)

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('AdminCreateContainer.title')}
      pageTitle={t('AdminCreateContainer.title')}
    >
      <ErrorAlertContainer errors={[errCreatingAdmin]} />
      <AdminCreateForm />
    </BasicPageContainerLoadable>
  )
}

export default AdminCreateContainer

import React from 'react'
import { useTranslation } from 'react-i18next'

import SelectTextField from 'fragments/Forms/SelectTextField'
import { TicketUsability } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketUsabilitySelectProps = {
  selected?: TicketUsability
  onChange: (s: TicketUsability) => void
}

export default function TicketUsabilitySelect(props: TicketUsabilitySelectProps) {
  const { onChange, selected } = props

  const { t } = useTranslation()

  const getTicketUsabilityOptions = ['NotYetUsable', 'Usable', 'NotUsableAnymore'].map((v) => ({
    value: v as TicketUsability,
    // i18next-extract-mark-context-next-line ["NotYetUsable", "Usable", "NotUsableAnymore"]
    label: t('ticket.usability', { context: v }) as string,
  }))

  return (
    <SelectTextField
      label={t('TicketUsabilitySelect.label') as string}
      variant='outlined'
      selected={selected}
      options={getTicketUsabilityOptions}
      onChange={onChange}
    />
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Divider } from '@material-ui/core'
import { ImportExport as ImportExportIcon } from '@material-ui/icons'

import SalesContainer from 'components/Sales/SalesContainer'
import SalesPerYearTableCard from 'components/Sales/SalesPerYear/SalesPerYearTableCard'
import { getSalesAct, resetSalesAct } from 'redux/Actions'
import { getTimeIntervalValue } from 'util/core'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

const SalesPerYearContainer = () => {
  const {
    sales: { salesPerCarPark, salesAllCarParks },
    lastSuccessDate,
  } = useSelector((state) => state.sales)
  const { selectedOperator, selectedCarParks } = useSelector((state) => state.globalFilters)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getSalesAct({ interval: getTimeIntervalValue('YEAR') }))
    return () => dispatch(resetSalesAct())
  }, [dispatch, selectedOperator])

  return (
    <SalesContainer>
      <Grid item xs={12}>
        <SalesPerYearTableCard title={t('SalesPerYearContainer.allCarParks')} data={salesAllCarParks} />
      </Grid>
      {selectedCarParks.length > 0 && (
        <Grid item xs={12} container justify='space-between' alignItems='center'>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item xs={1} container justify='center'>
            <ImportExportIcon />
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      )}
      {selectedCarParks.map((uuid) => {
        const data = salesPerCarPark.find((o) => o.carParkUuid === uuid) || {}
        return (
          <Grid item xs={12} key={uuid}>
            <SalesPerYearTableCard title={data?.carParkName || t('SalesPerYearContainer.unknownCarPark')} data={data} />
          </Grid>
        )
      })}
      <Grid item xs={12}>
        <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
      </Grid>
    </SalesContainer>
  )
}

export default SalesPerYearContainer

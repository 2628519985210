import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

export default [
  {
    label: 'info',
    path: APP.APP_USER,
  },
  {
    label: 'payments',
    path: APP.APP_USER_PAYMENTS,
  },
  {
    label: 'connections',
    path: APP.APP_USER_CONNECTIONS,
  },
]

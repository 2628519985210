import { isEmpty } from 'lodash'

import { BODY_OFFER, BODY_OFFER_VERSION, OFFER_TYPE_VALUES } from 'api/offerMgmt/offerMgmtModels'
import { BODY_LANGUAGE_PROPS } from 'api/backendConstants'
import { getTimeInMinByValueAndDuration, getMinutesFromMidnight } from 'util/core'
/* -------------------------------------------------------------------------- */

export const unpackOffers = (offers) => offers.map(unpackOffer)

/* options has this form
  {
    versionUuid: string,
    unpackAll: boolean
  }
*/
export const unpackOffer = (offer, options) => {
  const { OFFER_VERSIONS_ARR, UUID } = BODY_OFFER
  const versionUuid = options?.versionUuid ?? null
  const unpackAll = options?.unpackAll ?? false

  const versions = offer?.[OFFER_VERSIONS_ARR] ?? []
  const newestVersionUuid = versions?.[versions.length - 1]?.uuid ?? null

  const wantedVersion = !!versionUuid
    ? versions.find((v) => v.uuid === versionUuid)
    : // Last version is always last element in array
      versions[versions.length - 1]

  const unpackedVersions = unpackAll ? versions.map(unpackOfferVersion) : []

  return {
    ...unpackOfferVersion(wantedVersion || {}),
    versions: unpackedVersions,
    versionsCount: versions.length || null,
    newestVersionUuid,
    uuid: offer?.[UUID] ?? null,
  }
}

export const unpackOfferVersion = (oV) => {
  const {
    ACCESS_EARLIEST_IN_MIN_FROM_MIDNIGHT,
    ACCESS_LATEST_IN_MIN_FROM_MIDNIGHT,
    AUTHOR_UUID,
    AVAILABLE_FROM,
    AVAILABLE_UNTIL,
    CAR_PARK_NAME,
    CAR_PARK_UUID,
    CONTINGENT,
    CREATED_AT,
    IS_DELETED,
    IS_VISIBLE,
    LOCALIZED_GENERATED_NAME_OBJ,
    LOCALIZED_INFROMATION_OBJ,
    LOCALIZED_NAME_OBJ,
    OFFER_UUID,
    PRICE_IN_CENT,
    TICKET_USAGE_IN_MIN,
    TICKET_VALIDITY_IN_MIN,
    TYPE,
    UUID,
    UPDATED_AT,
  } = BODY_OFFER_VERSION
  const { EN, DE } = BODY_LANGUAGE_PROPS
  return {
    accessEarliestInMinFromMidnight: oV?.[ACCESS_EARLIEST_IN_MIN_FROM_MIDNIGHT],
    accessLatestInMinFromMidnight: oV?.[ACCESS_LATEST_IN_MIN_FROM_MIDNIGHT],
    authorUuid: oV?.[AUTHOR_UUID],
    availableFrom: oV?.[AVAILABLE_FROM],
    availableUntil: oV?.[AVAILABLE_UNTIL],
    carParkName: oV?.[CAR_PARK_NAME],
    carParkUuid: oV?.[CAR_PARK_UUID],
    contingent: oV?.[CONTINGENT],
    createdAt: oV?.[CREATED_AT],
    durationInMin: oV?.[TICKET_USAGE_IN_MIN],
    isDeleted: oV?.[IS_DELETED],
    isVisible: oV?.[IS_VISIBLE],
    localizedGeneratedNames: {
      de: oV?.[LOCALIZED_GENERATED_NAME_OBJ]?.[DE],
      en: oV?.[LOCALIZED_GENERATED_NAME_OBJ]?.[EN],
    },
    localizedInformation: {
      de: oV?.[LOCALIZED_INFROMATION_OBJ]?.[DE],
      en: oV?.[LOCALIZED_INFROMATION_OBJ]?.[EN],
    },
    localizedNames: {
      de: oV?.[LOCALIZED_NAME_OBJ]?.[DE],
      en: oV?.[LOCALIZED_NAME_OBJ]?.[EN],
    },
    priceInCent: oV?.[PRICE_IN_CENT],
    type: oV?.[TYPE],
    updatedAt: oV?.[UPDATED_AT],
    offerUuid: oV?.[OFFER_UUID],
    versionUuid: oV?.[UUID],
    validityInMin: oV?.[TICKET_VALIDITY_IN_MIN],
  }
}

export const pack = (offer) => {
  const {
    ACCESS_EARLIEST_IN_MIN_FROM_MIDNIGHT,
    ACCESS_LATEST_IN_MIN_FROM_MIDNIGHT,
    AVAILABLE_FROM,
    AVAILABLE_UNTIL,
    CONTINGENT,
    IS_VISIBLE,
    LOCALIZED_INFROMATION_OBJ,
    LOCALIZED_NAME_OBJ,
    PRICE_IN_CENT,
    TICKET_USAGE_IN_MIN,
    TICKET_VALIDITY_IN_MIN,
    TYPE,
    CAR_PARK_UUID,
  } = BODY_OFFER_VERSION
  const { DE, EN } = BODY_LANGUAGE_PROPS

  const offerType = offer.type
  const validityUnit = offerType === OFFER_TYPE_VALUES.LONG_TERM ? offer.usageUnit : offer.validityUnit
  const validityInMin = offerType === OFFER_TYPE_VALUES.LONG_TERM ? offer.usageDuration : offer.validityDuration

  return {
    ...(offer.carParkUuid && { [CAR_PARK_UUID]: offer.carParkUuid }), // only for create
    [LOCALIZED_INFROMATION_OBJ]: Object.values(offer.localizedInformation).every((e) => isEmpty(e))
      ? null
      : {
          ...(offer.localizedInformation.de && { [DE]: offer.localizedInformation.de }),
          ...(offer.localizedInformation.en && { [EN]: offer.localizedInformation.en }),
        },
    [LOCALIZED_NAME_OBJ]: Object.values(offer.localizedNames).every((e) => isEmpty(e))
      ? null
      : {
          ...(offer.localizedNames.de && { [DE]: offer.localizedNames.de }),
          ...(offer.localizedNames.en && { [EN]: offer.localizedNames.en }),
        },
    [TYPE]: offerType,
    [IS_VISIBLE]: offer.isVisible,
    [PRICE_IN_CENT]: (offer?.priceInEuro ?? 0) * 100,
    [TICKET_VALIDITY_IN_MIN]: getTimeInMinByValueAndDuration(validityInMin, validityUnit),
    [TICKET_USAGE_IN_MIN]: getTimeInMinByValueAndDuration(offer.usageDuration, offer.usageUnit),
    [CONTINGENT]: offer.contingent === '' ? null : offer.contingent,
    [AVAILABLE_FROM]: offer?.availableFrom ?? null,
    [AVAILABLE_UNTIL]: offer?.availableUntil ?? null,
    [ACCESS_EARLIEST_IN_MIN_FROM_MIDNIGHT]: offer.accessEarliestInMinFromMidnight
      ? getMinutesFromMidnight(offer.accessEarliestInMinFromMidnight)
      : null,
    [ACCESS_LATEST_IN_MIN_FROM_MIDNIGHT]: offer.accessLatestInMinFromMidnight
      ? getMinutesFromMidnight(offer.accessLatestInMinFromMidnight)
      : null,
  }
}

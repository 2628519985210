import React from 'react'

import BarrierOnlineStateChip from 'fragments/IconChips/BarrierOnlineStateChip'
import BeaconStatusChip from 'fragments/IconChips/BeaconStateChip'
import OperatingStateChip from 'fragments/IconChips/OperatingStateChip'
import { getBarrierTypeAsString } from 'util/custom'
import PlaceHolderText from 'fragments/PlaceHolderText'
/* -------------------------------------------------------------------------- */

export default (data, viewBarrierNr, viewSoftwareVersion, t) => [
  {
    name: 'carParkName',
    label: t('BarriersTableColumns.carPark'),
    options: {
      viewColumns: false,
    },
  },
  {
    name: 'isEntrance',
    label: t('BarriersTableColumns.barrierType'),
    options: {
      customBodyRender: (v) => getBarrierTypeAsString(t, v),
      viewColumns: false,
    },
  },
  {
    name: 'name',
    label: t('BarriersTableColumns.label'),
    options: {
      customBodyRender: (v) => v || <PlaceHolderText text={t('BarriersTableColumns.notSpecified')} />,
      viewColumns: false,
    },
  },
  {
    name: 'beaconMinor',
    label: t('BarriersTableColumns.barrierNumber'),
    options: {
      // https://github.com/gregnb/mui-datatables/issues/1576
      display: viewBarrierNr.toString(),
    },
  },
  {
    name: 'uuid',
    label: t('BarriersTableColumns.barrierStatus'),
    options: {
      customBodyRender: (uuid) => {
        const { isOnline, lastUpdateCheckAt } = data.find((o) => o.uuid === uuid)
        return <BarrierOnlineStateChip isOnline={isOnline} lastSeenAt={lastUpdateCheckAt} />
      },
      viewColumns: false,
    },
  },
  {
    name: 'uuid',
    label: t('BarriersTableColumns.beaconStatus'),
    options: {
      customBodyRender: (uuid) => {
        const { isBeaconOnline, lastBeaconTimestamp } = data.find((o) => o.uuid === uuid)
        return <BeaconStatusChip isOnline={isBeaconOnline} lastSeenAt={lastBeaconTimestamp} />
      },
      viewColumns: false,
    },
  },
  {
    name: 'state',
    label: t('BarriersTableColumns.operatingState'),
    options: {
      customBodyRender: (state) => <OperatingStateChip state={state} />,
      viewColumns: false,
    },
  },
  {
    name: 'softwareVersion',
    label: t('BarriersTableColumns.softwareVersion'),
    options: {
      customBodyRender: (v) => v || <PlaceHolderText text={t('BarriersTableColumns.notSpecified')} />,
      // https://github.com/gregnb/mui-datatables/issues/1576
      display: viewSoftwareVersion.toString(),
    },
  },
]

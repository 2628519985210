import {
  addMinutes,
  differenceInSeconds,
  format,
  getDaysInMonth,
  getHours,
  getMinutes,
  parseISO,
  startOfDay,
  compareDesc,
  compareAsc,
} from 'date-fns'
import { map, range } from 'lodash'
import compose from 'lodash/fp/compose'
import setSeconds from 'date-fns/fp/setSeconds'
import setMilliseconds from 'date-fns/fp/setMilliseconds'

import { STATISTICS_INTERVAL_VALUES } from 'api/statisticsMgmt/statisticsMgmtModels'
import { localeMap, localeCodes } from 'util/i18n'
/* -------------------------------------------------------------------------- */

/**
 * Helper methods for date and time
 * @namespace dateAndTime
 */

const MINUTES_PER_HOUR = 60
const HOURS_PER_DAY = 24
const MINUTES_PER_DAY = HOURS_PER_DAY * 60
const MS_PER_HOUR = 60 * 60 * 1000
const MS_IN_A_DAY = HOURS_PER_DAY * MS_PER_HOUR
const MONTH_PER_YEAR = 12

// ----- date-fns pattern -----

const PATTERN = {
  dateShort: 'P', // 11/30/2020  | 30.11.2020
  dateMedium: 'PP', // Sep 30, 2020 | 30. Sep. 2020
  dateLong: 'PPP', // May 29th, 2020 | 30. September 2020
  timeShort: 'p', // 4:24 PM | 16:24
  timeMedium: 'pp', // 4:24:10 AM | 16:24:10
  yearShort: 'yy', // 20,
  yearFull: 'yyyy', // 2020
  monthNumber: 'MM', // 11
  monthShort: 'MMM', // Sep
  monthFull: 'MMMM', // September
  weekShort: 'w', // 1...53
  weekMedium: 'ww', // 01...53
  weekLong: 'wo', // 1st...53th
  dayOfMonthShort: 'd', // 1..31
  dayOfMonthMedium: 'dd', // 01...31
  dayOfMonthLong: 'do', // 1st...31st
  default: "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
}

export const datePattern = PATTERN

// ----- UNITS -----

export const TIME_UNITS = {
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
}

/* -------------------------------------------------------------------------- */

export const nullifySecondsMilliseconds = compose(setSeconds(0), setMilliseconds(0))

// ----- SORTING -----

/**
 * Sort array with object by pecified date property
 * @function
 * @memberof dateAndTime
 */
export const sortArrayByDateProp = (array, prop, direction = 'desc') => {
  return []
    .concat(array)
    .sort((a, b) =>
      direction === 'asc'
        ? compareAsc(parseISO(b[prop]), parseISO(a[prop]))
        : compareDesc(parseISO(b[prop]), parseISO(a[prop])),
    )
}

/* -------------------------------------------------------------------------- */
// ----- FORMAT STRINGS -----

// Dates come from backend as ISO string without Z !!
/**
 * Add suffix Z (time zone 0 UTC) to an ISOstring
 * @function
 * @memberof dateAndTime
 * @param {string} isoStringNoZ - ISOstring without Z: 2020-05-06T10:01:25.458
 * @returns {string} ISO string UTC timezone 0: 2020-05-06T10:01:25.458Z
 */
export const appendZeroUTC = (isoStringNoZ) =>
  isoStringNoZ ? (isoStringNoZ.slice(0, -1) === 'Z' ? isoStringNoZ : isoStringNoZ + 'Z') : null

// Dates need to be send back to backend as ISOstring withot Z !!
/**
 * Format Date as ISO string with Z cut off
 * @function
 * @memberof dateAndTime
 * @param {Object} date
 * @returns {string} ISOstring without Z: 2020-05-06T10:01:25.458
 */
export const toISOStringNoZ = (date) => date.toISOString().slice(0, -1)

/**
 * Format date in defined shape
 * @function
 * @memberof dateAndTime
 * @param {string} isoString - date as ISOstring
 * @param {string} localeCode - language code, e.g. 'en'
 * @param {Object} [options={}] - optional options on how to format date and time
 * @param {string} options[].dateSize - short: 05/29/2020 | medium: May 29th, 2020
 * @param {string} options[].timeSize - short: 12:00 AM | medium: 12:00:00 AM
 * @returns {string} - formatted dateTime, default if no options passed: '2020-05-29T12:00:00.123+02:00'
 */
export const formatISOString = (isoString, localeCode, options = null) => {
  if (isoString) {
    const date = parseISO(isoString)
    const locale = localeMap[localeCode]
    // pattern
    let patternDate = ''
    if (options?.dateSize === 'short') patternDate = PATTERN.dateShort
    else if (options?.dateSize === 'medium') patternDate = PATTERN.dateMedium
    let patternTime = ''
    if (options?.timeSize === 'short') patternTime = PATTERN.timeShort
    if (options?.timeSize === 'medium') patternTime = PATTERN.timeMedium
    const pattern = patternDate + patternTime
    const finalPattern = pattern === '' ? PATTERN.default : pattern
    return format(date, finalPattern, { locale })
  } else return null
}

/**
 * Get formatted time defined by minutes from midnight
 * @memberof dateAndTime
 * @param {number} minFromMidnight - minutes from midnight, e.g. 480
 * @param {function} t - must be from the i18n-instance
 * @returns{string} - time, e.g. 8:00 AM
 */
export const timeByMinFromMidnight = (minFromMidnight, t) =>
  Number.isInteger(minFromMidnight)
    ? t('dateAndTime.timeByMinFromMidnightShort', {
        date: addMinutes(startOfDay(new Date()), minFromMidnight).toISOString(),
      })
    : minFromMidnight
/* -------------------------------------------------------------------------- */
// ----- DATES -----

/**
 * Get date defined by minutes from this days midnight
 * @memberof dateAndTime
 * @param {number} minFromMidnight - minutes from midnight
 * @returns{Object} - date
 */
export const getDateByMinFromMidnight = (minFromMidnight) =>
  Number.isInteger(minFromMidnight) ? addMinutes(startOfDay(new Date()), minFromMidnight) : minFromMidnight

/* -------------------------------------------------------------------------- */
// ----- DEPRECATED !!! -----

const getTimeAmountsObj = (tInMin) => {
  if (Number.isInteger(tInMin)) {
    const days = getDaysCount(tInMin)
    let hours = getHoursCount(tInMin - days * MINUTES_PER_DAY)
    return {
      days,
      hours,
      mins: tInMin - days * MINUTES_PER_DAY - hours * MINUTES_PER_HOUR,
    }
  } else return null
}

export const getRoundedTimeAmountString = (t, tInMin) => {
  if (Number.isInteger(tInMin)) {
    const { days, hours, mins } = getTimeAmountsObj(tInMin)
    if (days) return '> ' + t('dateAndTime.day', { count: days })
    else if (hours) return '> ' + t('dateAndTime.hour', { count: hours })
    else if (mins) return t('dateAndTime.minute', { count: mins })
  } else return null
}

export const getTimeAmountString = (t, tInMin) => {
  if (Number.isInteger(tInMin)) {
    const { days, hours, mins } = getTimeAmountsObj(tInMin)
    if (days && hours && mins) return t('dateAndTime.daysHoursMins', { days, hours, mins })
    else if (days && hours) return t('dateAndTime.daysHours', { days, hours })
    else if (days && mins) return t('dateAndTime.daysMins', { days, mins })
    else if (days) return t('dateAndTime.day', { count: days })
    else if (hours && mins) return t('dateAndTime.hoursMins', { hours, mins })
    else if (hours) return t('dateAndTime.hour', { count: hours })
    else return t('dateAndTime.minute', { count: mins })
  } else return null
}

export const getTimeInMinByValueAndDuration = (minutes, unit) => {
  const { HOURS, DAYS } = TIME_UNITS
  if (unit === DAYS) return minutes * MINUTES_PER_DAY
  else if (unit === HOURS) return minutes * MINUTES_PER_HOUR
  return minutes
}

export const getTimeWithBiggestPossibleUnit = (minutes) =>
  minutes >= MINUTES_PER_DAY && minutes % MINUTES_PER_DAY === 0
    ? { value: minutes / MINUTES_PER_DAY, unit: TIME_UNITS.DAYS }
    : minutes >= MINUTES_PER_HOUR && minutes % MINUTES_PER_HOUR === 0
    ? { value: minutes / MINUTES_PER_HOUR, unit: TIME_UNITS.HOURS }
    : { value: minutes, unit: TIME_UNITS.MINUTES }

export const getMinutesFromMidnight = (date) => getHours(date) * MINUTES_PER_HOUR + getMinutes(date)

// ----- LABELS -----

export const getDayIntervals = (params) => {
  const { other, d7, d14, d30, d60, d90 } = params
  return {
    ...(other && { other: { value: 0 } }),
    ...(d7 && { d7: { value: 7 } }),
    ...(d14 && { d14: { value: 14 } }),
    ...(d30 && { d30: { value: 30 } }),
    ...(d60 && { d60: { value: 60 } }),
    ...(d90 && { d90: { value: 90 } }),
  }
}

export const getTimeUnitSelects = (t) =>
  map(TIME_UNITS, (v, k) => ({
    value: v,
    // i18next-extract-mark-context-next-line ["", "MINUTES", "HOURS", "DAYS"]
    label: t('dateAndTime.unit', { context: k }),
  }))

/* -------------------------------------------------------------------------- */
// TIME INTERVALS: DAY, WEEK, MONTH, YEAR

export const getTimeIntervalSelects = (t) =>
  map(STATISTICS_INTERVAL_VALUES, (v, k) => ({
    value: v,
    label: getIntervalLabel(t, k),
  }))

export const getTimeIntervalValue = (key) => STATISTICS_INTERVAL_VALUES?.[key]

export const getIntervalLength = (interval, options = { date: new Date() }) => {
  const { date } = options
  const today = new Date()
  if (interval === getTimeIntervalValue('DAY')) {
    if ((today - date) / MS_PER_HOUR > HOURS_PER_DAY) return HOURS_PER_DAY
    else return today.getHours()
  } else if (interval === getTimeIntervalValue('WEEK')) {
    //if date is from this week, make data iteration stop at the current day
    if ((today - date) / MS_IN_A_DAY > 7) return 7
    else return today.getDay()
  } else if (interval === getTimeIntervalValue('MONTH')) {
    const daysInMonth = getDaysInMonth(date)
    if (date.getFullYear() === today.getFullYear()) {
      //date is from this year
      if (date.getMonth() === today.getMonth()) return today.getDate()
      //date is from this month
      else return daysInMonth //date is from a different month this year
    } else return daysInMonth //date is not from this year
  } else if (interval === getTimeIntervalValue('YEAR')) {
    //if date is from this year, make data iteration stop at the current month
    if (date.getFullYear() === today.getFullYear()) return today.getMonth()
    else return MONTH_PER_YEAR
  } else return null
}

// i18next-extract-mark-context-next-line ["DAY", "WEEK", "MONTH", "YEAR"]
export const getIntervalLabel = (t, interval) => t('dateAndTime.interval', { context: interval })

export const getIntervalLabels = (interval, options = { date: new Date(), localeCode: localeCodes?.de }) => {
  const { date, localeCode } = options
  let labels = []
  if (interval === getTimeIntervalValue('DAY')) {
    labels = getIntervalLabelsOfDay()
  } else if (interval === getTimeIntervalValue('WEEK')) {
    labels = getDayLabels(localeCode)
  } else if (interval === getTimeIntervalValue('MONTH')) {
    const daysInMonth = getDaysInMonth(date)
    labels = range(1, daysInMonth + 1).map((v) => `${v}`)
  } else if (interval === getTimeIntervalValue('YEAR')) {
    labels = getMonthLabels(localeCode)
  }
  return labels
}

const getIntervalLabelsOfDay = () => {
  let labels = []
  for (let i = 0; i < HOURS_PER_DAY; i++) {
    // add leading 0 for 1 digit number -> 01, 02, .. 09, 10
    labels.push(`${i}`.padStart(2, '0') + '-' + `${i + 1}`.padStart(2, '0'))
  }
  return labels
}

export const getMonthLabel = (i, localeCode) => localeMap[localeCode].localize.month(i, { width: 'abbreviated' })

export const months = (localeCode) =>
  range(12).map((i) => ({ label: getMonthLabel(i, localeCode), value: getMonthLabel(i, 'en') }))

const getDayLabels = (localeCode) =>
  [1, 2, 3, 4, 5, 6, 0].map((i) => localeMap[localeCode].localize.day(i, { width: 'abbreviated' }))

const getMonthLabels = (localeCode) => [...Array(MONTH_PER_YEAR).keys()].map((i) => getMonthLabel(i, localeCode))

/* -------------------------------------------------------------------------- */
// ----- HELPER -----

const getDaysCount = (minutes) => Math.floor(minutes / MINUTES_PER_DAY)

const getHoursCount = (minutes) => Math.floor(minutes / MINUTES_PER_HOUR)

export const getTimeDifferenceInSec = (d1, d2) => differenceInSeconds(parseISO(d1), parseISO(d2))

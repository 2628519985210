import React from 'react'
import Switch from '@material-ui/core/Switch'
import { uniqueId } from 'lodash'
import isNil from 'lodash/isNil'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PlaceHolderText from 'fragments/PlaceHolderText'
/* -------------------------------------------------------------------------- */

const BasicSwitch = (props) => {
  const { checked, onChange, disabled } = props
  const id = uniqueId()
  const { t } = useTranslation()

  return isNil(checked) ? (
    <PlaceHolderText text={t('BasicSwitch.invalidValue')} />
  ) : (
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      value={id}
      color='primary'
    />
  )
}

/* -------------------------------------------------------------------------- */

BasicSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

BasicSwitch.defaultProps = {
  checked: null,
  disabled: false,
}

export default BasicSwitch

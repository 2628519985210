import React from 'react'
import { TableFooter, TableRow, TableCell, TablePagination, makeStyles } from '@material-ui/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles({
  footerStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 24px 0px 24px',
  },
})

const CustomTableFooter = (props) => {
  const classes = useStyles()
  const { count, textLabels, rowsPerPage, page } = props

  const handleRowChange = (event) => {
    props.changeRowsPerPage(event.target.value)
  }

  const handlePageChange = (_, page) => {
    props.changePage(page)
  }

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.footerStyle}>
          <TablePagination
            component='div'
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={textLabels.rowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
            backIconButtonProps={{
              'aria-label': textLabels.previous,
            }}
            nextIconButtonProps={{
              'aria-label': textLabels.next,
            }}
            rowsPerPageOptions={[10, 25, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowChange}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}

export default CustomTableFooter

import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

export default [
  {
    label: 'month',
    path: APP.SALES_MONTH,
  },
  {
    label: 'year',
    path: APP.SALES_YEAR,
  },
]

import React from 'react'
import * as PropTypes from 'prop-types'

import IconChip from 'fragments/IconChips/IconChip'
/* -------------------------------------------------------------------------- */
const BinaryIconChip = (props) => {
  const { condition, falseIcon: FalseIcon, falseLabel, trueIcon: TrueIcon, trueLabel } = props

  const style = { color: 'white' }
  const chipStyle = condition
    ? { ...style, background: '#3ccc37' }
    : condition === false
    ? { ...style, background: 'red' }
    : undefined
  const trueIcon = TrueIcon ? <TrueIcon style={style} /> : undefined
  const falseIcon = FalseIcon ? <FalseIcon style={style} /> : undefined
  const icon = condition ? trueIcon : condition === false ? falseIcon : undefined
  const label = condition ? trueLabel : condition === false ? falseLabel : undefined

  return <IconChip icon={icon} label={label} chipStyle={chipStyle} />
}

/* -------------------------------------------------------------------------- */
BinaryIconChip.propTypes = {
  condition: PropTypes.bool,
  falseIcon: PropTypes.shape(),
  falseLabel: PropTypes.string,
  trueIcon: PropTypes.shape(),
  trueLabel: PropTypes.string,
}

BinaryIconChip.defaultProps = {
  condition: null,
  falseIcon: null,
  falseLabel: null,
  trueIcon: null,
  trueLabel: null,
}

export default BinaryIconChip

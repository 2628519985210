import {
  GET_ADMIN_BY_UUID,
  RESET_ADMIN,
  CREATE_ADMIN,
  RESET_CREATE_ADMIN,
  EDIT_ADMIN,
  RESET_EDIT_ADMIN,
} from 'redux/Types'
import { unpackAdmin } from 'api/adminMgmt/adminMgmtUnpackers'
import { STATUS } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  // GET
  admin: unpackAdmin(),
  lastSuccessDate: new Date(),
  isGettingAdmin: false,
  isReGettingAdmin: false,
  errGettingAdmin: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  // CREATE
  statusCreatingAdmin: STATUS.idle,
  errCreatingAdmin: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  // EDIT
  editAdminSuccess: false,
  isEditingAdmin: false,
  errEditingAdmin: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_ADMIN_BY_UUID.REQUEST: {
      const { isReload } = action
      return {
        ...state,
        isGettingAdmin: !isReload,
        isReGettingAdmin: !!isReload,
        errGettingAdmin: INIT_STATE.errGettingAdmin,
      }
    }
    case GET_ADMIN_BY_UUID.SUCCESS: {
      const { admin } = action
      return {
        ...state,
        isGettingAdmin: false,
        isReGettingAdmin: false,
        admin: unpackAdmin(admin),
        lastSuccessDate: new Date(),
      }
    }
    case GET_ADMIN_BY_UUID.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAdmin: false,
        isReGettingAdmin: false,
        errGettingAdmin: error,
      }
    }
    case CREATE_ADMIN.REQUEST: {
      return {
        ...state,
        statusCreatingAdmin: STATUS.pending,
        errCreatingAdmin: INIT_STATE.errCreatingAdmin,
      }
    }
    case CREATE_ADMIN.SUCCESS: {
      const { admin } = action
      return {
        ...state,
        statusCreatingAdmin: STATUS.resolved,
        admin: unpackAdmin(admin),
      }
    }
    case CREATE_ADMIN.ERROR: {
      const { error } = action
      return {
        ...state,
        statusCreatingAdmin: STATUS.rejected,
        errCreatingAdmin: error,
      }
    }
    case RESET_CREATE_ADMIN: {
      return {
        ...state,
        statusCreatingAdmin: STATUS.idle,
      }
    }
    case EDIT_ADMIN.REQUEST: {
      return {
        ...state,
        isEditingAdmin: true,
        editAdminSuccess: false,
        errEditingAdmin: INIT_STATE.errEditingAdmin,
      }
    }
    case EDIT_ADMIN.SUCCESS: {
      const { admin } = action
      return {
        ...state,
        isEditingAdmin: false,
        editAdminSuccess: true,
        admin: unpackAdmin(admin),
      }
    }
    case EDIT_ADMIN.ERROR: {
      const { error } = action
      return {
        ...state,
        isEditingAdmin: false,
        editAdminSuccess: false,
        errEditingAdmin: error,
      }
    }
    case RESET_EDIT_ADMIN: {
      return {
        ...state,
        editAdminSuccess: false,
      }
    }
    case RESET_ADMIN: {
      return {
        ...state,
        admin: INIT_STATE.admin,
        errEditingAdmin: INIT_STATE.errEditingAdmin,
      }
    }
    default:
      return { ...state }
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import BarriersTableCard from 'components/Barriers/BarriersTableCard'
import BarrierOpeningsTableCard from 'components/BarrierOpenings/BarrierOpeningsTableCard'
import CarParksNumbersCard from 'components/CarParks/CarParksNumbersCard'
import TicketsNumbersCard from 'components/Tickets/TicketsNumbersCard'
import AppUserNumbersCard from 'components/Clients/AppUsers/AppUsersNumbersCard'
import { FREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import { endpoints } from 'services/barrier-openings/barrier-openings-service'
import { BARRIER_ONLINE_STATES, isTriveParkAdmin } from 'util/custom'
import {
  getBarriersAct,
  getGeneralStatisticsAct,
  resetBarriersAct,
  resetBarriersFiltersAct,
  resetGeneralStatisticsAct,
} from 'redux/Actions'
/* -------------------------------------------------------------------------- */

class HomeContainer extends Component {
  componentDidMount() {
    const { resetBarriersFiltersAct } = this.props
    resetBarriersFiltersAct()

    this.loadData()
    this.setIntervalForLoading()
  }

  componentDidUpdate = (prevProps) => {
    const { selectedOperator, selectedCarParks } = this.props
    const { selectedOperator: _selectedOperator, selectedCarParks: _selectedCarParks } = prevProps
    if (selectedOperator !== _selectedOperator || selectedCarParks !== _selectedCarParks) {
      this.loadData()
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoReload)
    const { resetGeneralStatisticsAct, resetBarriersAct } = this.props
    resetGeneralStatisticsAct()
    resetBarriersAct()
  }

  setIntervalForLoading = () => {
    this.autoReload = setInterval(() => this.loadData(true), FREQUENT_UPDATE_TIME_IN_MS)
  }

  loadData = (isReload) => {
    const {
      getGeneralStatisticsAct,
      getBarriersAct,
      getBarrierOpenings,
      selectedOperator,
      selectedCarParks,
    } = this.props
    getGeneralStatisticsAct({ isReload })
    getBarriersAct({
      isReload,
      onlineState: BARRIER_ONLINE_STATES.offline,
      page: 0,
      rows: 50,
    })
    getBarrierOpenings({
      operatorUuid: selectedOperator,
      carParkUuid: selectedCarParks,
    })
  }

  render() {
    const { errors, t, role, selectedOperator, barrierOpenings } = this.props
    const isTpAdmin = !selectedOperator && isTriveParkAdmin(role)

    return (
      <BasicPageContainerLoadable helmetTitle={t('HomeContainer.title')} pageTitle={t('HomeContainer.title')}>
        <ErrorAlertContainer errors={errors} />
        <PageGrid>
          {isTpAdmin && (
            <Grid item xs={12} md={isTpAdmin ? 4 : 6}>
              <AppUserNumbersCard />
            </Grid>
          )}
          <Grid item xs={12} md={isTpAdmin ? 4 : 6}>
            <CarParksNumbersCard />
          </Grid>
          <Grid item xs={12} md={isTpAdmin ? 4 : 6}>
            <TicketsNumbersCard />
          </Grid>
          <Grid item xs={12}>
            <BarrierOpeningsTableCard
              dashboardUsage
              isLoading={barrierOpenings.isLoading}
              barrierOpenings={barrierOpenings?.data?.content}
            />
          </Grid>
          <Grid item xs={12}>
            <BarriersTableCard dashboardUsage />
          </Grid>
        </PageGrid>
      </BasicPageContainerLoadable>
    )
  }
}

/* -------------------------------------------------------------------------- */
HomeContainer.propTypes = {
  role: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getBarriersAct: PropTypes.func.isRequired,
  getGeneralStatisticsAct: PropTypes.func.isRequired,
  getBarrierOpenings: PropTypes.func.isRequired,
  barrierOpenings: PropTypes.shape().isRequired,
  selectedCarParks: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOperator: PropTypes.string,
  resetBarriersAct: PropTypes.func.isRequired,
  resetBarriersFiltersAct: PropTypes.func.isRequired,
  resetGeneralStatisticsAct: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

HomeContainer.defaultProps = {
  barriers: [],
  selectedOperator: null,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      user: { role },
    },
    globalFilters: { selectedOperator, selectedCarParks },
    barriers: { errGettingBarriers },
    carParks: { errGettingCarParks },
    statistics: { errGettingGeneralStatistics },
  } = state
  return {
    role,
    selectedOperator,
    selectedCarParks,
    barrierOpenings: endpoints.getBarrierOpenings.select({
      operatorUuid: selectedOperator,
      carParkUuid: selectedCarParks,
    })(state),
    errors: [errGettingBarriers, errGettingCarParks, errGettingGeneralStatistics],
  }
}

const mapDispatch = {
  getBarrierOpenings: endpoints.getBarrierOpenings.initiate,
  getBarriersAct,
  getGeneralStatisticsAct,
  resetBarriersAct,
  resetBarriersFiltersAct,
  resetGeneralStatisticsAct,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatch))(HomeContainer)

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'
/* -------------------------------------------------------------------------- */

const MoneyAmount = ({ valueInCent }) => {
  const { t } = useTranslation()
  return <div>{t('MoneyAmount.centInEuro', { cent: valueInCent || 0 })}</div>
}

MoneyAmount.propTypes = {
  valueInCent: PropTypes.number,
}

MoneyAmount.defaultProps = {
  valueInCent: null,
}

export default MoneyAmount

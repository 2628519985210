import axiosInstance from 'api/axiosConfig'
import { PARAMS_SALES, PATH_SEGMENTS_SALES } from 'api/sales/salesModels'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

export const getTransactionsReq = ({ carParkUuids, date, interval, operatorUuid, page, rows }) => {
  const { CAR_PARK_UUIDS, DATE, INTERVAL, OPERATOR_UUID, PAGE_NUMBER, PAGE_SIZE } = PARAMS_SALES
  const qs = getQueryString({
    [CAR_PARK_UUIDS]: carParkUuids,
    [DATE]: date,
    [INTERVAL]: interval,
    [OPERATOR_UUID]: operatorUuid,
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
  })
  const url = `${PATH_SEGMENTS_SALES.SALES}${qs}`
  return axiosInstance.get(url).then((res) => {
    const { data } = res
    return data
  })
}

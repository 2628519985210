import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { isOperatorManager } from 'util/custom'
import AdminInfoCard from 'components/Admin/AdminInfoCard'
import LanguageCard from 'components/Admin/LanguageCard'
import CarParksCard from 'components/Admin/CarParksCard'
/* -------------------------------------------------------------------------- */

const AdminGrid = (props) => {
  const role = useSelector((state) => state.admin.admin.role)
  const { isCreate, isEdit, formikProps } = props

  const formikRole = formikProps?.values?.role
  const isOpManager = isOperatorManager(isCreate || isEdit ? formikRole : role)

  return (
    <>
      <Grid item xs={12} sm={12} md={isOpManager ? 4 : 6}>
        <AdminInfoCard isCreate={isCreate} isEdit={isEdit} formikProps={formikProps} />
      </Grid>
      {isOpManager && (
        <Grid item xs={12} sm={6} md={4}>
          <CarParksCard isCreate={isCreate} isEdit={isEdit} formikProps={formikProps} />
        </Grid>
      )}
      <Grid item xs={12} sm={isOpManager ? 6 : 12} md={isOpManager ? 4 : 6}>
        <LanguageCard isCreate={isCreate} isEdit={isEdit} />
      </Grid>
    </>
  )
}

AdminGrid.propTypes = {
  formikProps: PropTypes.shape(),
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

AdminGrid.defaultProps = {
  formikProps: null,
  isCreate: false,
  isEdit: false,
}

export default AdminGrid

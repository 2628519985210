import React from 'react'
import { useTranslation } from 'react-i18next'
import { getYear } from 'date-fns'
import { Grid, Box, Typography, Link as MuiLink, Hidden, makeStyles } from '@material-ui/core'

import { parkmotiveLogoBlack, illustrationCombined, illustrationEntry, illustrationExit } from 'assets/svg'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  footer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  logoContainer: {
    width: 453,
  },
  parkmotiveLogo: {
    height: 20,
  },
  combinedIllustration: {
    margin: 16,
    height: 176,
  },
  entryIllustration: {
    height: 256,
  },
  exitIllustration: {
    height: 196,
  },
  footerLink: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
}))

const AuthFooter = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Hidden smDown>
        <div className={classes.logoContainer}>
          <img src={illustrationExit} className={classes.exitIllustration} alt='Illustration Exit' />
        </div>
      </Hidden>
      <Grid container justify='center' direction='column'>
        <Hidden mdUp>
          <Grid container justify='center'>
            <img
              src={illustrationCombined}
              className={classes.combinedIllustration}
              alt='Illustration Entry and Exit combined'
            />
          </Grid>
        </Hidden>
        <img src={parkmotiveLogoBlack} className={classes.parkmotiveLogo} alt='Parkmotive Logo black' />
        <Box m={2} />
        <Grid container justify='center'>
          <Typography className={classes.footerLink} variant='body1'>
            {t('AuthFooter.parkmotive', { year: getYear(new Date()) })}
          </Typography>
          <Typography className={classes.footerLink} variant='body1'>
            <MuiLink href='https://parkmotive.com/kontakt.html' id='contact' target='_blank' rel='noopener noreferrer'>
              {t('AuthFooter.contact')}
            </MuiLink>
          </Typography>
          <Typography className={classes.footerLink} variant='body1'>
            <MuiLink
              href='https://parkmotive.com/datenschutz.html'
              id='privacy'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('AuthFooter.privacy')}
            </MuiLink>
          </Typography>
          <Typography className={classes.footerLink} variant='body1'>
            <MuiLink
              href='https://parkmotive.com/impressum.html'
              id='imprint'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('AuthFooter.imprint')}
            </MuiLink>
          </Typography>
        </Grid>
        <Box m={2} />
      </Grid>
      <Hidden smDown>
        <div className={classes.logoContainer}>
          <img src={illustrationEntry} className={classes.entryIllustration} alt='Illustration Entry' />
        </div>
      </Hidden>
    </footer>
  )
}

export default AuthFooter

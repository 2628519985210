import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { getTimeAmountString } from 'util/core'
/* -------------------------------------------------------------------------- */

const TimeAmountCell = ({ valueInMin }) => {
  const { t } = useTranslation()

  return <Fragment>{getTimeAmountString(t, valueInMin)}</Fragment>
}

/* -------------------------------------------------------------------------- */
TimeAmountCell.propTypes = {
  valueInMin: PropTypes.number,
}

TimeAmountCell.defaultProps = {
  valueInMin: null,
}

export default TimeAmountCell

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import { EditButton } from 'fragments/Buttons'
import { getOperatorByUuidAct, resetOperatorAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam } from 'util/core'
import OperatorGrid from 'components/Operator/OperatorGrid'
import { useInterval } from 'hooks'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
/* -------------------------------------------------------------------------- */

const OperatorContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    errGettingOperator,
    isGettingOperator,
    isReGettingOperator,
    lastSuccessDate,
    operator: { name },
  } = useSelector((state) => state.operator)

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getOperatorByUuidAct({ uuid }))
    return () => {
      dispatch(resetOperatorAct())
    }
  }, [dispatch, uuid])

  useInterval(() => {
    if (uuid) {
      dispatch(getOperatorByUuidAct({ uuid, isReload: true }))
    }
  }, INFREQUENT_UPDATE_TIME_IN_MS)

  return (
    <BasicPageContainerLoadable
      isLoading={isGettingOperator}
      helmetTitle={t('OperatorContainer.title')}
      pageTitle={name || t('OperatorContainer.title')}
    >
      <ErrorAlertContainer errors={[errGettingOperator]} />
      <PageGrid justify='flex-end'>
        <OperatorGrid />
        <Grid container item justify='space-between' alignItems='flex-start'>
          <LastUpdateInfo
            lastSuccessDate={lastSuccessDate}
            isUpdating={isReGettingOperator}
            reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
          />
          <EditButton
            component={Link}
            to={{
              pathname: APP.OPERATOR_EDIT,
              search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
            }}
          />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default OperatorContainer

import React from 'react'

import MoneyAmount from 'fragments/Misc/MoneyAmount'
import { months } from 'util/core'
import { balanceItems } from 'util/custom'
/* -------------------------------------------------------------------------- */

export default ({ data, t, localeCode }) => [
  {
    name: 'label',
    label: t('SalesPerYearColumns.balanceItem'),
  },
  ...months(localeCode).map((month) => ({
    name: 'value',
    label: month.label,
    options: {
      customBodyRender: (v) => {
        if (v === balanceItems.turnover)
          return <MoneyAmount valueInCent={data?.month?.[month.value]?.transactionsSumInCent} />
        if (v === balanceItems.fees) return <MoneyAmount valueInCent={data?.month?.[month.value]?.feesSumInCent} />
        if (v === balanceItems.refunds)
          return <MoneyAmount valueInCent={data?.month?.[month.value]?.refundsSumInCent} />
        if (v === balanceItems.profit)
          return <MoneyAmount valueInCent={data?.month?.[month.value]?.operatorGrossInCent} />
      },
    },
  })),
  {
    name: 'value',
    label: '∑',
    options: {
      customBodyRender: (v) => {
        if (v === balanceItems.turnover) return <MoneyAmount valueInCent={data?.year?.transactionsSumInCent} />
        if (v === balanceItems.fees) return <MoneyAmount valueInCent={data?.year?.feesSumInCent} />
        if (v === balanceItems.refunds) return <MoneyAmount valueInCent={data?.year?.refundsSumInCent} />
        if (v === balanceItems.profit) return <MoneyAmount valueInCent={data?.year?.operatorGrossInCent} />
      },
    },
  },
]

import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects'

import { GET_BARRIERS, RESET_BARRIERS_FILTERS } from 'redux/Types'
import { getBarriersSuccessAct, getBarriersErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getBarriersReq } from 'api/requests'
import { isOnlineBarrierState } from 'util/custom'
/* -------------------------------------------------------------------------- */

function* workerGetBarriers({ onlineState: onlineStateAct }) {
  try {
    // Collect query params
    const {
      globalFilters: { selectedOperator, selectedCarParks },
      barriers: {
        filters: { operatingState, onlineState },
        pagination: { page, rows },
      },
    } = yield select()

    const isOnline = onlineStateAct ? isOnlineBarrierState(onlineState) : undefined

    // API call
    const data = yield call(getBarriersReq, {
      operatorUuid: selectedOperator,
      carParkUuids: selectedCarParks,
      operatingState,
      isOnline,
      page,
      rows,
    })

    yield put(getBarriersSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getBarriersErrorAct))
  }
}

function* watcherGetBarriers() {
  yield takeLatest([GET_BARRIERS.REQUEST, RESET_BARRIERS_FILTERS], workerGetBarriers)
}

// ----- EXPORT -----

export default function* rootSaga() {
  yield all([fork(watcherGetBarriers)])
}

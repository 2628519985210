import { ADMIN_API_BASE_PATH } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_AUTH = {
  LOGIN: `${ADMIN_API_BASE_PATH}/auth/login`,
  LOGOUT: `${ADMIN_API_BASE_PATH}/auth/logout`,
  ME: `${ADMIN_API_BASE_PATH}/me`,
}

export const BODY_AUTH = {
  CAR_PARK_UUIDS: 'carParkUuids',
  DELETED_AT: 'deletedAt',
  EMAIL: 'email',
  IS_ACTIVATED: 'isActivated',
  IS_DELETED: 'isDeleted',
  IS_EMAIL_VERIFIED: 'isEmailVerified',
  LANGUAGE: 'language',
  OPERATOR_UUID: 'operatorUuid',
  ROLE: 'role',
  UUID: 'uuid',
  PASSWORD: 'password',
}

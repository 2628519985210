import React from 'react'
import { Line } from 'react-chartjs-2'
import * as PropTypes from 'prop-types'

import EmptyChartComponent from 'components/Occupancy/EmptyChartComponent'
import { lineChartOptions, getLineChartDataWithConfig } from 'util/custom'
/* -------------------------------------------------------------------------- */

const OccupancyStatsLineChart = (props) => {
  const { averageData, labels } = props

  if (averageData.every((e) => Number.isInteger(e))) {
    const data = {
      labels,
      datasets: [getLineChartDataWithConfig(averageData)],
    }
    return <Line data={data} options={lineChartOptions} height={60} />
  } else return <EmptyChartComponent />
}

/* -------------------------------------------------------------------------- */
OccupancyStatsLineChart.propTypes = {
  averageData: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
}
export default OccupancyStatsLineChart

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group as GroupIcon } from '@material-ui/icons'

import LinksCard from 'fragments/Cards/LinksCard'
import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */
const LinksToConnectedAccountsCard = () => {
  const { t } = useTranslation()
  const links = [
    {
      linkLabel: t('LinksToConnectedAccountsCard.opLink'),
      helperText: t('LinksToConnectedAccountsCard.opText'),
      linkTo: {
        pathname: APP.OPERATORS,
      },
    },
  ]
  return <LinksCard title={t('LinksToConnectedAccountsCard.title')} action={<GroupIcon />} links={links} />
}
/* ----- SETUP -------------------------------------------------------------- */
export default LinksToConnectedAccountsCard

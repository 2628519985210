import { GET_SALES, RESET_SALES_FILTERS, RESET_SALES } from 'redux/Types'
import { BODY_PAGINATION } from 'api/backendConstants'
import { unpackSales } from 'api/sales/salesUnpackers'
import { getTimeIntervalValue } from 'util/core'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  sales: unpackSales([]),
  isGettingSales: false,
  isGettingSalesSuccess: false,
  errGettingSales: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  lastSuccessDate: null,
  filters: {
    date: new Date(),
    interval: getTimeIntervalValue('MONTH'),
  },
  pagination: {
    page: 0,
    rows: 10,
    totalCount: 0,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_SALES.REQUEST: {
      const { date, interval, page, rows } = action
      return {
        ...state,
        isGettingSales: true,
        isGettingSalesSuccess: false,
        errGettingSales: INIT_STATE.errGettingSales,
        pagination: {
          ...state.pagination,
          page: page ?? state.pagination.page,
          rows: rows ?? state.pagination.rows,
        },
        filters: {
          ...state.filters,
          date: date ?? state.filters.date,
          interval: interval ?? state.filters.interval,
        },
      }
    }
    case GET_SALES.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR, TOTAL_ELEMENTS, PAGE_NUMBER, PAGE_SIZE } = BODY_PAGINATION
      return {
        ...state,
        isGettingSales: false,
        sales: unpackSales(data?.[CONTENT_ARR]),
        pagination: {
          totalCount: data?.[TOTAL_ELEMENTS] ?? state.pagination.totalCount,
          page: data?.[PAGE_NUMBER] ?? state.pagination.page,
          rows: (data?.[PAGE_SIZE] ?? state.pagination.rows) || 10,
        },
        lastSuccessDate: new Date(),
      }
    }
    case GET_SALES.ERROR: {
      const { error } = action
      return {
        ...state,
        sales: INIT_STATE.sales,
        isGettingSales: false,
        errGettingSales: error,
        pagination: INIT_STATE.pagination,
      }
    }
    case RESET_SALES_FILTERS: {
      return {
        ...state,
        isGettingSales: true,
        filters: {
          ...state.filters,
          date: INIT_STATE.filters.date,
        },
      }
    }
    case RESET_SALES: {
      return {
        ...state,
        sales: INIT_STATE.sales,
      }
    }
    default:
      return { ...state }
  }
}

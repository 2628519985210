import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Person as PersonIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import AccountStatusChip from 'fragments/IconChips/AccountStatusChip'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import TextField from 'fragments/Forms/Formik/TextField'
import { getRoleString, getRoleSelects, getCompanyName } from 'util/custom'
import { ROLES } from 'constants/generalConstants'
import company from 'constants/company'
import { isPending } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const AdminInfoCard = (props) => {
  const { t } = useTranslation()
  const {
    isGettingAdmin,
    isReGettingAdmin,
    statusCreatingAdmin,
    admin: { email, role, operatorUuid, isActivated, isDeleted, deletedAt },
  } = useSelector((state) => state.admin)
  const { operators } = useSelector((state) => state.operators)
  const { isCreate, isEdit, formikProps } = props

  const getOperatorNameAsString = (t, uuid, operators) =>
    operators.filter((op) => op.uuid === uuid)[0]?.name || company.name

  const formikOpUuid = formikProps?.values?.operatorUuid
  const setFieldValue = formikProps?.setFieldValue

  useEffect(() => {
    if (setFieldValue) {
      if (!formikOpUuid) {
        setFieldValue('role', ROLES.TP_ADMIN)
      } else {
        setFieldValue('role', '')
      }
    }
  }, [formikOpUuid, setFieldValue])

  const roleInput = () =>
    formikOpUuid ? (
      <SelectTextField name='role' variant='standard' options={getRoleSelects(t)} />
    ) : (
      getRoleString(t, ROLES.TP_ADMIN)
    )

  const rows = [
    {
      label: t('AdminInfoCard.company'),
      value:
        isCreate || isEdit
          ? getOperatorNameAsString(t, formikOpUuid, operators)
          : getCompanyName(t, operatorUuid, operators, role),
    },
    {
      label: t('AdminInfoCard.email'),
      value: isCreate || isEdit ? <TextField name='email' /> : email,
    },
    {
      label: t('AdminInfoCard.role'),
      value: isCreate || isEdit ? roleInput() : getRoleString(t, role),
    },
    {
      label: t('AdminInfoCard.accountStatus'),
      value: <AccountStatusChip isActivated={isActivated} isDeleted={isDeleted} deletedAt={deletedAt} />,
      hideOn: isCreate,
    },
  ]

  return (
    <BasicCardLoadable
      title={t('AdminInfoCard.title')}
      action={<PersonIcon />}
      isLoading={isGettingAdmin || isReGettingAdmin || isPending(statusCreatingAdmin)}
    >
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

AdminInfoCard.propTypes = {
  formikProps: PropTypes.shape(),
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

AdminInfoCard.defaultProps = {
  formikProps: null,
  isCreate: false,
  isEdit: false,
}

export default AdminInfoCard

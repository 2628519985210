import React from 'react'
import { Bar } from 'react-chartjs-2'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EmptyChartComponent from 'components/Occupancy/EmptyChartComponent'
import { barChartOptions, getDataWithConfig } from 'util/custom'
/* -------------------------------------------------------------------------- */

const OpeningsStatsBarChart = (props) => {
  const { entriesApp, entriesNormal, exitsApp, exitsNormal, labels } = props
  const { t } = useTranslation()

  if (
    entriesApp.every((e) => e === 0) &&
    entriesNormal.every((e) => e === 0) &&
    exitsApp.every((e) => e === 0) &&
    exitsNormal.every((e) => e === 0)
  ) {
    return <EmptyChartComponent />
  } else {
    const data = {
      labels,
      datasets: [
        getDataWithConfig(1, 1, t('OccupancyTableCells.entrancesNormal'), entriesNormal),
        getDataWithConfig(1, 2, t('OccupancyTableCells.entrancesApp'), entriesApp),
        getDataWithConfig(2, 1, t('OccupancyTableCells.exitsNormal'), exitsNormal),
        getDataWithConfig(2, 2, t('OccupancyTableCells.exitsApp'), exitsApp),
      ],
    }
    return <Bar data={data} options={barChartOptions} height={60} />
  }
}

/* -------------------------------------------------------------------------- */
OpeningsStatsBarChart.propTypes = {
  entriesApp: PropTypes.arrayOf(PropTypes.number).isRequired,
  entriesNormal: PropTypes.arrayOf(PropTypes.number).isRequired,
  exitsApp: PropTypes.arrayOf(PropTypes.number).isRequired,
  exitsNormal: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default OpeningsStatsBarChart

import { call, put, takeLatest, all, fork } from 'redux-saga/effects'

import { GET_APP_USERS } from 'redux/Types'
import { getAppUsersSuccessAct, getAppUsersErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getAppUsersReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetAppUsers() {
  try {
    const data = yield call(getAppUsersReq)
    yield put(getAppUsersSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAppUsersErrorAct))
  }
}

function* watcherGetAppUsers() {
  yield takeLatest(GET_APP_USERS.REQUEST, workerGetAppUsers)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetAppUsers)])
}

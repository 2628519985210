import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FilterGridCard from 'fragments/Cards/FilterGridCard'
import OfferTypeSelect from 'fragments/Forms/Custom/OfferTypeSelect'
import OfferVisibilitySelect from 'fragments/Forms/Custom/OfferVisibilitySelect'
import { getOffersAct, resetOffersFiltersAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const OffersFilterCard = () => {
  const {
    filters: { offerType, isVisible },
  } = useSelector((state) => state.offers)
  const { isGettingCarParks } = useSelector((state) => state.carParks)
  const dispatch = useDispatch()

  const handleFilterChange = (key) => (v) => dispatch(getOffersAct({ [key]: v }))

  return (
    <FilterGridCard isLoading={isGettingCarParks} resetFilters={() => dispatch(resetOffersFiltersAct())}>
      <OfferTypeSelect selected={offerType} onChange={handleFilterChange('offerType')} />
      <OfferVisibilitySelect selected={isVisible} onChange={handleFilterChange('isVisible')} />
    </FilterGridCard>
  )
}

export default OffersFilterCard

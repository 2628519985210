import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects'

import { GET_OPERATORS } from 'redux/Types'
import { getAllOperatorsReq } from 'api/requests'
import { getOperatorsSuccessAct, getOperatorsErrorAct, catchSagaErrorsAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */
function* workerGetOperators(action) {
  try {
    const { page, rows } = action
    const {
      operators: { operators },
    } = yield select()

    if (page || rows) {
      yield put(getOperatorsSuccessAct(operators))
    } else {
      const response = yield call(getAllOperatorsReq)
      yield put(getOperatorsSuccessAct(response))
    }
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getOperatorsErrorAct))
  }
}

function* watcherGetOperators() {
  yield takeLatest(GET_OPERATORS.REQUEST, workerGetOperators)
}

// ----- Erstellen ---
export default function* rootSaga() {
  yield all([fork(watcherGetOperators)])
}

import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { GET_SALES, RESET_SALES_FILTERS } from 'redux/Types'
import { getSalesSuccessAct, getSalesErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getTransactionsReq } from 'api/requests'
import { toISOStringNoZ, getTimeIntervalValue } from 'util/core'
/* -------------------------------------------------------------------------- */
function* workerGetSales() {
  try {
    const {
      sales: {
        filters: { date, interval },
        pagination: { page, rows },
      },
      globalFilters: { selectedOperator: operatorUuid, selectedCarParks: carParkUuids },
    } = yield select()

    let data = []
    if (interval === getTimeIntervalValue('YEAR')) {
      // Get data for ALL car parks:
      // page/row -> null, no car park filters
      data = yield call(getTransactionsReq, {
        carParkUuids: [],
        date: toISOStringNoZ(date),
        interval,
        operatorUuid,
        page: 0,
        rows: 1000,
      })
    } else {
      data = yield call(getTransactionsReq, {
        carParkUuids,
        date: toISOStringNoZ(date),
        interval,
        operatorUuid,
        page,
        rows,
      })
    }
    yield put(getSalesSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getSalesErrorAct))
  }
}

function* watcherGetSales() {
  yield takeLatest([GET_SALES.REQUEST, RESET_SALES_FILTERS], workerGetSales)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetSales)])
}

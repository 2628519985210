import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
/* -------------------------------------------------------------------------- */

const ConfirmDialog = (props) => {
  const {
    open,
    dialogTitle,
    dialogContent,
    confirmLabel,
    cancelLabel,
    onConfirm: handleConfirm,
    onClose: handleClose,
    cancelAllowed,
  } = props
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelAllowed && (
          <Button onClick={handleClose} color='secondary'>
            {cancelLabel || t('ConfirmDialog.cancel')}
          </Button>
        )}
        <Button onClick={handleConfirm} color='primary' autoFocus>
          {confirmLabel || t('ConfirmDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
/* -------------------------------------------------------------------------- */
ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  dialogTitle: PropTypes.string.isRequired,
  dialogContent: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  cancelAllowed: PropTypes.bool,
}

ConfirmDialog.defaultProps = {
  open: false,
  confirmLabel: null,
  cancelLabel: null,
  cancelAllowed: true,
}

export default ConfirmDialog

import yup from 'util/yup'
import isEmpty from 'lodash/isEmpty'

import { TIME_UNITS } from 'util/core'
import { OFFER_TYPE_VALUES } from 'api/offerMgmt/offerMgmtModels'
/* -------------------------------------------------------------------------- */

export const validationSchema = yup.object().shape(
  {
    accessEarliestInMinFromMidnight: yup
      .date()
      .notEqual(yup.ref('accessLatestInMinFromMidnight'))
      .nullable()
      .when('accessLatestInMinFromMidnight', {
        is: (val) => val !== null,
        then: yup.date().notEqual(yup.ref('accessLatestInMinFromMidnight')).required(),
        otherwise: yup.date().notEqual(yup.ref('accessLatestInMinFromMidnight')).nullable(),
      }),
    accessLatestInMinFromMidnight: yup
      .date()
      .notEqual(yup.ref('accessEarliestInMinFromMidnight'))
      .nullable()
      .when('accessEarliestInMinFromMidnight', {
        is: (val) => val !== null,
        then: yup.date().notEqual(yup.ref('accessEarliestInMinFromMidnight')).required(),
        otherwise: yup.date().notEqual(yup.ref('accessEarliestInMinFromMidnight')).nullable(),
      }),
    availableFrom: yup.date().smallerThan(yup.ref('availableUntil')).nullable(),
    availableUntil: yup.date().greaterThan(yup.ref('availableFrom')).nullable(),
    contingent: yup.number().typeError().min(0).integer(),
    isVisible: yup.boolean().required(),
    localizedInformation: yup.object().shape({
      de: yup.string().trim(),
      en: yup
        .string()
        .trim()
        .when('de', {
          is: (val) => !isEmpty(val),
          then: yup.string().trim().required(),
          otherwise: yup.string().trim(),
        }),
    }),
    localizedNames: yup.object().shape({
      de: yup.string().trim(),
      en: yup
        .string()
        .trim()
        .when('de', {
          is: (val) => !isEmpty(val),
          then: yup.string().trim().required(),
          otherwise: yup.string().trim(),
        }),
    }),
    priceInEuro: yup.number().positive(),
    type: yup.string().oneOf([OFFER_TYPE_VALUES.LONG_TERM, OFFER_TYPE_VALUES.SHORT_TERM]).required(),
    usageDuration: yup.number().min(1).integer().required(),
    usageUnit: yup.string().oneOf(Object.values(TIME_UNITS)).required(),
    validityDuration: yup.number().when('type', {
      is: OFFER_TYPE_VALUES.LONG_TERM,
      then: null,
      otherwise: yup.number().positive().integer().required(),
    }),
    validityUnit: yup.string().oneOf(Object.values(TIME_UNITS)).required(),
  },
  [['accessLatestInMinFromMidnight', 'accessEarliestInMinFromMidnight']],
)

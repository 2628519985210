import React from 'react'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

import SelectTextField from 'fragments/Forms/SelectTextField'
import { getOfferTypeSelects } from 'util/custom'
/* -------------------------------------------------------------------------- */

const OfferTypeSelect = (props) => {
  const { onChange, selected } = props

  const { t } = useTranslation()

  return (
    <SelectTextField
      label={t('OfferTypeSelect.label')}
      variant='outlined'
      selected={selected}
      options={getOfferTypeSelects(t)}
      onChange={onChange}
    />
  )
}

/* -------------------------------------------------------------------------- */
OfferTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
}

OfferTypeSelect.defaultProps = {
  selected: null,
}

export default OfferTypeSelect

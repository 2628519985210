import { get } from 'lodash'

import { LOGIN_USER, LOGOUT_USER, GET_CURRENT_USER, CHANGE_MY_PROFILE } from 'redux/Types'
/* -------------------------------------------------------------------------- */

// ----- LOGIN -----

export const logInUserAct = (params) => ({
  type: LOGIN_USER.REQUEST,
  email: get(params, 'email', null),
  password: get(params, 'password', null),
})

export const logInUserSuccessAct = (user) => ({
  type: LOGIN_USER.SUCCESS,
  user,
})

export const logInUserErrorAct = (error) => ({
  type: LOGIN_USER.ERROR,
  error,
})

// ----- LOGOUT -----

export const logOutUserAct = () => ({
  type: LOGOUT_USER.REQUEST,
})

export const logOutUserSuccessAct = () => ({
  type: LOGOUT_USER.SUCCESS,
})

export const logOutUserErrorAct = (error) => ({
  type: LOGOUT_USER.ERROR,
  error,
})

// ----- GET USER -----

export const getCurrentUserAct = () => ({
  type: GET_CURRENT_USER.REQUEST,
})

export const getCurrentUserSuccessAct = (user) => ({
  type: GET_CURRENT_USER.SUCCESS,
  user,
})

export const getCurrentUserErrorAct = (error) => ({
  type: GET_CURRENT_USER.ERROR,
  error,
})

// ----- CHANGE MY PROFILE -----

export const changeMyProfileAct = (params) => ({
  type: CHANGE_MY_PROFILE.REQUEST,
  languageCode: get(params, 'languageCode', null),
})

export const changeMyProfileSuccessAct = (user) => ({
  type: CHANGE_MY_PROFILE.SUCCESS,
  user,
})

export const changeMyProfileErrorAct = (error) => ({
  type: CHANGE_MY_PROFILE.ERROR,
  error,
})

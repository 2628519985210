import AdminContainer from 'components/Admin/AdminContainer'
import AdminCreateContainer from 'components/Admin/Create/AdminCreateContainer'
import AdminEditContainer from 'components/Admin/Edit/AdminEditContainer'
import AdminsContainer from 'components/Admins/AdminsContainer'
import AppUserContainer from 'components/AppUser/AppUserContainer'
import AppUserConnectionsContainer from 'components/AppUser/BarrierConnections/BarrierConnectionsContainer'
import PaymentsContainer from 'components/AppUser/Payments/PaymentsContainer'
import ClientsContainer from 'components/Clients/ClientsContainer'
import BarrierContainer from 'components/Barrier/BarrierContainer'
import BarrierEditContainer from 'components/Barrier/Edit/BarrierEditContainer'
import BarrierOpeningsContainer from 'components/BarrierOpenings/BarrierOpeningsContainer'
import BarriersContainer from 'components/Barriers/BarriersContainer'
import CarParkBarriersContainer from 'components/CarPark/CarParkBarriersContainer'
import CarParkInfoContainer from 'components/CarPark/Info/InfoContainer'
import CarParkInfoCreateContainer from 'components/CarPark/Info/Create/InfoCreateContainer'
import CarParkInfoEditContainer from 'components/CarPark/Info/Edit/InfoEditContainer'
import CarParkOffersContainer from 'components/CarPark/CarParkOffersContainer'
import CarParkOpeningsContainer from 'components/CarPark/CarParkOpeningsContainer'
import CarParksContainer from 'components/CarParks/CarParksContainer'
import CarParkTicketsContainer from 'components/CarPark/CarParkTicketsContainer'
import HomeContainer from 'components/Home/HomeContainer'
import OccupancyContainer from 'components/Occupancy/OccupancyContainer'
import OfferContainer from 'components/Offer/OfferContainer'
import OfferCreateContainer from 'components/Offer/Create/OfferCreateContainer'
import OfferEditContainer from 'components/Offer/Edit/OfferEditContainer'
import OffersContainer from 'components/Offers/OffersContainer'
import OperatorContainer from 'components/Operator/OperatorContainer'
import OperatorCreateContainer from 'components/Operator/Create/OperatorCreateContainer'
import OperatorEditContainer from 'components/Operator/Edit/OperatorEditContainer'
import OperatorsContainer from 'components/Operators/OperatorsContainer'
import ProfileContainer from 'components/Profile/ProfileContainer'
import SalesPerMonthContainer from 'components/Sales/SalesPerMonth/SalesPerMonthContainer'
import SalesPerYearContainer from 'components/Sales/SalesPerYear/SalesPerYearContainer'
import SettingsContainer from 'components/Settings/SettingsContainer'
import TicketContainer from 'components/Ticket/TicketContainer'
import TicketsContainer from 'components/Tickets/TicketsContainer'

import NoRole from 'fragments/NoRole'
import { IsHavingRole, IsTpAdmin, IsOperatorAdmin, IsOperatorManager } from 'components/Session/AuthorizationHOC'
/* -------------------------------------------------------------------------- */

export const Admin = IsOperatorAdmin(AdminContainer)
export const AdminCreate = IsOperatorAdmin(AdminCreateContainer)
export const AdminEdit = IsOperatorAdmin(AdminEditContainer)
export const Admins = IsTpAdmin(AdminsContainer)
export const AdminsOperator = IsOperatorAdmin(AdminsContainer)
export const AppUser = IsTpAdmin(AppUserContainer)
export const AppUserConnections = IsTpAdmin(AppUserConnectionsContainer)
export const Payments = IsTpAdmin(PaymentsContainer)
export const Clients = IsTpAdmin(ClientsContainer)
export const Barrier = IsTpAdmin(BarrierContainer)
export const BarrierEdit = IsTpAdmin(BarrierEditContainer)
export const BarrierOpenings = IsOperatorManager(BarrierOpeningsContainer)
export const Barriers = IsOperatorManager(BarriersContainer)
export const CarParkBarriers = IsOperatorManager(CarParkBarriersContainer)
export const CarParkInfo = IsOperatorManager(CarParkInfoContainer)
export const CarParkInfoCreate = IsTpAdmin(CarParkInfoCreateContainer)
export const CarParkInfoEdit = IsOperatorManager(CarParkInfoEditContainer)
export const CarParkOffers = IsOperatorManager(CarParkOffersContainer)
export const CarParkOpenings = IsOperatorManager(CarParkOpeningsContainer)
export const CarParks = IsOperatorManager(CarParksContainer)
export const CarParkTickets = IsOperatorManager(CarParkTicketsContainer)
export const Home = IsHavingRole(NoRole, HomeContainer)
export const Occupancy = IsOperatorManager(OccupancyContainer)
export const Offer = IsOperatorManager(OfferContainer)
export const OfferCreate = IsOperatorManager(OfferCreateContainer)
export const OfferEdit = IsOperatorManager(OfferEditContainer)
export const Offers = IsOperatorManager(OffersContainer)
export const Operator = IsOperatorAdmin(OperatorContainer)
export const OperatorCreate = IsTpAdmin(OperatorCreateContainer)
export const OperatorEdit = IsOperatorAdmin(OperatorEditContainer)
export const Operators = IsTpAdmin(OperatorsContainer)
export const Profile = ProfileContainer
export const SalesPerMonth = IsOperatorManager(SalesPerMonthContainer)
export const SalesPerYear = IsOperatorManager(SalesPerYearContainer)
export const Settings = IsOperatorAdmin(SettingsContainer)
export const TicketDetail = IsOperatorManager(TicketContainer)
export const Tickets = IsOperatorManager(TicketsContainer)

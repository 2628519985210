import {
  GET_CAR_PARK_BY_UUID,
  RESET_CAR_PARK,
  EDIT_CAR_PARK,
  RESET_EDIT_CAR_PARK,
  CREATE_CAR_PARK,
  RESET_CREATE_CAR_PARK,
} from 'redux/Types'
/* -------------------------------------------------------------------------- */

// ----- LOAD -----

export const getCarParkByUuidAct = (params) => ({
  type: GET_CAR_PARK_BY_UUID.REQUEST,
  isReload: params?.isReload ?? null,
  uuid: params?.uuid ?? null,
})

export const getCarParkByUuidSuccessAct = (data) => ({
  type: GET_CAR_PARK_BY_UUID.SUCCESS,
  data,
})

export const getCarParkByUuidErrorAct = (error) => ({
  type: GET_CAR_PARK_BY_UUID.ERROR,
  error,
})

export const resetCarParkAct = () => ({
  type: RESET_CAR_PARK,
})

// ----- EDIT -----

export const editCarParkAct = (carParkUpdatable, uuid) => ({
  type: EDIT_CAR_PARK.REQUEST,
  carParkUpdatable,
  uuid,
})

export const editCarParkSuccessAct = (carPark) => ({
  type: EDIT_CAR_PARK.SUCCESS,
  carPark,
})

export const editCarParkErrorAct = (error) => ({
  type: EDIT_CAR_PARK.ERROR,
  error,
})

export const resetEditCarParkAct = () => ({
  type: RESET_EDIT_CAR_PARK,
})

// ----- CREATE -----

export const createCarParkAct = (carParkCreatable) => ({
  type: CREATE_CAR_PARK.REQUEST,
  carParkCreatable,
})

export const createCarParkSuccessAct = (carPark) => ({
  type: CREATE_CAR_PARK.SUCCESS,
  carPark,
})

export const createCarParkErrorAct = (error) => ({
  type: CREATE_CAR_PARK.ERROR,
  error,
})

export const resetCreateCarParkAct = () => ({
  type: RESET_CREATE_CAR_PARK,
})

import React from 'react'

import VisibilityChip from 'fragments/IconChips/VisibilityChip'
import { getOperatorNameAsString, getOccupancy, isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */

export default ({ t, carParks, operators, role }) => [
  {
    name: 'operatorUuid',
    label: t('CarParksTableColumns.operator'),
    options: {
      // https://github.com/gregnb/mui-datatables/issues/1576
      display: isTriveParkAdmin(role).toString(),
      customBodyRender: (uuid) => getOperatorNameAsString(t, uuid, operators),
    },
  },
  {
    name: 'city',
    label: t('CarParksTableColumns.city'),
  },
  {
    name: 'name',
    label: t('CarParksTableColumns.name'),
  },
  {
    name: 'uuid',
    label: t('CarParksTableColumns.occupancy'),
    options: {
      customBodyRender: (uuid) => {
        const { countUserInside, countParkingLots } = carParks.find((o) => o.uuid === uuid)
        return getOccupancy(countUserInside, countParkingLots)
      },
    },
  },
  {
    name: 'uuid',
    label: t('CarParksTableColumns.barriers'),
    options: {
      customBodyRender: (uuid) => {
        const { barriers } = carParks.find((o) => o.uuid === uuid)
        return (barriers ?? []).length
      },
    },
  },
  {
    name: 'uuid',
    label: t('CarParksTableColumns.visibility'),
    options: {
      customBodyRender: (uuid) => {
        const { isVisible } = carParks.find((o) => o.uuid === uuid)
        return <VisibilityChip isVisible={isVisible} />
      },
    },
  },
]

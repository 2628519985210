import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects'

import { GET_GENERAL_STATISTICS } from 'redux/Types'
import { getGeneralStatisticsSuccessAct, getGeneralStatisticsErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getGeneralStatisticsReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetGeneralStatistics() {
  try {
    // Collect query params
    const {
      globalFilters: { selectedOperator },
    } = yield select()

    // API call
    const generalStatistics = yield call(getGeneralStatisticsReq, {
      operatorUuid: selectedOperator,
    })

    yield put(getGeneralStatisticsSuccessAct({ generalStatistics }))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getGeneralStatisticsErrorAct))
  }
}

function* watcherGetGeneralStatistics() {
  yield takeLatest(GET_GENERAL_STATISTICS.REQUEST, workerGetGeneralStatistics)
}

// ----- EXPORT -----

export default function* rootSaga() {
  yield all([fork(watcherGetGeneralStatistics)])
}

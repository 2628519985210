import { GET_OFFERS, RESET_OFFERS, RESET_OFFERS_FILTERS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getOffersAct = (params) => ({
  type: GET_OFFERS.REQUEST,
  isReload: params?.isReload,
  page: params?.page,
  rows: params?.rows,
  offerType: params?.offerType,
  isVisible: params?.isVisible,
  carParkUuids: params?.carParkUuids,
})

export const getOffersSuccessAct = (data) => ({
  type: GET_OFFERS.SUCCESS,
  data,
})

export const getOffersErrorAct = (error) => ({
  type: GET_OFFERS.ERROR,
  error,
})

export const resetOffersFiltersAct = () => ({
  type: RESET_OFFERS_FILTERS,
})

export const resetOffersAct = () => ({
  type: RESET_OFFERS,
})

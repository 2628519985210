import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

import SelectTextField from 'fragments/Forms/SelectTextField'
import { getBarriersAct, resetBarriersFiltersAct } from 'redux/Actions'
import { getBarrierOperatingStates } from 'util/custom'
import FilterGridCard from 'fragments/Cards/FilterGridCard'
/* -------------------------------------------------------------------------- */

const BarriersFilterCard = (props) => {
  const { isGettingBarriers, operatingState, getBarriersAct, resetBarriersFiltersAct } = props

  const { t } = useTranslation()

  const operatingStates = getBarrierOperatingStates(t)

  return (
    <FilterGridCard isLoading={isGettingBarriers} resetFilters={resetBarriersFiltersAct}>
      <SelectTextField
        label={t('BarriersFilterCard.operatingState')}
        selected={operatingState}
        options={operatingStates}
        onChange={(v) => getBarriersAct({ operatingState: v })}
      />
    </FilterGridCard>
  )
}

/* ----- SETUP -------------------------------------------------------------- */
BarriersFilterCard.propTypes = {
  getBarriersAct: PropTypes.func.isRequired,
  resetBarriersFiltersAct: PropTypes.func.isRequired,
  isGettingBarriers: PropTypes.bool.isRequired,
  operatingState: PropTypes.string.isRequired,
}

const mapStateToProps = ({ barriers }) => {
  const {
    isGettingBarriers,
    filters: { operatingState },
  } = barriers
  return {
    isGettingBarriers,
    operatingState,
  }
}

const mapDispatch = {
  getBarriersAct,
  resetBarriersFiltersAct,
}

export default connect(mapStateToProps, mapDispatch)(BarriersFilterCard)

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from 'components/Clients/AppUsers/AppUsersTableColumns'
import options from 'components/Clients/AppUsers/appUsersTableOptions'
/* -------------------------------------------------------------------------- */

const AppUsersTableCard = () => {
  const { appUsers, isGettingAppUsers } = useSelector((state) => state.appUsers)
  const { t } = useTranslation()
  let history = useHistory()

  return (
    <BasicCardLoadable isLoading={isGettingAppUsers} title={t('AppUsersTableCard.title')}>
      <MuiDataTableThemed data={appUsers} columns={columns({ appUsers, t })} options={options({ appUsers, history })} />
    </BasicCardLoadable>
  )
}

export default AppUsersTableCard

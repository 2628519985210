import { GET_MINIFIED_CAR_PARKS, SET_SELECTED_CAR_PARKS, SET_SELECTED_OPERATOR } from 'redux/Types'
import { BODY_PAGINATION } from 'api/backendConstants'
import { unpackCarParksMinified } from 'api/carParkMgmt/carParkMgmtUnpackers'
import { STATUS } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  // CAR PARKS
  errGettingCarParks: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  carParks: [], // [{ uuid: string, name: string }, ...]
  statusGettingCarParks: STATUS.idle,
  selectedCarParks: [], // [uuid, ...]
  // OPERATORS
  operators: [], // [{ uuid: string, name: string }, ...]
  selectedOperator: null, // uuid
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_MINIFIED_CAR_PARKS.REQUEST: {
      return {
        ...state,
        statusGettingCarParks: STATUS.pending,
        errGettingCarParks: INIT_STATE.errGettingCarParks,
        selectedCarParks: INIT_STATE.selectedCarParks,
      }
    }
    case GET_MINIFIED_CAR_PARKS.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR: CAR_PARKS } = BODY_PAGINATION
      const carParks = data[CAR_PARKS]
      return {
        ...state,
        statusGettingCarParks: STATUS.resolved,
        carParks: unpackCarParksMinified(carParks),
      }
    }
    case GET_MINIFIED_CAR_PARKS.ERROR: {
      const { error } = action
      return {
        ...state,
        statusGettingCarParks: STATUS.rejected,
        errGettingCarParks: error,
      }
    }
    case SET_SELECTED_CAR_PARKS: {
      const { carParksUuids } = action
      return {
        ...state,
        selectedCarParks: carParksUuids,
      }
    }
    case SET_SELECTED_OPERATOR: {
      const { operatorUuid } = action
      return {
        ...state,
        selectedOperator: operatorUuid,
      }
    }
    default:
      return { ...state }
  }
}

import { useEffect, useRef } from 'react'
/* -------------------------------------------------------------------------- */

/**
 * @function
 * @param {function} callback
 * @param {number} delay
 * @param {boolean} immediate
 * @returns {undefined}
 */
const useInterval = (callback, delay, immediate) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    if (!immediate) return undefined
    savedCallback.current()
  }, [immediate])

  useEffect(() => {
    if (!delay) return undefined
    const intervalId = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(intervalId)
  }, [delay])
}

export default useInterval

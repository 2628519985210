import { GET_OPERATORS, RESET_OPERATORS_FILTERS } from 'redux/Types'
import { unpackOperators } from 'api/operatorMgmt/operatorMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  operators: [],
  isGettingOperators: false,
  errGettingOperators: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  lastSuccessDate: new Date(),
  pagination: {
    page: 0,
    rows: 10,
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPERATORS.REQUEST: {
      const { page, rows } = action
      return {
        ...state,
        isGettingOperators: true,
        errGettingOperators: INIT_STATE.errGettingOperators,
        pagination: {
          ...state.pagination,
          page: Number.isInteger(page) ? page : state.pagination.page,
          rows: Number.isInteger(rows) ? rows : state.pagination.rows,
        },
      }
    }
    case GET_OPERATORS.SUCCESS: {
      const { operators } = action
      return {
        ...state,
        isGettingOperators: false,
        lastSuccessDate: new Date(),
        operators: unpackOperators(operators),
      }
    }
    case GET_OPERATORS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingOperators: false,
        errGettingOperators: error,
      }
    }
    case RESET_OPERATORS_FILTERS: {
      return {
        ...state,
        pagination: {
          ...INIT_STATE.pagination,
        },
      }
    }

    default:
      return { ...state }
  }
}

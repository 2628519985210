/**
 * Functions to mock api calls
 * @namespace mockApi
 */

/**
 * A constant determining the minimum delay of mocked backend requests
 * @constant
 * @memberof mockApi
 * @type {number}
 */
const MIN_MOCKED_REQUEST_DELAY_IN_MS = 500

/**
 * A constant determining the maximum delay of mocked backend requests
 * @constant
 * @memberof mockApi
 * @type {number}
 */
const MAX_MOCKED_REQUEST_DELAY_IN_MS = 1500

/**
 * Mocks the network delay when requesting data from the backend
 * @function
 * @memberof mockApi
 * @param   {Object}  mockedResponse  the response object which should be returned after the delay
 * @return  {Promise}                 A Promise resolving after a random waiting time between MIN_MOCKED_REQUEST_DELAY_IN_MS and MAX_MOCKED_REQUEST_DELAY_IN_MS milliseconds
 */
export const mockDelayedApiCall = (mockedResponse) => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(mockedResponse),
      Math.floor(Math.random() * (MAX_MOCKED_REQUEST_DELAY_IN_MS - MIN_MOCKED_REQUEST_DELAY_IN_MS)) +
        MIN_MOCKED_REQUEST_DELAY_IN_MS,
    )
  })
}

import { ADMIN_API_BASE_PATH } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_ACCOUNT = {
  PW_RESET_REQ: `${ADMIN_API_BASE_PATH}/me/password-reset-request`,
  PW_RESET: `${ADMIN_API_BASE_PATH}/me/password-reset`,
  MODIFY_PASSWORD: `${ADMIN_API_BASE_PATH}/me/password`,
}

export const BODY_ACCOUNT = {
  EMAIL: 'email',
  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',
  TOKEN: 'token',
}

import i18n from 'i18next'
import enLocale from 'date-fns/locale/en-US'
import deLocale from 'date-fns/locale/de'
import { initReactI18next } from 'react-i18next'
import i18nextXhrBackend from 'i18next-xhr-backend'
import get from 'lodash/get'

import { formatISOString, centsToEuroLocalized } from 'util/core'
/* -------------------------------------------------------------------------- */

// Used to setup date pickers
export const localeMap = {
  en: enLocale,
  de: deLocale,
}

export const localeCodes = {
  en: get(enLocale, ['code'], 'en').split('-')[0],
  de: get(deLocale, ['code'], 'de').split('-')[0],
}

export const localeCodesArr = Object.values(localeCodes)

// i18next-extract-mark-context-next-line ["", "en", "de"]
export const getLanguage = (languageCode, t) => t('i18n.language', { context: languageCode })

i18n
  .use(i18nextXhrBackend)
  .use(initReactI18next)
  .init({
    lng: localeCodes.de,
    fallbackLng: localeCodes.de,
    whitelist: localeCodesArr,
    load: 'languageOnly',
    debug: false,
    interpolation: {
      escapeValue: false,
      format: (value, shape, lng) => {
        if (value || value === 0) {
          // DATES
          if (shape === 'dateShort') return formatISOString(value, lng, { dateSize: 'short' })
          else if (shape === 'dateMedium') return formatISOString(value, lng, { dateSize: 'medium' })
          else if (shape === 'timeShort') return formatISOString(value, lng, { timeSize: 'short' })
          else if (shape === 'timeMedium') return formatISOString(value, lng, { timeSize: 'medium' })
          else if (shape === 'dateShortTimeShort')
            return formatISOString(value, lng, { dateSize: 'short', timeSize: 'short' })
          else if (shape === 'dateMediumTimeShort')
            return formatISOString(value, lng, { dateSize: 'medium', timeSize: 'short' })
          else if (shape === 'dateShortTimeMedium')
            return formatISOString(value, lng, { dateSize: 'short', timeSize: 'medium' })
          else if (shape === 'dateShortTimeMedium')
            return formatISOString(value, lng, { dateSize: 'medium', timeSize: 'medium' })
          // NUMBERS
          else if (shape === 'toEuro') return centsToEuroLocalized(value, lng)
          return value
        } else return ''
      },
    },
  })

export default i18n

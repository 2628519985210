import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import {
  LocalOffer as LocalOfferIcon,
  AccessTime as AccessTimeIcon,
  NightsStay as NightsStayIcon,
  Build as BuildIcon,
} from '@material-ui/icons'

import IconChip from 'fragments/IconChips/IconChip'
import { OFFER_TYPE_VALUES } from 'api/offerMgmt/offerMgmtModels'
import { getOfferTypeAsString } from 'util/custom'
/* -------------------------------------------------------------------------- */

const OfferTypeChip = (props) => {
  const { type } = props

  const { t } = useTranslation()

  const { ADMIN, LONG_TERM, SHORT_TERM, INSTANT } = OFFER_TYPE_VALUES
  const style = { color: 'white' }

  const obj = {
    [ADMIN]: {
      icon: <BuildIcon style={style} />,
      style: { ...style, background: '#d932d0' },
    },
    [LONG_TERM]: {
      icon: <NightsStayIcon style={{ color: 'white' }} />,
      style: { ...style, background: '#3766cc' },
    },
    [SHORT_TERM]: {
      icon: <AccessTimeIcon style={style} />,
      style: { ...style, background: '#f5b942' },
    },
    [INSTANT]: {
      icon: <LocalOfferIcon style={{ color: 'white' }} />,
      style: { ...style, background: '#f56c42' },
    },
  }

  return (
    <IconChip
      icon={get(obj, [type, 'icon'], null)}
      label={getOfferTypeAsString(t, type)}
      chipStyle={get(obj, [type, 'style'], null)}
    />
  )
}

/* -------------------------------------------------------------------------- */
OfferTypeChip.propTypes = {
  type: PropTypes.string,
}

OfferTypeChip.defaultProps = {
  type: null,
}

export default OfferTypeChip

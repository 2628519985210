import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'

import AppRoutes from 'routes/AppRoutes'
import AuthRoutes from 'routes/AuthRoutes'
import { getCurrentUserAct } from 'redux/Actions'
import { isPending } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const BaseRoutes = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const { user, statusGettingUser, isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getCurrentUserAct())
  }, [dispatch])

  useEffect(() => {
    const lang = user?.languageCode
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [i18n, user])

  return (
    <Switch>
      {isPending(statusGettingUser) ? (
        <Route>
          {/* FullScreen loading would be nice */}
          <LinearProgress />
        </Route>
      ) : isAuthenticated ? (
        <AppRoutes />
      ) : (
        <AuthRoutes />
      )}
    </Switch>
  )
}

export default BaseRoutes

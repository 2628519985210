import React from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { KeyboardTimePicker } from 'formik-material-ui-pickers'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import DateFnsUtils from '@date-io/date-fns'

import { localeMap } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const CustomTimePicker = (props) => {
  const { t, i18n } = useTranslation()
  const { label, inputVariant, name, showTodayButton } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <Field
        component={KeyboardTimePicker}
        name={name}
        label={label}
        inputVariant={inputVariant}
        format='HH:mm'
        placeholder='HH:MM'
        KeyboardButtonProps={{
          'aria-label': t('CustomTimePicker.changeTime'),
        }}
        fullWidth
        ampm={false}
        showTodayButton={showTodayButton}
        disableFuture
        variant='dialog'
        okLabel={t('CustomTimePicker.ok')}
        cancelLabel={t('CustomTimePicker.cancel')}
        todayLabel={t('CustomTimePicker.today')}
        invalidDateMessage={t('CustomTimePicker.failure')}
        clearable
        clearLabel={t('CustomTimePicker.delete')}
      />
    </MuiPickersUtilsProvider>
  )
}
/* -------------------------------------------------------------------------- */
CustomTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  showTodayButton: PropTypes.bool,
}

CustomTimePicker.defaultProps = {
  label: null,
  inputVariant: 'standard',
  showTodayButton: false,
}
export default CustomTimePicker

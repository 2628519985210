import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import InfoCreateForm from 'components/CarPark/Info/Create/InfoCreateForm'
/* -------------------------------------------------------------------------- */

const InfoCreateContainer = () => {
  const { t } = useTranslation()
  const errCreatingCarPark = useSelector((state) => state.carPark?.errCreatingCarPark)

  return (
    <BasicPageContainerLoadable helmetTitle={t('InfoCreateContainer.title')} pageTitle={t('InfoCreateContainer.title')}>
      <ErrorAlertContainer errors={[errCreatingCarPark]} />
      <InfoCreateForm />
    </BasicPageContainerLoadable>
  )
}

export default InfoCreateContainer

import yup from 'util/yup'

import { getTimeWithBiggestPossibleUnit, getDateByMinFromMidnight } from 'util/core'
import { validationSchema as baseValidationSchema } from 'components/Offer/validationSchema'
/* -------------------------------------------------------------------------- */

export const validationSchema = baseValidationSchema.concat(
  yup.object().shape(
    {
      carParkUuid: yup.string().required(),
    },
    // https://github.com/jquense/yup/blob/master/CHANGELOG.md#breaking-changes
    [['accessLatestInMinFromMidnight', 'accessEarliestInMinFromMidnight']],
  ),
)

export const initialValues = () => {
  const { unit: usageUnit } = getTimeWithBiggestPossibleUnit(0)

  const { unit: validityUnit } = getTimeWithBiggestPossibleUnit(0)

  const accessEarliestInMinFromMidnight = getDateByMinFromMidnight(null)

  const accessLatestInMinFromMidnight = getDateByMinFromMidnight(null)

  return {
    carParkUuid: '',
    localizedInformation: {
      de: '',
      en: '',
    },
    localizedNames: {
      de: '',
      en: '',
    },
    isVisible: true,
    priceInEuro: '',
    validityDuration: '',
    validityUnit,
    usageDuration: '',
    usageUnit,
    contingent: '',
    availableFrom: null,
    availableUntil: null,
    accessEarliestInMinFromMidnight,
    accessLatestInMinFromMidnight,
    type: '',
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import AdminEditForm from 'components/Admin/Edit/AdminEditForm'
import { getAdminByUuidAct, resetAdminAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const AdminEditContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const { errEditingAdmin, errGettingAdmin, isGettingAdmin } = useSelector((state) => state.admin)

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getAdminByUuidAct({ uuid }))
    return () => {
      dispatch(resetAdminAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('AdminEditContainer.title')}
      pageTitle={t('AdminEditContainer.title')}
      isLoading={isGettingAdmin}
    >
      <ErrorAlertContainer errors={[errEditingAdmin, errGettingAdmin]} />
      <AdminEditForm />
    </BasicPageContainerLoadable>
  )
}

export default AdminEditContainer

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as PropTypes from 'prop-types'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import { sortBarriers } from 'util/custom'
import columns from 'components/Barriers/BarriersTableColumns'
import options from 'components/Barriers/barriersTableOptions'
/* -------------------------------------------------------------------------- */

const BarriersTableCard = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let history = useHistory()
  const role = useSelector((state) => state.auth.user.role)
  const {
    barriers: barriersState,
    isGettingBarriers,
    pagination: { page, rows, totalCount },
  } = useSelector((state) => state.barriers)

  const { barriers, dashboardUsage } = props
  const sortedData = sortBarriers(barriers || barriersState)

  const [viewBarrierNr, setViewBarrierNr] = useState(false)
  const [viewSoftwareVersion, setViewSoftwareVersion] = useState(false)

  return (
    <BasicCardLoadable
      title={dashboardUsage ? t('BarriersTableCard.offlineBarriers') : undefined}
      isLoading={isGettingBarriers}
    >
      <MuiDataTableThemed
        data={sortedData}
        columns={columns(sortedData, viewBarrierNr, viewSoftwareVersion, t)}
        options={options({
          dashboardUsage,
          data: sortedData,
          dispatch,
          history,
          page,
          role,
          rows,
          totalCount,
          setViewBarrierNr,
          setViewSoftwareVersion,
        })}
      />
    </BasicCardLoadable>
  )
}

BarriersTableCard.propTypes = {
  barriers: PropTypes.arrayOf(PropTypes.shape()),
  dashboardUsage: PropTypes.bool,
}

BarriersTableCard.defaultProps = {
  dashboardUsage: false,
}

export default BarriersTableCard

import { BODY_GENERAL_STATISTICS, BODY_STATISTICS } from 'api/statisticsMgmt/statisticsMgmtModels'
/* -------------------------------------------------------------------------- */

export const unpackStatistics = (stats) => stats.map(unpackStatisticsValues)

const unpackStatisticsValues = (stat) => {
  const {
    CAR_PARK_NAME,
    PARKING_LOTS,
    CAR_PARK_UUID,
    STATISTICS_VALUES_OBJ,
    AVERAGE_PROCESS_TIME,
    AVERAGE_USER_INSIDE,
    ENTRIES_APP,
    ENTRIES_TOTAL,
    EXITS_APP,
    EXITS_TOTAL,
    INDEX,
  } = BODY_STATISTICS
  const values = stat?.[STATISTICS_VALUES_OBJ] || []
  return {
    carParkUuid: stat?.[CAR_PARK_UUID],
    carParkName: stat?.[CAR_PARK_NAME],
    parkingLots: stat?.[PARKING_LOTS],
    statistics: values.map((v) => ({
      averageProcessTimeInMs: v?.[AVERAGE_PROCESS_TIME],
      averageUsersInside: v?.[AVERAGE_USER_INSIDE],
      entriesApp: v?.[ENTRIES_APP],
      entriesTotal: v?.[ENTRIES_TOTAL],
      exitsApp: v?.[EXITS_APP],
      exitsTotal: v?.[EXITS_TOTAL],
      timeIndex: v?.[INDEX],
    })),
  }
}

/* -------------------------------------------------------------------------- */

export const unpackGeneralStatistics = (v) => {
  const {
    APP_USERS_COUNT_OBJ,
    CAR_PARKS_COUNT_OBJ,
    TICKETS_COUNT_OBJ,
    CURRENT_DAY,
    CURRENT_MONTH,
    CURRENT_YEAR,
    TOTAL,
  } = BODY_GENERAL_STATISTICS
  const auc = v[APP_USERS_COUNT_OBJ]
  const cpc = v[CAR_PARKS_COUNT_OBJ]
  const tc = v[TICKETS_COUNT_OBJ]
  return {
    appUsersCount: {
      total: auc[TOTAL],
      year: auc[CURRENT_YEAR],
      month: auc[CURRENT_MONTH],
      day: auc[CURRENT_DAY],
    },
    carParksCount: {
      total: cpc[TOTAL],
      year: cpc[CURRENT_YEAR],
      month: cpc[CURRENT_MONTH],
      day: cpc[CURRENT_DAY],
    },
    ticketsCount: {
      total: tc[TOTAL],
      year: tc[CURRENT_YEAR],
      month: tc[CURRENT_MONTH],
      day: tc[CURRENT_DAY],
    },
  }
}

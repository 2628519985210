import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { Lock as LockIcon, LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'

import { BasicCardLoadable } from 'fragments/Loadables'
import PasswordField from 'fragments/Forms/Formik/PasswordField'
import { initialValues, validationSchema } from 'components/Profile/changePasswordForm'
import { modifyPasswordAct } from 'redux/Actions'
import { hasError } from 'util/core'
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '20px',
    textTransform: 'none',
    color: '#fff',
  },
}))

const ChangePasswordCard = () => {
  const { modifyPasswordSuccess, errModifyingPassword, isModifyingPassword } = useSelector((state) => state.account)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  useEffect(() => {
    if (modifyPasswordSuccess) {
      enqueueSnackbar(t('ChangePasswordCard.info.success'), { variant: 'success' })
    } else if (hasError(errModifyingPassword)) {
      enqueueSnackbar(t('ChangePasswordCard.error.oldPwWrong'), { variant: 'error' })
    }
  }, [modifyPasswordSuccess, errModifyingPassword, t, enqueueSnackbar])

  const handleSubmit = (values) => dispatch(modifyPasswordAct(values))

  return (
    <BasicCardLoadable title={t('ChangePasswordCard.title')} isLoading={isModifyingPassword} action={<LockIcon />}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { isValidating, isSubmitting, isValid, dirty } = formikProps
          return (
            <Form>
              <Grid container direction='row' spacing={3}>
                <Grid container item spacing={1} alignItems='flex-end'>
                  <Grid item>
                    <LockOutlinedIcon />
                  </Grid>
                  <Grid item xs>
                    <PasswordField
                      name='oldPassword'
                      autoComplete='current-password'
                      label={t('ChangePasswordCard.oldPassword')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={1} alignItems='flex-end'>
                  <Grid item>
                    <LockOutlinedIcon />
                  </Grid>
                  <Grid item xs>
                    <PasswordField
                      name='newPassword'
                      autoComplete='new-password'
                      label={t('ChangePasswordCard.newPassword')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    disabled={isValidating || isSubmitting || !isValid || !dirty}
                    className={classes.button}
                    color='primary'
                    variant='contained'
                    fullWidth
                  >
                    {t('ChangePasswordCard.modifyPassword')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </BasicCardLoadable>
  )
}

export default ChangePasswordCard

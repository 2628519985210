import { LOGIN_USER, LOGOUT_USER, GET_CURRENT_USER, CHANGE_MY_PROFILE } from 'redux/Types'
import { STATUS } from 'redux/Status'
import { unpackAdmin } from 'api/adminMgmt/adminMgmtUnpackers'
import { unpackError } from 'util/core'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  // Changing Profile
  statusChangingProfile: STATUS.idle,
  errChangingMyProfile: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // Getting User
  statusGettingUser: STATUS.idle,
  errGettingUser: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // Logging In
  statusLoggingIn: STATUS.idle,
  errUserLoggingIn: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // Logging Out
  statusLoggingOut: STATUS.idle,
  errUserLoggingOut: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // General
  isAuthenticated: false,
  user: unpackAdmin({}),
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // LOGIN
    case LOGIN_USER.REQUEST: {
      return {
        ...state,
        statusLoggingIn: STATUS.pending,
        statusLoggingOut: STATUS.idle,
        errUserLoggingIn: INIT_STATE.errUserLoggingIn,
        errGettingUser: INIT_STATE.errGettingUser,
      }
    }
    case LOGIN_USER.SUCCESS: {
      const { user } = action
      return {
        ...state,
        statusLoggingIn: STATUS.resolved,
        isAuthenticated: true,
        user: unpackAdmin(user),
      }
    }
    case LOGIN_USER.ERROR: {
      const { error } = action
      return {
        ...state,
        statusLoggingIn: STATUS.rejected,
        isAuthenticated: false,
        errUserLoggingIn: error,
      }
    }
    // LOGOUT
    case LOGOUT_USER.REQUEST: {
      return {
        ...state,
        statusLoggingIn: STATUS.idle,
        statusLoggingOut: STATUS.pending,
        errUserLoggingOut: INIT_STATE.errUserLoggingOut,
      }
    }
    case LOGOUT_USER.SUCCESS: {
      return {
        ...state,
        statusLoggingOut: STATUS.resolved,
        isAuthenticated: false,
        user: INIT_STATE.user,
      }
    }
    case LOGOUT_USER.ERROR: {
      const { error } = action
      return {
        ...state,
        statusLoggingOut: STATUS.rejected,
        errUserLoggingOut: error,
      }
    }
    // GET_CURRENT_USER
    case GET_CURRENT_USER.REQUEST: {
      return {
        ...state,
        statusGettingUser: STATUS.pending,
        errGettingUser: INIT_STATE.errGettingUser,
      }
    }
    case GET_CURRENT_USER.SUCCESS: {
      const { user } = action
      return {
        ...state,
        statusGettingUser: STATUS.resolved,
        isAuthenticated: true,
        user: unpackAdmin(user),
      }
    }
    case GET_CURRENT_USER.ERROR: {
      const { error } = action
      return {
        ...state,
        statusGettingUser: STATUS.rejected,
        errGettingUser: unpackError(error),
        isAuthenticated: false,
        user: INIT_STATE.user,
      }
    }
    // CHANGE PROFILE
    case CHANGE_MY_PROFILE.REQUEST: {
      return {
        ...state,
        statusChangingProfile: STATUS.pending,
        errChangingMyProfile: INIT_STATE.errChangingMyProfile,
      }
    }
    case CHANGE_MY_PROFILE.SUCCESS: {
      const { user } = action
      return {
        ...state,
        statusChangingProfile: STATUS.resolved,
        user: unpackAdmin(user),
      }
    }
    case CHANGE_MY_PROFILE.ERROR: {
      const { error } = action
      return {
        ...state,
        statusChangingProfile: STATUS.rejected,
        errChangingMyProfile: error,
        user: INIT_STATE.user,
      }
    }
    default:
      return { ...state }
  }
}

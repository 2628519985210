import React from 'react'

import FilterGridCard from 'fragments/Cards/FilterGridCard'
import OfferTypeSelect from 'fragments/Forms/Custom/OfferTypeSelect'
import TicketUsageSelect from 'fragments/Forms/Custom/TicketUsageSelect'
import TicketUsabilitySelect from 'fragments/Forms/Custom/TicketUsabilitySelect'
import { TicketUsability, TicketUsage, TicketType } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketsFilterCardProps = {
  isLoading: boolean
  resetFilters: () => void
  ticketType?: TicketType
  usability?: TicketUsability
  usage?: TicketUsage
  setTicketType: (s: TicketType) => void
  setUsage: (s: TicketUsage) => void
  setUsability: (s: TicketUsability) => void
}

export default function TicketsFilterCard(props: TicketsFilterCardProps) {
  const { isLoading, resetFilters, setTicketType, setUsage, setUsability, ticketType, usability, usage } = props

  return (
    <FilterGridCard isLoading={isLoading} resetFilters={resetFilters}>
      <OfferTypeSelect selected={ticketType} onChange={setTicketType} />
      <TicketUsageSelect selected={usage} onChange={setUsage} />
      <TicketUsabilitySelect selected={usability} onChange={setUsability} />
    </FilterGridCard>
  )
}

import yup from 'util/yup'

import { TIME_UNITS } from 'util/core'
/* -------------------------------------------------------------------------- */

export const validationSchema = yup.object().shape({
  operatorUuid: yup.string().trim().required(),
  name: yup.string().trim().required(),
  street: yup.string().trim(),
  houseNumber: yup.string().trim(),
  postalCode: yup.string().trim(),
  city: yup.string().trim(),
  latitude: yup.number().min(-90).max(90).required(),
  longitude: yup.number().min(-180).max(180).required(),
  telephoneNumber: yup.string().trim(),
  beaconMajor: yup.number().integer().min(1).max(65535).required(),
  countParkingLots: yup.number().integer().min(0),
  continuityPriceInEuro: yup.number().min(0),
  continuityPeriodDuration: yup.number().integer().min(0),
  continuityPeriodUnit: yup.string().oneOf(Object.values(TIME_UNITS)),
  priceMaxPerDayInEuro: yup.number().min(0),
  countUserInside: yup.number().integer().min(0),
  openingHours: yup.string().trim(),
  isVisible: yup.boolean(),
})

import { GET_APP_USERS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getAppUsersAct = () => ({
  type: GET_APP_USERS.REQUEST,
})

export const getAppUsersSuccessAct = (data) => ({
  type: GET_APP_USERS.SUCCESS,
  data,
})

export const getAppUsersErrorAct = (error) => ({
  type: GET_APP_USERS.ERROR,
  error,
})

import React from 'react'

import AccountStatusChip from 'fragments/IconChips/AccountStatusChip'
/* -------------------------------------------------------------------------- */

const columns = ({ appUsers, t }) => [
  {
    name: 'email',
    label: t('AppUsersTableColumns.emailAddress'),
  },
  {
    name: 'firstName',
    label: t('AppUsersTableColumns.firstName'),
  },
  {
    name: 'lastName',
    label: t('AppUsersTableColumns.lastName'),
  },
  {
    name: 'uuid',
    label: t('AppUsersTableColumns.accountStatus'),
    options: {
      customBodyRender: (uuid) => {
        const { isEmailVerified, isDeleted, deletedAt } = appUsers.find((o) => o.uuid === uuid)
        return <AccountStatusChip isActivated={isEmailVerified} isDeleted={isDeleted} deletedAt={deletedAt} />
      },
    },
  },
]

export default columns

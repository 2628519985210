import yup from 'util/yup'
/* -------------------------------------------------------------------------- */

export const validationSchema = yup.object().shape({
  email: yup.string().email().trim().required(),
})

export const initialValues = (email) => ({
  email: email || '',
})

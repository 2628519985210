import yup from 'util/yup'
/* -------------------------------------------------------------------------- */

export const validationSchema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().min(8).required(),
})

export const initialValues = {
  oldPassword: '',
  newPassword: '',
}

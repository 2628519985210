import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { getSalesAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const options = ({ dispatch, page, rows, totalCount }) => ({
  count: totalCount,
  onChangePage: (page) =>
    dispatch(
      getSalesAct({
        page,
      }),
    ),
  onChangeRowsPerPage: (rows) =>
    dispatch(
      getSalesAct({
        rows,
      }),
    ),
  page,
  rowsPerPage: rows,
  serverSide: true,
})

export default (args) => ({
  ...muiDataTableOptions(),
  ...options(args),
})

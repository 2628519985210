import React from 'react'

import StatsChartCell from 'components/Occupancy/StatsChartCell'
import { getStatsViewLabel } from 'util/custom'
/* -------------------------------------------------------------------------- */

export default ({ view, t, occupancy }) => [
  {
    name: 'carParkName',
    label: t('OccupancyTableColumns.carPark'),
  },
  {
    name: 'carParkUuid',
    label: getStatsViewLabel(t, view),
    options: {
      customBodyRender: (uuid) => {
        const stats = occupancy.find((o) => o?.carParkUuid === uuid)
        return <StatsChartCell stats={stats} />
      },
    },
  },
]

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@material-ui/core'

import { TicketUsability } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketUsabilityChipProps = {
  value?: TicketUsability
}

export default function TicketUsabilityChip(props: TicketUsabilityChipProps) {
  const { value } = props
  const { t } = useTranslation()

  function getTicketUsabilityAsString(type?: TicketUsability) {
    const validType = ['NotYetUsable', 'Usable', 'NotUsableAnymore'].some((v) => v === type)

    return validType
      ? // i18next-extract-mark-context-next-line ["NotYetUsable", "Usable", "NotUsableAnymore"]
        (t('ticket.usability', {
          context: type,
        }) as string)
      : (t('ticket.usability') as string)
  }

  function getTicketUsabilityStyle(type?: TicketUsability) {
    switch (type) {
      case 'NotYetUsable': {
        return {
          color: 'white',
          background: '#FFB70F',
        }
      }
      case 'NotUsableAnymore': {
        return {
          color: 'white',
          background: '#677080',
        }
      }
      case 'Usable': {
        return {
          color: 'white',
          background: '#26a1f1',
        }
      }
      default: {
        break
      }
    }
  }

  return <Chip size='small' label={getTicketUsabilityAsString(value)} style={getTicketUsabilityStyle(value)} />
}

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { getOccupancyAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const options = ({ dispatch, page, rows, totalCount }) => ({
  count: totalCount,
  onChangePage: (page) =>
    dispatch(
      getOccupancyAct({
        page,
      }),
    ),
  onChangeRowsPerPage: (rows) =>
    dispatch(
      getOccupancyAct({
        rows,
      }),
    ),
  page,
  rowsPerPage: rows,
  serverSide: true,
})

export default (props) => ({
  ...muiDataTableOptions(),
  ...options(props),
})

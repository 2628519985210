import axiosInstance from 'api/axiosConfig'
import { PATH_SEGMENTS_OPERATORS } from 'api/operatorMgmt/operatorMgmtModels'
import { pack } from 'api/operatorMgmt/operatorMgmtUnpackers'
/* -------------------------------------------------------------------------- */

export const getAllOperatorsReq = () =>
  axiosInstance.get(`${PATH_SEGMENTS_OPERATORS.OPERATORS_BASE}`).then((res) => res.data)

export const getOperatorByUuidReq = (uuid) =>
  axiosInstance.get(`${PATH_SEGMENTS_OPERATORS.OPERATORS_BASE}/${uuid}`).then((res) => res.data)

export const createOperatorReq = (operatorCreatable) => {
  const url = PATH_SEGMENTS_OPERATORS.OPERATORS_BASE
  const data = pack(operatorCreatable)

  return axiosInstance.post(url, data).then((res) => res.data)
}

export const editOperatorReq = (uuid, operatorUpdatable) => {
  const url = `${PATH_SEGMENTS_OPERATORS.OPERATORS_BASE}/${uuid}`
  const data = pack(operatorUpdatable)

  return axiosInstance.patch(url, data).then((res) => res.data)
}

import { call, put, takeLatest, all, fork, takeLeading } from 'redux-saga/effects'

import { GET_OFFER_BY_UUID, EDIT_OFFER_BY_UUID, CREATE_OFFER } from 'redux/Types'
import {
  getOfferByUuidErrorAct,
  getOfferByUuidSuccessAct,
  editOfferByUuidSuccessAct,
  editOfferByUuidErrorAct,
  createOfferSuccessAct,
  createOfferErrorAct,
  catchSagaErrorsAct,
} from 'redux/Actions'
import { getOfferByUuidReq, editOfferByUuidReq, createOfferReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

// ----- LOAD -----

function* workerGetOfferByUuid(action) {
  try {
    const { uuid, versionUuid } = action
    const offer = yield call(getOfferByUuidReq, uuid)
    yield put(getOfferByUuidSuccessAct(offer, versionUuid))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getOfferByUuidErrorAct))
  }
}

function* watcherGetOfferByUuid() {
  yield takeLeading(GET_OFFER_BY_UUID.REQUEST, workerGetOfferByUuid)
}

// ----- EDIT  -----

function* workerEditOfferByUuid(action) {
  try {
    const { offerUpdatable, uuid } = action
    const offer = yield call(editOfferByUuidReq, uuid, offerUpdatable)
    yield put(editOfferByUuidSuccessAct(offer))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editOfferByUuidErrorAct))
  }
}

function* watcherEditOfferByUuid() {
  yield takeLatest(EDIT_OFFER_BY_UUID.REQUEST, workerEditOfferByUuid)
}

// ----- CREATE -----

function* workerCreateOffer(action) {
  const { offerCreatable } = action
  try {
    const response = yield call(createOfferReq, offerCreatable)
    yield put(createOfferSuccessAct(response))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, createOfferErrorAct))
  }
}

function* watcherCreateOffer() {
  yield takeLatest(CREATE_OFFER.REQUEST, workerCreateOffer)
}

// ----- EXPORT ---

export default function* rootSaga() {
  yield all([fork(watcherGetOfferByUuid), fork(watcherEditOfferByUuid), fork(watcherCreateOffer)])
}

import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import {
  activateAdminErrorAct,
  activateAdminSuccessAct,
  catchSagaErrorsAct,
  modifyPasswordErrorAct,
  modifyPasswordSuccessAct,
  resetPasswordErrorAct,
  resetPasswordRequestErrorAct,
  resetPasswordRequestSuccessAct,
  resetPasswordSuccessAct,
  logInUserAct,
} from 'redux/Actions'
import { ACTIVATE_ADMIN, RESET_PASSWORD_REQUEST, RESET_PASSWORD, MODIFY_PASSWORD } from 'redux/Types'
import { activateAdminReq, resetPasswordRequestReq, resetPasswordReq, modifyPasswordReq } from 'api/requests'
import { unpackAdmin } from 'api/adminMgmt/adminMgmtUnpackers'
/* -------------------------------------------------------------------------- */

/* ----- ACCOUNT ACTIVATION ----- */

function* workerActivateAdminRequest({ newPassword, token }) {
  try {
    const res = yield call(activateAdminReq, newPassword, token)
    yield put(activateAdminSuccessAct())
    const { email } = unpackAdmin(res)
    yield put(logInUserAct({ email, password: newPassword }))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, activateAdminErrorAct))
  }
}

export function* watcherActivateAdminRequest() {
  yield takeEvery(ACTIVATE_ADMIN.REQUEST, workerActivateAdminRequest)
}

/* ----- PASSWORT RESET REQUEST ----- */

function* workerResetPasswordRequest({ email }) {
  try {
    yield call(resetPasswordRequestReq, email)
    yield put(resetPasswordRequestSuccessAct())
  } catch (error) {
    yield put(catchSagaErrorsAct(error, resetPasswordRequestErrorAct))
  }
}

export function* watcherResetPasswordRequest() {
  yield takeEvery(RESET_PASSWORD_REQUEST.REQUEST, workerResetPasswordRequest)
}

/* ----- PASSWORT RESET ----- */

function* workerResetPassword({ newPassword, token }) {
  try {
    yield call(resetPasswordReq, newPassword, token)
    yield put(resetPasswordSuccessAct())
  } catch (error) {
    yield put(catchSagaErrorsAct(error, resetPasswordErrorAct))
  }
}

export function* watcherResetPassword() {
  yield takeEvery(RESET_PASSWORD.REQUEST, workerResetPassword)
}

/* ----- MODIFY PASSWORT ----- */

function* workerModifyPassword({ oldPassword, newPassword }) {
  try {
    const res = yield call(modifyPasswordReq, oldPassword, newPassword)
    if (res.status === 204) {
      yield put(modifyPasswordSuccessAct(oldPassword, newPassword))
    } else {
      // FIXME: this code will never be executed in case of an error
      yield put(modifyPasswordErrorAct())
    }
  } catch (error) {
    yield put(catchSagaErrorsAct(error, modifyPasswordErrorAct))
  }
}

export function* watcherModifyPassword() {
  yield takeEvery(MODIFY_PASSWORD.REQUEST, workerModifyPassword)
}

/* ----- ROOT SAGA ----- */

export default function* rootSaga() {
  yield all([
    fork(watcherActivateAdminRequest),
    fork(watcherResetPasswordRequest),
    fork(watcherResetPassword),
    fork(watcherModifyPassword),
  ])
}

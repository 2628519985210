/* ----- UPDATE TIMES ------------------------------------------------------- */

export const FREQUENT_UPDATE_TIME_IN_MS = 30 * 1000
export const INFREQUENT_UPDATE_TIME_IN_MS = 60 * 1000

export const DELAY_REQUEST_IN_MS = 400

/* ----- CONTENT ------------------------------------------------------------ */

export const DEFAULT_TICKET_ADMIN_ID = '#Admin'

export const OS_TYPES = {
  ANDROID: 'Android',
  IOS: 'iOS',
}

export const ROLES = {
  TP_ADMIN: 'TpAdmin',
  OP_ADMIN: 'OpAdmin',
  OP_MANAGER: 'OpManager',
}

/* ----- ERROR CODES -------------------------------------------------------- */

export const JS_RUNTIME_ERROR = -1

export const HTTPS_STATUS_CODES = {
  SUCCESS_OK: 200,
  SUCCESS_CREATED: 201,
  SUCCESS_NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
}

import { GET_GENERAL_STATISTICS, RESET_GENERAL_STATISTICS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getGeneralStatisticsAct = (params) => ({
  type: GET_GENERAL_STATISTICS.REQUEST,
  isReload: params?.isReload,
})

export const getGeneralStatisticsSuccessAct = ({ generalStatistics }) => ({
  type: GET_GENERAL_STATISTICS.SUCCESS,
  generalStatistics,
})

export const getGeneralStatisticsErrorAct = (error) => ({
  type: GET_GENERAL_STATISTICS.ERROR,
  error,
})

export const resetGeneralStatisticsAct = () => ({
  type: RESET_GENERAL_STATISTICS,
})

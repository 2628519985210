import {
  GET_CAR_PARK_BY_UUID,
  RESET_CAR_PARK,
  EDIT_CAR_PARK,
  RESET_EDIT_CAR_PARK,
  CREATE_CAR_PARK,
  RESET_CREATE_CAR_PARK,
} from 'redux/Types'
import { unpackCarPark } from 'api/carParkMgmt/carParkMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  isGettingCarPark: false,
  isReGettingCarPark: false,
  lastSuccessDate: null,
  errGettingCarPark: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  editCarParkSuccess: false,
  isEditingCarPark: false,
  errEditingCarPark: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  createCarParkSuccess: false,
  isCreatingCarPark: false,
  errCreatingCarPark: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  carPark: unpackCarPark({}),
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_CAR_PARK_BY_UUID.REQUEST: {
      const { isReload } = action
      return {
        ...state,
        isGettingCarPark: !isReload,
        isReGettingCarPark: !!isReload,
        errGettingCarPark: INIT_STATE.errGettingCarPark,
      }
    }
    case GET_CAR_PARK_BY_UUID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isGettingCarPark: false,
        isReGettingCarPark: false,
        carPark: data,
        lastSuccessDate: new Date(),
      }
    }
    case GET_CAR_PARK_BY_UUID.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingCarPark: false,
        isReGettingCarPark: false,
        errGettingCarPark: error,
      }
    }
    /* -------------------------------------------------------------------------- */
    case EDIT_CAR_PARK.REQUEST: {
      return {
        ...state,
        isEditingCarPark: true,
        editCarParkSuccess: false,
        errEditingCarPark: INIT_STATE.errEditingCarPark,
      }
    }
    case EDIT_CAR_PARK.SUCCESS: {
      const { carPark } = action
      return {
        ...state,
        isEditingCarPark: false,
        editCarParkSuccess: true,
        carPark: unpackCarPark(carPark),
      }
    }
    case EDIT_CAR_PARK.ERROR: {
      const { error } = action
      return {
        ...state,
        isEditingCarPark: false,
        editCarParkSuccess: false,
        errEditingCarPark: error,
      }
    }
    case RESET_EDIT_CAR_PARK: {
      return {
        ...state,
        editCarParkSuccess: false,
      }
    }
    /* -------------------------------------------------------------------------- */
    case CREATE_CAR_PARK.REQUEST: {
      return {
        ...state,
        isCreatingCarPark: true,
        createCarParkSuccess: false,
        errCreatingCarPark: INIT_STATE.errCreatingCarPark,
      }
    }
    case CREATE_CAR_PARK.SUCCESS: {
      const { carPark } = action
      return {
        ...state,
        isCreatingCarPark: false,
        createCarParkSuccess: true,
        carPark: unpackCarPark(carPark),
      }
    }
    case CREATE_CAR_PARK.ERROR: {
      const { error } = action
      return {
        ...state,
        isCreatingCarPark: false,
        createCarParkSuccess: false,
        errCreatingCarPark: error,
      }
    }
    case RESET_CREATE_CAR_PARK: {
      return {
        ...state,
        createCarParkSuccess: false,
      }
    }
    /* -------------------------------------------------------------------------- */
    case RESET_CAR_PARK: {
      return {
        ...state,
        carPark: INIT_STATE.carPark,
      }
    }
    default:
      return { ...state }
  }
}

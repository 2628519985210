import React from 'react'
import * as PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles({ root: { fontStyle: 'oblique' } })

const PlaceHolderText = (props) => {
  const classes = useStyles()
  const { text } = props

  return (
    <Typography variant='body2' color='secondary' classes={classes}>
      {text}
    </Typography>
  )
}

PlaceHolderText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default PlaceHolderText

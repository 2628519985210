import yup from 'util/yup'
/* -------------------------------------------------------------------------- */

export const validationSchema = yup.object().shape({
  password: yup.string().min(8).required(),
  acceptedPrivacyPolicy: yup.boolean().oneOf([true]).required(),
})

export const initialValues = () => ({
  password: '',
  acceptedPrivacyPolicy: false,
})

import { GET_ADMINS } from 'redux/Types'
import { unpackAdmins } from 'api/adminMgmt/adminMgmtUnpackers'
/* -------------------------------------------------------------------------- */
const INIT_STATE = {
  admins: [],
  errGettingAdmins: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  isGettingAdmins: false,
  lastSuccessDate: new Date(),
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_ADMINS.REQUEST: {
      return {
        ...state,
        isGettingAdmins: true,
        errGettingAdmins: INIT_STATE.errGettingAdmins,
      }
    }
    case GET_ADMINS.SUCCESS: {
      const { admins } = action
      return {
        ...state,
        isGettingAdmins: false,
        lastSuccessDate: new Date(),
        admins: unpackAdmins(admins),
      }
    }
    case GET_ADMINS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAdmins: false,
        errGettingAdmins: error,
      }
    }
    default:
      return { ...state }
  }
}

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'

import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import { getCarParksAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const CarParksSelect = (props) => {
  const { getCarParksAct, isGettingCarParks, operatorUuid, carParks, name, label, variant } = props

  useEffect(() => {
    getCarParksAct({ page: 0, rows: 1000 }) // FIXME: hard coded page/rows
  }, [getCarParksAct, operatorUuid])

  return (
    <SelectTextField
      name={name}
      label={label}
      variant={variant}
      isLoading={isGettingCarParks}
      options={carParks.map((cp) => ({ value: cp.uuid, label: cp.name }))}
    />
  )
}

/* -------------------------------------------------------------------------- */
CarParksSelect.propTypes = {
  getCarParksAct: PropTypes.func.isRequired,
  isGettingCarParks: PropTypes.bool.isRequired,
  carParks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  operatorUuid: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
}

CarParksSelect.defaultProps = {
  operatorUuid: null,
  variant: null,
  label: null,
}

const mapStateToProps = (state) => {
  const {
    carParks: { carParks, isGettingCarParks },
    globalFilters: { selectedOperator },
  } = state
  return { carParks, isGettingCarParks, operatorUuid: selectedOperator }
}

const mapDispatch = {
  getCarParksAct,
}

export default connect(mapStateToProps, mapDispatch)(CarParksSelect)

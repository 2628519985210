import React from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { DirectionsCar as DirectionsCarIcon, Person as PersonIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import IconChip from 'fragments/IconChips/IconChip'
import EmailVerifiedChip from 'fragments/IconChips/EmailVerifiedChip'
/* -------------------------------------------------------------------------- */

const AppUserLongInfoCard = () => {
  const {
    isGettingAppUser,
    appUser: { email, isEmailVerified, firstName, lastName, licensePlate, createdAt, updatedAt, lastLogin },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()

  const rows = [
    {
      label: t('AppUserLongInfoCard.firstName'),
      value: firstName,
    },
    {
      label: t('AppUserLongInfoCard.lastName'),
      value: lastName,
    },
    {
      label: t('AppUserLongInfoCard.emailAddress'),
      value: email,
    },
    {
      label: t('AppUserLongInfoCard.verificationStatus'),
      value: <EmailVerifiedChip isEmailVerified={isEmailVerified} />,
    },
    {
      label: t('AppUserLongInfoCard.licensePlate'),
      value: (
        <IconChip
          icon={<DirectionsCarIcon style={{ color: 'white' }} />}
          label={
            licensePlate === ''
              ? t('AppUserLongInfoCard.unknown')
              : t('AppUserLongInfoCard.licensePlateValue', {
                  licensePlate: licensePlate,
                })
          }
          chipStyle={{
            color: 'white',
            background: '#f56c42',
          }}
        />
      ),
    },
    {
      label: t('AppUserLongInfoCard.createdAt'),
      value: t('AppUserLongInfoCard.dateMediumTimeShort', { date: createdAt }),
    },
    {
      label: t('AppUserLongInfoCard.updatedAt'),
      value: t('AppUserLongInfoCard.dateMediumTimeShort', { date: updatedAt }) ?? null,
    },
    {
      label: t('AppUserLongInfoCard.lastLogin'),
      value: t('AppUserLongInfoCard.dateMediumTimeShort', { date: lastLogin }),
    },
  ]

  return (
    <BasicCardLoadable isLoading={isGettingAppUser} title={t('AppUserLongInfoCard.user')} action={<PersonIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default AppUserLongInfoCard

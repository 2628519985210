import * as yup from 'yup'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'

import i18n from 'util/i18n'
import { nullifySecondsMilliseconds } from 'util/core'
/* -------------------------------------------------------------------------- */

const translateGreaterThan = () => i18n.t('yup.date.custom.greaterThan')

yup.addMethod(yup.date, 'greaterThan', function greaterThan(ref, message) {
  return this.test({
    name: 'greaterThan',
    exclusive: false,
    message: message || translateGreaterThan,
    params: {
      reference: ref.path,
    },
    test: function test(value) {
      const refDate = this.resolve(ref)
      return !!refDate && !!value
        ? isAfter(nullifySecondsMilliseconds(value), nullifySecondsMilliseconds(refDate))
        : true
    },
  })
})

const translateSmallerThan = () => i18n.t('yup.date.custom.smallerThan')

yup.addMethod(yup.date, 'smallerThan', function smallerThan(ref, message) {
  return this.test({
    name: 'smallerThan',
    exclusive: false,
    message: message || translateSmallerThan,
    params: {
      reference: ref.path,
    },
    test: function test(value) {
      const refDate = this.resolve(ref)
      return !!refDate & !!value
        ? isBefore(nullifySecondsMilliseconds(value), nullifySecondsMilliseconds(refDate))
        : true
    },
  })
})

const translateNotEqual = () => i18n.t('yup.date.custom.notEqual')

yup.addMethod(yup.date, 'notEqual', function notEqual(ref, message) {
  return this.test({
    name: 'notEqual',
    exclusive: false,
    message: message || translateNotEqual,
    params: {
      reference: ref.path,
    },
    test: function test(value) {
      const refDate = this.resolve(ref)
      return !isEqual(nullifySecondsMilliseconds(value), nullifySecondsMilliseconds(refDate))
    },
  })
})

const translateEven = () => i18n.t('yup.number.custom.even')

yup.addMethod(yup.number, 'even', function even(message) {
  return this.test({
    name: 'even',
    exclusive: false,
    message: message || translateEven,
    test: function test(value) {
      return value % 2 === 0 ? true : false
    },
  })
})

const translateOdd = () => i18n.t('yup.number.custom.odd')

yup.addMethod(yup.number, 'odd', function odd(message) {
  return this.test({
    name: 'odd',
    exclusive: false,
    message: message || translateOdd,
    test: function test(value) {
      return value % 2 === 0 ? false : true
    },
  })
})

const mixed = {
  default: () => i18n.t('yup.mixed.default'),
  required: () => i18n.t('yup.mixed.required'),
  oneOf: ({ values }) => i18n.t('yup.mixed.oneOf', { values }),
  notOneOf: ({ values }) => i18n.t('yup.mixed.notOneOf', { values }),
  defined: () => i18n.t('yup.mixed.defined'),
}

const string = {
  length: ({ length }) => i18n.t('yup.string.length', { length }),
  min: ({ min }) => i18n.t('yup.string.min', { min }),
  max: ({ max }) => i18n.t('yup.string.max', { max }),
  matches: ({ regex }) => i18n.t('yup.string.matches', { regex }),
  email: () => i18n.t('yup.string.email'),
  url: () => i18n.t('yup.string.url'),
  trim: () => i18n.t('yup.string.trim'),
  lowercase: () => i18n.t('yup.string.lowercase'),
  uppercase: () => i18n.t('yup.string.uppercase'),
}

const number = {
  min: ({ min }) => i18n.t('yup.number.min', { min }),
  max: ({ max }) => i18n.t('yup.number.max', { max }),
  lessThan: ({ less }) => i18n.t('yup.number.lessThan', { less }),
  moreThan: ({ more }) => i18n.t('yup.number.moreThan', { more }),
  notEqual: ({ notEqual }) => i18n.t('yup.number.notEqual', { notEqual }),
  positive: () => i18n.t('yup.number.positive'),
  negative: () => i18n.t('yup.number.negative'),
  integer: () => i18n.t('yup.number.integer'),
}

const date = {
  min: ({ min }) => i18n.t('yup.date.min', { min }),
  max: ({ max }) => i18n.t('yup.date.max', { max }),
}

const object = {
  noUnknown: ({ unknown }) => i18n.t('yup.object.noUnknown', { unknown }),
}

const array = {
  min: ({ min }) => i18n.t('yup.array.min', { min }),
  max: ({ max }) => i18n.t('yup.array.max', { max }),
}

yup.setLocale({ mixed, string, number, date, object, array })

export default yup

import { map, range, fill } from 'lodash'

import { getIntervalLength, getIntervalLabels } from 'util/core'
/* -------------------------------------------------------------------------- */

// ----- STATISTIC VIEWS -----

export const STATS_VIEW_VALUES = {
  OPENINGS: 'OPENINGS',
  OCCUPANCY_IN_PERCENT: 'OCCUPANCY_IN_PERCENT',
}

export const getStatsViewSelects = (t) =>
  map(STATS_VIEW_VALUES, (v, k) => ({
    value: v,
    label: getStatsViewLabel(t, k),
  }))

// i18next-extract-mark-context-next-line ["OCCUPANCY_IN_PERCENT", "OPENINGS"]
export const getStatsViewLabel = (t, value) => t('statistic.view', { context: value })

/* -------------------------------------------------------------------------- */
// ----- CALCULATION -----

export const calcOccupancyInPercent = (countUserInside, countParkingLots) => {
  // Handle following conditions:
  // userInside > parkingLots (Exit barrier offline)
  // userInside < 0 (Entry barrier offline)
  if (
    Number.isInteger(countUserInside) &&
    Number.isInteger(countParkingLots) &&
    countUserInside > 0 &&
    countParkingLots > 0
  ) {
    const percentage = Math.round((countUserInside / countParkingLots) * 100)
    return percentage > 100 ? 100 : percentage < 0 ? 0 : percentage
  } else return null
}

export const getOccupancy = (countUserInside, countParkingLots) => {
  const occupancy = calcOccupancyInPercent(countUserInside, countParkingLots)
  return Number.isInteger(occupancy) ? `${occupancy}%` : '-'
}

/* -------------------------------------------------------------------------- */
// ----- CHARTS -----

export const formatOpeningsChartData = (statsCarPark, interval, date, localeCode) => {
  const { statistics } = statsCarPark
  const length = getIntervalLength(interval, { date })
  return {
    labels: getIntervalLabels(interval, { date, localeCode }),
    ...getOpenings(statistics, length),
  }
}

export const formatOccupancyChartData = (statsCarPark, interval, date, localeCode) => {
  const { parkingLots, statistics } = statsCarPark
  const length = getIntervalLength(interval, { date, localeCode })
  const labels = getIntervalLabels(interval, { date, localeCode })
  return {
    labels,
    averageData: getAverageUserInside(statistics, length, parkingLots),
  }
}

const getAverageUserInside = (stats, length, parkingLots) => {
  let innerLoopIndex = 0
  let array = fill(Array(length), null)
  // Iterate over stats objects and extract entries and exits
  // where data is available (empty time intervals are not send from backend)
  for (let i = 0; i < length; i++) {
    if (innerLoopIndex === stats.length) break

    let nextElement = stats[innerLoopIndex]
    const { timeIndex } = nextElement
    if (timeIndex === i) {
      const { averageUsersInside } = nextElement
      innerLoopIndex += 1
      array[i] = calcOccupancyInPercent(averageUsersInside, parkingLots)
    }
  }
  // Interpolate average user inside values to eliminate pre set 'null' values
  for (let i = 0; i < length; i++) {
    if (array[i] === null) {
      // Slice out previous values and revert them to find next value that is not 'null'
      let lastValue = array
        .slice(0, i)
        .reverse()
        .find((e) => e !== null)
      // If there are no previous values search for next values !== 'null'
      if (lastValue === undefined) {
        lastValue = array.slice(i).find((e) => e !== null)
      }
      array[i] = lastValue
    }
  }
  return array
}

const getOpenings = (stats, length) => {
  let entriesTotalArr = range(0, length, 0)
  let exitsTotalArr = range(0, length, 0)
  let entriesNormalArr = range(0, length, 0)
  let entriesAppArr = range(0, length, 0)
  let exitsNormalArr = range(0, length, 0)
  let exitsAppArr = range(0, length, 0)

  stats.forEach((s) => {
    const { timeIndex, entriesTotal, entriesApp, exitsTotal, exitsApp } = s
    entriesTotalArr[timeIndex] = entriesTotal
    entriesNormalArr[timeIndex] = entriesTotal - entriesApp
    entriesAppArr[timeIndex] = entriesApp
    exitsTotalArr[timeIndex] = exitsTotal
    exitsNormalArr[timeIndex] = exitsTotal - exitsApp
    exitsAppArr[timeIndex] = exitsApp
  })

  return {
    entriesTotal: entriesTotalArr,
    exitsTotal: exitsTotalArr,
    entriesNormal: entriesNormalArr,
    entriesApp: entriesAppArr,
    exitsNormal: exitsNormalArr,
    exitsApp: exitsAppArr,
  }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import { BasicCardLoadable } from 'fragments/Loadables'
import columns from 'components/AppUser/Payments/PaymentsTableColumns'
import { muiDataTableOptions as options } from 'fragments/tables/TableConfig'
/* -------------------------------------------------------------------------- */
const PaymentsTable = () => {
  const {
    isGettingAppUser,
    appUser: { payments },
  } = useSelector((state) => state.appUser)

  const { t } = useTranslation()

  return (
    <BasicCardLoadable isLoading={isGettingAppUser}>
      <MuiDataTableThemed data={payments || []} columns={columns(t)} options={options()} />
    </BasicCardLoadable>
  )
}

export default PaymentsTable

export const BODY_APP_USER = {
  ACCEPTED_GDPR_AT: 'acceptedGdprAt',
  ACCEPTED_TERMS_AT: 'acceptedTermsAt',
  APP_VERSION: 'appVersion',
  CITY: 'city',
  CREATED_AT: 'createdAt',
  DELETED_AT: 'deletedAt',
  DEVICE: 'device',
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  HOUSE_NUMBER: 'houseNumber',
  IS_DELETED: 'isDeleted',
  IS_EMAIL_VERIFIED: 'isEmailVerified',
  LANGUAGE_CODE: 'languageCode',
  LAST_NAME: 'lastName',
  LICENSE_PLATE: 'licensePlate',
  OS_VERSION: 'osVersion',
  POSTAL_CODE: 'postalCode',
  SET_LICENSE_PLATE_AT: 'setLicensePlateAt',
  STREET: 'street',
  UPDATED_AT: 'updatedAt',
  UUID: 'uuid',
}

export const BODY_MOCK_APP_USER = {
  BARRIER_CONNECTIONS: 'barrierConnections',
  CREDIT_CARD_CVC: 'creditCardCvc',
  CREDIT_CARD_EXPIRY_DATE: 'creditCardExpiryDate',
  CREDIT_CARD_NUMBER: 'creditCardNumber',
  CREDIT_CARD_OWNER_FIRST_NAME: 'creditCardOwnerFirstName',
  CREDIT_CARD_OWNER_LAST_NAME: 'creditCardOwnerLastName',
  GDPR_TIMESTAMP: 'GDPRTimestamp',
  IS_GDPR_ACCEPTED: 'isGDPRAccepted',
  IS_TERMS_ACCEPTED: 'isTermsAccepted',
  LAST_LOGIN: 'lastLogin',
  PAYMENTS_ARR: 'payments',
  TERMS_TIMESTAMP: 'termsTimestamp',
}

export const BODY_MOCK_BARRIER_CONNECTION = {
  BEACON_MINOR: 'beaconMinor',
  BEACON_SIGNAL_IN_DBM: 'beaconSignalInDBM',
  CAR_PARK_NAME: 'carParkName',
  CONNECTION_TIME_IN_S: 'connectionTimeInS',
  CREATED_AT: 'createdAt',
  STATUS: 'status',
}

export default [
  {
    createdAt: '22.04.2020, 06:18',
    beaconSignalInDBM: 89,
    beaconMinor: 10,
    carParkName: 'Testhöhle Fulda',
    status: 5,
    connectionTimeInS: 17,
  },
  {
    createdAt: '22.04.2020, 10:12',
    beaconSignalInDBM: 81,
    beaconMinor: 11,
    carParkName: 'Testparkhaus Süd',
    status: 5,
    connectionTimeInS: 15,
  },
  {
    createdAt: '16.04.2020, 19:59',
    beaconSignalInDBM: 46,
    beaconMinor: 12,
    carParkName: 'Testhöhle Fulda',
    status: 7,
    connectionTimeInS: 3,
  },
  {
    createdAt: '01.04.2020, 09:02',
    beaconSignalInDBM: 50,
    beaconMinor: 12,
    carParkName: 'Testhöhle Fulda',
    status: 3,
    connectionTimeInS: 5,
  },
]

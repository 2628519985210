import { get } from 'lodash'

import { RES_ADMINS } from 'api/adminMgmt/adminMgmtModels'
/* -------------------------------------------------------------------------- */

export const unpackAdmin = (a) => {
  const {
    CAR_PARK_UUIDS,
    DELETED_AT,
    EMAIL,
    IS_ACTIVATED,
    IS_DELETED,
    IS_EMAIL_VERIFIED,
    LANGUAGE,
    OPERATOR_UUID,
    ROLE,
    UUID,
  } = RES_ADMINS
  return {
    carParkUuids: get(a, CAR_PARK_UUIDS, null),
    deletedAt: get(a, DELETED_AT, null),
    email: get(a, EMAIL, null),
    isActivated: get(a, IS_ACTIVATED, null),
    isDeleted: get(a, IS_DELETED, null),
    isEmailVerified: get(a, IS_EMAIL_VERIFIED, null),
    languageCode: get(a, LANGUAGE, null),
    operatorUuid: get(a, OPERATOR_UUID, null),
    role: get(a, ROLE, null),
    uuid: get(a, UUID, null),
  }
}

export const unpackAdmins = (admins) => admins.map(unpackAdmin)

export const pack = (admin) => {
  const { EMAIL, ROLE, OPERATOR_UUID, CAR_PARK_UUIDS, LANGUAGE } = RES_ADMINS

  return {
    [EMAIL]: admin.email,
    [ROLE]: admin.role,
    [OPERATOR_UUID]: admin.operatorUuid,
    [CAR_PARK_UUIDS]: admin.carParkUuids,
    [LANGUAGE]: admin.language,
  }
}

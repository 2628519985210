import {
  CREATE_ADMIN,
  EDIT_ADMIN,
  GET_ADMIN_BY_UUID,
  RESET_ADMIN,
  RESET_CREATE_ADMIN,
  RESET_EDIT_ADMIN,
} from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getAdminByUuidAct = (params) => ({
  type: GET_ADMIN_BY_UUID.REQUEST,
  isReload: params?.isReload,
  uuid: params?.uuid,
})

export const getAdminByUuidSuccessAct = (admin) => ({
  type: GET_ADMIN_BY_UUID.SUCCESS,
  admin,
})

export const getAdminByUuidErrorAct = (error) => ({
  type: GET_ADMIN_BY_UUID.ERROR,
  error,
})

export const resetAdminAct = () => ({
  type: RESET_ADMIN,
})

// ----- CREATE -----

export const createAdminAct = (adminCreatable) => ({
  type: CREATE_ADMIN.REQUEST,
  adminCreatable,
})

export const createAdminSuccessAct = (admin) => ({
  type: CREATE_ADMIN.SUCCESS,
  admin,
})

export const createAdminErrorAct = (error) => ({
  type: CREATE_ADMIN.ERROR,
  error,
})

export const resetCreateAdminAct = () => ({
  type: RESET_CREATE_ADMIN,
})

// ----- EDIT -----

export const editAdminAct = (adminUpdatable, uuid) => ({
  type: EDIT_ADMIN.REQUEST,
  adminUpdatable,
  uuid,
})

export const editAdminSuccessAct = (admin) => ({
  type: EDIT_ADMIN.SUCCESS,
  admin,
})

export const editAdminErrorAct = (error) => ({
  type: EDIT_ADMIN.ERROR,
  error,
})

export const resetEditAdminAct = () => ({
  type: RESET_EDIT_ADMIN,
})

import {
  GET_OPERATOR_BY_UUID,
  RESET_OPERATOR,
  CREATE_OPERATOR,
  RESET_CREATE_OPERATOR,
  EDIT_OPERATOR,
  RESET_EDIT_OPERATOR,
} from 'redux/Types'
import { unpackOperator } from 'api/operatorMgmt/operatorMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  errGettingOperator: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  isGettingOperator: false,
  isReGettingOperator: false,
  isCreatingOperator: false,
  createOperatorSuccess: false,
  errCreatingOperator: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  isEditingOperator: false,
  editOperatorSuccess: false,
  errEditingOperator: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  lastSuccessDate: new Date(),
  operator: unpackOperator(),
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_OPERATOR_BY_UUID.REQUEST: {
      const { isReload } = action
      return {
        ...state,
        isGettingOperator: !isReload,
        isReGettingOperator: !!isReload,
        errGettingOperator: INIT_STATE.errGettingOperator,
      }
    }
    case GET_OPERATOR_BY_UUID.SUCCESS: {
      const { operator } = action
      return {
        ...state,
        isGettingOperator: false,
        isReGettingOperator: false,
        operator: unpackOperator(operator),
        lastSuccessDate: new Date(),
      }
    }
    case GET_OPERATOR_BY_UUID.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingOperator: false,
        isReGettingOperator: false,
        errGettingOperator: error,
      }
    }
    case CREATE_OPERATOR.REQUEST: {
      return {
        ...state,
        isCreatingOperator: true,
        createOperatorSuccess: false,
        errCreatingOperator: INIT_STATE.errCreatingOperator,
      }
    }
    case CREATE_OPERATOR.SUCCESS: {
      const { operator } = action
      return {
        ...state,
        isCreatingOperator: false,
        createOperatorSuccess: true,
        operator: unpackOperator(operator),
      }
    }
    case CREATE_OPERATOR.ERROR: {
      const { error } = action
      return {
        ...state,
        isCreatingOperator: false,
        createOperatorSuccess: false,
        errCreatingOperator: error,
      }
    }
    case RESET_CREATE_OPERATOR: {
      return {
        ...state,
        createOperatorSuccess: false,
      }
    }
    case EDIT_OPERATOR.REQUEST: {
      return {
        ...state,
        isEditingOperator: true,
        editOperatorSuccess: false,
        errEditingOperator: INIT_STATE.errEditingOperator,
      }
    }
    case EDIT_OPERATOR.SUCCESS: {
      const { operator } = action
      return {
        ...state,
        isEditingOperator: false,
        editOperatorSuccess: true,
        operator: unpackOperator(operator),
      }
    }
    case EDIT_OPERATOR.ERROR: {
      const { error } = action
      return {
        ...state,
        isEditingOperator: false,
        editOperatorSuccess: false,
        errEditingOperator: error,
      }
    }
    case RESET_EDIT_OPERATOR: {
      return {
        ...state,
        editOperatorSuccess: false,
      }
    }
    case RESET_OPERATOR: {
      return {
        ...state,
        operator: INIT_STATE.operator,
      }
    }
    default:
      return {
        ...state,
      }
  }
}

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'

import PageGrid from 'fragments/Grid/PageGrid'
import OfferGrid from 'components/Offer/OfferGrid'
import { editOfferByUuidAct, resetEditOfferByUuidAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam, getRequiredInfo } from 'util/core'
import { validationSchema, initialValues } from 'components/Offer/Edit/offerEdit'
import { SaveButton, CancelButton } from 'fragments/Buttons'
/* -------------------------------------------------------------------------- */

const OfferEditForm = () => {
  const { editOfferByUuidSuccess, offer } = useSelector((state) => state.offer)
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const to = React.useMemo(
    () => ({
      pathname: APP.OFFER,
      search: getQueryString({
        [QUERY_PARAMS.UUID]: uuid,
      }),
    }),
    [uuid],
  )

  useEffect(() => {
    if (editOfferByUuidSuccess) {
      history.push(to)
    }
    return () => {
      if (editOfferByUuidSuccess) {
        dispatch(resetEditOfferByUuidAct())
      }
    }
  }, [dispatch, editOfferByUuidSuccess, history, to, uuid])

  const handleSubmit = (values) => dispatch(editOfferByUuidAct(values, uuid))

  return (
    <Formik
      initialValues={initialValues(offer)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
    >
      {(formikProps) => {
        const { isValidating, isSubmitting } = formikProps
        return (
          <Form onKeyDown={onKeyDown}>
            <PageGrid justify='space-between'>
              <OfferGrid isEdit formikProps={formikProps} />
              <Grid container justify='space-between' item>
                <Grid item>{getRequiredInfo(t)}</Grid>
                <Grid item xs>
                  <Grid container spacing={2} justify='flex-end'>
                    <Grid item>
                      <CancelButton component={Link} to={to} />
                    </Grid>
                    <Grid item>
                      <SaveButton disabled={isValidating || isSubmitting} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </PageGrid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OfferEditForm

import React from 'react'
import {
  Dashboard as DashboardIcon,
  LocationCity as LocationCityIcon,
  KeyboardTab as KeyboardTabIcon,
  Label as LabelIcon,
  LocalActivity as LocalActivityIcon,
  SwapVert as SwapVertIcon,
  Assessment as AssessmentIcon,
  LocalAtm as LocalAtmIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons'

import { ROLES } from 'constants/generalConstants'
import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

export default (t) => [
  [
    {
      title: t('MenuContent.home'),
      icon: <DashboardIcon fontSize='small' />,
      path: APP.HOME,
      allowedRoles: [],
    },
  ],
  [
    {
      title: t('MenuContent.carParks'),
      icon: <LocationCityIcon fontSize='small' />,
      path: APP.CAR_PARKS,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
    {
      title: t('MenuContent.barriers'),
      icon: <KeyboardTabIcon fontSize='small' />,
      path: APP.BARRIERS,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
    {
      title: t('MenuContent.offers'),
      icon: <LabelIcon fontSize='small' />,
      path: APP.OFFERS,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
  ],
  [
    {
      title: t('MenuContent.tickets'),
      icon: <LocalActivityIcon fontSize='small' />,
      path: APP.TICKETS,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
    {
      title: t('MenuContent.barrierOpenings'),
      icon: <SwapVertIcon fontSize='small' />,
      path: APP.BARRIER_OPENINGS,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
    {
      title: t('MenuContent.occupancy'),
      icon: <AssessmentIcon fontSize='small' />,
      path: APP.OCCUPANCY,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
  ],
  [
    {
      title: t('MenuContent.sales'),
      icon: <LocalAtmIcon fontSize='small' />,
      path: APP.SALES_MONTH,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN, ROLES.OP_MANAGER],
    },
    {
      title: t('MenuContent.clients'),
      icon: <PeopleIcon fontSize='small' />,
      path: APP.CLIENTS,
      allowedRoles: [ROLES.TP_ADMIN],
    },
  ],
  [
    {
      title: t('MenuContent.settings'),
      icon: <SettingsIcon fontSize='small' />,
      path: APP.SETTINGS,
      allowedRoles: [ROLES.TP_ADMIN, ROLES.OP_ADMIN],
    },
  ],
]

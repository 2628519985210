import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormHelperText } from '@material-ui/core'

import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import BasicSwitch from 'fragments/Forms/BasicSwitch'
import { editBarrierAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */
const ToggleSecretGeneration = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { uuid, isSecretGenerationAllowed } = useSelector((state) => state.barrier.barrier)
  const [open, setOpen] = useState(false)

  const { isEdit } = props

  const handleConfirm = () => {
    dispatch(editBarrierAct(uuid, { isSecretGenerationAllowed: !isSecretGenerationAllowed }))
    setOpen(false)
  }

  return (
    <>
      <ConfirmDialog
        open={open}
        dialogTitle={t('ToggleSecretGeneration.title')}
        dialogContent={
          isSecretGenerationAllowed
            ? t('ToggleSecretGeneration.contentTrigger')
            : t('ToggleSecretGeneration.contentCancel')
        }
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
      <BasicSwitch disabled={isEdit} checked={isSecretGenerationAllowed} onChange={() => setOpen(true)} />
      <FormHelperText>{isEdit ? t('ToggleSecretGeneration.helperText.editMode') : null}</FormHelperText>
    </>
  )
}

ToggleSecretGeneration.propTypes = {
  isEdit: PropTypes.bool,
}

ToggleSecretGeneration.defaultProps = {
  isEdit: false,
}

export default ToggleSecretGeneration

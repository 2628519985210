import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'
import { PersonAdd as PersonAddIcon } from '@material-ui/icons'

import NumbersWidget from 'fragments/widgets/NumbersWidget'
/* -------------------------------------------------------------------------- */

const AppUserNumbersCard = (props) => {
  const { isGettingGeneralStatistics, countTotal, countMonth } = props
  const { t } = useTranslation()

  return (
    <NumbersWidget
      title={t('AppUserNumbersCard.appUser')}
      isLoading={isGettingGeneralStatistics}
      action={<PersonAddIcon />}
      label1={t('AppUserNumbersCard.total')}
      val1={countTotal}
      label2={t('AppUserNumbersCard.month')}
      val2={countMonth}
    />
  )
}

/* ----- SETUP -------------------------------------------------------------- */
AppUserNumbersCard.propTypes = {
  isGettingGeneralStatistics: PropTypes.bool.isRequired,
  countTotal: PropTypes.number,
  countMonth: PropTypes.number,
}

AppUserNumbersCard.defaultProps = {
  countTotal: null,
  countMonth: null,
}

const mapStateToProps = ({ statistics }) => {
  const {
    appUsersCount: { total, month },
    isGettingGeneralStatistics,
  } = statistics
  return {
    isGettingGeneralStatistics,
    countTotal: total,
    countMonth: month,
  }
}

export default connect(mapStateToProps, null)(AppUserNumbersCard)

import { call, put, takeLatest, all, fork } from 'redux-saga/effects'

import { GET_APP_USER } from 'redux/Types'
import { getAppUserByUuidSuccessAct, getAppUserByUuidErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getAppUserReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetAppUserByUuid(action) {
  try {
    const { uuid } = action
    const data = yield call(getAppUserReq, uuid)
    yield put(getAppUserByUuidSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAppUserByUuidErrorAct))
  }
}

function* watcherGetUserAccountDetailById() {
  yield takeLatest(GET_APP_USER.REQUEST, workerGetAppUserByUuid)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetUserAccountDetailById)])
}

import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import CarParkContainer from 'components/CarPark/CarParkContainer'
import BarrierOpeningsTableCard from 'components/BarrierOpenings/BarrierOpeningsTableCard'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { useGetBarrierOpeningsQuery, usePrefetch } from 'services/barrier-openings/barrier-openings-service'
import { getParsedQueryParam } from 'util/core'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import PageGrid from 'fragments/Grid/PageGrid'
/* -------------------------------------------------------------------------- */

type RootState = {
  globalFilters: {
    selectedOperator: string
  }
}

export default function CarParkOpeningsTabContainer() {
  const { t } = useTranslation()
  const location = useLocation()
  const carParkUuids = React.useMemo(() => [getParsedQueryParam(location.search, 'uuid') as string], [location.search])
  const { selectedOperator } = useSelector((state: RootState) => state.globalFilters)
  const [page, setPage] = React.useState(0)
  const [rows, setRows] = React.useState(10)
  const prefetchBarrierOpenings = usePrefetch('getBarrierOpenings')
  const { data, fulfilledTimeStamp, isLoading, isFetching, error, refetch } = useGetBarrierOpeningsQuery(
    {
      carParkUuid: carParkUuids,
      operatorUuid: selectedOperator,
      pageNumber: page,
      pageSize: rows,
    },
    { pollingInterval: INFREQUENT_UPDATE_TIME_IN_MS },
  )

  const prefetchNextPage = React.useCallback(
    () =>
      prefetchBarrierOpenings({
        carParkUuid: carParkUuids,
        operatorUuid: selectedOperator,
        pageNumber: page + 1,
        pageSize: rows,
      }),
    [prefetchBarrierOpenings, carParkUuids, selectedOperator, page, rows],
  )

  React.useEffect(() => {
    setPage(0)
  }, [selectedOperator])

  React.useEffect(() => {
    if (!data?.isLastPage) {
      prefetchNextPage()
    }
  }, [data, prefetchNextPage])

  return (
    <CarParkContainer title={t('CarParkOpeningsTabContainer.openings') as string} errors={[error]}>
      <PageGrid>
        <Grid item xs={12}>
          <BarrierOpeningsTableCard
            barrierOpenings={data?.content ?? []}
            page={page}
            setPage={setPage}
            rows={rows}
            setRows={setRows}
            totalCount={data?.totalElements}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo
            lastSuccessDate={fulfilledTimeStamp}
            isUpdating={isFetching}
            reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
            refetch={refetch}
          />
        </Grid>
      </PageGrid>
    </CarParkContainer>
  )
}

import { BODY_APP_USER, BODY_MOCK_APP_USER, BODY_MOCK_BARRIER_CONNECTION } from 'api/appUserMgmt/appUserMgmtModels'
import { BODY_MOCK_PAYMENT } from 'api/sales/salesModels'
import { appendZeroUTC } from 'util/core'
/* -------------------------------------------------------------------------- */

export const unpackAppUsers = (appUsers) => appUsers.map(unpackAppUser)

export const unpackAppUser = (u) => {
  const {
    ACCEPTED_GDPR_AT,
    ACCEPTED_TERMS_AT,
    APP_VERSION,
    CITY,
    CREATED_AT,
    DELETED_AT,
    DEVICE,
    EMAIL,
    FIRST_NAME,
    HOUSE_NUMBER,
    IS_DELETED,
    IS_EMAIL_VERIFIED,
    LANGUAGE_CODE,
    LAST_NAME,
    LICENSE_PLATE,
    OS_VERSION,
    POSTAL_CODE,
    SET_LICENSE_PLATE_AT,
    STREET,
    UPDATED_AT,
    UUID,
  } = BODY_APP_USER
  return {
    acceptedGdprAt: appendZeroUTC(u?.[ACCEPTED_GDPR_AT] ?? null),
    acceptedTermsAt: appendZeroUTC(u?.[ACCEPTED_TERMS_AT] ?? null),
    appVersion: u?.[APP_VERSION] ?? null,
    city: u?.[CITY] ?? null,
    createdAt: appendZeroUTC(u?.[CREATED_AT] ?? null),
    deletedAt: appendZeroUTC(u?.[DELETED_AT] ?? null),
    device: u?.[DEVICE] ?? null,
    email: u?.[EMAIL] ?? null,
    firstName: u?.[FIRST_NAME] ?? null,
    houseNumber: u?.[HOUSE_NUMBER] ?? null,
    isDeleted: u?.[IS_DELETED] ?? null,
    isEmailVerified: u?.[IS_EMAIL_VERIFIED] ?? null,
    languageCode: u?.[LANGUAGE_CODE] ?? null,
    lastName: u?.[LAST_NAME] ?? null,
    licensePlate: u?.[LICENSE_PLATE] ?? null,
    osVersion: u?.[OS_VERSION] ?? null,
    postalCode: u?.[POSTAL_CODE] ?? null,
    setLicensePlateAt: appendZeroUTC(u?.[SET_LICENSE_PLATE_AT] ?? null),
    street: u?.[STREET] ?? null,
    updatedAt: appendZeroUTC(u?.[UPDATED_AT] ?? null),
    uuid: u?.[UUID] ?? null,
  }
}

export const unpackMockedAppUsers = (appUsers) => appUsers.map(unpackMockedAppUser)

export const unpackMockedAppUser = (u) => {
  const {
    BARRIER_CONNECTIONS,
    CREDIT_CARD_CVC,
    CREDIT_CARD_EXPIRY_DATE,
    CREDIT_CARD_NUMBER,
    CREDIT_CARD_OWNER_FIRST_NAME,
    CREDIT_CARD_OWNER_LAST_NAME,
    GDPR_TIMESTAMP,
    IS_GDPR_ACCEPTED,
    IS_TERMS_ACCEPTED,
    LAST_LOGIN,
    PAYMENTS_ARR,
    TERMS_TIMESTAMP,
  } = BODY_MOCK_APP_USER
  return {
    ...unpackAppUser(u),
    barrierConnections: unpackBarrierConnections(u[BARRIER_CONNECTIONS] || []),
    creditCardCvc: u[CREDIT_CARD_CVC],
    creditCardExpiryDate: appendZeroUTC(u[CREDIT_CARD_EXPIRY_DATE]),
    creditCardNumber: u[CREDIT_CARD_NUMBER],
    creditCardOwnerFirstName: u[CREDIT_CARD_OWNER_FIRST_NAME],
    creditCardOwnerLastName: u[CREDIT_CARD_OWNER_LAST_NAME],
    GDPRTimestamp: appendZeroUTC(u[GDPR_TIMESTAMP]),
    isGDPRAccepted: u[IS_GDPR_ACCEPTED],
    isTermsAccepted: u[IS_TERMS_ACCEPTED],
    lastLogin: u[LAST_LOGIN],
    payments: unpackPayments(u[PAYMENTS_ARR] || []),
    termsTimestamp: u[TERMS_TIMESTAMP],
  }
}

// FIXME: move to sales or ticket??
const unpackPayments = (p) => p.map((obj) => unpackPayment(obj))

const unpackPayment = (p) => {
  const {
    CAR_PARK_NAME,
    UPDATED_AT,
    OFFER_NAME,
    STATUS,
    TICKET_ID,
    TRANSACTION_ID,
    TRANSACTIONS_SUM_IN_CENT,
  } = BODY_MOCK_PAYMENT
  return {
    carParkName: p[CAR_PARK_NAME],
    updatedAt: appendZeroUTC(p[UPDATED_AT]),
    offerName: p[OFFER_NAME],
    status: p[STATUS],
    ticketId: p[TICKET_ID],
    transactionId: p[TRANSACTION_ID],
    transactionSumInCent: p[TRANSACTIONS_SUM_IN_CENT],
  }
}

const unpackBarrierConnections = (arr) => arr.map(unpackBarrierConnection)

const unpackBarrierConnection = (obj) => {
  const {
    BEACON_MINOR,
    BEACON_SIGNAL_IN_DBM,
    CAR_PARK_NAME,
    CONNECTION_TIME_IN_S,
    CREATED_AT,
    STATUS,
  } = BODY_MOCK_BARRIER_CONNECTION
  return {
    beaconMinor: obj[BEACON_MINOR],
    beaconSignalInDBM: obj[BEACON_SIGNAL_IN_DBM],
    carParkName: obj[CAR_PARK_NAME],
    connectionTimeInS: obj[CONNECTION_TIME_IN_S],
    createdAt: obj[CREATED_AT],
    status: obj[STATUS],
  }
}

import axiosInstance from 'api/axiosConfig'
import { PATH_SEGMENTS_ACCOUNT, BODY_ACCOUNT } from 'api/accountMgmt/accountMgmtModels'
/* -------------------------------------------------------------------------- */

export const resetPasswordRequestReq = (email) => {
  const { PW_RESET_REQ } = PATH_SEGMENTS_ACCOUNT
  const { EMAIL } = BODY_ACCOUNT
  const url = `${PW_RESET_REQ}`
  const data = {
    [EMAIL]: email,
  }
  return axiosInstance.post(url, data).then((res) => {
    const { status } = res
    return status
  })
}

export const resetPasswordReq = (newPassword, token) => {
  const { PW_RESET } = PATH_SEGMENTS_ACCOUNT
  const { NEW_PASSWORD, TOKEN } = BODY_ACCOUNT
  const url = PW_RESET
  const data = {
    [NEW_PASSWORD]: newPassword,
    [TOKEN]: token,
  }
  return axiosInstance.post(url, data)
}

export const modifyPasswordReq = (oldPassword, newPassword) => {
  const { MODIFY_PASSWORD } = PATH_SEGMENTS_ACCOUNT
  const { OLD_PASSWORD, NEW_PASSWORD } = BODY_ACCOUNT
  const url = `${MODIFY_PASSWORD}`
  const data = {
    [OLD_PASSWORD]: oldPassword,
    [NEW_PASSWORD]: newPassword,
  }
  return axiosInstance.post(url, data)
}

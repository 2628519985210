import { GET_OCCUPANCY, RESET_OCCUPANCY_FILTERS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getOccupancyAct = (params) => ({
  type: GET_OCCUPANCY.REQUEST,
  page: params?.page,
  rows: params?.rows,
  view: params?.view,
  date: params?.date,
  interval: params?.interval,
})

export const getOccupancySuccessAct = (data) => ({
  type: GET_OCCUPANCY.SUCCESS,
  data,
})
export const getOccupancyErrorAct = (error) => ({
  type: GET_OCCUPANCY.ERROR,
  error,
})

export const resetOccupancyFiltersAct = (params) => ({
  type: RESET_OCCUPANCY_FILTERS,
  skipRequest: params?.skipRequest,
})

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { redirectAuthorizationHOC } from 'components/Session/AuthorizationHOC'
import { ROLES } from 'constants/generalConstants'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const options = ({ history, admins }) => ({
  history: history,
  onRowClick: (_, { dataIndex }) => {
    const uuid = admins[dataIndex]?.uuid
    if (uuid) {
      redirectAuthorizationHOC([ROLES.OP_ADMIN])(
        history.push({
          pathname: APP.ADMIN,
          search: getQueryString({
            [QUERY_PARAMS.UUID]: uuid,
          }),
        }),
      )
    }
  },
})

export default (args) => ({
  ...muiDataTableOptions(),
  ...options(args),
})

import { barrierOpeningsApi } from 'services/barrier-openings/barrier-openings-service'
import { ticketsApi } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

export type Page<T> = {
  content: T
  isFirstPage: boolean
  isLastPage: boolean
  pageNumber: number
  pageSize: number
  totalElements: number
  totalPages: number
}

export type RtkQueryError = {
  status: number
  data: {
    code?: number
    message?: string
  }
}

export function transformRtkQueryError(error?: RtkQueryError) {
  const httpStatusCode = error?.status
  const errorCode = error?.data?.code
  const message = error?.data?.message

  return {
    httpStatusCode,
    errorCode,
    message,
  }
}

export const serviceReducers = {
  [barrierOpeningsApi.reducerPath]: barrierOpeningsApi.reducer,
  [ticketsApi.reducerPath]: ticketsApi.reducer,
}

export const serviceMiddlewares = [barrierOpeningsApi.middleware, ticketsApi.middleware]

import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, reverse } from 'lodash'
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core'

import { BasicCardLoadable } from 'fragments/Loadables'
import TicketBarrierOpeningRow from 'components/Ticket/TicketBarrierOpeningRow'
import { BarrierOpening } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type TicketBarrierOpeningsCardProps = {
  barrierOpenings?: BarrierOpening[]
  isLoading: boolean
}

const useStyles = makeStyles({
  scroll: {
    minHeight: 130,
    maxHeight: 600,
    overflow: 'auto',
  },
})

export default function TicketBarrierOpeningsCard(props: TicketBarrierOpeningsCardProps) {
  const { isLoading, barrierOpenings = [] } = props
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <BasicCardLoadable title={t('TicketBarrierOpeningsCard.barrierOpenings')} isLoading={isLoading}>
      <div className={classes.scroll}>
        <List>
          {reverse([...barrierOpenings]).map((opening) => (
            <TicketBarrierOpeningRow key={opening.uuid} opening={opening} />
          ))}
          {isEmpty(barrierOpenings) && (
            <ListItem alignItems='flex-start'>
              <ListItemText
                primary={t('TicketBarrierOpeningsCard.noActivity')}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          )}
        </List>
      </div>
    </BasicCardLoadable>
  )
}

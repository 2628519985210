import React from 'react'
import loadable from '@loadable/component'
import { CircularProgress, makeStyles } from '@material-ui/core'

import WidgetSkeleton from 'fragments/Skeletons/WidgetSkeleton'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
})

const CircularProgressLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  )
}

/* -------------------------------------------------------------------------- */

export const BasicCardLoadable = loadable(() => import('fragments/Cards/BasicCard'), {
  // FIXME: add updated BasicCard skeleton
  fallback: <WidgetSkeleton />,
})

export const BasicPageContainerLoadable = loadable(() => import('layouts/BasicPageContainer/BasicPageContainer'), {
  fallback: <CircularProgressLoader />,
})

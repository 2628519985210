import React from 'react'
import { useTranslation } from 'react-i18next'

import MultipleSelect from 'fragments/Forms/MultipleSelect'
import { BarrierOpeningsType } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type BarrierOpeningCategorySelectProps = {
  onChange: (ts: BarrierOpeningsType[]) => void
  selected: string[]
}

export default function BarrierOpeningCategorySelect(props: BarrierOpeningCategorySelectProps) {
  const { onChange, selected } = props
  const { t } = useTranslation()

  function getBarrierOpeningStatusGroups(): { id: BarrierOpeningsType; label: string }[] {
    return [
      { id: 'BLE', label: t('barrierOpening.status.label.app') as string },
      { id: 'MANUAL', label: t('barrierOpening.status.label.manual') as string },
      { id: 'REMOTE', label: t('barrierOpening.status.label.remote') as string },
      { id: 'FOREIGN', label: t('barrierOpening.status.label.standard') as string },
    ]
  }

  return (
    <MultipleSelect
      label={t('BarrierOpeningCategorySelect.category') as string}
      options={getBarrierOpeningStatusGroups()}
      selected={selected}
      onChange={onChange}
    />
  )
}

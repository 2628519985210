import { GET_BARRIER_BY_ID, RESET_BARRIER, EDIT_BARRIER, RESET_EDIT_BARRIER } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getBarrierByUuidAct = ({ isReload, uuid }) => ({
  type: GET_BARRIER_BY_ID.REQUEST,
  isReload,
  uuid,
})

export const getBarrierByUuidSuccessAct = (barrier) => ({
  type: GET_BARRIER_BY_ID.SUCCESS,
  barrier,
})

export const getBarrierByUuidErrorAct = (error) => ({
  type: GET_BARRIER_BY_ID.ERROR,
  error,
})

export const resetBarrierAct = () => ({
  type: RESET_BARRIER,
})

export const editBarrierAct = (uuid, params) => ({
  type: EDIT_BARRIER.REQUEST,
  uuid,
  formValues: params?.values,
  isUpdateAllowed: params?.isUpdateAllowed,
  isRebootAllowed: params?.isRebootAllowed,
  isSshTunnelEnabled: params?.isSshTunnelEnabled,
  isSecretGenerationAllowed: params?.isSecretGenerationAllowed,
})

export const editBarrierSuccessAct = (barrier) => ({
  type: EDIT_BARRIER.SUCCESS,
  barrier,
})

export const editBarrierErrorAct = (error) => ({
  type: EDIT_BARRIER.ERROR,
  error,
})

export const resetEditBarrierAct = () => ({
  type: RESET_EDIT_BARRIER,
})

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import OperatorCreateForm from 'components/Operator/Create/OperatorCreateForm'
/* -------------------------------------------------------------------------- */

const OperatorCreateContainer = () => {
  const { t } = useTranslation()
  const { errCreatingOperator } = useSelector((state) => state.operator)

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('OperatorCreateContainer.title')}
      pageTitle={t('OperatorCreateContainer.title')}
    >
      <ErrorAlertContainer errors={[errCreatingOperator]} />
      <OperatorCreateForm />
    </BasicPageContainerLoadable>
  )
}

/* -------------------------------------------------------------------------- */

export default OperatorCreateContainer

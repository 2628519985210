import React from 'react'
import * as PropTypes from 'prop-types'
import { TextField as FormikMUITextField } from 'formik-material-ui'
import { Field } from 'formik'
/* -------------------------------------------------------------------------- */

const TextField = ({ name, placeholder }) => (
  <Field component={FormikMUITextField} name={name} placeholder={placeholder} fullWidth />
)

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

TextField.defaultProps = {
  placeholder: null,
}

export default TextField

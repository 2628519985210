import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Field, useFormikContext } from 'formik'
import { Select } from 'formik-material-ui'
import { MenuItem, FormControl, FormHelperText, ListItemText, Checkbox } from '@material-ui/core'

import { getCarParksAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const CarParksMultipleSelect = (props) => {
  const dispatch = useDispatch()
  const { selectedOperator } = useSelector((state) => state.globalFilters)
  const { carParks } = useSelector((state) => state.carParks)
  const {
    errors,
    values: { carParkUuids },
  } = useFormikContext()

  const { name } = props
  const errorText = errors[name]

  useEffect(() => {
    dispatch(getCarParksAct())
  }, [dispatch, selectedOperator])

  return (
    <FormControl fullWidth error={!!errorText}>
      <Field
        component={Select}
        name={name}
        renderValue={(carParkUuids) =>
          carParkUuids.reduce((acc, cur) => acc.concat(carParks.find((p) => p.uuid === cur).name), []).join(', ')
        }
        multiple
      >
        {carParks.map((cp) => (
          <MenuItem key={cp.uuid} value={cp.uuid}>
            <Checkbox color='primary' checked={carParkUuids.indexOf(cp.uuid) > -1} disabled={false} />
            <ListItemText primary={cp.name} />
          </MenuItem>
        ))}
      </Field>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}

CarParksMultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
}

export default CarParksMultipleSelect

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import options from './occupancyTableOptions'
import columns from './OccupancyTableColumns'
/* -------------------------------------------------------------------------- */
const OccupancyTableCard = () => {
  const {
    filters: { view },
    isGettingOccupancy,
    occupancy,
    pagination: { page, rows, totalCount },
  } = useSelector((state) => state.occupancy)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <BasicCardLoadable isLoading={isGettingOccupancy}>
      <MuiDataTableThemed
        data={occupancy}
        columns={columns({ view, t, occupancy })}
        options={options({
          dispatch,
          page,
          rows,
          totalCount,
        })}
      />
    </BasicCardLoadable>
  )
}

export default OccupancyTableCard

import axiosInstance from 'api/axiosConfig'
import { PATH_SEGMENTS_OFFERS, PARAMS_OFFERS } from 'api/offerMgmt/offerMgmtModels'
import { pack } from 'api/offerMgmt/offerMgmtUnpackers'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

/**
 * Api functions for offers
 * @namespace offersMgmtApi
 */

/**
 * Get all offers
 * @function
 * @memberof offersMgmtApi
 * @param {Object} params
 * @returns {Object[]}
 */
export const getOffersReq = ({ carParkUuids, operatorUuid, page, rows, type, isVisible }) => {
  const { OFFERS_BASE } = PATH_SEGMENTS_OFFERS
  const { CAR_PARK_UUID, OPERATOR_UUID, PAGE_NUMBER, PAGE_SIZE, TYPE, IS_VISIBLE } = PARAMS_OFFERS
  const qs = getQueryString({
    [OPERATOR_UUID]: operatorUuid,
    [CAR_PARK_UUID]: carParkUuids,
    [PAGE_NUMBER]: page,
    [PAGE_SIZE]: rows,
    [TYPE]: type,
    [IS_VISIBLE]: isVisible,
  })

  return axiosInstance.get(`${OFFERS_BASE}${qs}`).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Get offer detail by uuid
 * @function
 * @memberof offersMgmtApi
 * @param {string} uuid
 * @returns {Object}
 */
export const getOfferByUuidReq = (uuid) =>
  axiosInstance.get(`${PATH_SEGMENTS_OFFERS.OFFERS_BASE}/${uuid}`).then((res) => res.data)

/**
 * Modify offer detail by uuid
 * @function
 * @memberof offersMgmtApi
 * @param {string} uuid
 * @param {Object} offerUpdateable
 * @returns {Object}
 */
export const editOfferByUuidReq = (uuid, offerUpdatable) => {
  const url = `${PATH_SEGMENTS_OFFERS.OFFERS_BASE}/${uuid}`
  const data = pack(offerUpdatable)

  return axiosInstance.post(url, data).then((res) => {
    const { data } = res
    return data
  })
}

/**
 * Create a new offer
 * @function
 * @memberof offersMgmtApi
 * @param {string} offerCreatable
 * @returns {Object}
 */
export const createOfferReq = (offerCreatable) => {
  const url = PATH_SEGMENTS_OFFERS.OFFERS_BASE
  const data = pack(offerCreatable)

  return axiosInstance.post(url, data).then((res) => {
    const { data } = res
    return data
  })
}

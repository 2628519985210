export { validationSchema } from 'components/Barrier/validationSchema'

export const initialValues = (barrier) => ({
  name: barrier.name ?? '',
  carParkUuid: barrier.carParkUuid ?? '',
  isEntrance: barrier.isEntrance ?? false,
  beaconMinor: barrier.beaconMinor ?? 0,
  state: barrier.state ?? '',
  updateUrl: barrier.updateUrl ?? '',
})

import React from 'react'

import CustomTableFooter from 'fragments/tables/CustomTableFooter'
/* -------------------------------------------------------------------------- */

export const muiDataTableOptions = (hideFooter = false) => ({
  download: false,
  elevation: 0,
  filter: false,
  filterType: 'dropdown',
  print: false,
  responsive: 'standard',
  rowsPerPage: 10, // needs to match first value of 'rowsPerPageOptions'
  search: false,
  selectableRows: 'none',
  sort: false,
  viewColumns: false,
  ...(!hideFooter && {
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => (
      <CustomTableFooter
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={changePage}
        textLabels={textLabels}
      />
    ),
  }),
})

export const muiDataTableTextLabels = (t) => ({
  textLabels: {
    body: {
      noMatch: t('MuiDataTableConfig.textLabels.body.noMatch'),
      toolTip: t('MuiDataTableConfig.textLabels.body.toolTip'),
      columnHeaderTooltip: (column) =>
        t('MuiDataTableConfig.textLabels.body.columnHeaderTooltip', {
          column: column.label,
        }),
    },
    pagination: {
      next: t('MuiDataTableConfig.textLabels.pagination.next'),
      previous: t('MuiDataTableConfig.textLabels.pagination.previous'),
      rowsPerPage: t('MuiDataTableConfig.textLabels.pagination.rowsPerPage'),
      displayRows: t('MuiDataTableConfig.textLabels.pagination.displayRows'),
    },
    toolbar: {
      search: t('MuiDataTableConfig.textLabels.toolbar.search'),
      downloadCsv: t('MuiDataTableConfig.textLabels.toolbar.downloadCsv'),
      print: t('MuiDataTableConfig.textLabels.toolbar.print'),
      viewColumns: t('MuiDataTableConfig.textLabels.toolbar.viewColumns'),
      filterTable: t('MuiDataTableConfig.textLabels.toolbar.filterTable'),
    },
    filter: {
      all: t('MuiDataTableConfig.textLabels.filter.all'),
      title: t('MuiDataTableConfig.textLabels.filter.title'),
      reset: t('MuiDataTableConfig.textLabels.filter.reset'),
    },
    viewColumns: {
      title: t('MuiDataTableConfig.textLabels.viewColumns.title'),
      titleAria: t('MuiDataTableConfig.textLabels.viewColumns.titleAria'),
    },
    selectedRows: {
      text: t('MuiDataTableConfig.textLabels.selectedRows.text'),
      delete: t('MuiDataTableConfig.textLabels.selectedRows.delete'),
      deleteAria: t('MuiDataTableConfig.textLabels.selectedRows.deleteAria'),
    },
  },
})

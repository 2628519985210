import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { isOperatorManager } from 'util/custom'
import ProfileInfoCard from 'components/Profile/ProfileInfoCard'
import LanguageCard from 'components/Profile/LanguageCard'
import CarParksCard from 'components/Profile/CarParksCard'
/* -------------------------------------------------------------------------- */

const ProfileGrid = () => {
  const role = useSelector((state) => state.auth.user.role)
  const isOpManager = isOperatorManager(role)

  return (
    <>
      <Grid item xs={12} sm={12} md={isOpManager ? 4 : 6}>
        <ProfileInfoCard />
      </Grid>
      <Grid item xs={12} sm={isOpManager ? 6 : 12} md={isOpManager ? 4 : 6}>
        <LanguageCard />
      </Grid>
      {isOpManager && (
        <Grid item xs={12} sm={6} md={4}>
          <CarParksCard />
        </Grid>
      )}
    </>
  )
}

export default ProfileGrid

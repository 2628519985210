import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CustomDatePicker from 'fragments/Forms/CustomDatePicker'
import { getSalesAct, resetSalesFiltersAct } from 'redux/Actions'
import FilterGridCard from 'fragments/Cards/FilterGridCard'
/* -------------------------------------------------------------------------- */
const SalesFilterCard = () => {
  const {
    filters: { date, interval },
    isGettingSales,
  } = useSelector((state) => state.sales)
  const dispatch = useDispatch()

  const handleFilterChange = (key) => (v) => dispatch(getSalesAct({ [key]: v }))

  return (
    <FilterGridCard isLoading={isGettingSales} resetFilters={() => dispatch(resetSalesFiltersAct())}>
      <CustomDatePicker date={date} onChange={handleFilterChange('date')} timeInterval={interval} showArrows />
    </FilterGridCard>
  )
}

export default SalesFilterCard

import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const OperatorGeneralCard = (props) => {
  const {
    operator: { name, createdAt },
    isGettingOfferByUuid,
  } = useSelector((state) => state.operator)
  const { t } = useTranslation()
  const { isCreate, isEdit } = props

  const rows = [
    {
      label: t('OperatorGeneralCard.name') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? <TextField name='name' /> : name,
    },
    {
      label: t('OperatorGeneralCard.createdAt'),
      value: t('OperatorGeneralCard.dateMediumTimeShort', { date: createdAt }),
      hideOn: isCreate,
    },
  ]

  return (
    <BasicCardLoadable title={t('OperatorGeneralCard.title')} action={<InfoIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

OperatorGeneralCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

OperatorGeneralCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default OperatorGeneralCard

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import PlaceHolderText from 'fragments/PlaceHolderText'
import { Typography } from '@material-ui/core'
/* -------------------------------------------------------------------------- */

const InfoRows = (props) => {
  const { t } = useTranslation()
  const { rows } = props

  return rows.map(
    ({ label, value, hideOn }) =>
      !hideOn && (
        <Fragment key={label}>
          <Grid item md={4} sm={12} xs={12}>
            <Typography variant='body2' color='secondary'>
              {label}
            </Typography>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            {value === '' || value === undefined || value === null ? (
              <PlaceHolderText text={t('InfoRows.notSpecified')} />
            ) : (
              <Typography component='div' variant='body2'>
                {value}
              </Typography>
            )}
          </Grid>
        </Fragment>
      ),
  )
}

InfoRows.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
      hideOn: PropTypes.bool,
    }),
  ).isRequired,
}

export default InfoRows

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, makeStyles } from '@material-ui/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles({
  root: { textTransform: 'none' },
})

const SaveButton = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Button type='submit' color='primary' variant='outlined' classes={{ root: classes.root }} {...props}>
      {t('SaveButton.save')}
    </Button>
  )
}

export default SaveButton

import React from 'react'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Event as EventIcon } from '@material-ui/icons'
import { IconButton, InputAdornment } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import { localeMap } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const CustomDateTimePicker = (props) => {
  const { date, disableFuture, disablePast, errorText, label, onChange, inputVariant } = props
  let { helperText, hasError } = props

  const { t, i18n } = useTranslation()

  if (errorText) {
    hasError = true
    helperText = errorText
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
      <DateTimePicker
        label={label || t('CustomDateTimePicker.dateAndTime')}
        value={date ? new Date(date) : null}
        onChange={(value) => onChange(value.toISOString())}
        format='dd.MM.yyyy HH:mm'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={hasError}
        helperText={helperText}
        inputVariant={inputVariant}
        fullWidth
        ampm={false}
        showTodayButton
        disableFuture={disableFuture}
        disablePast={disablePast}
        variant='dialog'
        okLabel={t('CustomDateTimePicker.ok')}
        cancelLabel={t('CustomDateTimePicker.cancel')}
        todayLabel={t('CustomDateTimePicker.today')}
        invalidDateMessage={t('CustomDateTimePicker.failure')}
        clearable
        clearLabel={t('CustomDateTimePicker.delete')}
      />
    </MuiPickersUtilsProvider>
  )
}
/* -------------------------------------------------------------------------- */
CustomDateTimePicker.propTypes = {
  date: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputVariant: PropTypes.string,
}

CustomDateTimePicker.defaultProps = {
  date: null,
  disableFuture: true,
  disablePast: false,
  errorText: null,
  hasError: false,
  helperText: null,
  label: null,
  inputVariant: 'standard',
}
export default CustomDateTimePicker

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import OperatorEditForm from 'components/Operator/Edit/OperatorEditForm'
import { getOperatorByUuidAct, resetOperatorAct } from 'redux/Actions'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

const OperatorEditContainer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    errEditingOperator,
    isGettingOperator,
    operator: { name },
  } = useSelector((state) => state.operator)

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    if (uuid) {
      dispatch(getOperatorByUuidAct({ uuid }))
    }
    return () => {
      dispatch(resetOperatorAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable
      isLoading={isGettingOperator}
      helmetTitle={t('OperatorEditContainer.title')}
      pageTitle={name || t('OperatorEditContainer.title')}
    >
      <ErrorAlertContainer errors={[errEditingOperator]} />
      <OperatorEditForm />
    </BasicPageContainerLoadable>
  )
}

/* -------------------------------------------------------------------------- */

export default OperatorEditContainer

import React from 'react'
import ReactDOM from 'react-dom'
import { LinearProgress } from '@material-ui/core'

import App from 'app/App'

import 'util/i18n'
/* -------------------------------------------------------------------------- */

ReactDOM.render(
  <React.Suspense fallback={<LinearProgress />}>
    <App />
  </React.Suspense>,
  document.getElementById('root'),
)

import {
  CREATE_OFFER,
  RESET_EDIT_OFFER,
  EDIT_OFFER_BY_UUID,
  GET_OFFER_BY_UUID,
  RESET_OFFER,
  RESET_CREATE_OFFER,
} from 'redux/Types'
import { unpackOffer } from 'api/offerMgmt/offerMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  offer: unpackOffer({}),
  isGettingOfferByUuid: false,
  editOfferByUuidSuccess: false,
  errGettingOfferByUuid: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  isReGettingOfferByUuid: false,
  isEditingOfferByUuid: false,
  errEditingOfferByUuid: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  isCreatingOffer: false,
  createOfferSuccess: false,
  errCreatingOffer: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  lastSuccessDate: null,
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_OFFER_BY_UUID.REQUEST: {
      const { isReload } = action
      return {
        ...state,
        isGettingOfferByUuid: !isReload,
        isReGettingOfferByUuid: !!isReload,
        errGettingOfferByUuid: INIT_STATE.errGettingOfferByUuid,
      }
    }
    case GET_OFFER_BY_UUID.SUCCESS: {
      const { offer, versionUuid } = action
      return {
        ...state,
        offer: unpackOffer(offer, { unpackAll: true, versionUuid }),
        isGettingOfferByUuid: false,
        isReGettingOfferByUuid: false,
        lastSuccessDate: new Date(),
      }
    }
    case GET_OFFER_BY_UUID.ERROR: {
      const { error } = action
      return {
        ...state,
        errGettingOfferByUuid: error,
        isGettingOfferByUuid: false,
        isLoadingFailed: true,
        isReGettingOfferByUuid: false,
      }
    }
    /* -------------------------------------------------------------------------- */
    case EDIT_OFFER_BY_UUID.REQUEST: {
      return {
        ...state,
        isEditingOfferByUuid: true,
        editOfferByUuidSuccess: false,
        errEditingOfferByUuid: INIT_STATE.errEditingOfferByUuid,
      }
    }
    case EDIT_OFFER_BY_UUID.SUCCESS: {
      const { offer } = action
      return {
        ...state,
        isEditingOfferByUuid: false,
        editOfferByUuidSuccess: true,
        offer: unpackOffer(offer, { unpackAll: true }),
      }
    }
    case EDIT_OFFER_BY_UUID.ERROR: {
      const { error } = action
      return {
        ...state,
        isEditingOfferByUuid: false,
        editOfferByUuidSuccess: false,
        errEditingOfferByUuid: error,
      }
    }
    case RESET_EDIT_OFFER: {
      return {
        ...state,
        editOfferByUuidSuccess: false,
      }
    }
    case CREATE_OFFER.REQUEST: {
      return {
        ...state,
        isCreatingOffer: true,
        createOfferSuccess: false,
        errCreatingOffer: INIT_STATE.errCreatingOffer,
      }
    }
    case CREATE_OFFER.SUCCESS: {
      const { offer } = action
      return {
        ...state,
        isCreatingOffer: false,
        createOfferSuccess: true,
        offer: unpackOffer(offer, { unpackAll: true }),
      }
    }
    case CREATE_OFFER.ERROR: {
      const { error } = action
      return {
        ...state,
        isCreatingOffer: false,
        createOfferSuccess: false,
        errCreatingOffer: error,
      }
    }
    case RESET_CREATE_OFFER: {
      return {
        ...state,
        createOfferSuccess: false,
      }
    }
    case RESET_OFFER: {
      return {
        ...state,
        errGettingOfferByUuid: INIT_STATE.errGettingOfferByUuid,
        offer: INIT_STATE.offer,
      }
    }
    default:
      return { ...state }
  }
}

import React from 'react'
import { Grid } from '@material-ui/core'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'

import InfoRows from 'fragments/Cards/InfoRows'
import { BasicCardLoadable } from 'fragments/Loadables'
import OsChip from 'fragments/IconChips/OsChip'
import { useTranslation } from 'react-i18next'
import { User } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type AppUserShortInfoCardProps = {
  user?: User
  isLoading: boolean
}

export default function AppUserShortInfoCard(props: AppUserShortInfoCardProps) {
  const { isLoading, user } = props
  const { t } = useTranslation()

  const rows = [
    {
      label: t('AppUserShortInfoCard.firstName') as string,
      value: user?.firstName,
    },
    {
      label: t('AppUserShortInfoCard.lastName') as string,
      value: user?.lastName,
    },
    {
      label: t('AppUserShortInfoCard.email') as string,
      value: user?.email,
    },
    {
      label: t('AppUserShortInfoCard.licensePlate') as string,
      value: user?.licensePlate,
    },
    {
      label: t('AppUserShortInfoCard.os') as string,
      value: <OsChip osString={user?.osVersion} />,
    },
  ]

  return (
    <BasicCardLoadable isLoading={isLoading} title={t('AppUserShortInfoCard.user')} action={<AccountCircleIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

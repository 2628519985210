import { History } from 'history'
import { TFunction } from 'i18next'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
import { BarrierOpening } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type Options = {
  barrierOpenings: BarrierOpening[]
  dashboardUsage?: boolean
  onChangePage?: (n: number) => void
  onChangeRowsPerPage?: (n: number) => void
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
  history: History
  page?: number
  rows?: number
  t: TFunction
  totalCount?: number
}

const options = ({
  barrierOpenings,
  dashboardUsage,
  onChangePage,
  onChangeRowsPerPage,
  enqueueSnackbar,
  history,
  page,
  rows,
  t,
  totalCount,
}: Options) => ({
  count: totalCount,
  onCellClick: (_: unknown, cellMeta: { colIndex: number; dataIndex: number }) => {
    if (barrierOpenings.length > 0) {
      const { colIndex, dataIndex } = cellMeta
      if (colIndex !== 6) {
        const publicId = barrierOpenings?.[dataIndex]?.ticket?.publicId
        if (publicId) {
          history.push({
            pathname: APP.TICKET,
            search: getQueryString({
              [QUERY_PARAMS.PUBLIC_ID]: publicId,
            }),
          })
        } else {
          enqueueSnackbar(t('BarrierOpeningsTableCard.notAssignedToTicket'), { variant: 'warning' })
        }
      }
    }
  },
  onChangePage,
  onChangeRowsPerPage,
  page,
  pagination: !dashboardUsage,
  rowsPerPage: rows,
  serverSide: true,
})

export default function getOptions(args: Options) {
  return {
    ...muiDataTableOptions(args.dashboardUsage),
    ...options(args),
  }
}

import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { getBarriersAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
import { isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */

const options = ({
  history,
  data,
  dashboardUsage,
  dispatch,
  page,
  role,
  rows,
  totalCount,
  setViewBarrierNr,
  setViewSoftwareVersion,
}) => ({
  count: totalCount,
  onChangePage: (page) =>
    dispatch(
      getBarriersAct({
        page,
      }),
    ),
  onChangeRowsPerPage: (rows) =>
    dispatch(
      getBarriersAct({
        rows,
      }),
    ),
  onRowClick: (_, { dataIndex }) => {
    const uuid = data?.[dataIndex]?.uuid
    if (uuid && isTriveParkAdmin(role)) {
      history.push({
        pathname: APP.BARRIER,
        search: getQueryString({
          [QUERY_PARAMS.UUID]: uuid,
        }),
      })
    }
  },
  onViewColumnsChange: (changedColumn, action) => {
    if (changedColumn === 'beaconMinor') setViewBarrierNr(action === 'add')
    if (changedColumn === 'softwareVersion') setViewSoftwareVersion(action === 'add')
  },
  page: page,
  pagination: !dashboardUsage,
  rowsPerPage: rows,
  viewColumns: isTriveParkAdmin(role),
  serverSide: true,
})

export default (args) => ({
  ...muiDataTableOptions(args.dashboardUsage),
  ...options(args),
})

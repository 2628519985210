import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Room as RoomIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import NumberField from 'fragments/Forms/Formik/NumberField'
/* -------------------------------------------------------------------------- */

const InfoLocationCard = (props) => {
  const {
    isGettingCarPark,
    carPark: { beaconMajor, latitude, longitude, timezone },
  } = useSelector((state) => state.carPark)
  const { t } = useTranslation()
  const { isEdit, isCreate } = props

  const inputBeaconMajor = () => <NumberField name='beaconMajor' min={1} max={65535} step={1} variant='standard' />

  const inputLatLong = () => (
    <span style={{ display: 'flex' }}>
      <NumberField name='latitude' placeholder={t('InfoLocationCard.latitude')} variant='standard' fullWidth />
      <NumberField name='longitude' placeholder={t('InfoLocationCard.longitude')} variant='standard' fullWidth />
    </span>
  )

  const rows = [
    {
      label: t('InfoLocationCard.coordinates') + (isCreate || isEdit ? ' *' : ''),
      value: isEdit || isCreate ? inputLatLong() : latitude && longitude && `${latitude}, ${longitude}`,
    },
    {
      label: t('InfoLocationCard.timezone'),
      value: isCreate ? 'Europe/Berlin' : timezone,
    },
    {
      label: t('InfoLocationCard.beaconMajor') + (isCreate || isEdit ? ' *' : ''),
      value: isEdit || isCreate ? inputBeaconMajor() : beaconMajor,
    },
  ]

  return (
    <BasicCardLoadable title={t('InfoLocationCard.title')} action={<RoomIcon />} isLoading={isGettingCarPark}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

InfoLocationCard.propTypes = {
  isEdit: PropTypes.bool,
  isCreate: PropTypes.bool,
}

InfoLocationCard.defaultProps = {
  isEdit: false,
  isCreate: false,
}

export default InfoLocationCard

import React from 'react'
import * as PropTypes from 'prop-types'
/* -------------------------------------------------------------------------- */

const Emoji = ({ symbol, label }) => (
  <span role='img' aria-label={label} aria-hidden={label ? 'false' : 'true'}>
    {symbol}
  </span>
)

Emoji.propTypes = {
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
}

export default Emoji

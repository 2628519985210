import { ADMIN_API_BASE_PATH, PARAMS_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_SALES = {
  SALES: `${ADMIN_API_BASE_PATH}/sales`,
}

// ----- SALES -----

export const PARAMS_SALES = {
  CAR_PARK_UUIDS: 'carParkUuid',
  DATE: 'date',
  INTERVAL: 'interval',
  OPERATOR_UUID: 'operatorUuid',
  ...PARAMS_PAGINATION,
}

export const BODY_SALES = {
  CAR_PARK_NAME: 'carParkName',
  CAR_PARK_UUID: 'carParkUuid',
  DATE: 'date',
  FEES_SUM_IN_CENT: 'feeAmountInCent',
  INDEX: 'index',
  INTERVAL: 'interval',
  REFUNDS_COUNT: 'refundCount',
  REFUNDS_SUM_IN_CENT: 'refundAmountInCent',
  TRANSACTIONS_COUNT: 'count',
  TRANSACTIONS_ARR: 'transactions',
  TRANSACTIONS_SUM_IN_CENT: 'totalAmountInCent',
}

export const SALES_INTERVAL_VALUES = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
}

export const BODY_MOCK_PAYMENT = {
  CAR_PARK_NAME: 'carParkName',
  UPDATED_AT: 'updatedAt',
  OFFER_NAME: 'offerName',
  STATUS: 'status',
  TICKET_ID: 'ticketId',
  TRANSACTION_ID: 'transactionId',
  TRANSACTIONS_SUM_IN_CENT: 'totalAmountInCent',
}

import { ADMIN_API_BASE_PATH, PARAMS_PAGINATION } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_OFFERS = {
  OFFERS_BASE: `${ADMIN_API_BASE_PATH}/offers`,
}

/* -------------------------------------------------------------------------- */

export const PARAMS_OFFERS = {
  CAR_PARK_UUID: 'carParkUuid',
  OPERATOR_UUID: 'operatorUuid',
  TYPE: 'type',
  IS_VISIBLE: 'isVisible',
  ...PARAMS_PAGINATION,
}

/* -------------------------------------------------------------------------- */

export const BODY_OFFERS = {
  // CONTENT when pagination is implemented backend side
}

export const BODY_OFFER = {
  OFFER_VERSIONS_ARR: 'offerVersions',
  UUID: 'uuid',
}

export const BODY_OFFER_VERSION = {
  ACCESS_EARLIEST_IN_MIN_FROM_MIDNIGHT: 'accessEarliestInMinFromMidnight',
  ACCESS_LATEST_IN_MIN_FROM_MIDNIGHT: 'accessLatestInMinFromMidnight',
  AUTHOR_UUID: 'authorUuid',
  AVAILABLE_FROM: 'availableFrom',
  AVAILABLE_UNTIL: 'availableUntil',
  CAR_PARK_NAME: 'carParkName',
  CAR_PARK_UUID: 'carParkUuid',
  CONTINGENT: 'contingent',
  CREATED_AT: 'createdAt',
  IS_DELETED: 'isDeleted',
  IS_VISIBLE: 'isVisible',
  LOCALIZED_GENERATED_NAME_OBJ: 'localizedGeneratedName',
  LOCALIZED_INFROMATION_OBJ: 'localizedInformation',
  LOCALIZED_NAME_OBJ: 'localizedName',
  OFFER_UUID: 'offerUuid',
  PRICE_IN_CENT: 'priceInCent',
  TICKET_USAGE_IN_MIN: 'ticketUsageInMin',
  TICKET_VALIDITY_IN_MIN: 'ticketValidityInMin',
  TYPE: 'type',
  UPDATED_AT: 'updatedAt',
  UUID: 'uuid',
}

/* -------------------------------------------------------------------------- */

export const OFFER_TYPE_VALUES = {
  ADMIN: 'ADMIN',
  LONG_TERM: 'LONG_TERM',
  SHORT_TERM: 'SHORT_TERM',
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getOperatorsAct } from 'redux/Actions'
import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import options from './OperatorsTableOptions'
import columns from './OperatorsTableColumns'
/* -------------------------------------------------------------------------- */

const OperatorsTableCard = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()
  const {
    isGettingOperators,
    operators,
    pagination: { page, rows },
  } = useSelector((state) => state.operators)

  useEffect(() => {
    dispatch(getOperatorsAct())
  }, [dispatch])

  return (
    <BasicCardLoadable isLoading={isGettingOperators}>
      <MuiDataTableThemed
        data={operators}
        columns={columns(t)}
        options={options({
          dispatch,
          history,
          operators,
          page,
          rows,
        })}
      />
    </BasicCardLoadable>
  )
}

export default OperatorsTableCard

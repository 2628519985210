import { TFunction } from 'react-i18next'

import { BarrierOpeningsStatus } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

// i18next-extract-mark-context-next-line ["", "NO_APP_USER", "BLE_SUCCESS", "PERMISSION_DENIED", "UPDATE_TICKET_FAILED", "REMOTE_SUCCESS", "REMOTE_FAILURE", "MANUAL_UNSPECIFIED", "MANUAL_BARRIER_ERROR", "MANUAL_APP_ERROR", "MANUAL_NO_PERMISSION"]
export function getBarrierOpeningStatusLabel(t: TFunction, status: BarrierOpeningsStatus) {
  return t('barrierOpening.statusLabel', { context: status })
}

type BarrierOpeningsStatusObj = {
  color: string
  label: string
  info: null | string
}

export function getBarrierOpeningStatusObj(t: TFunction, status?: BarrierOpeningsStatus): BarrierOpeningsStatusObj {
  switch (status) {
    case 'NO_APP_USER': {
      return {
        color: 'grey',
        label: t('barrierOpening.status.label.standard') as string,
        info: null,
      }
    }
    case 'BLE_SUCCESS': {
      return {
        color: '#00d014',
        label: t('barrierOpening.status.label.app') as string,
        info: null,
      }
    }
    case 'PERMISSION_DENIED': {
      return {
        color: '#ff3739',
        label: t('barrierOpening.status.label.app') as string,
        info: t('barrierOpening.status.info.app.permissionDenied') as string,
      }
    }
    case 'UPDATE_TICKET_FAILED': {
      return {
        color: '#ff3739',
        label: t('barrierOpening.status.label.app') as string,
        info: t('barrierOpening.status.info.app.updateFailed') as string,
      }
    }
    case 'REMOTE_SUCCESS': {
      return {
        color: '#00d014',
        label: t('barrierOpening.status.label.remote') as string,
        info: null,
      }
    }
    case 'REMOTE_FAILURE': {
      return {
        color: '#ff3739',
        label: t('barrierOpening.status.label.remote') as string,
        info: null,
      }
    }
    case 'MANUAL_UNSPECIFIED': {
      return {
        color: '#ffb70f',
        label: t('barrierOpening.status.label.manual') as string,
        info: t('barrierOpening.status.info.manual.unspecified') as string,
      }
    }
    case 'MANUAL_BARRIER_ERROR': {
      return {
        color: '#ffb70f',
        label: t('barrierOpening.status.label.manual') as string,
        info: t('barrierOpening.status.info.manual.barrierError') as string,
      }
    }
    case 'MANUAL_APP_ERROR': {
      return {
        color: '#ffb70f',
        label: t('barrierOpening.status.label.manual') as string,
        info: t('barrierOpening.status.info.manual.appConnection') as string,
      }
    }
    case 'MANUAL_NO_PERMISSION': {
      return {
        color: '#ffb70f',
        label: t('barrierOpening.status.label.manual') as string,
        info: t('barrierOpening.status.info.manual.appAuthFailed') as string,
      }
    }
    default: {
      return {
        color: 'grey',
        label: t('barrierOpening.unknown') as string,
        info: null,
      }
    }
  }
}

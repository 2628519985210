export const BASE_URL = process.env.REACT_APP_BACKEND

export const ADMIN_API_BASE_PATH = '/v1/admin'

/* -------------------------------------------------------------------------- */
// ----- SPECIFIC ERROR CODES -----

export const BACKEND_ERROR_CODES = {
  unspecified: 0,
  duplicateEmail: 1,
  missingParameter: 2,
  badCredentialsAuthentication: 3,
  notDeleted: 4,
  disabledAccount: 5,
  deletedAccount: 6,
  missingEmailVerification: 7,
  badPasswordAuthentication: 8,
  badTokenAuthentication: 9,
  duplicatePaymentMethod: 10,
  missingPaymentMethod: 11,
  noDefaultPaymentMethod: 12,
  defaultPaymentMethodRestricted: 13,
  missingCustomer: 14,
  missingPaymentIntent: 15,
  bookingDeclinedActiveTickets: 16,
  invalidLicensePlate: 17,
  expiredLicensePlate: 18,
  existingAccountNoPasswordMatch: 19,
  duplicateBeaconMajor: 20,
  manualOpeningDateInFuture: 21,
  manualOpeningDateBeforeLastEvent: 22,
  manualOpeningDenied: 23,
  bookingDeclinedNoPaymentSource: 24,
}

/* -------------------------------------------------------------------------- */
// ----- PAGINATION -----

export const BODY_PAGINATION = {
  CONTENT_ARR: 'content',
  IS_FIRST_PAGE: 'isFirstPage',
  IS_LAST_PAGE: 'isLastPage',
  PAGE_NUMBER: 'pageNumber',
  PAGE_SIZE: 'pageSize',
  TOTAL_ELEMENTS: 'totalElements',
  TOTAL_PAGES: 'totalPages',
}

export const PARAMS_PAGINATION = {
  PAGE_NUMBER: 'pageNumber',
  PAGE_SIZE: 'pageSize',
}

/* -------------------------------------------------------------------------- */

export const BODY_LANGUAGE_PROPS = {
  EN: 'en',
  DE: 'de',
}

/* -------------------------------------------------------------------------- */
export const TOKEN_CLAIMS = {
  EMAIL: 'sub', // subject
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import { BasicCardLoadable } from 'fragments/Loadables'
import columns from './OffersTableColumns'
import options from './offersTableOptions'
/* -------------------------------------------------------------------------- */

const OffersTableCard = () => {
  const {
    isGettingOffers,
    offers,
    pagination: { page, rows, totalCount },
  } = useSelector((state) => state.offers)
  const {
    user: { role },
  } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  let history = useHistory()

  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <BasicCardLoadable isLoading={isGettingOffers}>
      <MuiDataTableThemed
        data={offers}
        columns={columns({
          language,
          offers,
          role,
          t,
        })}
        options={options({
          dispatch,
          history,
          offers,
          page,
          rows,
          totalCount,
        })}
      />
    </BasicCardLoadable>
  )
}

export default OffersTableCard

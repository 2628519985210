import React from 'react'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import LanguageFlagIconWithString from 'fragments/Icons/LanguageFlagIconWithString'
import { localeCodesArr } from 'util/i18n'
/* -------------------------------------------------------------------------- */

const LanguageSelect = (props) => {
  const { onChange } = props

  const { i18n } = useTranslation()

  return (
    <Select value={i18n.language} onChange={(e) => onChange(e.target.value)}>
      {localeCodesArr.map((code) => (
        <MenuItem key={code} value={code}>
          <LanguageFlagIconWithString languageCode={code} />
        </MenuItem>
      ))}
    </Select>
  )
}

/* -------------------------------------------------------------------------- */
LanguageSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default LanguageSelect

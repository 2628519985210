import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Mail as MailIcon, VerifiedUser as VerifiedUserIcon, Delete as DeleteIcon } from '@material-ui/icons'

import IconChip from 'fragments/IconChips/IconChip'
/* -------------------------------------------------------------------------- */

const AccountStatusChip = (props) => {
  const { isActivated, isDeleted, deletedAt } = props
  const { t } = useTranslation()

  let icon = null
  let label = null
  let chipStyle = null
  const style = { color: 'white' }

  if (!isActivated && !isDeleted) {
    icon = <MailIcon style={style} />
    chipStyle = { ...style, background: '#f5b942' }
    label = t('AccountStatusChip.notActivated')
  } else if (isActivated && !isDeleted) {
    icon = <VerifiedUserIcon style={style} />
    chipStyle = { ...style, background: '#3ccc37' }
    label = t('AccountStatusChip.activated')
  } else if (deletedAt) {
    icon = <DeleteIcon style={style} />
    chipStyle = { ...style, background: 'red' }
    label = t('AccountStatusChip.deletedAt', { date: deletedAt })
  }

  return <IconChip icon={icon} label={label} chipStyle={chipStyle} />
}

/* -------------------------------------------------------------------------- */
AccountStatusChip.propTypes = {
  isActivated: PropTypes.bool,
  isDeleted: PropTypes.bool,
  deletedAt: PropTypes.string,
}

AccountStatusChip.defaultProps = {
  isActivated: null,
  isDeleted: null,
  deletedAt: null,
}

export default AccountStatusChip

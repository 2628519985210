import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { CATCH_SAGA_ERRORS } from 'redux/Types'
import { logOutUserSuccessAct } from 'redux/Actions'
import { unpackError } from 'util/core'
import { HTTPS_STATUS_CODES } from 'constants/generalConstants'
/* -------------------------------------------------------------------------- */

function* workerCatchSagaErrors({ error, actionCallback }) {
  const uError = unpackError(error)

  if (uError.httpStatusCode === HTTPS_STATUS_CODES.UNAUTHORIZED) {
    yield put(logOutUserSuccessAct())
  } else {
    yield put(actionCallback(uError))
  }
}

function* watcherCatchSagaErrors() {
  yield takeEvery(CATCH_SAGA_ERRORS, workerCatchSagaErrors)
}

export default function* rootSaga() {
  yield all([fork(watcherCatchSagaErrors)])
}

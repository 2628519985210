/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Event as EventIcon,
} from '@material-ui/icons'
import { IconButton, InputAdornment, Tooltip, Grid } from '@material-ui/core'
import {
  compareAsc,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  addDays,
  addWeeks,
  addYears,
  addMonths,
} from 'date-fns'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'

import { localeMap } from 'util/i18n'
import { getTimeIntervalValue, getIntervalLabel, datePattern } from 'util/core'
/* -------------------------------------------------------------------------- */
const CustomDatePicker = (props) => {
  const { date, onChange, disabled, timeInterval, showArrows } = props
  const { t, i18n } = useTranslation()
  const label = getIntervalLabel(t, timeInterval)
  let nextDate, prevDate, disableNextBtn, fieldFormat, view

  switch (timeInterval) {
    case getTimeIntervalValue('WEEK'): {
      nextDate = addWeeks(date, 1)
      prevDate = addWeeks(date, -1)
      disableNextBtn = compareAsc(date, startOfWeek(new Date(), { weekStartsOn: 1 })) > -1
      fieldFormat = datePattern.weekLong
      view = 'date'
      break
    }
    case getTimeIntervalValue('MONTH'): {
      nextDate = addMonths(date, 1)
      prevDate = addMonths(date, -1)
      disableNextBtn = compareAsc(startOfMonth(date), startOfMonth(new Date())) > -1
      fieldFormat = datePattern.monthShort + ' ' + datePattern.yearFull
      view = 'month'
      break
    }
    case getTimeIntervalValue('YEAR'): {
      nextDate = addYears(date, 1)
      prevDate = addYears(date, -1)
      disableNextBtn = compareAsc(startOfYear(date), startOfYear(new Date())) > -1
      fieldFormat = datePattern.yearFull
      view = 'year'
      break
    }
    default: {
      // 'DAY'
      nextDate = addDays(date, 1)
      prevDate = addDays(date, -1)
      disableNextBtn = compareAsc(startOfDay(date), startOfDay(new Date())) > -1
      fieldFormat = datePattern.dateShort
      view = 'date'
      break
    }
  }

  const onChangeDate = (date) => onChange(date)

  const onBackClick = () => onChange(prevDate)

  const onNextClick = () => onChange(nextDate)

  const renderDatPicker = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
        <DatePicker
          views={[view]}
          value={date}
          label={label}
          disabled={disabled}
          onChange={onChangeDate}
          format={fieldFormat}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputVariant='outlined'
          okLabel={t('CustomDatePicker.ok')}
          cancelLabel={t('CustomDatePicker.cancel')}
          maxDate={new Date()}
          animateYearScrolling={false}
          leftArrowIcon={<ChevronLeftIcon />}
          rightArrowIcon={<ChevronRightIcon />}
        />
      </MuiPickersUtilsProvider>
    )
  }

  return (
    <Grid container justify='space-between' alignItems='center'>
      {showArrows && (
        <Grid item xs={2}>
          <Tooltip title={t('CustomDatePicker.back')}>
            <IconButton onClick={onBackClick}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={showArrows ? 8 : 12}>
        {renderDatPicker()}
      </Grid>
      {showArrows && (
        <Grid item xs={2}>
          <Tooltip title={t('CustomDatePicker.next')}>
            <Fragment>
              <IconButton onClick={onNextClick} disabled={disableNextBtn}>
                <ChevronRightIcon />
              </IconButton>
            </Fragment>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

/* -------------------------------------------------------------------------- */
CustomDatePicker.propTypes = {
  date: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  timeInterval: PropTypes.string,
  disabled: PropTypes.bool,
  showArrows: PropTypes.bool,
}

CustomDatePicker.defaultProps = {
  timeInterval: 'day',
  disabled: false,
  showArrows: false,
}

export default CustomDatePicker

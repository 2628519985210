import React from 'react'
import { Link as MuiLink } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import { DEFAULT_TICKET_ADMIN_ID } from 'constants/generalConstants'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

type TicketIdCellProps = {
  value?: string
}

export default function TicketIdCell(props: TicketIdCellProps) {
  const { value } = props

  if (value !== DEFAULT_TICKET_ADMIN_ID) {
    return (
      <MuiLink
        component={RouterLink}
        to={{
          pathname: APP.TICKET,
          search: getQueryString({ [QUERY_PARAMS.PUBLIC_ID]: value }),
        }}
        target='_blank'
        noWrap
      >
        <span>{value}</span>
      </MuiLink>
    )
  }

  return <span>{value ? value : '-'}</span>
}

// https://github.com/facebook/create-react-app/issues/10088
// ----- ICONS -----
export const parkmotiveIconWhite = require('assets/svg/parkmotive_icon_white.svg').default
export const triveParkIconWhite = require('assets/svg/trivepark_icon_white.svg').default

// ----- TEXT LOGOS -----
export const parkmotiveLogoWhite = require('assets/svg/parkmotive_logo_white.svg').default
export const parkmotiveLogoBlack = require('assets/svg/parkmotive_logo_black.svg').default
export const triveParkLogoWhite = require('assets/svg/trivepark_logo_white.svg').default
export const triveParkIconLogoColored = require('assets/svg/trivepark_icon_logo_colored.svg').default

export const illustrationEntry = require('assets/svg/tpark_illustration_entry.svg').default
export const illustrationExit = require('assets/svg/tpark_illustration_exit.svg').default
export const illustrationCombined = require('assets/svg/tpark_illustration_combined.svg').default

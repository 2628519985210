import { call, put, takeLatest, takeLeading, all, fork } from 'redux-saga/effects'

import { GET_BARRIER_BY_ID, EDIT_BARRIER } from 'redux/Types'
import {
  getBarrierByUuidSuccessAct,
  getBarrierByUuidErrorAct,
  editBarrierSuccessAct,
  editBarrierErrorAct,
  catchSagaErrorsAct,
} from 'redux/Actions'
import { getBarrierByIdReq, editBarrierReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetBarrierById({ uuid }) {
  try {
    const barrier = yield call(getBarrierByIdReq, uuid)
    yield put(getBarrierByUuidSuccessAct(barrier))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getBarrierByUuidErrorAct))
  }
}

function* watcherGetBarrierById() {
  yield takeLeading(GET_BARRIER_BY_ID.REQUEST, workerGetBarrierById)
}

/* -------------------------------------------------------------------------- */

function* workerEditBarrier(action) {
  try {
    const { uuid, formValues, isUpdateAllowed, isRebootAllowed, isSshTunnelEnabled, isSecretGenerationAllowed } = action
    const barrierUpdatable = {
      ...formValues,
      isUpdateAllowed,
      isRebootAllowed,
      isSshTunnelEnabled,
      isSecretGenerationAllowed,
    }
    const barrier = yield call(editBarrierReq, uuid, barrierUpdatable)
    yield put(editBarrierSuccessAct(barrier))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, editBarrierErrorAct))
  }
}

function* watcherEditBarrier() {
  yield takeLatest(EDIT_BARRIER.REQUEST, workerEditBarrier)
}

/* -------------------------------------------------------------------------- */
export default function* rootSaga() {
  yield all([fork(watcherGetBarrierById), fork(watcherEditBarrier)])
}

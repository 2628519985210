import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Language as LanguageIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
/* -------------------------------------------------------------------------- */
const BarrierAddressCard = () => {
  const { t } = useTranslation()
  const {
    barrier: { macAddress, serviceUuid },
    isEditingBarrier,
  } = useSelector((state) => state.barrier)

  const rows = [
    {
      label: t('BarrierAddressCard.macAddress'),
      value: macAddress,
    },
    {
      label: t('BarrierAddressCard.serviceUuid'),
      value: serviceUuid,
    },
  ]

  return (
    <BasicCardLoadable title={t('BarrierAddressCard.title')} action={<LanguageIcon />} isLoading={isEditingBarrier}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

export default BarrierAddressCard

export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value)

export const arrayToObject = (arr, keyField) =>
  Object.assign(
    {},
    ...arr.map((item) => {
      const newItem = item
      const keyValue = item[keyField]
      delete newItem[keyField]
      return { [keyValue]: newItem }
    }),
  )

import React, { useEffect } from 'react'
import { Route, useLocation, useHistory } from 'react-router-dom'

import AppLayout from 'layouts/AppLayout/AppLayout'
import appRoutesConfig from 'routes/appRoutesConfig'
import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

const AppRoutes = () => {
  const location = useLocation()
  const history = useHistory()
  const isValidAppPath = Object.values(APP).includes(location.pathname)

  useEffect(() => {
    if (!isValidAppPath) {
      history.push(APP.HOME)
    }
  }, [history, isValidAppPath])

  return (
    <AppLayout>
      {appRoutesConfig.map(({ path, element: Element }) => (
        <Route key={path} exact path={path}>
          <Element />
        </Route>
      ))}
    </AppLayout>
  )
}

export default AppRoutes

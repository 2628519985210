import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import ProfileGrid from 'components/Profile/ProfileGrid'
import ChangePasswordCard from './ChangePasswordCard'
/* -------------------------------------------------------------------------- */

const ProfileContainer = () => {
  const errChangingMyProfile = useSelector((state) => state.auth.errChangingMyProfile)
  const { t } = useTranslation()

  return (
    <BasicPageContainerLoadable helmetTitle={t('ProfileContainer.title')} pageTitle={t('ProfileContainer.title')}>
      <ErrorAlertContainer errors={[errChangingMyProfile]} />
      <PageGrid>
        <ProfileGrid />
        <Grid item xs={12} sm={12} md={6}>
          <ChangePasswordCard />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default ProfileContainer

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, InputAdornment } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { Euro as EuroIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import { getTimeAmountString, getTimeUnitSelects } from 'util/core'
import NumberField from 'fragments/Forms/Formik/NumberField'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
/* -------------------------------------------------------------------------- */

const OfferPricingCard = (props) => {
  const carParks = useSelector((state) => state.carParks.carParks)
  const {
    isGettingOfferByUuid,
    offer: { carParkUuid: _carParkUuid, durationInMin, priceInCent },
  } = useSelector((state) => state.offer)
  const { t } = useTranslation()
  const { isCreate, isEdit, formikProps } = props

  const carParkUuid = isCreate ? formikProps?.values?.carParkUuid : _carParkUuid
  const carPark = carParks.find((cp) => cp.uuid === carParkUuid)
  const continuityPeriodInMinutes = carPark?.continuityPeriodInMinutes ?? null
  const continuityPriceInCent = carPark?.continuityPriceInCent ?? null
  const priceMaxPerDayInCent = carPark?.priceMaxPerDayInCent ?? null

  const priceInput = () => (
    <NumberField
      name='priceInEuro'
      min={0}
      step={0.01}
      endAdornment={<InputAdornment position='end'>&euro;</InputAdornment>}
      variant='standard'
    />
  )

  const durationInput = () => (
    <NumberField
      name='usageDuration'
      min={0}
      step={1}
      variant='standard'
      endAdornment={<SelectTextField name='usageUnit' options={getTimeUnitSelects(t)} variant='standard' />}
    />
  )

  const rows = [
    {
      label: t('OfferPricingCard.price'),
      value: isCreate || isEdit ? priceInput() : t('OfferPricingCard.priceValue', { cents: priceInCent }),
    },
    {
      label: t('OfferPricingCard.duration') + (isCreate || isEdit ? ' *' : ''),
      value: isCreate || isEdit ? durationInput() : getTimeAmountString(t, durationInMin),
    },
    {
      label: t('OfferPricingCard.continuityRate'),
      value: t('OfferPricingCard.pricePerPeriod', {
        cents: continuityPriceInCent,
        timeAmount: getTimeAmountString(t, continuityPeriodInMinutes),
      }),
    },
    {
      label: t('OfferPricingCard.priceMax'),
      value: t('OfferPricingCard.pricePerPeriod', {
        cents: priceMaxPerDayInCent,
        timeAmount: getTimeAmountString(t, 24 * 60),
      }),
    },
  ]

  return (
    <BasicCardLoadable title={t('OfferPricingCard.title')} action={<EuroIcon />} isLoading={isGettingOfferByUuid}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}
/* -------------------------------------------------------------------------- */
OfferPricingCard.propTypes = {
  formikProps: PropTypes.shape(),
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
}

OfferPricingCard.defaultProps = {
  formikProps: null,
  isCreate: false,
  isEdit: false,
}

export default OfferPricingCard

import { GET_APP_USERS } from 'redux/Types'
import { unpackMockedAppUsers } from 'api/appUserMgmt/appUserMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  appUsers: [],
  isGettingAppUsers: false,
  errGettingAppUsers: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_APP_USERS.REQUEST: {
      return {
        ...state,
        isGettingAppUsers: true,
        errGettingAppUsers: INIT_STATE.errGettingAppUsers,
      }
    }
    case GET_APP_USERS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isGettingAppUsers: false,
        appUsers: unpackMockedAppUsers(data),
      }
    }
    case GET_APP_USERS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingAppUsers: false,
        errGettingAppUsers: error,
      }
    }
    default:
      return { ...state }
  }
}

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD,
  MODIFY_PASSWORD,
  RESET_ACCOUNT_MGMT_FLAGS,
  ACTIVATE_ADMIN,
} from 'redux/Types'
import { STATUS } from 'redux/Status'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  // ACCOUNT ACTIVATION
  statusActivatingAdmin: STATUS.idle,
  errActivatingAdmin: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // RESET PASSWORD REQUEST
  isResetPasswordRequesting: false,
  isResetPasswordRequestingSuccess: false,
  errResetPasswordRequesting: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // RESET PASSWORD
  statusResettingPassword: STATUS.idle,
  errResettingPassword: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  // CHANGE PASSWORD
  isModifyingPassword: false,
  modifyPasswordSuccess: false,
  errModifyingPassword: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    // ACCOUNT ACTIVATION
    case ACTIVATE_ADMIN.REQUEST: {
      return {
        ...state,
        statusActivatingAdmin: STATUS.pending,
        errActivatingAdmin: INIT_STATE.errActivatingAdmin,
      }
    }
    case ACTIVATE_ADMIN.SUCCESS: {
      return {
        ...state,
        statusActivatingAdmin: STATUS.resolved,
      }
    }
    case ACTIVATE_ADMIN.ERROR: {
      const { error } = action
      return {
        ...state,
        statusActivatingAdmin: STATUS.rejected,
        errActivatingAdmin: error,
      }
    }
    // PASSWORD RESET REQUEST
    case RESET_PASSWORD_REQUEST.REQUEST: {
      return {
        ...state,
        isResetPasswordRequesting: true,
        isResetPasswordRequestingSuccess: false,
        errResetPasswordRequesting: INIT_STATE.errResetPasswordRequesting,
      }
    }
    case RESET_PASSWORD_REQUEST.SUCCESS: {
      return {
        ...state,
        isResetPasswordRequesting: false,
        isResetPasswordRequestingSuccess: true,
      }
    }
    case RESET_PASSWORD_REQUEST.ERROR: {
      const { error } = action
      return {
        ...state,
        isResetPasswordRequesting: false,
        isResetPasswordRequestingSuccess: false,
        errResetPasswordRequesting: error,
      }
    }
    // PASSWORD RESET
    case RESET_PASSWORD.REQUEST: {
      return {
        ...state,
        statusResettingPassword: STATUS.pending,
        errResettingPassword: INIT_STATE.errResettingPassword,
      }
    }
    case RESET_PASSWORD.SUCCESS: {
      return {
        ...state,
        statusResettingPassword: STATUS.resolved,
      }
    }
    case RESET_PASSWORD.ERROR: {
      const { error } = action
      return {
        ...state,
        statusResettingPassword: STATUS.rejected,
        errResettingPassword: error,
      }
    }
    // MODIFY PASSWORD
    case MODIFY_PASSWORD.REQUEST: {
      return {
        ...state,
        isModifyingPassword: true,
        modifyPasswordSuccess: false,
        errModifyingPassword: INIT_STATE.errModifyingPassword,
      }
    }
    case MODIFY_PASSWORD.SUCCESS: {
      return {
        ...state,
        isModifyingPassword: false,
        modifyPasswordSuccess: true,
      }
    }
    case MODIFY_PASSWORD.ERROR: {
      const { error } = action
      return {
        ...state,
        isModifyingPassword: false,
        modifyPasswordSuccess: false,
        errModifyingPassword: error,
      }
    }
    // RESET FLAGS
    case RESET_ACCOUNT_MGMT_FLAGS: {
      return {
        ...state,
        isResetPasswordRequestingSuccess: false,
      }
    }
    default:
      return { ...state }
  }
}

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Menu, makeStyles } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

import SearchItems from 'layouts/AppLayout/Topbar/SearchItems'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
import { useGetTicketsQuery } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: 25,
    backgroundColor: '#F4F6F7',
  },
  searchIcon: {
    width: theme.spacing(5),
    color: '#575756',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    height: 34,
    [theme.breakpoints.down('sm')]: {
      height: 19,
    },
  },
  paper: {
    maxHeight: 38 * 6,
    width: '80%',
    maxWidth: 541,
  },
}))

export default function Search() {
  const { t } = useTranslation()
  const classes = useStyles()
  let history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')
  const [search] = useDebounce(searchTerm, 300)
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const { data, isLoading } = useGetTicketsQuery(
    {
      pageNumber: 0,
      pageSize: 10,
      search: search,
    },
    { skip: !search },
  )

  const handleClose = () => {
    setAnchorEl(undefined)
    setSearchTerm('')
  }

  function handleChange(event) {
    setSearchTerm(event.target.value)
    // only set anchor if none is set yet
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    }
  }

  function handleKeyDown(keyEvent) {
    if (keyEvent.key === 'Enter') {
      history.push({
        pathname: APP.TICKETS,
        search: getQueryString({
          [QUERY_PARAMS.SEARCH]: searchTerm,
        }),
      })
      handleClose()
    }
  }

  // Remove anchor if search is being emptied by user
  useEffect(() => {
    if (searchTerm === '') {
      setAnchorEl(undefined)
    }
  }, [searchTerm])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <Input
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={t('Search.placeholder')}
          classes={{
            input: classes.inputInput,
          }}
          inputProps={{
            'aria-label': t('Search.placeholder'),
            'aria-controls': open ? 'search-list-grow' : undefined,
            'aria-haspopup': 'true',
          }}
          disableUnderline
          fullWidth
        />
      </div>
      <Menu
        id='search-list-grow'
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        classes={{ paper: classes.paper }}
        disableAutoFocus
        disableEnforceFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <SearchItems items={data?.content ?? []} onSelect={handleClose} isLoading={isLoading} />
      </Menu>
    </>
  )
}

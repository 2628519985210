import React from 'react'
import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DotChip from 'fragments/IconChips/DotChip'
import { TicketPayment, TicketPaymentStatus } from 'services/tickets/tickets-service'
/*-------------------------------------------------------------------------- */

type TicketPaymentRowProps = {
  payment: TicketPayment
}

const useStyles = makeStyles((theme) => ({
  itemIcon: {
    minWidth: 12 + theme.spacing(1),
  },
}))

function getTicketPaymentColor(status: TicketPaymentStatus) {
  switch (status) {
    case 'requires_payment_method': {
      return '#ffb70f'
    }
    case 'requires_confirmation': {
      return '#ffb70f'
    }
    case 'requires_action': {
      return 'grey'
    }
    case 'requires_capture': {
      return 'grey'
    }
    case 'processing': {
      return '#ffb70f'
    }
    case 'canceled': {
      return '#ff3739'
    }
    case 'succeeded': {
      return '#00d014'
    }
    default: {
      return 'grey'
    }
  }
}

export default function TicketPaymentRow(props: TicketPaymentRowProps) {
  const { payment } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const color = getTicketPaymentColor(payment?.status)
  // i18next-extract-mark-context-next-line ["", "requires_payment_method", "requires_confirmation", "requires_action", "requires_capture", "processing", "canceled", "succeeded" ]
  const statusString = t('TicketPaymentRow.status', { context: payment?.status })

  return (
    <ListItem>
      <ListItemIcon classes={{ root: classes.itemIcon }}>
        <DotChip color={color} />
      </ListItemIcon>
      <ListItemText
        primary={t('TicketPaymentRow.paymentRow', {
          date: payment?.updatedAt,
          amount: payment?.amountInCent,
          status: statusString,
        })}
        primaryTypographyProps={{ variant: 'body2' }}
      />
    </ListItem>
  )
}

import { map, get, pick } from 'lodash'

import { OFFER_TYPE_VALUES } from 'api/offerMgmt/offerMgmtModels'
import { errorStringMinValue, errorNumberIsRequired } from 'util/core'
/* -------------------------------------------------------------------------- */

// ----- TYPES -----

export const getOfferTypeAsString = (t, type, options = { returnNull: false }) => {
  const validType = Object.values(OFFER_TYPE_VALUES).some((v) => v === type)
  return validType
    ? // i18next-extract-mark-context-next-line ["ADMIN", "LONG_TERM", "SHORT_TERM"]
      t('offer.offerType', { context: type })
    : options.returnNull
    ? null
    : t('offer.offerType')
}

export const getOfferTypeSelects = (t) => {
  const EDITABLE_OFFER_TYPE_VALUES = pick(OFFER_TYPE_VALUES, ['LONG_TERM', 'SHORT_TERM'])
  return map(EDITABLE_OFFER_TYPE_VALUES, (v, k) => ({
    value: v,
    // i18next-extract-mark-context-next-line ["LONG_TERM", "SHORT_TERM"]
    label: t('offer.offerType', { context: k }),
  }))
}

/* -------------------------------------------------------------------------- */
// ----- NAME -----

export const getOfferName = ({ localizedGeneratedNames, localizedNames, language }) =>
  get(localizedNames, language.substring(0, 2), null) || get(localizedGeneratedNames, language.substring(0, 2), null)

// ----- CONTINGENT -----

export const getContingentFormLabel = (t, contingent) => (contingent === '0' ? t('offer.contingent.inf') : null)

export const getContingentError = (t, value) =>
  errorNumberIsRequired({ t, value }) || errorStringMinValue({ t, value, minValueIncluded: 0 })

// ----- DURATION -----

export const getDurationError = (t, value) =>
  errorNumberIsRequired({ t, value }) || errorStringMinValue({ t, value, minValueIncluded: 0 })

// ----- PRICE -----

export const getPriceError = (t, value) =>
  errorNumberIsRequired({ t, value }) || errorStringMinValue({ t, value, minValueIncluded: 0 })

// ----- VALIDITY -----

export const getValidityError = (t, value) =>
  errorNumberIsRequired({ t, value }) || errorStringMinValue({ t, value, minValueIncluded: 0 })

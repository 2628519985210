import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from './CarParksTableColumns'
import options from './carParksTableOptions'
/* -------------------------------------------------------------------------- */

const CarParksTableCard = () => {
  const { role } = useSelector((state) => state.auth.user)
  const {
    carParks,
    isGettingCarParks,
    pagination: { page, rows, totalCount },
  } = useSelector((state) => state.carParks)
  const { operators } = useSelector((state) => state.operators)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()

  return (
    <BasicCardLoadable isLoading={isGettingCarParks}>
      <MuiDataTableThemed
        data={carParks}
        columns={columns({
          carParks,
          operators,
          role,
          t,
        })}
        options={options({
          carParks,
          dispatch,
          history,
          page,
          rows,
          totalCount,
        })}
      />
    </BasicCardLoadable>
  )
}

export default CarParksTableCard

import { getTimeWithBiggestPossibleUnit } from 'util/core'
/* -------------------------------------------------------------------------- */

export { validationSchema } from 'components/CarPark/Info/validationSchema'

export const initialValues = () => {
  const { unit: continuityPeriodUnit } = getTimeWithBiggestPossibleUnit(0)

  return {
    operatorUuid: '',
    name: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    latitude: '',
    longitude: '',
    telephoneNumber: '',
    beaconMajor: '',
    countParkingLots: '',
    continuityPriceInEuro: '',
    continuityPeriodDuration: '',
    continuityPeriodUnit,
    priceMaxPerDayInEuro: '',
    countUserInside: '',
    openingHours: '',
    isVisible: false,
  }
}

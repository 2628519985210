import { map } from 'lodash'

import { PAYMENT_STATUS_VALUES } from 'api/ticketMgmt/ticketMgmtModels'
import { getKeyByValue } from 'util/core'
/* -------------------------------------------------------------------------- */

export const balanceItems = {
  turnover: 'TURNOVER',
  fees: 'FEES',
  refunds: 'REFUNDS',
  profit: 'PROFIT',
}

export const balanceItemsArr = (t) =>
  map(balanceItems, (v) => ({
    value: v,
    // i18next-extract-mark-context-next-line ["TURNOVER", "FEES", "REFUNDS", "PROFIT"]
    label: t('sales.balanceItems', { context: v }),
  }))

export const getPaymentStatusString = (t, status) => {
  const validStatus = Object.values(PAYMENT_STATUS_VALUES).some((v) => v === status)
  return validStatus
    ? // i18next-extract-mark-context-next-line ["SUCCEEDED", "CANCELED", "PROCESSING"]
      t('sales.paymentStatus', { context: getKeyByValue(PAYMENT_STATUS_VALUES, status) })
    : null
}

import account from 'redux/Account/AccountReducer'
import admins from 'redux/Admins/AdminsReducer'
import admin from 'redux/Admin/AdminReducer'
import appUsers from 'redux/AppUsers/AppUsersReducer'
import appUser from 'redux/AppUser/AppUserReducer'
import auth from 'redux/Auth/AuthReducer'
import barriers from 'redux/Barriers/BarriersReducer'
import barrier from 'redux/Barrier/BarrierReducer'
import carParks from 'redux/CarParks/CarParksReducer'
import carPark from 'redux/CarPark/CarParkReducer'
import globalFilters from 'redux/GlobalFilters/GlobalFiltersReducer'
import occupancy from 'redux/Occupancy/OccupancyReducer'
import offers from 'redux/Offers/OffersReducer'
import offer from 'redux/Offer/OfferReducer'
import operators from 'redux/Operators/OperatorsReducer'
import operator from 'redux/Operator/OperatorReducer'
import sales from 'redux/Sales/SalesReducer'
import statistics from 'redux/Statistics/StatisticsReducer'
/* -------------------------------------------------------------------------- */

export default {
  account,
  admins,
  admin,
  appUsers,
  appUser,
  auth,
  barriers,
  barrier,
  carParks,
  carPark,
  globalFilters,
  occupancy,
  offers,
  offer,
  operators,
  operator,
  sales,
  statistics,
}

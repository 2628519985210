import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { withLastLocation } from 'react-router-last-location'
import { Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { isTriveParkAdmin } from 'util/custom'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import PageGrid from 'fragments/Grid/PageGrid'
import CarParksTableCard from 'components/CarParks/CarParksTableCard'
import { getCarParksAct } from 'redux/Actions'
import { APP } from 'routes/routes'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

class CarParksContainer extends Component {
  componentDidMount = () => {
    const { getCarParksAct } = this.props
    getCarParksAct()
  }

  componentDidUpdate = (prevProps) => {
    const { operatorUuid, getCarParksAct } = this.props
    const { operatorUuid: _operatorUuid } = prevProps
    if (operatorUuid !== _operatorUuid) getCarParksAct()
  }

  createCarPark = () => {
    const { t } = this.props

    return (
      <Button
        type='button'
        component={Link}
        to={APP.CAR_PARK_INFO_CREATE}
        color='primary'
        variant='outlined'
        style={{ textTransform: 'none' }}
      >
        {t('OffersContainer.createCarPark')}
      </Button>
    )
  }

  isTpAdmin = (role, operatorUuid) => !operatorUuid && isTriveParkAdmin(role)

  render = () => {
    const { errors, t, role, operatorUuid, lastSuccessDate } = this.props
    return (
      <BasicPageContainerLoadable
        helmetTitle={t('CarParksContainer.carParks')}
        pageTitle={t('CarParksContainer.carParks')}
        titleBarEndAdornment={this.isTpAdmin(role, operatorUuid) ? this.createCarPark : null}
      >
        <ErrorAlertContainer errors={errors} />
        <PageGrid>
          <Grid item xs={12}>
            <CarParksTableCard />
          </Grid>
          <Grid item xs={12}>
            <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
          </Grid>
        </PageGrid>
      </BasicPageContainerLoadable>
    )
  }
}
/* -------------------------------------------------------------------------- */
CarParksContainer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getCarParksAct: PropTypes.func.isRequired,
  operatorUuid: PropTypes.string,
  t: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  lastSuccessDate: PropTypes.shape(),
}
CarParksContainer.defaultProps = {
  operatorUuid: null,
}

const mapStateToProps = (state) => {
  const {
    carParks: { errGettingCarParks, lastSuccessDate },
    globalFilters: { selectedOperator },
    auth: {
      user: { role },
    },
  } = state
  return {
    errors: [errGettingCarParks],
    lastSuccessDate,
    operatorUuid: selectedOperator,
    role,
  }
}

const mapDispatch = {
  getCarParksAct,
}

export default withTranslation()(connect(mapStateToProps, mapDispatch)(withLastLocation(CarParksContainer)))

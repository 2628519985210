import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@material-ui/core'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import PageGrid from 'fragments/Grid/PageGrid'
import OffersTableCard from 'components/Offers/OffersTableCard'
import { getOffersAct, resetOffersFiltersAct, resetOfferAct } from 'redux/Actions'
import OffersFilterCard from 'components/Offers/OffersFilterCard'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import { APP } from 'routes/routes'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

const OffersContainer = () => {
  const { errGettingOffers, lastSuccessDate } = useSelector((state) => state.offers)
  const { errGettingCarParks } = useSelector((state) => state.carParks)
  const { selectedOperator, selectedCarParks } = useSelector((state) => state.globalFilters)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const lastLocation = useLastLocation()
  const lastPathname = lastLocation?.pathname ?? ''

  useEffect(() => {
    if (lastPathname !== APP.OFFER) {
      dispatch(resetOffersFiltersAct())
    } else {
      dispatch(getOffersAct())
    }
    return () => dispatch(resetOfferAct())
  }, [dispatch, lastPathname, selectedOperator, selectedCarParks])

  const createOffer = () => (
    <Button
      type='button'
      component={Link}
      to={APP.OFFER_CREATE}
      color='primary'
      variant='outlined'
      style={{ textTransform: 'none' }}
    >
      {t('OffersContainer.createOffer')}
    </Button>
  )

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('OffersContainer.offers')}
      pageTitle={t('OffersContainer.offers')}
      titleBarEndAdornment={createOffer}
    >
      <ErrorAlertContainer errors={[errGettingOffers, errGettingCarParks]} />
      <PageGrid>
        <Grid item xs={12}>
          <OffersFilterCard />
        </Grid>
        <Grid item xs={12}>
          <OffersTableCard />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default OffersContainer

import React from 'react'

import MoneyAmount from 'fragments/Misc/MoneyAmount'
/* -------------------------------------------------------------------------- */

const getValueInCentColumn = (salesPerCarPark, t, context) => ({
  name: 'carParkUuid',
  // i18next-extract-mark-context-next-line ["transactionsSumInCent", "feesSumInCent", "refundsSumInCent", "operatorGrossInCent"]
  label: t('SalesPerMonthColumns.sum', { context }),
  options: {
    customBodyRender: (carParkUuid) => {
      const sales = salesPerCarPark.find((o) => o.carParkUuid === carParkUuid)
      return <MoneyAmount valueInCent={sales?.year?.[context]} />
    },
  },
})
const getCountColumn = (salesPerCarPark, t, context) => ({
  name: 'carParkUuid',
  // i18next-extract-mark-context-next-line ["transactionsCount", "refundsCount"]
  label: t('SalesPerMonthColumns.count', { context }),
  options: {
    customBodyRender: (carParkUuid) => {
      const sales = salesPerCarPark.find((o) => o.carParkUuid === carParkUuid)
      return sales?.year?.[context]
    },
  },
})

export default ({ salesPerCarPark, t }) => [
  {
    name: 'carParkName',
    label: t('SalesPerMonthColumns.carPark'),
  },
  getValueInCentColumn(salesPerCarPark, t, 'transactionsSumInCent'),
  getCountColumn(salesPerCarPark, t, 'transactionsCount'),
  getValueInCentColumn(salesPerCarPark, t, 'feesSumInCent'),
  getValueInCentColumn(salesPerCarPark, t, 'refundsSumInCent'),
  getCountColumn(salesPerCarPark, t, 'refundsCount'),
  getValueInCentColumn(salesPerCarPark, t, 'operatorGrossInCent'),
]

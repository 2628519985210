import { GET_GENERAL_STATISTICS, RESET_GENERAL_STATISTICS } from 'redux/Types'
import { unpackGeneralStatistics } from 'api/statisticsMgmt/statisticsMgmtUnpackers'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  isGettingGeneralStatistics: false,
  isReGettingGeneralStatistics: false,
  errGettingGeneralStatistics: {
    httpStatusCode: null,
    errorCode: null,
    message: null,
  },
  appUsersCount: {
    day: null,
    month: null,
    year: null,
    total: null,
  },
  carParksCount: {
    day: null,
    month: null,
    year: null,
    total: null,
  },
  ticketsCount: {
    day: null,
    month: null,
    year: null,
    total: null,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action
  switch (type) {
    case GET_GENERAL_STATISTICS.REQUEST: {
      const { isReload } = action
      return {
        ...state,
        isGettingGeneralStatistics: !isReload,
        isReGettingGeneralStatistics: !!isReload,
        errGettingGeneralStatistics: INIT_STATE.errGettingGeneralStatistics,
      }
    }
    case GET_GENERAL_STATISTICS.SUCCESS: {
      const { generalStatistics } = action
      const { appUsersCount, carParksCount, ticketsCount } = unpackGeneralStatistics(generalStatistics)
      return {
        ...state,
        isGettingGeneralStatistics: false,
        isReGettingGeneralStatistics: false,
        appUsersCount: {
          ...INIT_STATE.appUsersCount,
          ...appUsersCount,
        },
        carParksCount: {
          ...INIT_STATE.carParksCount,
          ...carParksCount,
        },
        ticketsCount: {
          ...INIT_STATE.ticketsCount,
          ...ticketsCount,
        },
      }
    }
    case GET_GENERAL_STATISTICS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingGeneralStatistics: false,
        isReGettingGeneralStatistics: false,
        errGettingGeneralStatistics: error,
      }
    }
    case RESET_GENERAL_STATISTICS: {
      return {
        ...state,
        appUsersCount: INIT_STATE.appUsersCount,
        carParksCount: INIT_STATE.carParksCount,
        ticketsCount: INIT_STATE.ticketsCount,
      }
    }
    default:
      return { ...state }
  }
}

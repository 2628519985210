import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import SalesContainer from 'components/Sales/SalesContainer'
import SalesPerMonthTableCard from 'components/Sales/SalesPerMonth/SalesPerMonthTableCard'
import { getSalesAct, resetSalesAct } from 'redux/Actions'
import { getTimeIntervalValue } from 'util/core'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
/* -------------------------------------------------------------------------- */

const SalesPerMonthContainer = () => {
  const { selectedOperator, selectedCarParks } = useSelector((state) => state.globalFilters)
  const { lastSuccessDate } = useSelector((state) => state.sales)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSalesAct({ rows: 10, page: 0, interval: getTimeIntervalValue('MONTH') }))
    return () => dispatch(resetSalesAct())
  }, [dispatch, selectedOperator, selectedCarParks])

  return (
    <SalesContainer>
      <Grid item xs={12}>
        <SalesPerMonthTableCard />
      </Grid>
      <Grid item xs={12}>
        <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
      </Grid>
    </SalesContainer>
  )
}

export default SalesPerMonthContainer

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FilterGridCard from 'fragments/Cards/FilterGridCard'
import CustomDatePicker from 'fragments/Forms/CustomDatePicker'
import TimeIntervalSelect from 'fragments/Forms/Custom/TimeIntervalSelect'
import StatsViewSelect from 'fragments/Forms/Custom/StatsViewSelect'
import { getOccupancyAct, resetOccupancyFiltersAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const OccupancyFilterCard = () => {
  const dispatch = useDispatch()
  const {
    filters: { date, interval, view },
  } = useSelector((state) => state.occupancy)
  const { isGettingCarParks } = useSelector((state) => state.carParks)
  const handleFilterChange = (key) => (v) => dispatch(getOccupancyAct({ [key]: v }))

  return (
    <FilterGridCard isLoading={isGettingCarParks} resetFilters={() => dispatch(resetOccupancyFiltersAct())}>
      <TimeIntervalSelect onChange={handleFilterChange('interval')} selected={interval} />
      <CustomDatePicker date={date} onChange={handleFilterChange('date')} timeInterval={interval} showArrows />
      <StatsViewSelect onChange={handleFilterChange('view')} selected={view} />
    </FilterGridCard>
  )
}

export default OccupancyFilterCard

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import OfferEditForm from 'components/Offer/Edit/OfferEditForm'
import { getCarParksAct, getOfferByUuidAct, resetOfferAct } from 'redux/Actions'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

const OfferEditContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const { errGettingOfferByUuid, errEditingOfferByUuid } = useSelector((state) => state.offer)

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getOfferByUuidAct({ uuid }))
    dispatch(getCarParksAct())
    return () => {
      dispatch(resetOfferAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable helmetTitle={t('OfferEditContainer.title')} pageTitle={t('OfferEditContainer.title')}>
      <ErrorAlertContainer errors={[errGettingOfferByUuid, errEditingOfferByUuid]} />
      <OfferEditForm />
    </BasicPageContainerLoadable>
  )
}

export default OfferEditContainer

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CarParkContainer from 'components/CarPark/CarParkContainer'
import BarriersTableCard from 'components/Barriers/BarriersTableCard'
import { getCarParkByUuidAct } from 'redux/Actions'
import { QUERY_PARAMS } from 'routes/routes'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { getParsedQueryParam } from 'util/core'
import { useInterval } from 'hooks'
/* -------------------------------------------------------------------------- */

const CarParkBarriersContainer = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    isReGettingCarPark,
    lastSuccessDate,
    errGettingCarPark,
    carPark: { barriers },
  } = useSelector((state) => state.carPark)
  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useInterval(() => {
    dispatch(getCarParkByUuidAct({ uuid, isReload: true }))
  }, INFREQUENT_UPDATE_TIME_IN_MS)

  return (
    <CarParkContainer
      title={t('CarParkBarriersContainer.barriers')}
      errors={[errGettingCarPark]}
      lastSuccessDate={lastSuccessDate}
      isUpdating={isReGettingCarPark}
      reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
    >
      <BarriersTableCard barriers={barriers} />
    </CarParkContainer>
  )
}

export default CarParkBarriersContainer

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

import LanguageFlagIcon from 'fragments/Icons/LanguageFlagIcon'
import { getLanguage } from 'util/i18n'
/* -------------------------------------------------------------------------- */
const LanguageFlagIconWithString = (props) => {
  const { languageCode } = props
  const { t } = useTranslation()
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item>
        <LanguageFlagIcon {...props} />
      </Grid>
      <Grid item>{getLanguage(languageCode, t)}</Grid>
    </Grid>
  )
}

/* -------------------------------------------------------------------------- */
LanguageFlagIconWithString.propTypes = {
  languageCode: PropTypes.string,
}

LanguageFlagIconWithString.defaultProps = {
  languageCode: null,
}

export default LanguageFlagIconWithString

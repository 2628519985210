import {
  GET_OPERATOR_BY_UUID,
  RESET_OPERATOR,
  RESET_CREATE_OPERATOR,
  CREATE_OPERATOR,
  RESET_EDIT_OPERATOR,
  EDIT_OPERATOR,
} from 'redux/Types'
/* -------------------------------------------------------------------------- */

// ----- GET -----

export const getOperatorByUuidAct = (params) => ({
  type: GET_OPERATOR_BY_UUID.REQUEST,
  uuid: params?.uuid ?? null,
  isReload: params?.isReload ?? null,
})

export const getOperatorByUuidSuccessAct = (operator) => ({
  type: GET_OPERATOR_BY_UUID.SUCCESS,
  operator,
})

export const getOperatorByUuidErrorAct = (error) => ({
  type: GET_OPERATOR_BY_UUID.ERROR,
  error,
})

export const resetOperatorAct = () => ({
  type: RESET_OPERATOR,
})

// ----- CREATE -----

export const createOperatorAct = (operatorCreatable) => ({
  type: CREATE_OPERATOR.REQUEST,
  operatorCreatable,
})

export const createOperatorSuccessAct = (operator) => ({
  type: CREATE_OPERATOR.SUCCESS,
  operator,
})

export const createOperatorErrorAct = (error) => ({
  type: CREATE_OPERATOR.ERROR,
  error,
})

export const resetCreateOperatorAct = () => ({
  type: RESET_CREATE_OPERATOR,
})

// ----- EDIT -----

export const editOperatorAct = (operatorUpdatable, uuid) => ({
  type: EDIT_OPERATOR.REQUEST,
  operatorUpdatable,
  uuid,
})

export const editOperatorSuccessAct = (operator) => ({
  type: EDIT_OPERATOR.SUCCESS,
  operator,
})

export const editOperatorErrorAct = (error) => ({
  type: EDIT_OPERATOR.ERROR,
  error,
})

export const resetEditOperatorAct = () => ({
  type: RESET_EDIT_OPERATOR,
})

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BasicCardLoadable } from 'fragments/Loadables'
import MuiDataTableThemed from 'fragments/tables/MuiDataTableThemed'
import columns from 'components/Sales/SalesPerMonth/SalesPerMonthColumns'
import options from 'components/Sales/SalesPerMonth/tableOptions'
/* -------------------------------------------------------------------------- */

const SalesPerMonthTableCard = () => {
  const {
    isGettingSales,
    sales: { salesPerCarPark },
    pagination: { rows, page, totalCount },
  } = useSelector((state) => state.sales)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <BasicCardLoadable isLoading={isGettingSales}>
      <MuiDataTableThemed
        data={salesPerCarPark}
        columns={columns({ salesPerCarPark, t })}
        options={options({ rows, totalCount, page, dispatch })}
      />
    </BasicCardLoadable>
  )
}

export default SalesPerMonthTableCard

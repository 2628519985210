import React from 'react'
/* -------------------------------------------------------------------------- */

export default function usePersistedState(key, defaultState) {
  const [state, setState] = React.useState(() => {
    try {
      const cachedState = window.localStorage.getItem(key)
      if (!cachedState) {
        return defaultState
      }
      try {
        return JSON.parse(cachedState)
      } catch (err) {
        return defaultState
      }
    } catch (err) {
      return defaultState
    }
  })

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LocalActivity as LocalActivityIcon } from '@material-ui/icons'

import NumbersWidget from 'fragments/widgets/NumbersWidget'
/* -------------------------------------------------------------------------- */

type RootState = {
  statistics: {
    isGettingGeneralStatistics: boolean
    ticketsCount: {
      total: number
      month: number
    }
  }
}

export default function TicketsNumbersCard() {
  const {
    isGettingGeneralStatistics,
    ticketsCount: { total, month },
  } = useSelector((state: RootState) => state.statistics)
  const { t } = useTranslation()

  return (
    <NumbersWidget
      title={t('TicketsNumbersCard.bookings') as string}
      isLoading={isGettingGeneralStatistics}
      action={<LocalActivityIcon />}
      label1={t('TicketsNumbersCard.total') as string}
      val1={total}
      label2={t('TicketsNumbersCard.month') as string}
      val2={month}
    />
  )
}

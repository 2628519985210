import React from 'react'
import * as PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import { getBarrierTypeAsString, getBarrierTypeOptions } from 'util/custom'
import NumberField from 'fragments/Forms/Formik/NumberField'
import SelectTextField from 'fragments/Forms/Formik/SelectTextField'
import CarParksSelect from 'fragments/Forms/Formik/CarParksSelect'
import TextField from 'fragments/Forms/Formik/TextField'
/* -------------------------------------------------------------------------- */

const BarrierInfoCard = (props) => {
  const { t } = useTranslation()
  const {
    barrier: { name, carParkName, isEntrance, beaconMinor },
    isEditingBarrier,
  } = useSelector((state) => state.barrier)
  const { isEdit } = props

  const beaconMinorInput = () => <NumberField name='beaconMinor' variant='standard' fullWidth />
  const barrierTypeInput = () => (
    <SelectTextField name='isEntrance' options={getBarrierTypeOptions(t)} variant='standard' />
  )
  const carParkInput = () => <CarParksSelect name='carParkUuid' variant='standard' />
  const nameInput = () => <TextField name='name' placeholder={t('BarrierInfoCard.label')} />

  const rows = [
    {
      label: t('BarrierInfoCard.carPark') + (isEdit ? ' *' : ''),
      value: isEdit ? carParkInput() : carParkName,
    },
    {
      label: t('BarrierInfoCard.barrierType') + (isEdit ? ' *' : ''),
      value: isEdit ? barrierTypeInput() : getBarrierTypeAsString(t, isEntrance),
    },
    {
      label: t('BarrierInfoCard.label'),
      value: isEdit ? nameInput() : name,
    },
    {
      label: t('BarrierInfoCard.barrierNumber') + (isEdit ? ' *' : ''),
      value: isEdit ? beaconMinorInput() : beaconMinor,
    },
  ]

  return (
    <BasicCardLoadable title={t('BarrierInfoCard.title')} action={<InfoIcon />} isLoading={isEditingBarrier}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

BarrierInfoCard.propTypes = {
  isEdit: PropTypes.bool,
}

BarrierInfoCard.defaultProps = {
  isEdit: false,
}

export default BarrierInfoCard

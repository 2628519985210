import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Grid, Button, Link as MuiLink, makeStyles } from '@material-ui/core'
import { MailOutline as MailOutlineIcon, LockOutlined as LockOutlinedIcon } from '@material-ui/icons'

import AuthPageContainer from 'layouts/AuthPageContainer/AuthPageContainer'
import PasswordField from 'fragments/Forms/Formik/PasswordField'
import { logInUserAct } from 'redux/Actions'
import ROUTES from 'routes/routes'
import { isRejected, isPending } from 'redux/Status'
import { initialValues, validationSchema } from 'components/Login/loginForm'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '20px',
    textTransform: 'none',
    color: '#fff',
  },
}))

const LoginContainer = () => {
  const { statusLoggingIn } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const locationEmail = location?.state?.email

  useEffect(() => {
    if (isRejected(statusLoggingIn)) {
      enqueueSnackbar(t('LoginContainer.badCredentials'), { variant: 'error' })
    }
  }, [enqueueSnackbar, history, statusLoggingIn, t])

  const handleSubmit = (values) => dispatch(logInUserAct(values))

  return (
    <AuthPageContainer isLoading={isPending(statusLoggingIn)}>
      <Formik
        initialValues={initialValues(locationEmail)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          const { isValidating, isSubmitting, isValid, dirty } = formikProps
          return (
            <Form>
              <Grid container justify='center' spacing={3}>
                <Grid item xs={10} sm={8}>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item>
                      <MailOutlineIcon />
                    </Grid>
                    <Grid item xs>
                      <Field
                        component={TextField}
                        type='email'
                        name='email'
                        autoComplete='username'
                        label={t('LoginContainer.email')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={10} sm={8}>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item>
                      <LockOutlinedIcon />
                    </Grid>
                    <Grid item xs>
                      <PasswordField
                        name='password'
                        autoComplete='current-password'
                        label={t('LoginContainer.password')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify='flex-end' alignItems='center' item xs={10} sm={8}>
                  <MuiLink
                    component={RouterLink}
                    to={{
                      pathname: ROUTES.AUTH.RESET_PW_REQUEST,
                      state: { email: formikProps?.values?.email },
                    }}
                  >
                    {t('LoginContainer.forgotPassword')}
                  </MuiLink>
                </Grid>
                <Grid item xs={10} sm={8}>
                  <Button
                    type='submit'
                    disabled={isValidating || isSubmitting || !isValid || !dirty}
                    className={classes.button}
                    color='primary'
                    variant='contained'
                    fullWidth
                  >
                    {t('LoginContainer.logIn')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </AuthPageContainer>
  )
}

export default LoginContainer

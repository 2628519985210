import React from 'react'
import * as PropTypes from 'prop-types'

import { de, en, unknown } from 'assets/img/flags'
/* -------------------------------------------------------------------------- */
const LanguageFlagIcon = (props) => {
  const { className, height, languageCode, width } = props

  const getFlag = () => {
    const shortLanguageCode = languageCode?.substring(0, 2)
    const flags = {
      de,
      en,
      unknown,
    }
    return flags[shortLanguageCode] || flags.unknown
  }

  return <img className={className} src={getFlag()} style={{ width, height }} alt='lang-icon' />
}

/* -------------------------------------------------------------------------- */
LanguageFlagIcon.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  languageCode: PropTypes.string,
  width: PropTypes.number,
}

LanguageFlagIcon.defaultProps = {
  className: '',
  height: 16,
  width: 25,
  languageCode: null,
}

export default LanguageFlagIcon

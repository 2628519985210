import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { GET_ADMINS } from 'redux/Types'
import { getAdminsReq } from 'api/requests'
import { getAdminsSuccessAct, getAdminsErrorAct, catchSagaErrorsAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */
function* workerGetAdmins() {
  try {
    const {
      globalFilters: { selectedOperator },
    } = yield select()

    const res = yield call(getAdminsReq, selectedOperator)
    yield put(getAdminsSuccessAct(res))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getAdminsErrorAct))
  }
}

function* watcherGetAdmins() {
  yield takeLatest(GET_ADMINS.REQUEST, workerGetAdmins)
}

/* -------------------------------------------------------------------------- */
export default function* rootSaga() {
  yield all([fork(watcherGetAdmins)])
}

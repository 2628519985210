import React from 'react'
import { useTranslation } from 'react-i18next'

import FilterGridCard from 'fragments/Cards/FilterGridCard'
import BarrierOpeningCategorySelect from 'fragments/Forms/Custom/BarrierOpeningCategorySelect'
import CustomDateTimePicker from 'fragments/Forms/CustomDateTimePicker'
import { BarrierOpeningsType } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type BarrierOpeningsFilterCardProps = {
  beforeDate?: string
  setBeforeDate: (s: string) => void
  setStatusGroup: (ts: BarrierOpeningsType[]) => void
  resetFilters: () => void
  isLoading: boolean
  statusGroup: string[]
}

export default function BarrierOpeningsFilterCard(props: BarrierOpeningsFilterCardProps) {
  const { beforeDate, setBeforeDate, setStatusGroup, resetFilters, isLoading, statusGroup } = props
  const { t } = useTranslation()

  return (
    <FilterGridCard isLoading={isLoading} resetFilters={resetFilters}>
      <BarrierOpeningCategorySelect onChange={setStatusGroup} selected={statusGroup} />
      <CustomDateTimePicker
        date={beforeDate}
        onChange={setBeforeDate}
        disableFuture
        disablePast={false}
        label={t('BarrierOpeningsFilterCard.beforeDate') as string}
        inputVariant='outlined'
      />
    </FilterGridCard>
  )
}

import React from 'react'
import { TFunction } from 'i18next'

import TicketIdCell from 'components/Tickets/TicketIdCell'
import OpeningsStatusCell from 'components/BarrierOpenings/OpeningsStatusCell'
import OpeningsProcessTimeCell from 'components/BarrierOpenings/OpeningsProcessTimeCell'
import { getBarrierTypeAsString, getBeaconMinorString } from 'util/custom'
import { getTimeDifferenceInSec } from 'util/core'
import { DEFAULT_TICKET_ADMIN_ID } from 'constants/generalConstants'
import {
  BarrierOpening,
  BarrierOpeningsType,
  BarrierOpeningsStatus,
} from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type GetColumnsArgs = {
  barrierOpenings: BarrierOpening[]
  isTpAdmin: boolean
  t: TFunction
}

function isAdminOpening(status?: BarrierOpeningsStatus, tUuid?: string) {
  return status !== 'NO_APP_USER' && !tUuid
}

export default function getColumns({ barrierOpenings, isTpAdmin, t }: GetColumnsArgs) {
  return [
    {
      name: 'uuid',
      label: t('BarrierOpeningsTableColumns.openingTime'),
      options: {
        customBodyRender: (uuid: string) => {
          const barrierOpening = barrierOpenings.find((o) => o.uuid === uuid)
          return t('BarrierOpeningsTableColumns.dateShortTimeShort', { date: barrierOpening?.processStartAt })
        },
      },
    },
    {
      name: 'uuid',
      label: t('BarrierOpeningsTableColumns.carPark'),
      options: {
        customBodyRender: (uuid: string) => {
          const barrierOpening = barrierOpenings.find((o) => o.uuid === uuid)
          return barrierOpening?.ticket?.carParkName
        },
      },
    },
    {
      name: 'isEntrance',
      label: t('BarrierOpeningsTableColumns.barrierType'),
      options: {
        customBodyRender: (v: BarrierOpeningsType) => getBarrierTypeAsString(t, v),
      },
    },
    {
      name: 'uuid',
      label: t('BarrierOpeningsTableColumns.barrierNumber'),
      options: {
        customBodyRender: (uuid: string) => {
          const barrierOpening = barrierOpenings.find((o) => o.uuid === uuid)
          return getBeaconMinorString(t, barrierOpening?.barrier?.beaconMinor)
        },
      },
    },
    {
      name: 'uuid',
      label: `${t('BarrierOpeningsTableColumns.processTime')}`,
      options: {
        // https://github.com/gregnb/mui-datatables/issues/1576
        display: isTpAdmin.toString(),
        customBodyRender: (uuid: string) => {
          const barrierOpening = barrierOpenings.find((o) => o.uuid === uuid)
          const tInSec = getTimeDifferenceInSec(barrierOpening?.processEndAt, barrierOpening?.processStartAt)
          return <OpeningsProcessTimeCell timeInSec={tInSec} />
        },
      },
    },
    {
      name: 'uuid',
      label: t('BarrierOpeningsTableColumns.category'),
      options: {
        customBodyRender: (uuid: string) => {
          const barrierOpening = barrierOpenings.find((o) => o.uuid === uuid)
          return <OpeningsStatusCell status={barrierOpening?.status} />
        },
      },
    },
    {
      name: 'uuid',
      label: t('BarrierOpeningsTableColumns.ticketId'),
      options: {
        customBodyRender: (uuid: string) => {
          const barrierOpening = barrierOpenings.find((o) => o.uuid === uuid)
          return (
            <TicketIdCell
              value={
                isAdminOpening(barrierOpening?.status, barrierOpening?.ticket?.publicId)
                  ? DEFAULT_TICKET_ADMIN_ID
                  : barrierOpening?.ticket?.publicId
              }
            />
          )
        },
      },
    },
  ]
}

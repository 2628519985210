import { GET_BARRIERS, RESET_BARRIERS_FILTERS, RESET_BARRIERS } from 'redux/Types'
import { BODY_PAGINATION } from 'api/backendConstants'
import { BARRIER_ONLINE_STATES } from 'util/custom'
import { unpackBarriers } from 'api/carParkMgmt/carParkMgmtUnpackers'
import { BARRIER_STATE_VALUES } from 'api/carParkMgmt/carParkMgmtModels'
/* -------------------------------------------------------------------------- */

const INIT_STATE = {
  barriers: [],
  errGettingBarriers: {
    errorCode: null,
    httpStatusCode: null,
    message: null,
  },
  filters: {
    onlineState: BARRIER_ONLINE_STATES.offline,
    operatingState: BARRIER_STATE_VALUES.operating,
  },
  isGettingBarriers: false,
  isReGettingBarriers: false,
  lastSuccessDate: undefined,
  pagination: {
    page: 0,
    rows: 10,
    totalCount: 0,
  },
}

export default (state = INIT_STATE, action) => {
  const { type } = action

  switch (type) {
    case GET_BARRIERS.REQUEST: {
      const { isReload, page, rows, operatingState, onlineState } = action
      return {
        ...state,
        isGettingBarriers: !isReload,
        isReGettingBarriers: !!isReload,
        errGettingBarriers: INIT_STATE.errGettingBarriers,
        filters: {
          ...state.filters,
          operatingState: operatingState || state.filters.operatingState,
          onlineState: onlineState || state.filters.onlineState,
        },
        pagination: {
          ...state.pagination,
          page: page ?? state.pagination.page,
          rows: rows ?? state.pagination.rows,
        },
      }
    }
    case GET_BARRIERS.SUCCESS: {
      const { data } = action
      const { CONTENT_ARR: BARRIERS, PAGE_NUMBER, PAGE_SIZE, TOTAL_ELEMENTS } = BODY_PAGINATION
      const barriers = data[BARRIERS]
      return {
        ...state,
        barriers: unpackBarriers(barriers),
        isGettingBarriers: false,
        isReGettingBarriers: false,
        lastSuccessDate: new Date(),
        pagination: {
          totalCount: data?.[TOTAL_ELEMENTS] ?? state.pagination.totalCount,
          page: data?.[PAGE_NUMBER] ?? state.pagination.page,
          rows: (data?.[PAGE_SIZE] ?? state.pagination.rows) || 10,
        },
      }
    }
    case GET_BARRIERS.ERROR: {
      const { error } = action
      return {
        ...state,
        isGettingBarriers: false,
        isReGettingBarriers: false,
        errGettingBarriers: error,
        pagination: INIT_STATE.pagination,
      }
    }
    case RESET_BARRIERS_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters,
        },
      }
    }
    case RESET_BARRIERS: {
      return {
        ...state,
        barriers: INIT_STATE.barriers,
      }
    }
    default:
      return { ...state }
  }
}

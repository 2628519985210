import { getTimeWithBiggestPossibleUnit, TIME_UNITS } from 'util/core'
export { validationSchema } from 'components/CarPark/Info/validationSchema'
/* -------------------------------------------------------------------------- */

export const initialValues = (carPark) => {
  const { continuityPeriodInMinutes, continuityPriceInCent, priceMaxPerDayInCent } = carPark

  const { value: continuityPeriodDuration, unit: continuityPeriodUnit } = Number.isInteger(continuityPeriodInMinutes)
    ? getTimeWithBiggestPossibleUnit(continuityPeriodInMinutes)
    : { value: '', unit: TIME_UNITS.MINUTES }

  return {
    operatorUuid: carPark.operatorUuid ?? '',
    name: carPark.name ?? '',
    street: carPark.street ?? '',
    houseNumber: carPark.houseNumber ?? '',
    postalCode: carPark.postalCode ?? '',
    city: carPark.city ?? '',
    latitude: carPark.latitude ?? '',
    longitude: carPark.longitude ?? '',
    telephoneNumber: carPark.telephoneNumber ?? '',
    beaconMajor: carPark.beaconMajor ?? '',
    countParkingLots: carPark.countParkingLots ?? '',
    continuityPriceInEuro: Number.isInteger(continuityPriceInCent) ? continuityPriceInCent / 100 : '',
    continuityPeriodDuration,
    continuityPeriodUnit,
    priceMaxPerDayInEuro: Number.isInteger(priceMaxPerDayInCent) ? priceMaxPerDayInCent / 100 : '',
    countUserInside: carPark.countUserInside ?? '',
    openingHours: carPark.openingHours ?? '',
    isVisible: carPark.isVisible ?? false,
  }
}

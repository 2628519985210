import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { List, ListItem, makeStyles, ListItemIcon, ListItemText, Tooltip, Box } from '@material-ui/core'

import sidebarConfig from 'layouts/AppLayout/Sidebar/sidebarConfig'
import HasRole from 'components/Session/HasRole'
/* -------------------------------------------------------------------------- */

const BORDER_WIDTH = 5

const useStyles = makeStyles((theme) => ({
  navList: {
    width: theme.sidebar.width,
  },
  item: {
    height: 53,
  },
  icon: {
    color: theme.sidebar.color,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    minWidth: 40,
  },
  text: {
    color: theme.sidebar.color,
  },
  active: {
    background: theme.sidebar.active,
    borderLeft: `${BORDER_WIDTH}px solid ${theme.sidebar.color}`,
    '& div': {
      marginLeft: theme.spacing(3) - BORDER_WIDTH,
    },
    '& div + div': {
      marginLeft: theme.spacing(0),
    },
  },
  popper: {
    marginLeft: -195,
  },
}))

const SidebarNav = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isCollapsed, onSelect } = props

  return (
    <List className={classes.navList} disablePadding>
      {sidebarConfig(t).map((group) => (
        <Fragment key={group[0].title}>
          <Box m={3} />
          {group.map((entry) => (
            <HasRole key={entry.title} allowedRoles={entry.allowedRoles}>
              <Tooltip
                title={entry.title}
                placement='right'
                disableHoverListener={!isCollapsed}
                classes={{ popper: classes.popper }}
                arrow
              >
                <ListItem
                  button
                  onClick={onSelect}
                  component={NavLink}
                  className={classes.item}
                  activeClassName={classes.active}
                  to={entry.path}
                  disableGutters
                >
                  <ListItemIcon className={classes.icon}>{entry.icon}</ListItemIcon>
                  <ListItemText className={classes.text} primaryTypographyProps={{ variant: 'subtitle1' }}>
                    {entry.title}
                  </ListItemText>
                </ListItem>
              </Tooltip>
            </HasRole>
          ))}
        </Fragment>
      ))}
    </List>
  )
}

SidebarNav.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

SidebarNav.defaultProps = {
  onSelect: () => {},
}

export default SidebarNav

import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, Badge } from '@material-ui/core'
import { FilterList as FilterListIcon } from '@material-ui/icons'

import { isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles(() => ({
  icon: {
    color: '#575756',
  },
  badge: {
    color: 'white',
  },
}))

const FilterIconWithBadges = () => {
  const classes = useStyles()

  const { selectedCarParks, selectedOperator } = useSelector((state) => state.globalFilters)
  const { role } = useSelector((state) => state.auth.user)

  return (
    <Badge
      // show dot if operator select is active
      variant='dot'
      color='primary'
      overlap='circle'
      invisible={!isTriveParkAdmin(role) || !selectedOperator}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Badge
        // show count for active car park selects
        badgeContent={selectedCarParks.length}
        color='primary'
        overlap='rectangle'
        classes={{ colorPrimary: classes.badge }}
      >
        <FilterListIcon className={classes.icon} />
      </Badge>
    </Badge>
  )
}

export default FilterIconWithBadges

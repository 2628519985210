import { muiDataTableOptions } from 'fragments/tables/TableConfig'
import { getOffersAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const options = ({ dispatch, history, offers, page, rows, totalCount }) => ({
  count: totalCount,
  onChangePage: (page) =>
    dispatch(
      getOffersAct({
        page,
      }),
    ),
  onChangeRowsPerPage: (rows) =>
    dispatch(
      getOffersAct({
        rows,
      }),
    ),
  onRowClick: (_rowData, { dataIndex }) => {
    const offerUuid = offers?.[dataIndex]?.offerUuid ?? null
    if (offerUuid)
      history.push({
        pathname: APP.OFFER,
        search: getQueryString({ [QUERY_PARAMS.UUID]: offerUuid }),
      })
  },
  page: page,
  rowsPerPage: rows,
  serverSide: true,
})

export default (args) => ({
  ...muiDataTableOptions(),
  ...options(args),
})

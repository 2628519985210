import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
/* -------------------------------------------------------------------------- */

const baseTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#26a1f1',
    },
    secondary: {
      main: '#677080',
    },
    background: {
      default: '#F4F6F7',
    },
  },
  typography: {
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
        color: '#464d69',
      },
      root: {
        padding: '8px 0px 8px 24px',
      },
    },
    // mui-datatables
    MUIDataTableToolbar: {
      root: {
        minHeight: 56,
        maxHeight: 64,
      },
    },
    MUIDataTableHeadRow: {
      root: {
        height: 48,
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        zIndex: '0',
        paddingTop: 4,
      },
    },
    MUIDataTablePagination: {
      root: {
        maxHeight: 48,
      },
    },
  },
  props: {
    MuiTooltip: {
      enterDelay: 1000,
    },
  },
})

const custom = {
  header: {
    background: '#fff',
  },
  sidebar: {
    width: 260,
    widthCollapsed: 65,
    background: 'linear-gradient(#26a1f1, #0074bf)',
    color: '#f2f2f2',
    active: 'rgba(0, 0, 0, 0.1)',
    logoBackground: '#0074bf',
  },
}

const lightTheme = {
  ...baseTheme,
  ...custom,
}

export default responsiveFontSizes(lightTheme)

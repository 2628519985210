import React from 'react'

import MoneyAmount from 'fragments/Misc/MoneyAmount'
import TimeAmountCell from 'components/Offers/TimeAmountCell'
import VisibilityChip from 'fragments/IconChips/VisibilityChip'
import { getOfferTypeAsString, isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */

export default ({ language, offers, role, t }) => [
  {
    name: 'uuid',
    label: t('OffersTableColumns.carPark'),
    options: {
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return offer?.carParkName
      },
    },
  },
  {
    name: 'uuid',
    label: t('OffersTableColumns.type'),
    options: {
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return getOfferTypeAsString(t, offer?.type)
      },
    },
  },
  {
    name: 'uuid',
    label: t('OffersTableColumns.name'),
    options: {
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return offer?.localizedNames?.[language] || offer?.localizedGeneratedNames?.[language]
      },
    },
  },
  {
    name: 'uuid',
    label: `${t('OffersTableColumns.price')}`,
    options: {
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return <MoneyAmount valueInCent={offer?.priceInCent} />
      },
    },
  },
  {
    name: 'uuid',
    label: t('OffersTableColumns.duration'),
    options: {
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return <TimeAmountCell valueInMin={offer?.durationInMin} />
      },
    },
  },
  {
    name: 'uuid',
    label: t('OffersTableColumns.versions'),
    options: {
      // https://github.com/gregnb/mui-datatables/issues/1576
      display: isTriveParkAdmin(role).toString(),
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return (offer?.versionsCount ?? 0) + 1
      },
    },
  },
  {
    name: 'uuid',
    label: t('OffersTableColumns.lastUpdate'),
    options: {
      customBodyRender: (uuid) => {
        const { updatedAt } = offers.find((o) => o.uuid === uuid)
        return t('OffersTableColumns.dateShortTimeShort', { date: updatedAt })
      },
    },
  },
  {
    name: 'uuid',
    label: t('OffersTableColumns.visibility'),
    options: {
      customBodyRender: (uuid) => {
        const offer = offers.find((o) => o.uuid === uuid)
        return <VisibilityChip isVisible={offer?.isVisible} />
      },
    },
  },
]

import yup from 'util/yup'
import { localeCodesArr } from 'util/i18n'
import { ROLES } from 'constants/generalConstants'
/* -------------------------------------------------------------------------- */

export const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  role: yup.string().oneOf(Object.values(ROLES)).required(),
  carParkUuids: yup
    .array()
    .of(yup.string())
    .when('role', {
      is: (role) => role === ROLES.OP_MANAGER,
      then: yup.array().of(yup.string()).required().min(1),
      otherwise: yup.array().of(yup.string()),
    }),
  operatorUuid: yup.string().nullable(),
  language: yup.string().oneOf(localeCodesArr),
})

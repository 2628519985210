import React from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { LocalActivity as LocalActivityIcon } from '@material-ui/icons'

import TicketUsabilityChip from 'components/Tickets/TicketUsabilityChip'
import TicketUsageChip from 'components/Tickets/TicketUsageChip'
import InfoRows from 'fragments/Cards/InfoRows'
import { BasicCardLoadable } from 'fragments/Loadables'
import { Ticket } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketStatusCardProps = {
  ticket?: Ticket
  isLoading: boolean
}

export default function TicketStatusCard(props: TicketStatusCardProps) {
  const { ticket, isLoading } = props

  const { t } = useTranslation()

  const validFrom = ticket?.validFrom
  const validUntil = ticket?.validUntil
  const bookedUsageFrom = ticket?.bookedUsageFrom
  const bookedUsageUntil = ticket?.bookedUsageUntil

  const rowsLongTermTicket = [
    {
      label: t('TicketStatusCard.booking') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: validFrom }),
    },
    {
      label: t('TicketStatusCard.ticketActivation') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: bookedUsageFrom }),
    },
    {
      label: t('TicketStatusCard.endOfDeal') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: bookedUsageUntil }),
    },
  ]

  const rowsShortTermTicketActivated = [
    {
      label: t('TicketStatusCard.booking') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: validFrom }),
    },
    {
      label: t('TicketStatusCard.ticketActivation') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: bookedUsageFrom }),
    },
    {
      label: t('TicketStatusCard.endOfDeal') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: bookedUsageUntil }),
    },
  ]

  const rowsShortTermTicketNotActivated = [
    {
      label: t('TicketStatusCard.booking') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: validFrom }),
    },
    {
      label: t('TicketStatusCard.usableFrom') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: validFrom }),
    },
    {
      label: t('TicketStatusCard.usableUntil') as string,
      value: t('TicketStatusCard.dateMediumTimeShort', { date: validUntil }),
    },
  ]

  const rowsShortTermTicket = [
    bookedUsageFrom === null ? rowsShortTermTicketNotActivated : rowsShortTermTicketActivated,
  ].flat()

  const rows = [
    {
      label: t('TicketStatusCard.usageStatus') as string,
      value: <TicketUsageChip value={ticket?.usage} />,
    },
    {
      label: t('TicketStatusCard.usability') as string,
      value: <TicketUsabilityChip value={ticket?.usability} />,
    },
    ticket?.offerVersion?.type === 'LONG_TERM' ? rowsLongTermTicket : rowsShortTermTicket,
  ].flat()

  return (
    <BasicCardLoadable isLoading={isLoading} title={t('TicketStatusCard.status')} action={<LocalActivityIcon />}>
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

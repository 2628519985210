import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { TFunction, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { parseISO, isBefore } from 'date-fns'

import { BasicCardLoadable } from 'fragments/Loadables'
import CustomDateTimePicker from 'fragments/Forms/CustomDateTimePicker_DEPRECATED'
import ConfirmDialog from 'fragments/Dialogs/ConfirmDialog'
import SelectTextField from 'fragments/Forms/SelectTextField'
import { getBarrierOpeningStatusLabel } from 'util/custom'
import { sortArrayByDateProp } from 'util/core'
import { SaveButton } from 'fragments/Buttons'
import { ManualOpeningQueryArgs, Ticket, ManualOpeningStatus } from 'services/tickets/tickets-service'
import { BarrierOpening } from 'services/barrier-openings/barrier-openings-service'
/* -------------------------------------------------------------------------- */

type ManualOpeningCardProps = {
  ticket?: Ticket
  isLoading: boolean
  addManualOpening: (args: ManualOpeningQueryArgs) => void
}

function getManualOpeningDateError(t: TFunction, d: Date, openings?: BarrierOpening[], validFrom?: string) {
  if (!d) {
    return t('ManualOpeningCard.error.noDate') as string
  } else if (isBefore(new Date(), d)) {
    return t('ManualOpeningCard.error.future') as string
  } else if (!isEmpty(openings)) {
    const sortedOpenings: BarrierOpening[] = sortArrayByDateProp(openings, 'processStartAt')
    const refDate = parseISO(sortedOpenings[0].processStartAt)
    if (isBefore(d, refDate)) {
      return t('ManualOpeningCard.error.beforeLastOpening') as string
    }
  } else if (isBefore(d, new Date(validFrom ?? ''))) {
    return t('ManualOpeningCard.error.beforeBooking') as string
  }
  return null
}

function getManualStatusLabels(t: TFunction): { label: String; value: ManualOpeningStatus }[] {
  return [
    {
      label: getBarrierOpeningStatusLabel(t, 'MANUAL_UNSPECIFIED') as string,
      value: 'MANUAL_UNSPECIFIED',
    },
    {
      label: getBarrierOpeningStatusLabel(t, 'MANUAL_BARRIER_ERROR') as string,
      value: 'MANUAL_BARRIER_ERROR',
    },
    {
      label: getBarrierOpeningStatusLabel(t, 'MANUAL_APP_ERROR') as string,
      value: 'MANUAL_APP_ERROR',
    },
    {
      label: getBarrierOpeningStatusLabel(t, 'MANUAL_NO_PERMISSION') as string,
      value: 'MANUAL_NO_PERMISSION',
    },
  ]
}

export default function ManualOpeningCard(props: ManualOpeningCardProps) {
  const { ticket, isLoading, addManualOpening } = props
  const { t } = useTranslation()
  const [date, setDate] = useState(new Date())
  const [dateError, setDateError] = useState<string | null>(null)
  const [reason, setReason] = useState<ManualOpeningStatus>('MANUAL_UNSPECIFIED')
  const [showDialog, setShowDialog] = useState(false)

  const handleConfirm = () => {
    addManualOpening({
      uuid: ticket?.uuid ?? '',
      date: date?.toISOString(),
      status: reason,
    })
    setShowDialog(false)
  }

  const handleDateChange = (newDate: Date) => {
    const error = getManualOpeningDateError(t, newDate, ticket?.barrierOpenings, ticket?.validFrom)
    setDateError(error)
    setDate(newDate)
  }

  const handleClose = () => setShowDialog(false)
  const handleOpenDialog = () => setShowDialog(true)

  return (
    <BasicCardLoadable title={t('ManualOpeningCard.adjustTicketStatus')} isLoading={isLoading}>
      <ConfirmDialog
        open={showDialog}
        dialogTitle={t('ManualOpeningCard.adjustTicketStatus') as string}
        dialogContent={
          t('ManualOpeningCard.dialogContent', {
            date: date?.toISOString(),
            reason: getBarrierOpeningStatusLabel(t, reason),
          }) as string
        }
        confirmLabel={t('ManualOpeningCard.save') as string}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
      <Grid container justify='center' spacing={2}>
        <Grid item sm={12} md={6}>
          <CustomDateTimePicker date={date} onChange={handleDateChange} errorText={dateError} />
        </Grid>
        <Grid item sm={12} md={6}>
          <SelectTextField
            label={t('ManualOpeningCard.reason') as string}
            selected={reason}
            options={getManualStatusLabels(t)}
            onChange={setReason}
          />
        </Grid>
      </Grid>
      <Grid container justify='flex-end' spacing={2}>
        <Grid item>
          <SaveButton type='button' disabled={!!dateError} onClick={handleOpenDialog} />
        </Grid>
      </Grid>
    </BasicCardLoadable>
  )
}

import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects'

import { GET_CAR_PARKS, GET_MINIFIED_CAR_PARKS } from 'redux/Types'

import {
  catchSagaErrorsAct,
  getCarParksErrorAct,
  getCarParksSuccessAct,
  getMinifiedCarParksErrorAct,
  getMinifiedCarParksSuccessAct,
} from 'redux/Actions'
import { getCarParksReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

// CAR PARKS

function* workerGetCarParks(action) {
  try {
    const {
      globalFilters: { selectedOperator },
      carParks: {
        pagination: { page, rows },
      },
    } = yield select()

    const data = yield call(getCarParksReq, {
      operatorUuid: action.operatorUuid || selectedOperator,
      page: action.page || page,
      rows: action.rows || rows,
    })

    // API call
    if (action.type === GET_CAR_PARKS.REQUEST) {
      yield put(getCarParksSuccessAct(data))
    } else if (action.type === GET_MINIFIED_CAR_PARKS.REQUEST) {
      yield put(getMinifiedCarParksSuccessAct(data))
    }
  } catch (error) {
    if (action.type === GET_CAR_PARKS.REQUEST) {
      yield put(catchSagaErrorsAct(error, getCarParksErrorAct))
    } else if (action.type === GET_MINIFIED_CAR_PARKS.REQUEST) {
      yield put(catchSagaErrorsAct(error, getMinifiedCarParksErrorAct))
    }
  }
}

function* watcherGetCarParks() {
  yield takeLatest(GET_CAR_PARKS.REQUEST, workerGetCarParks)
}

function* watcherGetCarParksGlobal() {
  yield takeLatest(GET_MINIFIED_CAR_PARKS.REQUEST, workerGetCarParks)
}

export default function* rootSaga() {
  yield all([fork(watcherGetCarParks), fork(watcherGetCarParksGlobal)])
}

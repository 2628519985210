import { ADMIN_API_BASE_PATH } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

export const PATH_SEGMENTS_OPERATORS = {
  OPERATORS_BASE: `${ADMIN_API_BASE_PATH}/operators`,
}

export const BODY_OPERATORS = {
  CITY: 'city',
  COUNTRY: 'country',
  CREATED_AT: 'createdAt',
  EMAIL: 'email',
  HOUSE_NUMBER: 'houseNumber',
  NAME: 'name',
  PHONE_NUMBER: 'phoneNumber',
  POSTAL_CODE: 'postalCode',
  STREET: 'street',
  STRIPE_ACCOUNT_ID: 'stripeAccountId',
  UPDATED_AT: 'updatedAt',
  UUID: 'uuid',
}

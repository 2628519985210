import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
/* -------------------------------------------------------------------------- */

const AppUserIdentity = () => {
  const {
    isGettingAppUser,
    appUser: { firstName, lastName, email },
  } = useSelector((state) => state.appUser)
  const { t } = useTranslation()

  const fullName = (firstName ? firstName : '') + (lastName ? (firstName ? ' ' + lastName : lastName) : '')
  const identityString = fullName !== '' ? email + ' | ' + fullName : email

  return (
    <BasicCardLoadable isLoading={isGettingAppUser}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item>
          <AccountCircleIcon color='primary' />
        </Grid>
        <Grid item>
          <Typography variant='body1'>{isGettingAppUser ? t('AppUserIdentity.loading') : identityString}</Typography>
        </Grid>
      </Grid>
    </BasicCardLoadable>
  )
}

export default AppUserIdentity

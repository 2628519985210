import map from 'lodash/fp/map'

import company from 'constants/company'
import { isTriveParkAdmin } from 'util/custom'
/* -------------------------------------------------------------------------- */

export const getOperatorNameAsString = (t, uuid, operators) =>
  operators.filter((op) => op.uuid === uuid)[0]?.name || t('operator.unknown')

// getOperatorSelects :: operators[] -> { value: string, label: string }[]
export const getOperatorSelects = map((o) => ({
  value: o.uuid,
  label: o.name,
}))

export const getCompanyName = (t, uuid, operators, role) => {
  return isTriveParkAdmin(role) ? company.name : getOperatorNameAsString(t, uuid, operators)
}

import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid, Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CircularProgressOverlay from 'fragments/CircularProgressOverlay'
import { triveParkIconLogoColored } from 'assets/svg'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: '20px',
    paddingBottom: '40px',
    paddingTop: '40px',
    position: 'relative',
    height: '100%',
  },
  tpLogo: {
    maxHeight: '100px',
    maxWidth: '100%',
    marginBottom: '10px',
  },
}))

const AuthPageContainer = (props) => {
  const classes = useStyles()
  const { children, isLoading } = props

  return (
    <Card raised className={classes.card}>
      {isLoading && <CircularProgressOverlay />}
      <CardContent>
        <Grid container item justify='center'>
          <Grid item xs={4}>
            <img src={triveParkIconLogoColored} className={classes.tpLogo} alt='trive park logo colored' />
          </Grid>
        </Grid>
        {children}
      </CardContent>
    </Card>
  )
}

AuthPageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isLoading: PropTypes.bool,
}

AuthPageContainer.defaultProps = {
  isLoading: false,
}

export default AuthPageContainer

export const STATUS = {
  idle: 'idle',
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
}

export const isIdle = (status) => status === STATUS.idle
export const isPending = (status) => status === STATUS.pending
export const isResolved = (status) => status === STATUS.resolved
export const isRejected = (status) => status === STATUS.rejected

import React from 'react'
import { useSelector } from 'react-redux'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import OccupancyStatsLineChart from 'components/Occupancy/OccupancyStatsLineChart'
import OpeningsStatsBarChart from 'components/Occupancy/OpeningsStatsBarChart'
import { formatOpeningsChartData, formatOccupancyChartData, STATS_VIEW_VALUES } from 'util/custom'
/* -------------------------------------------------------------------------- */
const StatsChartCell = ({ stats }) => {
  const {
    filters: { date, interval, view },
  } = useSelector((state) => state.occupancy)
  const {
    i18n: { language: localeCode },
  } = useTranslation()
  const { OPENINGS, OCCUPANCY_IN_PERCENT } = STATS_VIEW_VALUES

  if (view === OPENINGS) {
    const { labels, entriesNormal, entriesApp, exitsNormal, exitsApp } = formatOpeningsChartData(
      stats,
      interval,
      date,
      localeCode,
    )
    return (
      <OpeningsStatsBarChart
        labels={labels}
        entriesNormal={entriesNormal}
        entriesApp={entriesApp}
        exitsNormal={exitsNormal}
        exitsApp={exitsApp}
      />
    )
  } else if (view === OCCUPANCY_IN_PERCENT) {
    const { averageData, labels } = formatOccupancyChartData(stats, interval, date, localeCode)
    return <OccupancyStatsLineChart averageData={averageData} labels={labels} />
  } else return null
}
/* -------------------------------------------------------------------------- */
StatsChartCell.propTypes = {
  stats: PropTypes.shape().isRequired,
}

export default StatsChartCell

export default (t) => [
  {
    name: 'createdAt',
    label: t('BarrierConnectionTableColumns.timestamp'),
  },
  {
    name: 'carParkName',
    label: t('BarrierConnectionTableColumns.carParkName'),
  },
  {
    name: 'beaconMinor',
    label: t('BarrierConnectionTableColumns.barrierNumber'),
  },
  {
    name: 'connectionTimeInS',
    label: t('BarrierConnectionTableColumns.connectionTime'),
    options: {
      customBodyRender: (v) => t('BarrierConnectionTableColumns.connectionTimeValue', { value: v }),
    },
  },
  {
    name: 'beaconSignalInDBM',
    label: t('BarrierConnectionTableColumns.beaconSignal'),
    options: {
      customBodyRender: (v) => t('BarrierConnectionTableColumns.beaconSignalValue', { value: v }),
    },
  },
  {
    name: 'status',
    label: t('BarrierConnectionTableColumns.status'),
  },
]

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import OccupancyTableCard from 'components/Occupancy/OccupancyTableCard'
import OccupancyFilterCard from 'components/Occupancy/OccupancyFilterCard'
import { getOccupancyAct, resetOccupancyFiltersAct } from 'redux/Actions'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
/* -------------------------------------------------------------------------- */

const OccupancyContainer = () => {
  const dispatch = useDispatch()
  const { errGettingOccupancy } = useSelector((state) => state.occupancy)
  const { errGettingCarParks } = useSelector((state) => state.carParks)
  const { selectedOperator, selectedCarParks } = useSelector((state) => state.globalFilters)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(resetOccupancyFiltersAct({ skipRequest: true }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getOccupancyAct())
  }, [dispatch, selectedOperator, selectedCarParks])

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('OccupancyContainer.occupancy')}
      pageTitle={t('OccupancyContainer.occupancy')}
    >
      <ErrorAlertContainer errors={[errGettingOccupancy, errGettingCarParks]} />
      <PageGrid>
        <Grid item xs={12}>
          <OccupancyFilterCard />
        </Grid>
        <Grid item xs={12}>
          <OccupancyTableCard />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default OccupancyContainer

import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Paper, Tabs, Tab } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'

import { QUERY_PARAMS } from 'routes/routes'
import { getQueryString, getParsedQueryParam } from 'util/core'
import tabConfig from 'components/CarPark/tabConfig'
/* -------------------------------------------------------------------------- */

const CarParkTabBar = () => {
  const { t } = useTranslation()
  let history = useHistory()
  const location = useLocation()
  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  const [tab, setTab] = useState(0)

  useEffect(() => {
    const tabNum = tabConfig.findIndex((tab) => tab.path === location.pathname)
    setTab(tabNum)
  }, [location.pathname])

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  })

  const handleChange = (_, newTab) => {
    setTab(newTab)
    const newPath = tabConfig[newTab].path
    history.replace({
      pathname: newPath,
      search: getQueryString({
        [QUERY_PARAMS.UUID]: uuid,
      }),
    })
  }

  return (
    <Paper>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label='Tabs'
        indicatorColor='primary'
        variant='scrollable'
        textColor='primary'
      >
        {tabConfig.map((tab, index) => (
          <Tab
            key={uniqueId()}
            label={
              // i18next-extract-mark-context-next-line ["info", "barriers", "offers", "tickets", "openings"]
              t('CarParkTabBar.tabs', { context: tab.label })
            }
            {...a11yProps(index)}
            style={{ textTransform: 'none' }}
          />
        ))}
      </Tabs>
    </Paper>
  )
}

export default CarParkTabBar

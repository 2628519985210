import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Tooltip, makeStyles } from '@material-ui/core'
import { Person as PersonIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons'

import CircularProgressOverlay from 'fragments/CircularProgressOverlay'
import { logOutUserAct } from 'redux/Actions'
import { isPending } from 'redux/Status'
import { APP } from 'routes/routes'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 22.5 + theme.spacing(2),
  },
  menuEntry: {
    fontSize: theme.typography.pxToRem(18),
  },
  iconPerson: {
    color: '#575756',
  },
  iconExit: {
    color: '#FF7285',
  },
  paper: {
    borderRadius: 15,
  },
}))

const UserMenu = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { statusLoggingOut } = useSelector((state) => state.auth)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(logOutUserAct())
    handleClose()
  }

  return (
    <>
      {isPending(statusLoggingOut) && <CircularProgressOverlay />}

      <Tooltip title={t('UserMenu.userMenu')} placement='bottom'>
        <IconButton
          aria-label={t('UserMenu.userMenu')}
          aria-controls='user-menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <PersonIcon className={classes.iconPerson} />
        </IconButton>
      </Tooltip>
      <Menu
        id='user-menu-appbar'
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem component={Link} to={APP.USER_PROFILE} onClick={handleClose} divider>
          <ListItemIcon className={classes.listItemIcon}>
            <PersonIcon className={classes.iconPerson} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.menuEntry }}>{t('UserMenu.profile')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppIcon className={classes.iconExit} />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.menuEntry }}>{t('UserMenu.logOut')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu

import axiosInstance from 'api/axiosConfig'

import { PATH_SEGMENTS_ADMINS, RES_ADMINS, REQ_ADMINS, PARAMS_ADMINS } from 'api/adminMgmt/adminMgmtModels'
import { pack } from 'api/adminMgmt/adminMgmtUnpackers'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

export const activateAdminReq = (password, token) => {
  const url = PATH_SEGMENTS_ADMINS.ACTIVATION
  const { NEW_PASSWORD, TOKEN } = REQ_ADMINS
  const data = {
    [NEW_PASSWORD]: password,
    [TOKEN]: token,
  }
  return axiosInstance.post(url, data).then((res) => {
    const { data } = res
    return data
  })
}

export const getAdminsReq = (operatorUuid) => {
  const { OPERATOR_UUID } = PARAMS_ADMINS
  const qs = getQueryString({
    [OPERATOR_UUID]: operatorUuid,
  })
  return axiosInstance.get(`${PATH_SEGMENTS_ADMINS.ADMINS}${qs}`).then((res) => {
    const { data: admins } = res
    return admins
  })
}

export const getAdminByUuidReq = (uuid) => {
  return axiosInstance.get(`${PATH_SEGMENTS_ADMINS.ADMINS}/${uuid}`).then((res) => {
    const { data: admin } = res
    return admin
  })
}

export const createAdminReq = (adminCreatable) => {
  const url = PATH_SEGMENTS_ADMINS.ADMINS
  const data = pack(adminCreatable)

  return axiosInstance.post(url, data).then((res) => {
    const { data } = res
    return data
  })
}

export const editAdminReq = (uuid, adminUpdatable) => {
  const url = `${PATH_SEGMENTS_ADMINS.ADMINS}/${uuid}`
  const data = pack(adminUpdatable)

  return axiosInstance.patch(url, data).then((res) => {
    const { data } = res
    return data
  })
}

export const changeMyProfileReq = ({ languageCode }) => {
  const { ME } = PATH_SEGMENTS_ADMINS
  const { LANGUAGE } = RES_ADMINS
  const url = `${ME}`
  const data = {
    [LANGUAGE]: languageCode,
  }
  return axiosInstance.patch(url, data).then((res) => {
    const { data: user } = res
    return user
  })
}

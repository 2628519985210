import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import PageGrid from 'fragments/Grid/PageGrid'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import { EditButton } from 'fragments/Buttons'
import { getParsedQueryParam, getQueryString } from 'util/core'
import AdminGrid from 'components/Admin/AdminGrid'
import { getAdminByUuidAct, resetAdminAct } from 'redux/Actions'
import { APP, QUERY_PARAMS } from 'routes/routes'
/* -------------------------------------------------------------------------- */

const AdminContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isGettingAdmin, errGettingAdmin, lastSuccessDate } = useSelector((state) => state.admin)

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getAdminByUuidAct({ uuid }))
    return () => {
      dispatch(resetAdminAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable
      isLoading={isGettingAdmin}
      helmetTitle={t('AdminContainer.title')}
      pageTitle={t('AdminContainer.title')}
    >
      <ErrorAlertContainer errors={[errGettingAdmin]} />
      <PageGrid justify='flex-end'>
        <AdminGrid />
        <Grid container item justify='space-between' alignItems='flex-start'>
          <LastUpdateInfo lastSuccessDate={lastSuccessDate} />
          <EditButton
            component={Link}
            to={{
              pathname: APP.ADMIN_EDIT,
              search: getQueryString({ [QUERY_PARAMS.UUID]: uuid }),
            }}
          />
        </Grid>
      </PageGrid>
    </BasicPageContainerLoadable>
  )
}

export default AdminContainer

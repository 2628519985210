import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ROLES } from 'constants/generalConstants'
import { hasRole } from 'util/custom'
/* -------------------------------------------------------------------------- */

const HasRole = (props) => {
  const { children, userRole, allowedRoles } = props

  if (hasRole(allowedRoles, userRole)) {
    return <React.Fragment>{children}</React.Fragment>
  }
  return null
}

HasRole.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  userRole: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

HasRole.defaultProps = {
  userRole: '',
}

const mapStateToProps = (extendWith = {}) => ({ auth }) => {
  const {
    user: { role: userRole },
  } = auth
  return { userRole, ...extendWith }
}

export default connect(mapStateToProps())(HasRole)

export const IsTriveParkAdmin = connect(mapStateToProps({ allowedRoles: [ROLES.TP_ADMIN] }))(HasRole)

import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const NoRole = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <h1>{t('NoRole.youHaveNoRole')}</h1>
      <p>{t('NoRole.contactAdmin')}</p>
    </div>
  )
}

export default NoRole

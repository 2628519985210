import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import * as PropTypes from 'prop-types'
import { uniqueId } from 'lodash'
/* -------------------------------------------------------------------------- */
const SelectTextField = (props) => {
  const { label, options, selected, onChange, variant } = props

  return (
    <TextField
      id={uniqueId()}
      select
      label={label}
      value={selected ?? ''}
      onChange={(event) => onChange(event.target.value)}
      fullWidth
      variant={variant}
    >
      {options.map((o) => {
        const { label, value } = o
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}
/* -------------------------------------------------------------------------- */
SelectTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selected: PropTypes.string,
  variant: PropTypes.string,
}

SelectTextField.defaultProps = {
  label: null,
  selected: '',
  variant: 'outlined',
}
export default SelectTextField

import payments from 'api/mock/payments'
import barrierConnections from 'api/mock/barrierConnections'
/* -------------------------------------------------------------------------- */
export default [
  {
    appVersion: '1.1',
    barrierConnections,
    city: 'Demodorf',
    createdAt: '2019-10-01T10:24:00.123',
    creditCardCvc: 532,
    creditCardExpiryDate: '2019-10-31T22:59:59.123',
    creditCardNumber: '************7964',
    creditCardOwnerFirstName: 'Brigitte',
    creditCardOwnerLastName: 'Beispiel',
    deletedAt: null,
    device: 'iPhone 6',
    email: 'brigitte.beispiel@example.com',
    firstName: 'Brigitte',
    GDPRTimestamp: '2019-10-01T10:25:10.123',
    houseNumber: 1,
    isDeleted: false,
    isEmailVerified: true,
    isGDPRAccepted: true,
    isTermsAccepted: true,
    languageCode: 'de',
    lastLogin: '2019-12-01T22:50:30.123',
    lastName: 'Beispiel',
    licensePlate: 'BBBB123',
    osVersion: 'iOS 8.1',
    payments,
    postalCode: 12333,
    street: 'Beispielweg',
    termsTimestamp: '2019-10-01T10:25:35.123',
    updatedAt: '2020-04-02T12:17:40.498',
    uuid: 'zud091',
  },
  {
    appVersion: '1.2',
    barrierConnections,
    city: 'Bruckmosern',
    createdAt: '2019-10-01T10:24:00.123',
    creditCardCvc: 211,
    creditCardExpiryDate: '2019-10-31T22:59:59.123',
    creditCardNumber: '************6978',
    creditCardOwnerFirstName: 'Anton',
    creditCardOwnerLastName: 'Mustermensch',
    deletedAt: null,
    device: 'Samsung Galaxy S9',
    email: 'anton.mustermensch@example.com',
    firstName: 'Anton',
    GDPRTimestamp: '2019-10-01T10:25:10.123',
    houseNumber: 1,
    isDeleted: false,
    isEmailVerified: true,
    isGDPRAccepted: true,
    isTermsAccepted: true,
    languageCode: 'de',
    lastLogin: '2019-12-01T22:50:30.123',
    lastName: 'Mustermensch',
    licensePlate: 'ROLN333',
    osVersion: 'Android 10.2',
    payments,
    postalCode: 12345,
    street: 'Egon-Allee',
    termsTimestamp: '2019-10-01T10:25:35.123',
    updatedAt: '2020-04-02T12:17:40.498',
    uuid: 'klm332',
  },
  {
    appVersion: '1.3',
    barrierConnections,
    city: 'West-Town',
    createdAt: '2019-07-01T10:24:00.123',
    creditCardCvc: 1337,
    creditCardExpiryDate: '2019-11-30T22:59:59.123',
    creditCardNumber: '************1337',
    creditCardOwnerFirstName: 'Dalton',
    creditCardOwnerLastName: 'Dashboard',
    deletedAt: null,
    device: 'iPhone 12',
    email: 'dalton.dashboard@me.com',
    firstName: 'Dalton',
    GDPRTimestamp: '2019-10-01T10:25:10.123',
    houseNumber: 1,
    isDeleted: false,
    isEmailVerified: true,
    isGDPRAccepted: true,
    isTermsAccepted: true,
    languageCode: 'de',
    lastLogin: '2020-04-13T22:50:30.123',
    lastName: 'Dashboard',
    licensePlate: 'DD33',
    osVersion: 'Android 10.2',
    payments,
    postalCode: 12345,
    street: 'Western-Street',
    termsTimestamp: '2019-08-02T11:25:35.123',
    updatedAt: '2020-04-02T12:17:40.498',
    uuid: 'dda1337',
  },
  {
    appVersion: '1.2',
    barrierConnections,
    city: 'Musterstadt',
    createdAt: '2019-10-01T10:24:00.123',
    creditCardCvc: 567,
    creditCardExpiryDate: '2019-10-31T22:59:59.123',
    creditCardNumber: '************3210',
    creditCardOwnerFirstName: 'Maximilian',
    creditCardOwnerLastName: 'Muster',
    deletedAt: '2020-04-02T12:17:40.498',
    device: 'iPhone 11',
    email: 'max.muster@example.com',
    firstName: 'Maximilian',
    GDPRTimestamp: '2019-10-01T10:25:10.123',
    houseNumber: 1,
    isDeleted: true,
    isEmailVerified: true,
    isGDPRAccepted: true,
    isTermsAccepted: true,
    languageCode: 'de',
    lastLogin: '2019-12-01T22:50:30.123',
    lastName: 'Muster',
    licensePlate: 'MAXM333',
    osVersion: 'iOS 11.0',
    payments,
    postalCode: 54321,
    street: 'Musterstraße',
    termsTimestamp: '2019-10-01T10:25:35.123',
    updatedAt: '2020-04-02T12:17:40.498',
    uuid: 'abc120',
  },
  {
    appVersion: '1.2',
    barrierConnections: [],
    city: 'Bruckmosern',
    createdAt: '2019-10-01T10:24:00.123',
    creditCardCvc: 501,
    creditCardExpiryDate: '2019-10-31T22:59:59.123',
    creditCardNumber: '************3210',
    creditCardOwnerFirstName: 'Manuela',
    creditCardOwnerLastName: 'Muster',
    deletedAt: '2020-04-20T21:17:40.498',
    device: 'Samsung Galaxy S10',
    email: 'therealmusterman@example.com',
    firstName: 'Manfred',
    GDPRTimestamp: '2019-10-01T10:25:10.123',
    houseNumber: 12,
    isDeleted: true,
    isEmailVerified: true,
    isGDPRAccepted: true,
    isTermsAccepted: true,
    languageCode: 'de',
    lastLogin: '2019-12-01T22:50:30.123',
    lastName: 'Muster',
    licensePlate: 'MANNI22',
    osVersion: 'Android 9.2',
    payments: [],
    postalCode: 12345,
    street: 'Exempelallee',
    termsTimestamp: '2019-10-01T10:25:35.123',
    updatedAt: '2020-04-02T12:17:40.498',
    uuid: 'def780',
  },
]

import React from 'react'
import { useTranslation } from 'react-i18next'
import * as PropTypes from 'prop-types'

import SelectTextField from 'fragments/Forms/SelectTextField'
import { getStatsViewSelects } from 'util/custom'
/* -------------------------------------------------------------------------- */

const StatsViewSelect = (props) => {
  const { onChange, selected } = props

  const { t } = useTranslation()

  return (
    <SelectTextField
      label={t('StatsViewSelect.label')}
      selected={selected}
      options={getStatsViewSelects(t)}
      onChange={onChange}
      variant='outlined'
    />
  )
}

/* -------------------------------------------------------------------------- */
StatsViewSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
}

StatsViewSelect.defaultProps = {
  selected: null,
}

export default StatsViewSelect

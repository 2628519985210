import React from 'react'
import * as PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import useMobile from 'hooks/useMobile'
/* -------------------------------------------------------------------------- */

const PageGrid = ({ children, ...rest }) => {
  const isMobile = useMobile()

  return (
    <Grid container spacing={isMobile ? 2 : 6} {...rest}>
      {children}
    </Grid>
  )
}

PageGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

PageGrid.defaultProps = {
  children: null,
}

export default PageGrid

import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles(() => ({
  loadingOverlay: {
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.6)',
    zIndex: 1150,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loadingSpinner: {
    zIndex: 99,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const CircularProgressOverlay = () => {
  const classes = useStyles()

  return (
    <div className={classes.loadingOverlay}>
      <CircularProgress className={classes.loadingSpinner} />
    </div>
  )
}

export default CircularProgressOverlay

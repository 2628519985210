import { BODY_SALES } from 'api/sales/salesModels'
import { arrayToObject, getMonthLabel, months } from 'util/core'
/* -------------------------------------------------------------------------- */

export const unpackSales = (sales) => {
  const salesPerCarPark = sales.map(unpackSalesPerCarPark)
  const { month, year } = getAccumulatedTransactionsPerYear(salesPerCarPark)
  return {
    salesPerCarPark,
    salesAllCarParks: {
      month,
      year,
    },
  }
}

const unpackSalesPerCarPark = (obj) => {
  const { CAR_PARK_NAME, CAR_PARK_UUID, TRANSACTIONS_ARR } = BODY_SALES
  const transactions = obj?.[TRANSACTIONS_ARR] || []
  const transactionsUnpacked = transactions.map(unpackTransaction)
  return {
    carParkName: obj?.[CAR_PARK_NAME],
    carParkUuid: obj?.[CAR_PARK_UUID],
    month: arrayToObject(transactionsUnpacked, 'month'),
    year: getAccumulatedTransactions(transactionsUnpacked),
  }
}

const unpackTransaction = (t) => {
  const {
    FEES_SUM_IN_CENT,
    INDEX,
    REFUNDS_COUNT,
    REFUNDS_SUM_IN_CENT,
    TRANSACTIONS_COUNT,
    TRANSACTIONS_SUM_IN_CENT,
  } = BODY_SALES
  const transactionsSumInCent = t?.[TRANSACTIONS_SUM_IN_CENT]
  const feesSumInCent = t?.[FEES_SUM_IN_CENT]
  const refundsSumInCent = t?.[REFUNDS_SUM_IN_CENT]
  return {
    transactionsCount: t?.[TRANSACTIONS_COUNT],
    transactionsSumInCent,
    feesSumInCent,
    refundsCount: t?.[REFUNDS_COUNT],
    refundsSumInCent,
    operatorGrossInCent: transactionsSumInCent - feesSumInCent - refundsSumInCent,
    month: getMonthLabel(t?.[INDEX], 'en'),
  }
}

const getAccumulatedTransactions = (t) => {
  return t.reduce(
    (acc, val) => ({
      transactionsCount: acc.transactionsCount + (val?.transactionsCount || 0),
      transactionsSumInCent: acc.transactionsSumInCent + (val?.transactionsSumInCent || 0),
      feesSumInCent: acc.feesSumInCent + (val?.feesSumInCent || 0),
      refundsCount: acc.refundsCount + (val?.refundsCount || 0),
      refundsSumInCent: acc.refundsSumInCent + (val?.refundsSumInCent || 0),
      operatorGrossInCent: acc.operatorGrossInCent + (val?.operatorGrossInCent || 0),
    }),
    {
      transactionsCount: 0,
      transactionsSumInCent: 0,
      feesSumInCent: 0,
      refundsCount: 0,
      refundsSumInCent: 0,
      operatorGrossInCent: 0,
    },
  )
}

const getAccumulatedTransactionsPerYear = (salesPerCarPark) => {
  const transactionsPerMonth = months('en').map((month) => {
    const transactions = salesPerCarPark.map((transaction) => transaction.month?.[month.value])
    return { month: month.value, ...getAccumulatedTransactions(transactions) }
  })
  return {
    month: arrayToObject(transactionsPerMonth, 'month'),
    year: getAccumulatedTransactions(transactionsPerMonth),
  }
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import InfoEditForm from 'components/CarPark/Info/Edit/InfoEditForm'
import { getCarParkByUuidAct, resetCarParkAct } from 'redux/Actions'
import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
/* -------------------------------------------------------------------------- */

const InfoEditContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const {
    errGettingCarPark,
    errEditingCarPark,
    carPark: { name: carParkName },
  } = useSelector((state) => state.carPark)

  const uuid = getParsedQueryParam(location?.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getCarParkByUuidAct({ uuid }))
    return () => {
      dispatch(resetCarParkAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('InfoEditContainer.carPark')}
      pageTitle={carParkName || t('InfoEditContainer.carPark')}
    >
      <ErrorAlertContainer errors={[errGettingCarPark || errEditingCarPark]} />
      <InfoEditForm />
    </BasicPageContainerLoadable>
  )
}

export default InfoEditContainer

export { validationSchema } from 'components/Operator/validationSchema'

export const initialValues = () => ({
  email: '',
  name: '',
  street: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  phoneNumber: '',
  stripeAccountId: '',
})

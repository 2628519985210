export default (t) => [
  {
    name: 'name',
    label: t('operatorsTableColumns.name'),
  },
  {
    name: 'city',
    label: t('operatorsTableColumns.city'),
  },
]

import { get, isNull } from 'lodash'

import { JS_RUNTIME_ERROR } from 'constants/generalConstants'
import { getKeyByValue } from 'util/core'
import { BACKEND_ERROR_CODES } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

// ----- FORM ERROR VALIDATION

export const errorStringIsRequired = ({ t, value }) =>
  isNull(value) || value === '' ? t('error.formErrors.isRequired') : null

export const errorNumberIsRequired = ({ t, value }) =>
  isNull(value) || isNaN(value) ? t('error.formErrors.isRequired') : null

export const errorStringMinValue = ({ t, value, minValueIncluded }) =>
  value < minValueIncluded ? t('error.formErrors.minValue', { value: minValueIncluded }) : null

/* -------------------------------------------------------------------------- */

export const hasError = (error) =>
  Number.isInteger(get(error, 'httpStatusCode', null)) || Number.isInteger(get(error, 'errorCode', null))

export const unpackError = (error) => {
  const { unspecified } = BACKEND_ERROR_CODES
  const httpStatusCode = error?.response?.status ?? null
  const errorCode = error?.response?.data?.code ?? JS_RUNTIME_ERROR
  const message = errorCode === unspecified ? error?.response?.data?.message ?? null : null
  return {
    httpStatusCode,
    errorCode,
    message,
  }
}

const getErrorCodeMessage = (t, code) =>
  // i18next-extract-mark-context-next-line ["", "unspecified", "duplicateEmail", "missingParameter", "badCredentialsAuthentication", "notDeleted", "disabledAccount", "deletedAccount", "missingEmailVerification", "badPasswordAuthentication", "badTokenAuthentication", "duplicatePaymentMethod", "missingPaymentMethod", "noDefaultPaymentMethod", "defaultPaymentMethodRestricted", "missingCustomer", "missingPaymentIntent", "bookingDeclinedActiveTickets", "invalidLicensePlate", "expiredLicensePlate", "existingAccountNoPasswordMatch", "duplicateBeaconMajor", "manualOpeningDateInFuture", "manualOpeningDateBeforeLastEvent", "manualOpeningDenied", "bookingDeclinedNoPaymentSource"]
  t('error.specificError', { context: getKeyByValue(BACKEND_ERROR_CODES, code) })

export const getErrorMessage = (error, t) => {
  const { httpStatusCode, errorCode, message } = error
  const { unspecified } = BACKEND_ERROR_CODES

  if (errorCode === JS_RUNTIME_ERROR) {
    // Handle JS runtime error
    return t('error.runtimeError')
  } else if (httpStatusCode && errorCode === unspecified) {
    // i18next-extract-mark-context-next-line ["", "400", "401", "403", "404", "500"]
    const httpErrorMessage = t('error.httpError', { context: httpStatusCode })

    if (message) return `${httpErrorMessage}: ${message}`
    return httpErrorMessage
  } else if (httpStatusCode && errorCode) {
    // Print specific and localized error message corresponding to error code
    return t('error.specificErrorBase', {
      errorMessage: getErrorCodeMessage(t, errorCode),
    })
  } else return t('error.unknown')
}

import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { GET_OCCUPANCY, RESET_OCCUPANCY_FILTERS } from 'redux/Types'
import { getOccupancySuccessAct, getOccupancyErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getStatisticsReq } from 'api/requests'
import { toISOStringNoZ } from 'util/core'
/* -------------------------------------------------------------------------- */

function* workerGetOccupancy({ view, skipRequest }) {
  try {
    if (view || skipRequest) return // no backend call for this cases

    const {
      occupancy: {
        filters: { date, interval },
        pagination: { page, rows },
      },
      globalFilters: { selectedOperator, selectedCarParks },
    } = yield select()

    const data = yield call(getStatisticsReq, {
      carParkUuids: selectedCarParks,
      date: toISOStringNoZ(date),
      interval,
      operatorUuid: selectedOperator,
      page,
      rows,
    })
    yield put(getOccupancySuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getOccupancyErrorAct))
  }
}

function* watcherGetOccupancy() {
  yield takeLatest([GET_OCCUPANCY.REQUEST, RESET_OCCUPANCY_FILTERS], workerGetOccupancy)
}

// ----- EXPORT -----
export default function* rootSaga() {
  yield all([fork(watcherGetOccupancy)])
}

import { GET_SALES, RESET_SALES, RESET_SALES_FILTERS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getSalesAct = (params) => ({
  type: GET_SALES.REQUEST,
  page: params?.page,
  rows: params?.rows,
  date: params?.date,
  interval: params?.interval,
})

export const getSalesSuccessAct = (data) => ({
  type: GET_SALES.SUCCESS,
  data,
})

export const getSalesErrorAct = (error) => ({
  type: GET_SALES.ERROR,
  error,
})

export const resetSalesFiltersAct = () => ({
  type: RESET_SALES_FILTERS,
})

export const resetSalesAct = () => ({
  type: RESET_SALES,
})

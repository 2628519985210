import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import TicketsTableCard from 'components/Tickets/TicketsTableCard'
import { getParsedQueryParam } from 'util/core'
import CarParkContainer from 'components/CarPark/CarParkContainer'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import LastUpdateInfo from 'fragments/Misc/LastUpdateInfo'
import PageGrid from 'fragments/Grid/PageGrid'
import { usePrefetch, useGetTicketsQuery } from 'services/tickets/tickets-service'
import debounceFn from 'debounce-fn'
import { RtkQueryError, transformRtkQueryError } from 'services/services'
/* -------------------------------------------------------------------------- */

type RootState = {
  globalFilters: {
    selectedOperator: string
  }
}

export default function CarParkTicketsContainer() {
  const { t } = useTranslation()
  const location = useLocation()
  const carParkUuids = React.useMemo(() => [getParsedQueryParam(location.search, 'uuid') as string], [location.search])
  const { selectedOperator } = useSelector((state: RootState) => state.globalFilters)
  const [page, setPage] = React.useState(0)
  const [rows, setRows] = React.useState(10)
  const [search, setSearch] = React.useState<string>()
  const debouncedSetSearch = debounceFn(setSearch, { wait: 300 })

  const prefetchTickets = usePrefetch('getTickets')
  const { data, fulfilledTimeStamp, isLoading, isFetching, error, refetch } = useGetTicketsQuery(
    {
      carParkUuid: carParkUuids,
      operatorUuid: selectedOperator,
      pageNumber: page,
      pageSize: rows,
      search,
    },
    { pollingInterval: INFREQUENT_UPDATE_TIME_IN_MS },
  )

  const prefetchNextPage = React.useCallback(
    () =>
      prefetchTickets({
        carParkUuid: carParkUuids,
        operatorUuid: selectedOperator,
        pageNumber: page + 1,
        pageSize: rows,
        search,
      }),
    [prefetchTickets, carParkUuids, selectedOperator, search, page, rows],
  )

  React.useEffect(() => {
    setPage(0)
  }, [selectedOperator, search, rows])

  React.useEffect(() => {
    if (!data?.isLastPage) {
      prefetchNextPage()
    }
  }, [data, prefetchNextPage])

  return (
    <CarParkContainer
      title={t('CarParkTicketsContainer.tickets') as string}
      errors={[transformRtkQueryError(error as RtkQueryError)]}
    >
      <PageGrid>
        <Grid item xs={12}>
          <TicketsTableCard
            tickets={data?.content ?? []}
            page={page}
            setPage={setPage}
            rows={rows}
            setRows={setRows}
            totalCount={data?.totalElements}
            isLoading={isLoading}
            search={search}
            onSearchChange={debouncedSetSearch}
          />
        </Grid>
        <Grid item xs={12}>
          <LastUpdateInfo
            lastSuccessDate={fulfilledTimeStamp}
            isUpdating={isFetching}
            reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
            refetch={refetch}
          />
        </Grid>
      </PageGrid>
    </CarParkContainer>
  )
}

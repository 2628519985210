import { ROLES } from 'constants/generalConstants'
import curry from 'lodash/curry'
import isEmpty from 'lodash/isEmpty'
/* -------------------------------------------------------------------------- */

const uncurriedHasRole = (allowedRoles, currentRole) =>
  isEmpty(allowedRoles) || (!isEmpty(allowedRoles) && allowedRoles.includes(currentRole))

/* -------------------------------------------------------------------------- */

export const hasRole = curry(uncurriedHasRole)

export const isTriveParkAdmin = hasRole([ROLES.TP_ADMIN])

export const isOperatorAdmin = hasRole([ROLES.OP_ADMIN])

export const isOperatorManager = hasRole([ROLES.OP_MANAGER])

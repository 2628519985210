import yup from 'util/yup'
import { BARRIER_STATE_VALUES } from 'api/carParkMgmt/carParkMgmtModels'
/* -------------------------------------------------------------------------- */

const MAX_BEACON_MINOR = 9223372036854776000

export const validationSchema = yup.object().shape({
  name: yup.string(),
  carParkUuid: yup.string().required(),
  isEntrance: yup.boolean().required(),
  beaconMinor: yup
    .number()
    .typeError()
    .min(0)
    .max(MAX_BEACON_MINOR)
    .integer()
    .required()
    .when('isEntrance', {
      is: (val) => (val === true ? true : false),
      then: yup.number().typeError().min(0).max(MAX_BEACON_MINOR).integer().even().required(),
      otherwise: yup.number().typeError().min(0).max(MAX_BEACON_MINOR).integer().odd().required(),
    }),
  state: yup.string().oneOf(Object.values(BARRIER_STATE_VALUES)).required(),
  updateUrl: yup.string().trim().url().required(),
})

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Formik, Form } from 'formik'
import { Grid, Button, Typography, Box, Link as MuiLink, makeStyles } from '@material-ui/core'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'

import AuthPageContainer from 'layouts/AuthPageContainer/AuthPageContainer'
import PasswordField from 'fragments/Forms/Formik/PasswordField'
import { resetPasswordAct } from 'redux/Actions'
import ROUTES, { QUERY_PARAMS } from 'routes/routes'
import { initialValues, validationSchema } from 'components/Password/resetPasswordForm'
import { getParsedQueryParam, jwtDecodeClaim } from 'util/core'
import { isRejected, isPending, isResolved } from 'redux/Status'
import { TOKEN_CLAIMS } from 'api/backendConstants'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '20px',
    textTransform: 'none',
    color: '#fff',
  },
}))

const ResetPasswordContainer = () => {
  const { statusResettingPassword } = useSelector((state) => state.account)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const emailHistory = location.state?.email
  const token = getParsedQueryParam(location.search, QUERY_PARAMS.TOKEN)
  const email = jwtDecodeClaim(token, TOKEN_CLAIMS.EMAIL)

  useEffect(() => {
    if (isRejected(statusResettingPassword)) {
      enqueueSnackbar(t('ResetPasswordContainer.error'), { variant: 'error' })
    }
  }, [enqueueSnackbar, statusResettingPassword, t])

  const handleSubmit = (values) => dispatch(resetPasswordAct({ password: values.password, token }))

  const form = () => (
    <Formik
      initialValues={initialValues(emailHistory)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const { isValidating, isSubmitting, isValid, dirty } = formikProps
        return (
          <Form>
            <Grid container justify='center' spacing={3}>
              <Grid item xs={10} sm={8}>
                <Typography variant='body1'>
                  {t('ResetPasswordContainer.resetInfo')}
                  <Box fontWeight='fontWeightBold'>{email}</Box>
                </Typography>
                <Grid container spacing={1} alignItems='flex-end'>
                  <Grid item>
                    <LockOutlinedIcon />
                  </Grid>
                  <Grid item xs>
                    <PasswordField
                      name='password'
                      autoComplete='new-password'
                      label={t('ResetPasswordContainer.password')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={10} sm={8}>
                <Button
                  type='submit'
                  disabled={isValidating || isSubmitting || !isValid || !dirty}
                  className={classes.button}
                  color='primary'
                  variant='contained'
                  fullWidth
                >
                  {t('ResetPasswordContainer.resetPassword')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )

  const successInfo = () => (
    <Grid container justify='center' spacing={3}>
      <Grid item xs={10} sm={8}>
        <Typography variant='h5'>{t('ResetPasswordContainer.successTitle')}</Typography>
      </Grid>
      <Grid item xs={10} sm={8}>
        <Typography variant='body1'>{t('ResetPasswordContainer.successInfo')}</Typography>
      </Grid>
      <Grid container justify='flex-end' item xs={10} sm={8}>
        <MuiLink component={RouterLink} to={{ pathname: ROUTES.AUTH.SIGN_IN, state: { email } }}>
          {t('ResetPasswordContainer.operatorLogin')}
        </MuiLink>
      </Grid>
    </Grid>
  )

  return (
    <AuthPageContainer isLoading={isPending(statusResettingPassword)}>
      {isResolved(statusResettingPassword) ? successInfo() : form()}
    </AuthPageContainer>
  )
}

export default ResetPasswordContainer

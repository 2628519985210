import { createApi } from '@rtk-incubator/rtk-query'

import { getBaseQuery } from 'services/baseQuery'
import { Page } from 'services/services'
import { Ticket } from 'services/tickets/tickets-service'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

type Barrier = {
  beaconMajor: number
  beaconMinor: number
  carParkName: string
  carParkUuid: string
  createdAt: string
  isBeaconAlive: boolean
  isEntrance: boolean
  isMock: boolean
  isOnline: boolean
  isRebootAllowed: boolean
  isSecretGenerationAllowed: boolean
  isSshTunnelEnabled: boolean
  isUpdateAllowed: boolean
  lastSuccessfulBeaconCheckAt: string
  lastUpdateCheckAt: string
  macAddress: string
  name: string
  operatorUuid: string
  serviceUuid: string
  softwareVersion: string
  state: string
  updateUrl: string
  updatedAt: string
  uuid: string
}

export type BarrierOpeningsStatus =
  | 'NO_APP_USER'
  | 'BLE_SUCCESS'
  | 'PERMISSION_DENIED'
  | 'UPDATE_TICKET_FAILED'
  | 'REMOTE_SUCCESS'
  | 'REMOTE_FAILURE'
  | 'MANUAL_UNSPECIFIED'
  | 'MANUAL_BARRIER_ERROR'
  | 'MANUAL_APP_ERROR'
  | 'MANUAL_NO_PERMISSION'

export type BarrierOpeningsType = 'FOREIGN' | 'BLE' | 'REMOTE' | 'MANUAL'

type BarrierOpeningsQueryArgs = {
  before?: string
  carParkUuid?: string[]
  operatorUuid?: string
  pageNumber?: number
  pageSize?: number
  type?: BarrierOpeningsType[]
}

export type BarrierOpening = {
  adminUuid: string
  barrier: Barrier
  barrierUuid: string
  carParkUuid: string
  createdAt: string
  isEntrance: boolean
  manualOpeningAt: string
  processEndAt: string
  processStartAt: string
  status: BarrierOpeningsStatus
  ticket: Ticket
  ticketUuid: string
  type: BarrierOpeningsType
  usersInside: number
  uuid: string
}

function getBarrierOpeningsQuery(queryArgs: BarrierOpeningsQueryArgs) {
  const {
    before,
    carParkUuid,
    operatorUuid,
    pageNumber = 0,
    pageSize = 10,
    type = ['BLE', 'MANUAL', 'REMOTE'],
  } = queryArgs

  const params = getQueryString({
    before,
    carParkUuid,
    operatorUuid,
    pageNumber,
    pageSize,
    type,
  })

  return {
    url: params,
  }
}

export const barrierOpeningsApi = createApi({
  reducerPath: 'barrierOpeningsApi',
  keepUnusedDataFor: 1000 * 60 * 5, // 5 Minutes
  baseQuery: getBaseQuery('barrier-openings'),
  endpoints: (builder) => ({
    getBarrierOpenings: builder.query<Page<BarrierOpening[]>, BarrierOpeningsQueryArgs>({
      query: getBarrierOpeningsQuery,
    }),
  }),
})

export const {
  endpoints, // for use with class components
  useGetBarrierOpeningsQuery,
  usePrefetch,
} = barrierOpeningsApi

import createSagaMiddleware from 'redux-saga'
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import { serviceReducers, serviceMiddlewares } from 'services/services'
import rootReducer from 'redux/Reducers'
import rootSaga from 'redux/Sagas'
/* -------------------------------------------------------------------------- */

const reducer = combineReducers({ ...rootReducer, ...serviceReducers })

const devTools = process.env.REACT_APP_DEV_TOOLS === 'true'

const sagaMiddleware = createSagaMiddleware()
const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
  .concat(sagaMiddleware)
  .concat(serviceMiddlewares)

export default function createStore() {
  const store = configureStore({
    reducer,
    devTools,
    middleware,
  })

  sagaMiddleware.run(rootSaga)

  return store
}

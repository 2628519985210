import { APP } from 'routes/routes'
import {
  Admin,
  AdminCreate,
  AdminEdit,
  Admins,
  AdminsOperator,
  AppUser,
  AppUserConnections,
  Payments,
  Barrier,
  BarrierEdit,
  BarrierOpenings,
  Barriers,
  CarParkBarriers,
  CarParkInfo,
  CarParkInfoCreate,
  CarParkInfoEdit,
  CarParkOffers,
  CarParkOpenings,
  CarParks,
  CarParkTickets,
  Clients,
  Home,
  Occupancy,
  Offer,
  OfferCreate,
  OfferEdit,
  Offers,
  Operator,
  OperatorCreate,
  OperatorEdit,
  Operators,
  Profile,
  SalesPerMonth,
  SalesPerYear,
  Settings,
  TicketDetail,
  Tickets,
} from 'pages'
/* -------------------------------------------------------------------------- */

const routes = [
  {
    path: APP.ADMIN,
    element: Admin,
  },
  {
    path: APP.ADMINS,
    element: AdminsOperator,
  },
  {
    path: APP.ADMIN_CREATE,
    element: AdminCreate,
  },
  {
    path: APP.ADMIN_EDIT,
    element: AdminEdit,
  },
  {
    path: APP.APP_USER,
    element: AppUser,
  },
  {
    path: APP.APP_USER_CONNECTIONS,
    element: AppUserConnections,
  },
  {
    path: APP.APP_USER_PAYMENTS,
    element: Payments,
  },
  {
    path: APP.CLIENTS,
    element: Clients,
  },
  {
    path: APP.BARRIER,
    element: Barrier,
  },
  {
    path: APP.BARRIERS,
    element: Barriers,
  },
  {
    path: APP.BARRIER_EDIT,
    element: BarrierEdit,
  },
  {
    path: APP.BARRIER_OPENINGS,
    element: BarrierOpenings,
  },
  {
    path: APP.CAR_PARKS,
    element: CarParks,
  },
  {
    path: APP.CAR_PARK_BARRIERS,
    element: CarParkBarriers,
  },
  {
    path: APP.CAR_PARK_INFO,
    element: CarParkInfo,
  },
  {
    path: APP.CAR_PARK_INFO_CREATE,
    element: CarParkInfoCreate,
  },
  {
    path: APP.CAR_PARK_INFO_EDIT,
    element: CarParkInfoEdit,
  },
  {
    path: APP.CAR_PARK_OFFERS,
    element: CarParkOffers,
  },
  {
    path: APP.CAR_PARK_OPENINGS,
    element: CarParkOpenings,
  },
  {
    path: APP.CAR_PARK_TICKETS,
    element: CarParkTickets,
  },
  {
    path: APP.HOME,
    element: Home,
  },
  {
    path: APP.OCCUPANCY,
    element: Occupancy,
  },
  {
    path: APP.OFFER,
    element: Offer,
  },
  {
    path: APP.OFFERS,
    element: Offers,
  },
  {
    path: APP.OFFER_CREATE,
    element: OfferCreate,
  },
  {
    path: APP.OFFER_EDIT,
    element: OfferEdit,
  },
  {
    path: APP.OPERATOR,
    element: Operator,
  },
  {
    path: APP.OPERATORS,
    element: Operators,
  },
  {
    path: APP.OPERATOR_CREATE,
    element: OperatorCreate,
  },
  {
    path: APP.OPERATOR_EDIT,
    element: OperatorEdit,
  },
  {
    path: APP.PARKMOTIVE_ADMINS,
    element: Admins,
  },
  {
    path: APP.SALES_MONTH,
    element: SalesPerMonth,
  },
  {
    path: APP.SALES_YEAR,
    element: SalesPerYear,
  },
  {
    path: APP.SETTINGS,
    element: Settings,
  },
  {
    path: APP.TICKET,
    element: TicketDetail,
  },
  {
    path: APP.TICKETS,
    element: Tickets,
  },
  {
    path: APP.USER_PROFILE,
    element: Profile,
  },
]

export default routes

import { SET_SELECTED_CAR_PARKS, SET_SELECTED_OPERATOR } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const setSelectedCarParks = (carParksUuids) => ({
  type: SET_SELECTED_CAR_PARKS,
  carParksUuids,
})

export const setSelectedOperatorAct = (operatorUuid) => ({
  type: SET_SELECTED_OPERATOR,
  operatorUuid,
})

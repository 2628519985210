import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { QUERY_PARAMS } from 'routes/routes'
import { getParsedQueryParam } from 'util/core'
import { BasicPageContainerLoadable } from 'fragments/Loadables'
import ErrorAlertContainer from 'fragments/Alerts/ErrorAlertContainer'
import BarrierEditForm from 'components/Barrier/Edit/BarrierEditForm'
import { getBarrierByUuidAct, resetBarrierAct } from 'redux/Actions'
/* -------------------------------------------------------------------------- */

const BarrierEditContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const { errEditingBarrier, errGettingBarrier, isGettingBarrier } = useSelector((state) => state.barrier)

  const uuid = getParsedQueryParam(location.search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getBarrierByUuidAct({ uuid }))
    return () => {
      dispatch(resetBarrierAct())
    }
  }, [dispatch, uuid])

  return (
    <BasicPageContainerLoadable
      helmetTitle={t('BarrierEditContainer.title')}
      pageTitle={t('BarrierEditContainer.title')}
      isLoading={isGettingBarrier}
    >
      <ErrorAlertContainer errors={[errEditingBarrier, errGettingBarrier]} />
      <BarrierEditForm />
    </BasicPageContainerLoadable>
  )
}

export default BarrierEditContainer

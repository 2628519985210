import { GET_APP_USER, DELETE_APP_USER, EXPORT_USER_ACCOUNT, RESET_APP_USER } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getAppUserByUuidAct = (uuid) => ({
  type: GET_APP_USER.REQUEST,
  uuid,
})

export const getAppUserByUuidSuccessAct = (data) => ({
  type: GET_APP_USER.SUCCESS,
  data,
})

export const getAppUserByUuidErrorAct = (error) => ({
  type: GET_APP_USER.ERROR,
  error,
})

export const resetAppUserAct = () => ({
  type: RESET_APP_USER,
})

// ----- DELETE -----

export const deleteAppUserByUuidAct = () => ({
  type: DELETE_APP_USER.REQUEST,
})

// ----- EXPORT -----

export const exportAppUserByUuidAct = () => ({
  type: EXPORT_USER_ACCOUNT.REQUEST,
})

export const exportUserAccountErrorAct = (error) => ({
  type: EXPORT_USER_ACCOUNT.ERROR,
  error,
})

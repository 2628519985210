// ----- HELPER METHOD -----

const getReqTypesObj = (nameOfReq) => {
  const nameUpperCase = nameOfReq.toUpperCase()
  return {
    REQUEST: `${nameUpperCase}_REQUEST`,
    SUCCESS: `${nameUpperCase}_SUCCESS`,
    ERROR: `${nameUpperCase}_ERROR`,
  }
}

/* ----- ACCOUNT MANAGEMENT ------------------------------------------------- */

export const ACTIVATE_ADMIN = getReqTypesObj('ACTIVATE_ADMIN')
export const RESET_PASSWORD_REQUEST = getReqTypesObj('RESET_PASSWORD_REQUEST')
export const RESET_PASSWORD = getReqTypesObj('RESET_PASSWORD')
export const MODIFY_PASSWORD = getReqTypesObj('MODIFY_PASSWORD')
export const RESET_ACCOUNT_MGMT_FLAGS = 'RESET_ACCOUNT_MGMT_FLAGS'

/* ----- ADMINS ------------------------------------------------------------ */

export const GET_ADMINS = getReqTypesObj('GET_ADMIN')

export const GET_ADMIN_BY_UUID = getReqTypesObj('GET_ADMIN_BY_UUID')
export const RESET_ADMIN = 'RESET_ADMIN'

export const CREATE_ADMIN = getReqTypesObj('CREATE_ADMIN')
export const RESET_CREATE_ADMIN = 'RESET_CREATE_ADMIN'

export const EDIT_ADMIN = getReqTypesObj('EDIT_ADMIN')
export const RESET_EDIT_ADMIN = 'RESET_EDIT_ADMIN'

/* ----- AUTHENTICATION ----------------------------------------------------- */

export const LOGIN_USER = getReqTypesObj('LOGIN_USER')
export const LOGOUT_USER = getReqTypesObj('LOGOUT_USER')
export const GET_CURRENT_USER = getReqTypesObj('GET_CURRENT_USER')
export const CHANGE_MY_PROFILE = getReqTypesObj('CHANGE_MY_PROFILE')

/* ----- BARRIERS ----------------------------------------------------------- */

export const GET_BARRIERS = getReqTypesObj('GET_BARRIERS')
export const RESET_BARRIERS_FILTERS = 'RESET_BARRIERS_FILTERS'
export const RESET_BARRIERS = 'RESET_BARRIERS'

export const GET_BARRIER_BY_ID = getReqTypesObj('GET_BARRIER_BY_ID')
export const RESET_BARRIER = 'RESET_BARRIER'
export const EDIT_BARRIER = getReqTypesObj('EDIT_BARRIER')
export const RESET_EDIT_BARRIER = 'RESET_EDIT_BARRIER'

/* ----- CAR PARKS ---------------------------------------------------------- */

export const GET_CAR_PARKS = getReqTypesObj('GET_CAR_PARKS')
export const GET_MINIFIED_CAR_PARKS = getReqTypesObj('GET_MINIFIED_CAR_PARKS')

export const GET_CAR_PARK_BY_UUID = getReqTypesObj('GET_CAR_PARK_BY_UUID')
export const RESET_CAR_PARK = 'RESET_CAR_PARK'

export const EDIT_CAR_PARK = getReqTypesObj('EDIT_CAR_PARK')
export const RESET_EDIT_CAR_PARK = 'RESET_EDIT_CAR_PARK'

export const CREATE_CAR_PARK = getReqTypesObj('CREATE_CAR_PARK')
export const RESET_CREATE_CAR_PARK = 'RESET_CREATE_CAR_PARK'

/* ----- GLOBAL FILTERS ----------------------------------------------------- */
export const SET_SELECTED_CAR_PARKS = 'SET_SELECTED_CAR_PARKS'

/* ----- OCCUPANCY ---------------------------------------------------------- */

export const GET_OCCUPANCY = getReqTypesObj('GET_OCCUPANCY')
export const RESET_OCCUPANCY_FILTERS = 'RESET_OCCUPANCY_FILTERS'

/* ----- OFFERS ------------------------------------------------------------- */

export const GET_OFFERS = getReqTypesObj('GET_OFFERS')
export const RESET_OFFERS = 'RESET_OFFERS'
export const RESET_OFFERS_FILTERS = 'RESET_OFFERS_FILTERS'

export const GET_OFFER_BY_UUID = getReqTypesObj('GET_OFFER_BY_UUID')
export const RESET_OFFER = 'RESET_OFFER'

export const EDIT_OFFER_BY_UUID = getReqTypesObj('EDIT_OFFER_BY_UUID')
export const RESET_EDIT_OFFER = 'RESET_EDIT_OFFER'

export const CREATE_OFFER = getReqTypesObj('CREATE_OFFER')
export const RESET_CREATE_OFFER = 'RESET_CREATE_OFFER'

/* ----- OPERATORS ---------------------------------------------------------- */

export const GET_OPERATORS = getReqTypesObj('GET_OPERATORS')
export const RESET_OPERATORS_FILTERS = getReqTypesObj('RESET_OPERATORS_FILTERS')

export const GET_OPERATOR_BY_UUID = getReqTypesObj('GET_OPERATOR_BY_UUID')
export const RESET_OPERATOR = 'RESET_OPERATOR'

export const CREATE_OPERATOR = getReqTypesObj('CREATE_OPERATOR')
export const RESET_CREATE_OPERATOR = 'RESET_CREATE_OPERATOR'

export const EDIT_OPERATOR = getReqTypesObj('EDIT_OPERATOR')
export const RESET_EDIT_OPERATOR = 'RESET_EDIT_OPERATOR'

/* ----- SALES -------------------------------------------------------------- */

export const GET_SALES = getReqTypesObj('GET_SALES')
export const RESET_SALES = 'RESET_SALES'
export const RESET_SALES_FILTERS = 'RESET_SALES_FILTERS'

/* ----- USER ACCOUNTS ------------------------------------------------------ */

export const GET_APP_USERS = getReqTypesObj('GET_APP_USERS')
export const GET_APP_USER = getReqTypesObj('GET_APP_USER')
export const RESET_APP_USER = 'RESET_APP_USER'
export const DELETE_APP_USER = getReqTypesObj('DELETE_APP_USER')
export const EXPORT_USER_ACCOUNT = getReqTypesObj('EXPORT_USER_ACCOUNT')

/* ----- SAGA ERRORS--------------------------------------------------------- */
export const CATCH_SAGA_ERRORS = 'CATCH_SAGA_ERRORS'

/* ----- STATISTICS --------------------------------------------------------- */
export const GET_GENERAL_STATISTICS = getReqTypesObj('GET_GENERAL_STATISTICS')
export const RESET_GENERAL_STATISTICS = 'RESET_GENERAL_STATISTICS'

/* ----- SETTINGS  ---------------------------------------------------------- */

export const SET_SELECTED_OPERATOR = 'SET_SELECTED_OPERATOR'

import React from 'react'
import { MenuItem, Grid, Typography, makeStyles } from '@material-ui/core'
import { LocalActivity as LocalActivityIcon, Announcement as AnnouncementIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP, QUERY_PARAMS } from 'routes/routes'
import { getQueryString } from 'util/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  icon: {
    paddingRight: theme.spacing(1),
  },
}))

const SearchItem = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { items, isLoading, onSelect } = props

  function renderFeedback(label) {
    return (
      <MenuItem ref={ref}>
        <Grid container alignItems='center' justify='flex-start'>
          <AnnouncementIcon className={classes.icon} color='secondary' />
          <Typography variant='body1'>{label}</Typography>
        </Grid>
      </MenuItem>
    )
  }

  return isLoading
    ? renderFeedback(t('SearchItems.loading'))
    : items.length === 0
    ? renderFeedback(t('SearchItems.noMatch'))
    : items.map((ticket) => (
        <MenuItem
          key={ticket.publicId}
          ref={ref}
          onClick={onSelect}
          component={Link}
          to={{
            pathname: APP.TICKET,
            search: getQueryString({ [QUERY_PARAMS.PUBLIC_ID]: ticket.publicId }),
          }}
        >
          <Grid container alignItems='center' justify='flex-start'>
            <LocalActivityIcon className={classes.icon} color='secondary' />
            <Typography variant='body1'>
              {`${t('SearchItems.ticketId', { id: ticket.publicId })} ${ticket.carParkName}`}
            </Typography>
          </Grid>
        </MenuItem>
      ))
})

export default SearchItem

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { SettingsRemote as SettingsRemoteIcon } from '@material-ui/icons'

import { BasicCardLoadable } from 'fragments/Loadables'
import InfoRows from 'fragments/Cards/InfoRows'
import ToggleBarrierReboot from 'components/Barrier/ToggleBarrierReboot'
import ToggleBarrierSsh from 'components/Barrier/ToggleBarrierSsh'
import ToggleSecretGeneration from 'components/Barrier/ToggleSecretGeneration'
/* -------------------------------------------------------------------------- */
const BarrierControlCard = (props) => {
  const { t } = useTranslation()
  const isEditingBarrier = useSelector((state) => state.barrier.isEditingBarrier)
  const { isEdit } = props

  const rows = [
    {
      label: t('BarrierControlCard.allowReboot'),
      value: <ToggleBarrierReboot isEdit={isEdit} />,
    },
    {
      label: t('BarrierControlCard.allowSsh'),
      value: <ToggleBarrierSsh isEdit={isEdit} />,
    },
    {
      label: t('BarrierControlCard.allowSecret'),
      value: <ToggleSecretGeneration isEdit={isEdit} />,
    },
  ]

  return (
    <BasicCardLoadable
      title={t('BarrierControlCard.title')}
      action={<SettingsRemoteIcon />}
      isLoading={isEditingBarrier}
    >
      <Grid container spacing={1}>
        <InfoRows rows={rows} />
      </Grid>
    </BasicCardLoadable>
  )
}

BarrierControlCard.propTypes = {
  isEdit: PropTypes.bool,
}

BarrierControlCard.defaultProps = {
  isEdit: false,
}

export default BarrierControlCard

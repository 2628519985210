import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import DateFnsUtils from '@date-io/date-fns'
import { LastLocationProvider } from 'react-router-last-location'

import lightTheme from 'themes/lightTheme'
import createStore from 'util/store'

import 'util/i18n'
/* -------------------------------------------------------------------------- */

export default function AppProviders(props: React.PropsWithChildren<{}>) {
  const { children } = props

  return (
    <HelmetProvider>
      <MuiThemeProvider theme={lightTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider store={createStore()}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <Router>
                <LastLocationProvider>{children}</LastLocationProvider>
              </Router>
            </SnackbarProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </HelmetProvider>
  )
}

import { GET_ADMINS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getAdminsAct = () => ({
  type: GET_ADMINS.REQUEST,
})

export const getAdminsSuccessAct = (admins) => ({
  type: GET_ADMINS.SUCCESS,
  admins,
})

export const getAdminsErrorAct = (error) => ({
  type: GET_ADMINS.ERROR,
  error,
})

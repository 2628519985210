import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import CarParkContainer from 'components/CarPark/CarParkContainer'
import OffersTableCard from 'components/Offers/OffersTableCard'
import { getOffersAct } from 'redux/Actions'
import { getParsedQueryParam } from 'util/core'
import { INFREQUENT_UPDATE_TIME_IN_MS } from 'constants/generalConstants'
import { useInterval } from 'hooks'
import { QUERY_PARAMS } from 'routes/routes'
/* -------------------------------------------------------------------------- */

const CarParkOffersContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { errGettingOffers, lastSuccessDate, isReGettingOffers } = useSelector((state) => state.offers)
  const carParkUuid = getParsedQueryParam(search, QUERY_PARAMS.UUID)

  useEffect(() => {
    dispatch(getOffersAct({ carParkUuids: [carParkUuid] }))
  }, [dispatch, carParkUuid])

  useInterval(() => {
    dispatch(getOffersAct({ carParkUuids: [carParkUuid], isReload: true }))
  }, INFREQUENT_UPDATE_TIME_IN_MS)

  return (
    <CarParkContainer
      title={t('CarParkOffersContainer.offers')}
      errors={[errGettingOffers]}
      lastSuccessDate={lastSuccessDate}
      isUpdating={isReGettingOffers}
      reloadIntervalInMs={INFREQUENT_UPDATE_TIME_IN_MS}
    >
      <OffersTableCard />
    </CarParkContainer>
  )
}

export default CarParkOffersContainer

import { GET_CAR_PARKS, GET_MINIFIED_CAR_PARKS } from 'redux/Types'
/* -------------------------------------------------------------------------- */

export const getCarParksAct = (params) => ({
  type: GET_CAR_PARKS.REQUEST,
  page: params?.page,
  rows: params?.rows,
  operatorUuid: params?.operatorUuid,
})

export const getCarParksSuccessAct = (data) => ({
  type: GET_CAR_PARKS.SUCCESS,
  data,
})

export const getCarParksErrorAct = (error) => ({
  type: GET_CAR_PARKS.ERROR,
  error,
})

// GLOBAL FILTERS

export const getMinifiedCarParksAct = (params) => ({
  type: GET_MINIFIED_CAR_PARKS.REQUEST,
  page: params?.page,
  rows: params?.rows,
})

export const getMinifiedCarParksSuccessAct = (data) => ({
  type: GET_MINIFIED_CAR_PARKS.SUCCESS,
  data,
})

export const getMinifiedCarParksErrorAct = (error) => ({
  type: GET_MINIFIED_CAR_PARKS.ERROR,
  error,
})

import axiosInstance from 'api/axiosConfig'
import { PATH_SEGMENTS_AUTH, BODY_AUTH } from 'api/adminAuth/adminAuthModels'
/* -------------------------------------------------------------------------- */

export const loginReq = (email, password) => {
  const { LOGIN } = PATH_SEGMENTS_AUTH
  const { EMAIL, PASSWORD } = BODY_AUTH
  const url = `${LOGIN}`
  const data = {
    [EMAIL]: email,
    [PASSWORD]: password,
  }
  return axiosInstance.post(url, data).then((res) => {
    const { data: user } = res
    return user
  })
}

export const logoutReq = () => {
  const { LOGOUT } = PATH_SEGMENTS_AUTH
  const url = `${LOGOUT}`
  return axiosInstance.post(url)
}

export const getCurrentUserReq = () =>
  axiosInstance.get(`${PATH_SEGMENTS_AUTH.ME}`).then((res) => {
    const { data: user } = res
    return user
  })

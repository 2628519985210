// Status values are defined in PAYMENT_STATUS_VALUES: processing | canceled | succeeded

export default [
  {
    carParkName: 'Testhöhle Fulda',
    updatedAt: '2020-04-23T10:01:00.000',
    offerName: 'Super Saver Offer',
    status: 'processing',
    ticketId: '05-05-19-1122',
    totalAmountInCent: 550,
    transactionId: '********12ab',
  },
  {
    carParkName: 'Testhöhle Fulda',
    updatedAt: '2020-04-19T08:05:00.000',
    offerName: 'Super Saver Offer',
    status: 'succeeded',
    ticketId: '05-05-19-1122',
    totalAmountInCent: 550,
    transactionId: '********7cdb',
  },
  {
    carParkName: 'Testhöhle Fulda',
    updatedAt: '2020-03-01T18:00:00.000',
    offerName: 'Dauerticket',
    status: 'succeeded',
    ticketId: '03-12-19-1234',
    totalAmountInCent: 8000,
    transactionId: '********4449',
  },
  {
    carParkName: 'Testhöhle Fulda',
    updatedAt: '2020-02-10T09:05:00.000',
    offerName: 'Super Saver Offer',
    status: 'succeeded',
    ticketId: '05-04-20-1278',
    totalAmountInCent: 550,
    transactionId: '********xx43',
  },
  {
    carParkName: 'Testhöhle Fulda',
    updatedAt: '2020-02-10T09:01:00.000',
    offerName: 'Sparticket',
    status: 'canceled',
    ticketId: '05-01-20-1560',
    totalAmountInCent: 300,
    transactionId: '********e7d8',
  },
]

import React from 'react'

import AccountStatusChip from 'fragments/IconChips/AccountStatusChip'
import { getCompanyName, isTriveParkAdmin, getRoleString } from 'util/custom'
/* -------------------------------------------------------------------------- */

export default ({ admins, operators, role, t }) => {
  return [
    {
      name: 'uuid',
      label: t('AdminsTableColumns.operator'),
      options: {
        // https://github.com/gregnb/mui-datatables/issues/1576
        display: isTriveParkAdmin(role).toString(),
        customBodyRender: (uuid) => {
          const { operatorUuid, role } = admins.find((a) => a.uuid === uuid)
          return getCompanyName(t, operatorUuid, operators, role)
        },
      },
    },
    {
      name: 'email',
      label: t('AdminsTableColumns.email'),
    },
    {
      name: 'role',
      label: t('AdminsTableColumns.role'),
      options: {
        customBodyRender: (role) => getRoleString(t, role),
      },
    },
    {
      name: 'uuid',
      label: t('AdminsTableColumns.accountStatus'),
      options: {
        customBodyRender: (uuid) => {
          const { isActivated, isDeleted, deletedAt } = admins.find((o) => o.uuid === uuid)
          return <AccountStatusChip isActivated={isActivated} isDeleted={isDeleted} deletedAt={deletedAt} />
        },
      },
    },
  ]
}

import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects'

import { GET_OFFERS, RESET_OFFERS_FILTERS } from 'redux/Types'
import { getOffersSuccessAct, getOffersErrorAct, catchSagaErrorsAct } from 'redux/Actions'
import { getOffersReq } from 'api/requests'
/* -------------------------------------------------------------------------- */

function* workerGetOffers() {
  try {
    const {
      globalFilters: { selectedOperator, selectedCarParks },
      offers: {
        filters: { offerType, isVisible, carParkUuids },
        pagination: { page, rows },
      },
    } = yield select()

    const data = yield call(getOffersReq, {
      // ignore global car parks filter e.g. to load only offers for a single car park in car park detail
      carParkUuids: carParkUuids || selectedCarParks,
      type: offerType,
      isVisible,
      operatorUuid: selectedOperator,
      page,
      rows,
    })

    yield put(getOffersSuccessAct(data))
  } catch (error) {
    yield put(catchSagaErrorsAct(error, getOffersErrorAct))
  }
}

function* watcherGetOffers() {
  yield takeLatest([GET_OFFERS.REQUEST, RESET_OFFERS_FILTERS], workerGetOffers)
}

/* -------------------------------------------------------------------------- */
export default function* rootSaga() {
  yield all([fork(watcherGetOffers)])
}

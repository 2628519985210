import React from 'react'
import MuiDataTable from 'mui-datatables'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { muiDataTableTextLabels } from 'fragments/tables/TableConfig'
/* -------------------------------------------------------------------------- */

const MuiDataTableThemed = (props) => {
  const { data, columns, options } = props
  const { t } = useTranslation()

  return <MuiDataTable data={data} columns={columns} options={{ ...options, ...muiDataTableTextLabels(t) }} />
}

/* ----- SETUP -------------------------------------------------------------- */

MuiDataTableThemed.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape().isRequired,
}

export default MuiDataTableThemed

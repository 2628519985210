import React from 'react'
import { TextField } from 'formik-material-ui'
import MenuItem from '@material-ui/core/MenuItem'
import * as PropTypes from 'prop-types'
import { Field } from 'formik'
/* -------------------------------------------------------------------------- */

const SelectTextField = (props) => {
  const { name, options, variant, label } = props

  return (
    <Field name={name} component={TextField} label={label} select fullWidth variant={variant}>
      {options.map((o) => {
        const { label, value } = o
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </Field>
  )
}
/* -------------------------------------------------------------------------- */
SelectTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  variant: PropTypes.string,
}

SelectTextField.defaultProps = {
  variant: 'outlined',
  label: null,
}
export default SelectTextField

import React from 'react'
import { useTranslation } from 'react-i18next'
import { reverse } from 'lodash'
import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'

import { BasicCardLoadable } from 'fragments/Loadables'
import TicketPaymentRow from 'components/Ticket/TicketPaymentRow'
import DotChip from 'fragments/IconChips/DotChip'
import { Ticket } from 'services/tickets/tickets-service'
/* -------------------------------------------------------------------------- */

type TicketPaymentsCardProps = {
  isLoading: boolean
  ticket?: Ticket
}
const useStyles = makeStyles((theme) => ({
  scroll: {
    minHeight: 130,
    maxHeight: 410,
    overflow: 'auto',
  },
  itemIcon: {
    minWidth: 12 + theme.spacing(1),
  },
}))

export default function TicketPaymentsCard(props: TicketPaymentsCardProps) {
  const { isLoading, ticket } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const payments = ticket?.payments ?? []

  const totalPriceInCent = ticket?.totalPriceInCent ?? 0
  const paidPriceInCent = ticket?.paidPriceInCent ?? 0

  const remainingAmountInCentToPay = totalPriceInCent - paidPriceInCent

  return (
    <BasicCardLoadable title={t('TicketPaymentsCard.title')} isLoading={isLoading}>
      <p>{t('TicketPaymentsCard.totalCost', { cent: totalPriceInCent })}</p>
      <div className={classes.scroll}>
        <List>
          {remainingAmountInCentToPay ? (
            <ListItem>
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                <DotChip color={'#ffb70f'} />
              </ListItemIcon>
              <ListItemText
                primary={t('TicketPaymentsCard.upcomingPayment', {
                  date: new Date().toISOString(),
                  amount: remainingAmountInCentToPay,
                })}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          ) : null}
          {reverse([...payments]).map((payment) => (
            <TicketPaymentRow key={payment.uuid} payment={payment} />
          ))}
        </List>
      </div>
    </BasicCardLoadable>
  )
}

import { GET_OPERATORS, RESET_OPERATORS_FILTERS } from 'redux/Types'

/* -------------------------------------------------------------------------- */

export const getOperatorsAct = (params) => ({
  type: GET_OPERATORS.REQUEST,
  page: params?.page ?? null,
  rows: params?.rows ?? null,
})

export const getOperatorsSuccessAct = (operators) => ({
  type: GET_OPERATORS.SUCCESS,
  operators,
})

export const getOperatorsErrorAct = (error) => ({
  type: GET_OPERATORS.ERROR,
  error,
})

export const resetOperatorsFiltersAct = () => ({
  type: RESET_OPERATORS_FILTERS,
})

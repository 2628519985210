import React from 'react'

import MoneyAmount from 'fragments/Misc/MoneyAmount'
import PaymentStatusChip from 'fragments/IconChips/PaymentStatusChip'
/* -------------------------------------------------------------------------- */
export default (t) => [
  {
    name: 'transactionSumInCent',
    label: t('PaymentsTableColumns.amount'),
    options: {
      customBodyRender: (v) => <MoneyAmount valueInCent={v} />,
    },
  },
  {
    name: 'ticketId',
    label: t('PaymentsTableColumns.ticketId'),
  },
  {
    name: 'status',
    label: t('PaymentsTableColumns.status'),
    options: {
      customBodyRender: (v) => <PaymentStatusChip status={v} />,
    },
  },
  {
    name: 'carParkName',
    label: t('PaymentsTableColumns.carParkName'),
  },
  {
    name: 'offerName',
    label: t('PaymentsTableColumns.offerName'),
  },
  {
    name: 'transactionId',
    label: t('PaymentsTableColumns.transactionId'),
  },
  {
    name: 'updatedAt',
    label: t('PaymentsTableColumns.updatedAt'),
    options: {
      customBodyRender: (v) => t('PaymentsTableColumns.dateShortTimeShort', { date: v }),
    },
  },
]

import React from 'react'
import * as PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'
import { Grid, makeStyles } from '@material-ui/core'

import ErrorAlert from 'fragments/Alerts/ErrorAlert'
import { getErrorMessage, hasError } from 'util/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(1),
  },
}))

const ErrorAlertContainer = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { errors } = props

  const renderErrorAlertItem = (e) => {
    const id = uniqueId()
    return hasError(e) ? (
      <Grid key={id} item xs={12}>
        <ErrorAlert key={id} message={getErrorMessage(e, t)} />
      </Grid>
    ) : null
  }

  return errors.length === 0 ? null : (
    <Grid container spacing={2} className={classes.gridContainer}>
      {errors.map((e) => renderErrorAlertItem(e))}
    </Grid>
  )
}

/* -------------------------------------------------------------------------- */
ErrorAlertContainer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default ErrorAlertContainer

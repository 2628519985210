import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, makeStyles } from '@material-ui/core'
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles({
  root: { textTransform: 'none' },
})

const CancelButton = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Button type='button' color='secondary' variant='outlined' classes={{ root: classes.root }} {...props}>
      {t('CancelButton.cancel')}
    </Button>
  )
}

export default CancelButton

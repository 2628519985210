import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import LoginContainer from 'components/Login/LoginContainer'
import ResetPasswordRequestContainer from 'components/Password/ResetPasswordRequestContainer'
import ResetPasswordContainer from 'components/Password/ResetPasswordContainer'
import ActivateAdminContainer from 'components/Admin/Activate/ActivateAdminContainer'
import ROUTES from 'routes/routes'
/* -------------------------------------------------------------------------- */

const AuthRoutes = () => (
  <AuthLayout>
    <Switch>
      <Route exact path={ROUTES.AUTH.RESET_PW_REQUEST}>
        <ResetPasswordRequestContainer />
      </Route>
      <Route exact path={ROUTES.AUTH.RESET_PW}>
        <ResetPasswordContainer />
      </Route>
      <Route exact path={ROUTES.AUTH.ACTIVATE_ADMIN}>
        <ActivateAdminContainer />
      </Route>
      <Route path={[ROUTES.AUTH.SIGN_IN, ROUTES.CATCH_ALL]}>
        <LoginContainer />
      </Route>
    </Switch>
  </AuthLayout>
)

export default AuthRoutes
